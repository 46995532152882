import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { Helmet } from 'react-helmet';

export default function Legal() {
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === '_blank') {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };

  return (
    <>
      <Helmet>
        <title>Safe Haven Security Services - Legal Information | Safe Haven</title>
        <meta name="description" content="Access Safe Haven Security's legal information, including the Privacy Policy, Terms & Conditions, and information on requesting or deleting your personal information." />
        <meta name="keywords" content="Safe Haven Security, legal information, privacy policy, terms and conditions, personal information request, deletion" />
      </Helmet>

      <Box sx={{ paddingBottom: '64px', }}>
        <Container sx={{
          paddingTop: '15vh',
          paddingBottom: '56px',
          display: 'flex',
        }}>
          <Typography
            sx={{ color: '#000', fontWeight: '700', fontSize: { xs: '24px', md: '32px', lg: '32px' }, textAlign: 'center' }}
          >
            Safe Haven Security Services – Legal Information
          </Typography>
        </Container>

        <Container
          sx={{
            backgroundColor: '#ffffff',
          }}
        >
          <Box sx={{
            marginBottom: '56px',
          }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <ListItem alignItems='center'>
                <ListItemText>
                  <Button
                    variant='text'
                    sx={{
                      fontSize: { xs: '18px', md: '24px' },
                      textAlign: 'left',
                      padding: '0',
                      textTransform: 'inherit',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      handleNavigate('/legal/privacy/');
                    }}
                  >
                    Privacy Policy
                  </Button>
                </ListItemText>
              </ListItem>

              <ListItem alignItems='center'>
                <ListItemText>
                  <Button
                    variant='text'
                    sx={{
                      fontSize: { xs: '18px', md: '24px' },
                      textAlign: 'left',
                      padding: '0',
                      textTransform: 'inherit',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      handleNavigate('/legal/affirmation/');
                    }}
                  >
                    Request, Correct, or Delete My Personal Information
                  </Button>
                </ListItemText>
              </ListItem>

              <ListItem alignItems='center'>
                <ListItemText>
                  <Button
                    variant='text'
                    sx={{
                      fontSize: { xs: '18px', md: '24px' },
                      textAlign: 'left',
                      padding: '0',
                      textTransform: 'inherit',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      handleNavigate(
                        '/legal/safe-haven-terms-and-conditions/'
                      );
                    }}
                  >
                    Terms & Conditions for Safe Haven Subscriptions
                  </Button>
                </ListItemText>
              </ListItem>

              <ListItem alignItems='center'>
                <ListItemText>
                  <Button
                    variant='text'
                    sx={{
                      fontSize: { xs: '18px', md: '24px' },
                      textAlign: 'left',
                      padding: '0',
                      textTransform: 'inherit',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      handleNavigate('/terms-and-conditions/');
                    }}
                  >
                    Terms & Conditions for ADT Security Services
                  </Button>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Container>

        {/* <Container maxWidth={"md"} sx={{ minHeight: '25vh' }}>
          <Grid
            container
            sx={{ margin: '10px auto' }}
            flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          >
            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', md: 'initial' } }}>

              <Button
                variant='text'
                sx={{
                  fontSize: { xs: '18px', md: '24px' },
                  textAlign: 'left',
                  padding: '0',
                  textTransform: 'inherit',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  {
                    handleNavigate('/legal/privacy/');
                  }
                }}
              >
                Privacy Policy
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                variant='text'
                sx={{
                  fontSize: { xs: '18px', md: '24px' },
                  textAlign: 'left',
                  padding: '0',
                  textTransform: 'inherit',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  {
                    handleNavigate('/legal/affirmation/');
                  }
                }}
              >
                Request, Correct, or Delete My Personal Information
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                variant='text'
                sx={{
                  fontSize: { xs: '18px', md: '24px' },
                  textAlign: 'left',
                  padding: '0',
                  textTransform: 'inherit',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  {
                    handleNavigate(
                      '/legal/safe-haven-terms-and-conditions/'
                    );
                  }
                }}
              >
                Terms & Conditions for Safe Haven Subscriptions
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                variant='text'
                sx={{
                  fontSize: { xs: '18px', md: '24px' },
                  textAlign: 'left',
                  padding: '0',
                  textTransform: 'inherit',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  {
                    handleNavigate('/terms-and-conditions/');
                  }
                }}
              >
                Terms & Conditions for ADT Security Services
              </Button>
            </Grid>
          </Grid>
        </Container> */}
      </Box>
    </>
  );
}