import { useNavigate } from 'react-router-dom';
import './index.scss';
import { Box, Container, Stack } from '@mui/system';
import { Button, Grid } from '@mui/material';

export default function SideBySide(props: any) {
    const { leftContent, rightContent, leftSize = 6, rightSize = 6 } = props;
    const navigate = useNavigate();
    const handleNavigate = (route: string, location: string | null = null) => {
        if (location === '_blank') {
            window.open(route, location);
        } else {
            navigate(route);
        }
    };

    return (
        <Grid container className="" sx={{}}>
            <Grid item xs={12} lg={leftSize} sx={{ paddingRight: '24px', display: 'flex' }}>
                {leftContent}
            </Grid>
            <Grid item xs={12} lg={rightSize} sx={{ paddingLeft: '24px', display: 'flex', alignItems: 'center', }}>
                {rightContent}
            </Grid>
        </Grid>
    );
}