import { Box, Container, Stack } from "@mui/system";
import { Button, Grid, Link, Typography } from "@mui/material";
import roundPointFam from "../../../../../Assets/Templates/roundpoint-1.png";
import roundpointVoucher from "../../../../../Assets/Templates/roundpoint-4.png";
import panel from "../../../../../Assets/Templates/roundpoint-55.png";
import familyPic from "../../../../../Assets/Partner/FreedomMortgage/Stock_HappyFamilyHouseADTsign.png";
import installTech from "../../../../../Assets/Partner/FreedomMortgage/Install-Tech.jpg";
import voucher500 from "../../../../../Assets/Templates/voucher-500.png";
import locksmithsLogo from "../../../../../Assets/Templates/Keyme3.jpg";
import keymeCallBtn from "../../../../../Assets/Templates/KeyMe-2.jpg";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "use-http";
import panelBgCropped from "../../../../../Assets/Templates/panel-image-cropped.png"
import LogoBanner from "../../Components/LogoBanner";
import PartnerFooter from "../../Components/PartnerFooter";
import Billboard from "../../Components/Billboard";
import { ReactTagManager } from "react-gtm-ts";
import CookieConsentPopup from "../../../../../Components/CookieConsent/CookieConsentPopup";
import GrayFormBanner from "../../Components/GrayFormBanner";
import ScrollToTopButton from "../../../../../Components/ScrollToTopButton";
import * as Yup from "yup";
import "./index.scss";
import GrayFormBannerExtended from "../../Components/GrayFormBannerExtended";
import AlternatingShowcase from "../../Components/AlternatingShowcase";

interface propsWrapper {
	partnerInfo: PartnerInfo
}

export default function TemplateOne(props: propsWrapper) {
	const { pathname } = useLocation();
	const { partnerInfo } = props;
	const navigate = useNavigate();
	const handleNavigate = (route: string, location: string | null = null) => {
		if (location === '_blank') {
			window.open(route, location);
		} else {
			navigate(route);
		}
	};
	const topFormRef = useRef<HTMLDivElement>(null);
	const [isXxl, setIsXxl] = useState(window.innerWidth > 1900);
	const [showButton, setShowButton] = useState(false);
	const SHOW_SCROLL_BUTTON_THRESHOLD = 300;
	const [voucherImage, setVoucherImage] = useState(roundpointVoucher);
	const formRef = useRef<HTMLDivElement>(null);
	const [partnerPhoneText, setPartnerPhoneText] = useState("Call us: ");
	const [leadCreationStatus, setLeadCreationStatus] = useState<boolean | null>(null);
	const [billboardText, setBillboardText] = useState('Helping to keep your home & family safe');
	const [phoneNumber, setPhoneNumber] = useState('(877) 664-0673');
	const bottomFormRef = useRef<HTMLDivElement>(null);
	const [logoBannerType, setLogoBannerType] = useState('default');
	const [grayFormBanner, setGrayFormBanner] = useState(false);
	const [grayFormBannerExtended, setGrayFormExtendedBanner] = useState(false);
	const [installationCharge, setInstallationCharge] = useState('$149.99');
	const [monthlyRate, setMonthlyRate] = useState('$55.99');
	const [quoteButtonText, setQuoteButtonText] = useState('GET A FREE QUOTE');

	const openInNewTab = (url: string) => {
		window.open(url, "_blank", "noreferrer");
	};

	const onconsent = () => {
		console.log("consent given");
		ReactTagManager.action({
			event: "pw_page_view",
			data: {
				page_path: "/partners/pw-home-security",
				page_title: document.title,
			},
		});
	};

	useEffect(() => {
		const scrollListener = () => {
			setShowButton(window.scrollY > SHOW_SCROLL_BUTTON_THRESHOLD);
		};
		const hasConsent = localStorage.getItem("cookie-consent") === "true";
		if (hasConsent) {
			ReactTagManager.action({
				event: "pw_page_view",
				data: {
					page_path: "/partners/pw-home-security",
					page_title: document.title,
				},
			});
		}
		window.addEventListener("scroll", scrollListener);
		return () => window.removeEventListener("scroll", scrollListener);
	}, []);

	useEffect(() => {
		if (partnerInfo.CampaignId === "3723") {
			setPartnerPhoneText("Call us: ");
		}
	}, [partnerInfo]);

	useEffect(() => {
		if (pathname === '/partners/porch-leads') {
			setLogoBannerType('default');
			setGrayFormExtendedBanner(true);
			setBillboardText('Porch Agent Callback Form');
			setInstallationCharge('$99.00');
		} else if (pathname === '/partners/porch') {
			setGrayFormBanner(true);
			setLogoBannerType('button');
			setBillboardText('Helping to keep your home & family safe');
			setPhoneNumber('(877) 664-0673');
		} else if (pathname === '/partners/my-vu') {
			setGrayFormBanner(true);
		} else if (pathname === '/partners/pw-home-security') {
			setGrayFormBanner(true);
			setLogoBannerType('button');
		} else if (pathname === '/partners/roundpoint-email') {
			setGrayFormBanner(true);
			setPhoneNumber('(877) 850-1518');
			setMonthlyRate('$63.99');
			setLogoBannerType('logoButton')
			setQuoteButtonText('GET A FREE QUOTE');
		} else if (pathname === '/partners/key-me') {
			setGrayFormBanner(true);
			setPhoneNumber('(877) 355-8440');
			setMonthlyRate('$63.99');
			setLogoBannerType('button')
		} else if (pathname === '/partners/roundpoint-offer') {
			setGrayFormBanner(true);
			setPhoneNumber('(877) 850-1995');
			setMonthlyRate('$63.99');
			setLogoBannerType('logoButton')
		}

		if (pathname === "/partners/pw-home-security" || pathname === "/partners/porch" || pathname === "/partners/porch-leads") {
			setVoucherImage(voucher500);
		}
	}, []);

	const handleScrollToForm = () => {
		if (formRef.current) {
			const elementTop = formRef.current.getBoundingClientRect().top;
			const offsetPosition =
				elementTop + window.pageYOffset - window.innerHeight * 0.2;

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
		}
	};

	return (
		<Box id="template1-wrapper">
			<ScrollToTopButton showButton={showButton}></ScrollToTopButton>

			<LogoBanner
				partnerInfo={partnerInfo}
				variant={logoBannerType}
				phoneText={partnerPhoneText}
				phoneNumber={phoneNumber}
				quoteButtonText={quoteButtonText}
				formRef={formRef}
			/>

			{pathname != "/partners/key-me" && (
				<Billboard
					text={billboardText}
					imageUrl={panelBgCropped}
					buttonClass='blue-rounded-btn'
					buttonText="GET STARTED NOW"
					onClick={() => {
						handleScrollToForm();
					}}
				/>
			)}

			{pathname === "/partners/key-me" && (
				<Container
					maxWidth={"xl"}
					className=""
					sx={{
						color: "#545859",
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<Box>
						<Typography
							sx={{
								font: { xs: 'normal 800 32px/36px SHS Body Font,sans-serif', sm: 'normal 800 44px/54px SHS Body Font,sans-serif' },
								textAlign: { xs: 'center', sm: 'initial' },
								width: "100%",
							}}
						>
							Unlock exclusive savings through KeyMe
						</Typography>
						<Typography
							sx={{
								font: { xs: 'normal 600 24px/32px SHS Body Font,sans-serif', sm: 'normal 600 34px/44px SHS Body Font,sans-serif' },
								textAlign: { xs: 'center', sm: 'initial' },
								margin: { xs: '12px auto', sm: 'initial' },
								width: "100%",
							}}
						>
							And help protect what matters most
						</Typography>
					</Box>

					<Box sx={{ display: "flex" }}>
						<Box
							component="img"
							sx={{
								maxWidth: "100%",
								height: "auto",
							}}
							src={locksmithsLogo}
						/>
					</Box>

					<Box sx={{
						bgcolor: '#0061aa !important',
						borderRadius: '20px !important',
						padding: '24px',
						minWidth: { md: '700px' },
						marginTop: { xs: '16px', sm: 'auto' }
					}}>
						<Typography sx={{ color: '#ffffff', textAlign: 'center', fontWeight: 700, font: { xs: 'normal 800 24px / 34px SHS Body Font,sans-serif', sm: 'normal 800 44px / 54px SHS Body Font,sans-serif' } }}>Call {phoneNumber}</Typography>
						<Typography sx={{ color: '#ffffff', textAlign: 'center', fontWeight: 700, font: { xs: 'normal 600 16px/24px SHS Body Font,sans-serif', sm: 'normal 600 34px/44px SHS Body Font,sans-serif' } }}>to get a Free Quote and Redeem*</Typography>
					</Box>
					<Typography sx={{ color: '#545859', textAlign: 'center', maxWidth: '550px', margin: '16px auto', textSize: '12px', lineHeight: '18px' }}>
						*With $99.00 Customer Installation Charge and purchase of Remote alarm monitoring services package.
						Specific equipment and pricing will vary by package. Early termination fees apply.
						See important Terms and Conditions to this offer{" "}
						<Link sx={{ textDecoration: 'underline' }} title="terms-and-conditions" href="https://mysafehaven.com/terms-and-conditions/">here</Link>.
					</Typography>
				</Container>
			)}

			{grayFormBannerExtended &&
				<Box ref={formRef}>
					<GrayFormBannerExtended partnerInfo={partnerInfo}></GrayFormBannerExtended>
				</Box>

			}

			{grayFormBanner &&
				<Box ref={formRef}>
					<GrayFormBanner partnerInfo={partnerInfo} partnerPhone={phoneNumber}></GrayFormBanner>
				</Box>
			}

			<AlternatingShowcase formRef={formRef}></AlternatingShowcase>

			{pathname !== "/partners/key-me" && (
				<Container maxWidth={"xl"}>
					<Grid
						container
						columnSpacing={{ lg: 4 }}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							margin: "36px 0",
						}}
					>
						<Grid item xs={12} md={6}>
							<Stack className="">
								<Typography
									className=""
									sx={{
										color: "#545859",
										font: 'normal 600 34px/44px SHS Body Font,sans-serif',
									}}
								>
									Get a security system starting at {monthlyRate}/mth*
								</Typography>
								<Typography
									className=""
									sx={{
										color: "#545859",
										font: 'normal 15px/27px SHS Body Font,sans-serif',
									}}
								>
									You may also qualify for a voucher up to $500* for customizing your system with additional equipment.
								</Typography>
								<Box
									component="img"
									sx={{
										maxWidth: "100%",
										height: "auto",
										margin: "0",
									}}
									src={voucherImage}
								/>
								<Typography
									className=""
									sx={{
										color: "#545859",
										font: 'normal 14px/24px SHS Body Font,sans-serif',
									}}
								>
									*With {installationCharge} Customer Installation Charge and purchase of Remote alarm monitoring services package.
									Specific equipment and pricing will vary by package.
									Early termination fees apply. See important Terms and Conditions to this offer {" "}
									<Link
										sx={{ textDecoration: "underline" }}
										href="https://mysafehaven.com/terms-and-conditions"
										onMouseDown={(event) => {
											if (event.button === 1) {
												openInNewTab(`https://mysafehaven.com/terms-and-conditions`);
											}
										}}>
										here
									</Link>.
								</Typography>
							</Stack>
						</Grid>

						<Grid item xs={12} md={6} sx={{}}>
							<Box
								component="img"
								sx={{
									maxWidth: "100%",
									height: "auto",
									margin: '16px auto auto auto',
									display: 'flex'
								}}
								src={panel}
							/>
						</Grid>
					</Grid>
				</Container>
			)}

			<PartnerFooter></PartnerFooter>

			<CookieConsentPopup onconsent={onconsent} />
		</Box>
	);
}
