import { PHONE_NUMBER_MAPPINGS } from "../Constants";

export const fetchContentfulImageId = async (campaignId: string) => {
  const apiKey = process.env.REACT_APP_X_API_KEY;

  if (!apiKey) {
    throw new Error(`Issue validating MSH API credentials.`);
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API}/Partner/ContentfulImageInfo?campaignId=${campaignId}`, {
      headers: {
        'X-API-Key': apiKey
      }
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data: ImageInfo = await response.json();
    return data;

  } catch (error) {
    console.error("Error fetching image information:", error);

    return null;
  }
}

export const fetchContentfulImage = async (assetId: any) => {
  const API_KEY = 'yqJHy0TftcJ6CxdB50Y94IbrP9HU2aSpNjioAE6-Tlc';
  const CONTENTFUL_SPACE = 'qumupcatyn98';

  const contentful = require('contentful')
  const client = contentful.createClient({
    space: CONTENTFUL_SPACE,
    accessToken: API_KEY,
    //Are these two needed?
    //host: "cdn.contentful.com",
    //environment: 'master',
  });

  try {
    const asset = await client.getAsset(assetId);
    const imgUrl = asset.fields.file.url;

    return imgUrl;
  } catch (error) {
    console.error("Error fetching Contentful image:", error);
    // Handle errors (e.g., show a placeholder image, display an error message)
    return null; // Or a default image URL
  }
}

export const fetchPartnerCompanyInfoByPagePath = async (pagePath: string) => {
  const apiKey = process.env.REACT_APP_X_API_KEY;

  if (!apiKey) {
    throw new Error(`Issue validating MSH API credentials.`);
  }

  try {
    const apiResponse = await fetch(`${process.env.REACT_APP_API}/Partner/PartnerCompanyInfo?pagePath=${pagePath}`, {
      headers: {
        'X-API-Key': apiKey
      }
    });

    if (!apiResponse.ok) {
      throw new Error(`Request failed with status ${apiResponse.status}`);
    }

    const data = await apiResponse.json();

    if (data.code === "404") {
      return null;
    } else {
      const parsedContent = JSON.parse(data.content);
      return parsedContent;
    }
  } catch (error) {
    console.error("Error fetching partner information:", error);

    return "{partnerName: \"Default Partner\",partnerId: \"0000\",campaignName: \"Default Campaign\",campaignId: \"0000\",template: \"0\",}";
  }
}

export function getPhoneNumberForPath(pathname: string) {
  console.log(pathname);
  return PHONE_NUMBER_MAPPINGS[pathname] || '+1 (877) 842-0818';
}