import {
  Box,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import './index.scss';
import { Helmet } from 'react-helmet';
import mainimage from '../../Assets/HomeIsConnected/Gear_DRH_Jan 2024.png';
import step1 from "../../Assets/Templates/Step-01 copy.png";
import step2 from "../../Assets/Templates/Step-02 copy.png";
import step3 from "../../Assets/Templates/Step-03 copy.png";
import step4 from "../../Assets/Templates/Step-04 copy.png";
import YoutubeEmbed from '../../Components/YoutubeEmbed';
import HomeIsConnectedForm from '../../Components/Forms/HomeIsConnectedForm';

export default function HomeIsConnected() {

  return (
    <>
      <Helmet>
        <title>Home Is Connected | Safe Haven</title>
        <meta name="description" content="Home is connected page for builder" />
        <meta
          name="keywords"
          content="affirmation submission, submit affirmation, online affirmation request, affirmation form, affirmation service, [your company name] affirmation submission, submit affirmation for [specific purpose], free affirmation request, confidential affirmation submission, secure affirmation platform, how to submit an affirmation online, where to submit an affirmation for free, submit an affirmation for a specific goal, confidential online affirmation service, secure platform for submitting affirmations"
        />
      </Helmet>
      <Box sx={{ width: '100%', maxWidth: '100vw', overflow: 'hidden', background: "#fff" }}>
        <Container maxWidth={'xl'}>
          <Box sx={{ padding: '70px 0', width: '100%' }}>
            <Typography
              sx={{ fontWeight: '700', fontSize: '32px', textAlign: 'center', color: '#55595a', marginBottom: '32px' }}
            >
              Welcome to your new D.R. Horton home!
            </Typography>
            <Typography sx={{ fontSize: '24px', color: '#55595a' }}>
              Safe Haven is the smart home integrator for your new D.R. Horton home. Our job is to set up your <span className="blue-text">Home is Connected®</span> system and complete installation. This includes not only delivering items you’ve paid for in your home purchase, but also our white glove installation and set-up service that includes helping you learn all the features of your new home.
            </Typography>
          </Box>
          <Box
            component='img'
            sx={{
              width: 'auto',
              maxWidth: '100%',
              maxHeight: '75vh',
              margin: 'auto',
            }}
            alt='The house from the offer.'
            src={mainimage}
          />
          <Typography sx={{ fontSize: '12px', textAlign: 'center', color: '#55595a', marginBottom: '32px' }}>
            Package may vary by geographic location. Smartphone only shown to demonstrate Alarm.com app. Phone not included in package.
          </Typography>

          <Grid
            container
            maxWidth={'xl'}
            spacing={5}
            sx={{
              paddingTop: '5vh',
              justifyContent: 'center'
            }}
          >
            <Grid item lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                  height: "auto",
                }}
                alt='The house from the offer.'
                src={step1}
              />
            </Grid>

            <Grid item lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                  height: "auto",
                }}
                alt='The house from the offer.'
                src={step2}
              />
            </Grid>

            <Grid item lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                  height: "auto",
                }}
                alt='The house from the offer.'
                src={step3}
              />
            </Grid>

            <Grid item lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                  height: "auto",
                }}
                alt='The house from the offer.'
                src={step4}
              />
            </Grid>
          </Grid>

          <Box sx={{ margin: { xs: '70px 0px', lg: '70px' }, textAlign: 'center' }}>
            <Typography sx={{ fontSize: '24px', color: '#55595a', textAlign: 'center', marginBottom: '8px!important' }}>
              Speak to someone now from your D.R. Horton Concierge Team.
            </Typography>
            <Link href="tel:877.643.6612" underline="none" sx={{ fontSize: '48px', color: '#66a746' }}>
              877.643.6612
            </Link>
            <Typography sx={{ fontSize: '24px', color: '#55595a', textAlign: 'center', marginTop: '8px!important' }}>
              Or complete the form below and you will be contacted.
            </Typography>
          </Box>

          <HomeIsConnectedForm></HomeIsConnectedForm>

          <Typography
            sx={{ fontWeight: '700', fontSize: '32px', textAlign: 'center', color: '#55595a', marginBottom: '32px' }}
          >
            Learn more about your new smart home!
          </Typography>

          <Box>
            <YoutubeEmbed embedId="4zJfKox07oU?si=6WYbMqJBEb6ouOCv" />
          </Box>

          <Stack sx={{ margin: { xs: '70px 0px', lg: '70px' }, textAlign: 'center' }}>
            <Typography sx={{ fontSize: '24px', color: '#55595a', textAlign: 'center', marginBottom: '8px!important' }}>
              Additional questions?
            </Typography>
            <Link href="tel:877.643.6612" underline="none" sx={{ fontSize: '36px', color: '#66a746' }}>
              877.643.6612
            </Link>
            <Link href="tel:877.643.6612" underline="none" sx={{ fontSize: '24px', marginTop: '12px' }}>
              DRHorton@MySafeHaven.com
            </Link>
          </Stack>
        </Container>
      </Box>


    </>
  );
}
