import "./index.scss";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Link,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { HEADER_HEIGHT } from "../../Constants/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../App/hooks";
import { useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PreHeaderBanner from "./PreHeaderBanner";
import CompanyLogo from "./CompanyLogo";
import { navItems } from "../../Constants/Header";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Logo from "../../Assets/Global/Horiz_Prim_FC.svg";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";

export default function Header(props: any) {
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === "_blank") {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [partnersDropdown, setPartnersDropdown] = useState(false);
  const [customersDropdown, setCustomersDropdown] = useState(false);
  const [careersDropdown, setCareersDropdown] = useState(false);
  const [departmentsDropdown, setDepartmentsDropdown] = useState(false);
  const [supportDropdown, setSupportDropdown] = useState(false);
  const location = useLocation();

  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
  const [scrolled, setScrolled] = useState<boolean>(false);

  const handleMouseEnter = (index: number) => {
    setIsHovered(true);
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleClose = () => {
    setIsHovered(false);
  };

  const generalHeaderDisplay = useAppSelector((state) => state.header.display);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY >= 80) {
        setScrolled(true);
      } else if (window.scrollY < 80) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      {generalHeaderDisplay && (
        <Box sx={{ marginBottom: 7, paddingRight: "0!important", }}>
          {/* <PreHeaderBanner></PreHeaderBanner> */}
          <AppBar
            className="appbar-component"
            onMouseLeave={handleMouseLeave}
            sx={{
              paddingRight: "0!important",
              position: "fixed",
              transition: "0.1s",
              backgroundColor: "#fff",
              borderBottom: scrolled ? '1px solid rgba(0,0,0,.16)' : 'none',
              height: {
                xs: scrolled ? "40px" : "70px",
                sm: scrolled ? "60px" : "90px",
              },
              boxShadow: "none",
            }}
          >
            <Container
              maxWidth={"xl"}
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Toolbar
                disableGutters
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                  maxHeight: { xs: "60px" },
                  width: "100%",
                }}
              >
                {/* Logo */}
                <Box
                  className="header-logo-image"
                  component="img"
                  alt="The house from the offer."
                  src={Logo}
                  sx={{
                    alignContent: "right",
                    maxHeight: { xs: "30px", sm: "50px" },
                    cursor: "pointer",
                  }}
                  onMouseDown={(
                    event: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    if (event.button === 1) {
                      handleNavigate("/about-us", "_blank");
                    }
                  }}
                  onClick={() => {
                    handleNavigate("/");
                  }}
                />

                {/* Desktop header menu */}
                {/* <Box
                  onMouseLeave={handleMouseLeave}
                  sx={{
                    float: "right",
                    minWidth: "100px",
                    display: {
                      xs: "none",
                      lg: "flex",
                    },
                    height: "100%",
                    maxHeight: { xs: "60px", lg: "78px" },
                  }}
                >

                </Box> */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Icons and Quote Button */}
                  <Box sx={{ display: "flex" }}>
                    {navItems.map((item, index) => (
                      <Box
                        key={item.value}
                        className="partners-wrapper"
                        sx={{
                          display: {
                            xs: "none",
                            lg: "flex",
                          },
                          flexDirection: "column",
                        }}
                      >
                        <Button
                          className={`partners-button`}
                          endIcon={
                            <>
                              {item.subMenuItems?.length ? (
                                <ArrowDropDownIcon />
                              ) : null}
                            </>
                          }
                          sx={{
                            height: "100%",
                            "& .MuiButton-endIcon": { margin: "0!important" },
                            "&.partners-button:hover": {
                              backgroundColor: "transparent!important",
                            },
                            padding: "0 16px",
                          }}
                          onClick={() => {
                            item.path && handleNavigate(item.path);
                          }}
                          onMouseDown={(event) => {
                            if (event.button === 1) {
                              item.path && openInNewTab(`${item.path}`);
                            }
                          }}
                          onMouseEnter={() => handleMouseEnter(index)}
                        >
                          <Typography
                            className={`partners-button-text`}
                            sx={{
                              color:
                                location.pathname === item.value
                                  ? "rgb(68, 200, 245)"
                                  : "#00519C",
                              fontWeight: 450,
                              fontSize: "16px",
                              textTransform: "none",
                            }}
                          >
                            {item.label}
                            <i className="underline"></i>
                          </Typography>
                        </Button>
                        {isHovered &&
                          hoveredIndex === index &&
                          item.subMenuItems?.length && (
                            <Box
                              onMouseLeave={handleMouseLeave}
                              style={{
                                top: "100%",
                                position: "absolute",
                                width: "240px",
                                background: "#fff",
                                padding: "10px",
                                boxShadow: "0 0 4px rgba(0,0,0,.2)",
                                boxSizing: "border-box",
                                borderRadius: "3px",
                                zIndex: "999999999",
                              }}
                            >
                              {item.subMenuItems.map((subMenuItem: any) => (
                                <MenuItem
                                  className="partners-dropdown"
                                  sx={{
                                    padding: 0,
                                    minHeight: 32,
                                    zIndex: "999999999",
                                  }}
                                  key={index + subMenuItem.path}
                                  onClick={() => {
                                    handleClose();
                                    handleNavigate(subMenuItem.path);
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#075faa",
                                      fontSize: "16px!important",
                                      fontWeight: "450",
                                      padding: "10px 12px",
                                    }}
                                  >
                                    {subMenuItem.label}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Box>
                          )}
                      </Box>
                    ))}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: { xs: "0", md: "0 16px 0 16px" },
                      }}
                    >
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href="tel:18778420818"
                      >
                        <IconButton
                          aria-label="delete"
                          sx={{
                            marginRight: { md: "0px" },
                            width: { xs: "32px", sm: "48px" },
                            height: { xs: "32px", sm: "48px" },
                            "&:hover>svg": {
                              transform: "scale(1.10)",
                              color: "#999",
                            },
                          }}
                        >
                          <PhoneInTalkIcon
                            sx={{
                              color: "#00519C",
                              padding: "0 2px",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </a>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href="mailto:Service@mysafehaven.com"
                      >
                        <IconButton
                          aria-label="delete"
                          sx={{
                            width: "48px",
                            height: "48px",
                            "&:hover>svg": {
                              transform: "scale(1.10)",
                              color: "#999",
                            },
                          }}
                        >
                          <EmailIcon
                            sx={{
                              color: "#00519C",
                              padding: "0 2px",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </a>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "none", md: "flex" },
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="home-free-quote-btn"
                        sx={{
                          backgroundColor: "#00519C",
                          color: "#ffffff",
                          borderRadius: "30px",
                          height: "45px",
                          maxHeight: "45px",
                          fontSize: "14px",
                          padding: "0 24px",
                          fontFamily: "Rubik",
                          textTransform: "capitalize",
                          "&.home-free-quote-btn:hover": {
                            backgroundColor: "#00519C!important",
                          },
                          "&.home-free-quote-btn>span": {
                            marginLeft: "4px",
                          },
                        }}
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate("/contact-us", "_blank");
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/contact-us");
                        }}
                        endIcon={
                          <ArrowForwardIosIcon
                            id="btn-arrow-slide-right"
                            sx={{ height: "14px", paddingBottom: "1px" }}
                          />
                        }
                      >
                        Get a Free Quote
                      </Button>
                    </Box>
                  </Box>

                  {/* Hamburger menu and drawer for mobile/tablet */}
                  <Box
                    sx={{
                      display: { xs: "flex", lg: "none" },
                      justifyContent: "space-around",
                      marginRight: { xs: "0", sm: "16px", md: "64px" },
                      marginLeft: "0px",
                    }}
                  >
                    <IconButton
                      size="large"
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                      onClick={() => {
                        setDrawerOpen(!drawerOpen);
                      }}
                    >
                      <MenuIcon
                        sx={{
                          width: "32px",
                          height: "32px",
                        }}
                      />
                    </IconButton>

                    <Drawer
                      anchor={"left"}
                      open={drawerOpen}
                      onClose={() => {
                        setDrawerOpen(false);
                      }}
                    >
                      <Box
                        sx={{
                          margin: "1rem",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        className="header-logo-image"
                        component="img"
                        alt="The house from the offer."
                        src={Logo}
                        onMouseDown={(
                          event: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                          if (event.button === 1) {
                            handleNavigate("/about-us", "_blank");
                          }
                        }}
                        onClick={() => {
                          handleNavigate("/");
                        }}
                      ></Box>
                      <Divider></Divider>

                      <Box sx={{ width: { xs: "80vw" } }}>
                        <Box>
                          <Button
                            onClick={() => {
                              {
                                handleNavigate("/");
                                setDrawerOpen(false);
                              }
                            }}
                            className="drawer-button"
                          >
                            Home
                          </Button>
                        </Box>

                        <Box>
                          <Button
                            onClick={() => {
                              {
                                handleNavigate("/about-us");
                                setDrawerOpen(false);
                              }
                            }}
                            className="drawer-button"
                          >
                            Story
                          </Button>
                        </Box>

                        <Box>
                          <Button
                            className="drawer-button"
                            onClick={() => {
                              {
                                handleNavigate("/careers");
                                setDrawerOpen(false);
                              }
                            }}
                          >
                            Careers
                          </Button>
                        </Box>

                        {/* Products */}
                        <Box>
                          <Button
                            className="drawer-button"
                            onClick={() => {
                              {
                                handleNavigate("/?scroll=products");
                                setDrawerOpen(false);
                              }
                            }}
                          >
                            Products
                          </Button>
                        </Box>

                        <Box className="customers-wrapper">
                          <Button
                            className="customers-button drawer-button"
                            endIcon={<ArrowDropDownIcon />}
                            sx={{
                              minWidth: "120px",
                              minHeight: "70px",
                              padding: "8px 16px",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                            onClick={() => {
                              {
                                setDepartmentsDropdown(!departmentsDropdown);
                                setPartnersDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                          >
                            <Typography className="customers-button-text">
                              Departments
                            </Typography>
                          </Button>
                          <Button
                            className="partners-button drawer-button"
                            onClick={() => {
                              {
                                handleNavigate("/solar");
                                setDrawerOpen(false);
                                setDepartmentsDropdown(false);
                                setCustomersDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                            sx={{
                              display: departmentsDropdown
                                ? "flex"
                                : "none!important",
                              minWidth: "120px",
                              minHeight: "50px",
                              padding: "8px 16px",
                              marginLeft: "2rem",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                          >
                            <Typography>Solar</Typography>
                          </Button>
                          <Button
                            className="partners-button drawer-button"
                            onClick={() => {
                              {
                                handleNavigate(
                                  "/partners/homebuilder-partners"
                                );
                                setDrawerOpen(false);
                                setCustomersDropdown(false);
                                setDepartmentsDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                            sx={{
                              display: departmentsDropdown
                                ? "flex"
                                : "none!important",
                              minWidth: "120px",
                              minHeight: "50px",
                              padding: "8px 16px",
                              marginLeft: "2rem",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                          >
                            <Typography className="partners-button-text">
                              Builder
                            </Typography>
                          </Button>
                          <Button
                            className="partners-button drawer-button"
                            onClick={() => {
                              {
                                handleNavigate("/partners");
                                setDrawerOpen(false);
                                setCustomersDropdown(false);
                                setDepartmentsDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                            sx={{
                              display: departmentsDropdown
                                ? "flex"
                                : "none!important",
                              minWidth: "120px",
                              minHeight: "50px",
                              marginLeft: "2rem",
                              padding: "8px 16px",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                          >
                            <Typography className="partners-button-text">
                              Affiliate
                            </Typography>
                          </Button>
                          <Button
                            className="partners-button drawer-button"
                            onClick={() => {
                              {
                                handleNavigate("/partners/agent-referral");
                                setDrawerOpen(false);
                                setCustomersDropdown(false);
                                setDepartmentsDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                            sx={{
                              display: departmentsDropdown
                                ? "flex"
                                : "none!important",
                              minWidth: "120px",
                              marginLeft: "2rem",
                              minHeight: "50px",
                              padding: "8px 16px",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                          >
                            <Typography className="partners-button-text">
                              Realtor
                            </Typography>
                          </Button>
                          <Button
                            className="partners-button drawer-button"
                            onClick={() => {
                              {
                                handleNavigate("/customer-solutions");
                                setDrawerOpen(false);
                                setCustomersDropdown(false);
                                setDepartmentsDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                            sx={{
                              display: departmentsDropdown
                                ? "flex"
                                : "none!important",
                              minWidth: "120px",
                              minHeight: "50px",
                              marginLeft: "2rem",
                              padding: "8px 16px",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                          >
                            <Typography className="partners-button-text">
                              PinPoint
                            </Typography>
                          </Button>
                          <Button
                            className="partners-button drawer-button"
                            onClick={() => {
                              {
                                handleNavigate("/installers");
                                setDrawerOpen(false);
                                setDepartmentsDropdown(false);
                                setCustomersDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                            sx={{
                              display: departmentsDropdown
                                ? "flex"
                                : "none!important",
                              minWidth: "120px",
                              minHeight: "50px",
                              padding: "8px 16px",
                              marginLeft: "2rem",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                          >
                            <Typography>Technicians</Typography>
                          </Button>
                        </Box>

                        {/* Products */}
                        <Box>
                          <Button
                            className="drawer-button"
                            onClick={() => {
                              {
                                handleNavigate("/contact-us");
                                setDrawerOpen(false);
                              }
                            }}
                          >
                            Contact Us
                          </Button>
                        </Box>

                        <Box className="customers-wrapper">
                          <Button
                            className="customers-button drawer-button"
                            endIcon={<ArrowDropDownIcon />}
                            sx={{
                              minWidth: "120px",
                              minHeight: "70px",
                              padding: "8px 16px",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                            onClick={() => {
                              {
                                setSupportDropdown(!supportDropdown);
                                setPartnersDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                          >
                            <Typography className="customers-button-text">
                              Support
                            </Typography>
                          </Button>
                          <Button
                            className="partners-button drawer-button"
                            onClick={() => {
                              {
                                handleNavigate("/customer-support");
                                setDrawerOpen(false);
                                setSupportDropdown(true);
                                setDepartmentsDropdown(false);
                                setCustomersDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                            sx={{
                              display: supportDropdown
                                ? "flex"
                                : "none!important",
                              minWidth: "120px",
                              minHeight: "50px",
                              padding: "8px 16px",
                              marginLeft: "2rem",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                          >
                            <Typography>Customer Support</Typography>
                          </Button>
                          <Button
                            className="partners-button drawer-button"
                            onClick={() => {
                              {
                                handleNavigate(
                                  "/customer-support/?scroll=verifyRep"
                                );
                                setDrawerOpen(false);
                                setSupportDropdown(true);
                                setCustomersDropdown(false);
                                setDepartmentsDropdown(false);
                                setCareersDropdown(false);
                              }
                            }}
                            sx={{
                              display: supportDropdown
                                ? "flex"
                                : "none!important",
                              minWidth: "120px",
                              minHeight: "50px",
                              padding: "8px 16px",
                              marginLeft: "2rem",
                              justifyContent: "flex-start",
                              "& .MuiButton-endIcon": {
                                margin: "0!important",
                              },
                            }}
                          >
                            <Typography className="partners-button-text">
                              Verify Rep
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Drawer>
                  </Box>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </Box>
      )}
    </>
  );
}
