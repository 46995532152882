export const HIDE_PARTNER_LOGO = [
    '/partners/pw-home-security',
    '/partners/porch',
    '/partners/my-vu',

    '/partners/marketing-essentials',
    '/partners/ahp',
    '/partners/y-consulting',
    '/partners/key-me-kiosk',
    '/partners/key-me',
    '/partners/pmh-affiliate',
    '/partners/phh-mortgage',
    '/partners/pmh-sold',
    '/partners/my-home-group',
    '/partners/marketing-essentials',
];

// {HIDE_PARTNER_LOGO.includes(pathname) ? null : <PartnerLogo partnerId={partnerInfo?.partnerId} campaignId={partnerInfo?.campaignId}></PartnerLogo>}

export const BUTTON_LOGO_BANNER = [
    '/partners/marketing-essentials',
    '/partners/quality-builder',
    '/partners/selectquotedigitalreferral',
    '/partners/freedom-csr',
    '/partners/freedom-app',
    '/partners/freedom-acp',
    '/partners/freedom-rehash',
    '/partners/my-protection-hero',
    '/partners/freedom-mortgage-professionals',
    '/partners/select-quote',
    '/partners/freedom-partners',
    '/partners/roundpoint-mortgage'
    , '/partners/freedom-welcome-email'
    , '/partners/freedom-customer-video'
    , '/partners/freedom-welcome-kit'
    , '/partners/roundpoint-marketplace'
    , '/partners/roundpoint'
    , '/partners/freedom-statement'
    , '/partners/atlantic-bay'
    , '/partners/freedom-blog'
    , '/partners/your-freedom-mortgage'
    , '/partners/your-freedom-offer'
    , '/partners/freedom-nurture'
    , '/partners/freedom-employee-offer'
    , '/partners/freedom-employee'
    , '/partners/freedom-rewards'
    , '/partners/freedom'
    , '/partners/bethany-white-group'
    , '/partners/freedom-onboarding'
    , '/partners/freedom-mobile'
    , '/partners/freedom-mortgage-popup'
    , '/partners/prequal-freedom-journey'
    , '/partners/freedom-customer-advocate'
    , '/partners/kinecta'
    , '/partners/freedom-savings'
    , '/partners/pmh-affiliate'
    , '/partners/jocelyn-rivard'];

export const SHOW_AGENT_NAME = [
    '3733',
    '2913',
    '2632',
    '2604',
    '3007',
    '1999',
    '1980',
    '1919',
    '3012',
    '1968',
    '2153',
    '1907',
];

export const HIDE_ADRESS_FIELD = [
    '2751',
    '1702',
    '2000',
    '1974',
    '1962',
    '1910',
    '1812',
    '1800',
    '1795',
    '1755',
    '1807',
    '1791',
    '1785',
    '1769',
    '1702',
    '3050',
    '2071',
    '1818',
    '3156',
    '1761',
];
export const HIDE_COMMENTS_FIELD = [
    '2751',
    '1702',
    '2000',
    '1974',
    '1962',
    '1910',
    '1812',
    '1800',
    '1795',
    '1755',
    '1807',
    '1791',
    '1785',
    '1769',
    '1702',
    '3050',
    '2071',
    '1818',
    '3156',
    '1761',
];
export const HIDE_DOB_FIELD = ["3046", "2238"];
export const HIDE_SSN_FIELD = ["3046", "2238"];

export const SHOW_CRM_ID = ['3007', '2632'];

export const PASSWORD_PROTECTED = ['/partners/y-consulting',];

export const PHONE_NUMBER_MAPPINGS: PhoneNumberMappings = {
    '/partners/urban-wireless-usa': '+1 (877) 667-3008',
    '/partners/my-home-group': '+1 (877) 704-1684',
    '/partners/jocelyn-rivard': '+1 (877) 755-2122',
    '/partners/pmh-affiliate': '+1 (877) 850-0939',
    '/partners/freedom-savings': '+1 (877) 850-1182',
    '/partners/kinecta': '+1 (877) 565-4840',
    '/partners/freedom-customer-advocate': '+1 (877) 850-2009',
    '/partners/prequal-freedom-journey': '+1 (877) 850-0867',
    '/partners/freedom-mortgage-popup': '+1 (877) 850-0644',
    '/partners/freedom-mobile': '+1 (877) 585-0642',
    '/partners/freedom-onboarding': '+1 (877) 850-1378',
    '/partners/bethany-white-group': '+1 (877) 755-2122',
    '/partners/freedom': '+1 (877) 850-1506',
    '/partners/freedom-rewards': '+1 (877) 850-1423',
    '/partners/freedom-employee': '+1 (877) 764-0201',
    '/partners/freedom-employee-offer': '+1 (877) 850-0373',
    '/partners/freedom-nurture': '+1 (877) 850-0757',
    '/partners/your-freedom-offer': '+1 (877) 850-0765',
    '/partners/your-freedom-mortgage': '+1 (877) 850-1185',
    '/partners/freedom-blog': '+1 (877) 850-1361',
    '/partners/atlantic-bay': '+1 (877) 336-0455',
    '/partners/freedom-statement': '+1 (877) 850-1568',
    '/partners/roundpoint': '+1 (877) 850-1472',
    '/partners/roundpoint-marketplace': '+1 (877) 850-1426',
    '/partners/freedom-welcome-kit': '+1 (877) 850-0792',
    '/partners/freedom-customer-video': '+1 (877) 850-2169',
    '/partners/freedom-welcome-email': '+1 (877) 850-2088',
    '/partners/roundpoint-mortgage': '+1 (877) 850-1575',
    '/partners/freedom-partners': '+1 (877) 850-1121',
    '/partners/select-quote': '+1 (877) 355-8505',
    '/partners/freedom-mortgage-professionals': '+1 (877) 842-8547',
    '/partners/my-protection-hero': '+1 (833) 618-0086',
    '/partners/freedom-acp': '+1 (877) 850-0432',
    '/partners/freedom-csr': '+1 (877) 850-1184',
    '/partners/select-quote-digital-referral': '+1 (877) 355-8505',
    '/partners/quality-builder': '+1 (877) 873-2850',
    '/partners/marketing-essentials': '+1 (877) 526-0684',
    '/partners/my-vu': '+1 (816) 768-0565',
};

