import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SetStateAction, useLayoutEffect, useRef, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContactUsForm from "../../Components/Forms/ContactUsForm";
import EmployeeVerification from "../../Components/EmployeeVerification";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { Navigation, Pagination } from "swiper";
import productDemo from "../../Assets/Home/Technician with Homeowners 02 Cropped.png";
import productDemoMobile from "../../Assets/Home/Technician with Homeowners 02_mobile2.png";
import StarIcon from "@mui/icons-material/Star";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  LIST_SECTION,
  PANEL_ONE,
  PANEL_TWO,
  PANEL_THREE,
  SECTION_ONE,
} from "../../Constants/CustomerSupport";
import "./index.scss";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { AWARD_IMAGES, HOMEPAGE_REVIEWS } from "../../Constants";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import "swiper/css";
import "swiper/css/pagination";
import ConnectWithUs from "../../Assets/CustomerSupport/CS-Hero.jpg";

export default function CustomerSupport() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === "_blank") {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };

  const [activePanel, setActivePanel] = useState(0);
  const togglePanelVisibility = (panelIndex: number) => {
    if (panelIndex === activePanel) {
      setActivePanel(0);
    } else if (panelIndex !== activePanel) {
      setActivePanel(panelIndex);
    }
  };
  const verifyRepRef = useRef<HTMLDivElement>(null);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const swiperRef = useRef<SwiperRef | null>(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev(500, true);
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext(500, true);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = (swiper: {
    activeIndex: SetStateAction<number>;
  }) => {
    setActiveSlideIndex(swiper.activeIndex);
    console.log(swiper.activeIndex);
  };

  useLayoutEffect(() => {
    if (searchParams.get("scroll") === "verifyRep") {
      setTimeout(() => {
        verifyRepRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 10); // Small delay
    }
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Customer Support | Safe Haven</title>
        <meta
          name="description"
          content="Get help and support from Safe Haven Security. Find answers to your questions, troubleshoot issues, and learn more about using your security system."
        />
        <meta
          name="keywords"
          content="Safe Haven Security, customer support, help, troubleshooting, security system, questions, contact us, phone number, email, hours of operation"
        />
      </Helmet>
      <Box
        sx={{
          display: { md: "grid" },
          maxWidth: "1700px",
          minHeight: { sx: "100px", md: "752px" },
          margin: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
          }}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              gridColumn: "1",
              gridRow: "1",
              display: "block",
              width: "100%",
              maxWidth: "90%",
              height: "90%",
              margin: "auto",
              transition: "filter 0.5s ease",
            }}
            alt="Hero image of father entering home with kids."
            src={ConnectWithUs}
            onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
              const target = e.target as HTMLImageElement;
              target.style.filter = "blur(0px)";
            }}
          />
          <Box
            sx={{
              gridColumn: "1",
              gridRow: "1",
              width: "100%",
              height: "100%",
              zIndex: 1,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ background: "#ffffff" }}>
        <Container
          maxWidth="xl"
          sx={{ marginTop: { xs: "0", lg: "35px" }, marginBottom: "70px" }}
        >
          <Grid container spacing={{ xs: 0, md: 5 }}>
            <Grid item xs={12} lg={6}>
              <Stack direction={"column"}>{SECTION_ONE}</Stack>
            </Grid>
            {/* <Grid item xs={12} lg={6}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  borderTopLeftRadius: "200px 45%",
                }}
                alt="The house from the offer."
                src={concierge}
              />
            </Grid> */}
            <Grid item xs={12} lg={6}>
              <List sx={{ marginTop: "35px" }}>
                <ListItem alignItems="flex-start" sx={{ paddingLeft: "0" }}>
                  <ListItemAvatar sx={{ marginTop: "0" }}>
                    <LocalPhoneIcon sx={{ color: "#075faa" }}></LocalPhoneIcon>
                  </ListItemAvatar>
                  <ListItemText sx={{ margin: "0 auto" }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#4d4f51",
                      }}
                    >
                      844.413.1920
                    </Typography>
                  </ListItemText>
                </ListItem>

                <ListItem alignItems="flex-start" sx={{ paddingLeft: "0" }}>
                  <ListItemAvatar sx={{ marginTop: "0" }}>
                    <MailOutlineIcon
                      sx={{ color: "#075faa" }}
                    ></MailOutlineIcon>
                  </ListItemAvatar>
                  <ListItemText sx={{ margin: "0 auto" }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#4d4f51",
                      }}
                    >
                      customerservice@mysafehaven.com
                    </Typography>
                  </ListItemText>
                </ListItem>

                <ListItem alignItems="flex-start" sx={{ paddingLeft: "0" }}>
                  <ListItemAvatar sx={{ marginTop: "0" }}>
                    <AccessTimeIcon sx={{ color: "#075faa" }}></AccessTimeIcon>
                  </ListItemAvatar>
                  <ListItemText sx={{ margin: "0 auto" }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#4d4f51",
                      }}
                    >
                      Monday - Friday: 8:00am – 6:00pm CST
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>

        <Box
          ref={verifyRepRef}
          sx={{
            backgroundColor: "rgb(241, 241, 241)",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EmployeeVerification></EmployeeVerification>
        </Box>

        <Box sx={{ backgroundColor: "", padding: "70px 0" }}>
          <Container maxWidth="xl">
            <Grid container spacing={{ xs: 0, lg: 5 }} sx={{}}>
              <Grid item xs={12} lg={6}>
                <Stack direction={"column"}>{LIST_SECTION}</Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack sx={{ alignItems: "center" }}>
                  <Button
                    id="button1"
                    variant={"contained"}
                    className="support-demo-buttons"
                    endIcon={
                      <AddCircleIcon
                        sx={{
                          height: "40px",
                          width: "40px",
                          paddingBottom: "3px",
                        }}
                      />
                    }
                    onClick={() => {
                      togglePanelVisibility(1);
                    }}
                  >
                    Getting Started
                  </Button>

                  {activePanel === 1 && PANEL_ONE}

                  <Button
                    id="button2"
                    variant={"contained"}
                    className="support-demo-buttons"
                    endIcon={
                      <AddCircleIcon
                        sx={{
                          height: "40px",
                          width: "40px",
                          paddingBottom: "3px",
                        }}
                      />
                    }
                    onClick={() => {
                      togglePanelVisibility(2);
                    }}
                  >
                    Once You Log In
                  </Button>

                  {activePanel === 2 && PANEL_TWO}

                  <Button
                    id="button3"
                    variant={"contained"}
                    className="support-demo-buttons"
                    endIcon={
                      <AddCircleIcon
                        sx={{
                          height: "40px",
                          width: "40px",
                          paddingBottom: "3px",
                        }}
                      />
                    }
                    onClick={() => {
                      togglePanelVisibility(3);
                    }}
                  >
                    My ADT
                  </Button>

                  {activePanel === 3 && PANEL_THREE}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box sx={{ backgroundColor: "white" }}>
          <Box sx={{ backgroundColor: "#00519C" }}>
            <Stack sx={{ padding: "95px 0 75px 0 ", textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#98b0d2",
                  fontWeight: "700",
                  fontSize: { xs: "35px", lg: "45px" },
                }}
              >
                QUESTIONS?
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: { xs: "24px", lg: "32px" },
                  color: "#ffffff",
                }}
              >
                CALL US AT 877.842.0818 OR
              </Typography>
              <Box sx={{ marginTop: "1rem" }}>
                <Button
                  className="home-free-quote-btn"
                  sx={{
                    backgroundColor: "#ffffff",
                    color: "#00519C",
                    borderRadius: "30px",
                    height: "45px",
                    maxHeight: "45px",
                    fontSize: "14px",
                    padding: "0 24px",
                    fontFamily: "Rubik",
                    textTransform: "capitalize",
                    "&.home-free-quote-btn:hover": {
                      backgroundColor: "#ffffff",
                    },
                    "&.home-free-quote-btn>span": {
                      marginLeft: "4px",
                    },
                  }}
                  onClick={handleModalOpen}
                  endIcon={
                    <ArrowForwardIosIcon
                      id="btn-arrow-slide-right"
                      sx={{ height: "14px", paddingBottom: "1px" }}
                    />
                  }
                >
                  CLICK HERE
                </Button>
                <Modal
                  open={modalOpen}
                  closeAfterTransition
                  onClose={handleModalClose}
                  className=""
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Slide direction="up" in={modalOpen}>
                    <Box
                      sx={{
                        maxWidth: 900,
                        bgcolor: "#fff",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                        overflowX: "auto",
                        maxHeight: "100vh",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ paddingLeft: "8px" }}>
                          Contact Us
                        </Typography>
                        <Box
                          className="full-width"
                          sx={{ display: "flex", marginRight: 0 }}
                        >
                          <IconButton
                            sx={{ margin: "auto 0 auto auto" }}
                            onClick={() => {
                              handleModalClose();
                            }}
                          >
                            <ClearIcon></ClearIcon>
                          </IconButton>
                        </Box>
                      </Box>
                      <Divider sx={{ margin: "10px" }}></Divider>

                      {!confirmationOpen && (
                        <ContactUsForm
                          setConfirmationOpen={setConfirmationOpen}
                        ></ContactUsForm>
                      )}

                      <Modal
                        open={confirmationOpen}
                        closeAfterTransition
                        onClose={() => {
                          handleConfirmationClose();
                        }}
                        className=""
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Slide direction="up" in={confirmationOpen}>
                          <Container
                            maxWidth={false}
                            sx={{
                              maxWidth: "700px",
                              minHeight: "400px",
                              height: "auto",
                              bgcolor: "#fff",
                              borderRadius: "9px",
                              boxShadow: 24,
                              padding: "0!important",
                            }}
                          >
                            <Box
                              sx={{
                                background: "rgb(18, 96, 168)",
                                width: "100%",
                                minHeight: "64px",
                                borderRadius: "9px 9px 0 0px",
                                padding: "10px 0",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                  textAlign: "center",
                                  color: "#fff",
                                }}
                              >
                                Thank you for contacting Safe Haven!
                              </Typography>
                              <Box
                                sx={{
                                  position: "absolute",
                                  right: "5px",
                                }}
                              >
                                <IconButton
                                  sx={{}}
                                  onClick={() => {
                                    handleConfirmationClose();
                                    handleModalClose();
                                  }}
                                >
                                  <ClearIcon sx={{ color: "#fff" }}></ClearIcon>
                                </IconButton>
                              </Box>
                            </Box>
                            <Stack
                              direction={"row"}
                              sx={{
                                alignItems: "center",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <Box
                                sx={{
                                  paddingLeft: "36px",
                                  minHeight: "350px",
                                  minWidth: "250px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-around",
                                }}
                              >
                                <Box>
                                  <Box sx={{ width: "100%", display: "flex" }}>
                                    <CheckCircleOutlineIcon
                                      sx={{
                                        color: "rgb(18, 96, 168)",
                                        margin: "auto",
                                        height: "4rem",
                                        width: "auto",
                                        marginBottom: "15px",
                                      }}
                                    ></CheckCircleOutlineIcon>
                                  </Box>

                                  <Typography
                                    sx={{ fontSize: "24px", maxWidth: "600px" }}
                                  >
                                    We received your request for more
                                    information and a member of our team will be
                                    in touch with you shortly to answer any
                                    questions you have.
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: "24px 0",
                                    }}
                                  >
                                    <Button
                                      variant={"contained"}
                                      onClick={() => {
                                        handleNavigate("/");
                                      }}
                                      sx={{
                                        minWidth: "100px",
                                        minHeight: "45px",
                                        fontSize: "16px",
                                        textTransform: "none",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Back To Home
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Stack>
                          </Container>
                        </Slide>
                      </Modal>
                    </Box>
                  </Slide>
                </Modal>
              </Box>
            </Stack>
          </Box>

          {/* Awards Carousel Section */}
          <Container
            sx={{
              maxHeight: "500px",
              margin: "88px auto",
              position: "relative",
            }}
          >
            <Swiper
              style={{ height: "100%", width: "100%" }}
              loop={true}
              ref={swiperRef}
              className="about-us-swiper"
              navigation={false}
              spaceBetween={0}
              modules={[Pagination, Navigation]}
              initialSlide={activeIndex}
              onSlideChange={handleSlideChange}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 2,
                },
                900: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
            >
              {AWARD_IMAGES.map((award, index) => (
                <SwiperSlide
                  key={index}
                  className={`about-us-swiper-slide-${index}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    paddingRight: "1px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "300px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        height: "210px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        loading="lazy"
                        sx={{
                          width: "auto",
                          maxHeight: "100%",
                          maxWidth: "250px",
                          cursor: "pointer",
                        }}
                        alt={`Product ${index + 1}`}
                        src={award.image}
                      />
                    </Box>
                    <Typography
                      variant="body2"
                      align="center"
                      sx={{
                        fontSize: "20px",
                        lineHeight: "22px",
                        maxWidth: "250px",
                        height: "70px",
                        paddingTop: "20px",
                      }}
                    >
                      {award.text}
                    </Typography>
                    <Box
                      sx={{
                        position: "absolute",
                        borderLeft: "1px solid #cecece",
                        height: "125px",
                        right: "0",
                        bottom: "45%",
                      }}
                    ></Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: { xs: "50%", sm: "75%", md: "50%" },
                height: "300px",
                background:
                  "linear-gradient(to right, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
                zIndex: 1,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: { xs: "50%", sm: "75%", md: "50%" },
                height: "300px",
                background:
                  "linear-gradient(to left, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
                zIndex: 1,
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "8px",
                zIndex: 2,
              }}
            >
              <Box sx={{ margin: "18px" }}>
                <IconButton
                  className="award-carousel-nav-button"
                  aria-label="delete"
                  onClick={handlePrev}
                  sx={{
                    backgroundColor: "rgb(68,200,245)",
                    minHeight: "50px",
                    minWidth: "50px",
                  }}
                >
                  <WestIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Box>

              <Box sx={{ margin: "18px" }}>
                <IconButton
                  className="award-carousel-nav-button"
                  aria-label="delete"
                  onClick={handleNext}
                  sx={{
                    backgroundColor: "rgb(68,200,245)",
                    minHeight: "50px",
                    minWidth: "50px",
                  }}
                >
                  <EastIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Box>
            </Box>
          </Container>
          {/* Desktop: Reviews Sections */}
          <Box
            sx={{
              display: { xs: "none", lg: "grid" },
              position: "relative",
              marginBottom: "250px",
            }}
          >
            {/* Background Image */}
            <Box
              component="img"
              sx={{
                width: "100%",
                maxWidth: "xl",
                height: "auto",
                margin: "auto",
                display: { xs: "none", lg: "grid" },
                gridColumn: "1",
                gridRow: "1",
                filter: "blur(10px)",
                transition: "filter 0.5s ease",
              }}
              alt="The house from the offer."
              src={productDemo}
              loading="lazy"
              onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
                const target = e.target as HTMLImageElement;
                target.style.filter = "blur(0px)";
              }}
            />

            {/* Dark Tint Overlay */}
            <Box
              sx={{
                width: "100%",
                maxWidth: "xl",
                margin: "auto",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                display: "grid",
                gridColumn: "1",
                gridRow: "1",
                zIndex: 1,
              }}
            />

            {/* Blue Review Side-By-Side */}
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, 10%)",
                color: "#ffffff",
                zIndex: "2",
                width: "100%",
                maxWidth: "1300px",
              }}
            >
              <Grid container className="">
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    backgroundColor: "rgba(0, 39, 78)",
                    padding: {
                      xs: "56px 32px 48px 32px",
                      md: "56px 80px 80px 60px",
                    },
                  }}
                >
                  <Stack sx={{ marginBottom: "8px" }}>
                    <Box>
                      <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                      <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                      <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                      <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                      <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                    </Box>
                  </Stack>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      letterSpacing: "1px",
                      marginBottom: "8px",
                    }}
                  >
                    22,000+ Reviews
                  </Typography>
                  <Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
                    Rated Excellent by our customers, with 4.8 stars from over
                    22,000 reviews, with an A+ BBB rating, we are proud to
                    deliver top-notch home security solutions that exceed
                    expectations.
                  </Typography>
                  <Box sx={{ marginTop: "48px" }}>
                    <Button
                      variant="outlined"
                      className=""
                      sx={{
                        display: "flex",
                        color: "#ffffff",
                        borderColor: "#ffffff",
                        borderWidth: "2px",
                        borderRadius: "30px",
                        height: "50px",
                        minWidth: "150px",
                        fontSize: "12px",
                        letterSpacing: "1px",
                        padding: "0 24px",
                        textTransform: "capitalize",
                      }}
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/contact-us", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/contact-us");
                      }}
                    >
                      CONTACT US
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    padding: "102px 80px 80px 60px",
                    backgroundColor: "rgba(0, 83, 156)",
                    borderTopRightRadius: "30% 45%",
                  }}
                >
                  <Swiper
                    style={{ height: "100%", width: "100%" }}
                    loop={true}
                    className="home-reviews-swiper"
                    navigation={false}
                    pagination={{
                      clickable: true,

                      bulletClass: "home-reviews-carousel-bullet",
                      bulletActiveClass: "home-reviews-carousel-bullet-active",
                    }}
                    spaceBetween={5}
                    modules={[Pagination, Navigation]}
                    slidesPerView={1}
                  >
                    {HOMEPAGE_REVIEWS.map((review, index) => (
                      <SwiperSlide
                        key={index}
                        className={`homepage-reviews-slide-${index}`}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                          paddingRight: "1px",
                          width: "60%",
                        }}
                      >
                        <Stack>
                          <Typography
                            sx={{ fontSize: "18px", lineHeight: "24px" }}
                          >
                            {review.text}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              letterSpacing: "1px",
                              marginTop: "24px",
                            }}
                          >
                            {review.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "rgba(68, 200, 245)",
                              fontWeight: "700",
                            }}
                          >
                            Google Review, 5 Stars
                          </Typography>
                        </Stack>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* Mobile: Reviews Sections */}
          <Box
            sx={{
              display: { xs: "grid", lg: "none" },
              position: "relative",
              paddingBottom: "32px",
              background: "rgba(0, 83, 156)",
            }}
          >
            {/* Background Image */}
            <Box
              component="img"
              sx={{
                width: "100%",
                maxWidth: "100%",
                height: "auto",
                margin: "auto",
                display: { xs: "grid", lg: "none" },
                gridColumn: "1",
                gridRow: "1",
              }}
              alt="The house from the offer."
              src={productDemoMobile}
              loading="lazy"
            />

            {/* Dark Tint Overlay */}
            <Box
              sx={{
                width: "100%",
                maxWidth: "xl",
                margin: "auto",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                display: "grid",
                gridColumn: "1",
                gridRow: "1",
              }}
            />

            <Box
              sx={{
                gridColumn: "1",
                gridRow: "2",
                backgroundColor: "rgba(0, 39, 78)",
                padding: {
                  xs: "56px 32px 48px 32px",
                  md: "56px 80px 80px 60px",
                },
                color: "#fff",
              }}
            >
              <Stack sx={{ marginBottom: "8px" }}>
                <Box>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                </Box>
              </Stack>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: "700",
                  letterSpacing: "1px",
                  marginBottom: "8px",
                }}
              >
                22,000+ Reviews
              </Typography>
              <Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
                Rated Excellent by our customers, with 4.8 stars from over
                22,000 reviews, with an A+ BBB rating, we are proud to deliver
                top-notch home security solutions that exceed expectations.
              </Typography>
              <Box sx={{ marginTop: "48px" }}>
                <Button
                  variant="outlined"
                  className=""
                  sx={{
                    display: "flex",
                    color: "#ffffff",
                    borderColor: "#ffffff",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    height: "50px",
                    minWidth: "150px",
                    fontSize: "12px",
                    letterSpacing: "1px",
                    padding: "0 24px",
                    textTransform: "capitalize",
                  }}
                >
                  CONTACT US
                </Button>
              </Box>
            </Box>

            <Box
              sx={{ backgroundColor: "rgba(0, 39, 78)", minHeight: "350px" }}
            >
              <Box
                sx={{
                  gridColumn: "1",
                  gridRow: "3",
                  backgroundColor: "rgba(0, 83, 156)",
                  borderTopRightRadius: {
                    xs: "50% 60%",
                    sm: "50% 60%",
                    md: "25% 45%",
                  },
                  color: "#fff",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Swiper
                  style={{ height: "100%" }}
                  loop={true}
                  className="home-reviews-swiper mobile-reviews-swiper"
                  navigation={false}
                  pagination={{
                    clickable: true,
                    renderBullet: function (index, className) {
                      return `
                          <span class="${className} mobile-reviews-bullet">
                            <span class="inner-dot"></span> 
                          </span>
                        `;
                    },
                    bulletClass: "home-reviews-carousel-bullet",
                    bulletActiveClass: "home-reviews-carousel-bullet-active",
                  }}
                  spaceBetween={5}
                  modules={[Pagination, Navigation]}
                  slidesPerView={1}
                >
                  {HOMEPAGE_REVIEWS.map((review, index) => (
                    <SwiperSlide
                      key={index}
                      className={`homepage-reviews-slide-${index}`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                        paddingRight: "1px",
                        width: "60%",
                        marginLeft: "0",
                      }}
                    >
                      <Stack
                        sx={{ height: "100%", justifyContent: " flex-start" }}
                      >
                        <Typography
                          sx={{
                            maxWidth: "70%",
                            fontSize: { xs: "12px", sm: "18px", md: "24px" },
                            lineHeight: { xs: "22px", sm: "28px", md: "32px" },
                            minHeight: "225px",
                            maxHeight: "225px",
                            marginTop: "24px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {review.text}
                        </Typography>
                        <Box sx={{ minHeight: "60px" }}>
                          <Typography
                            sx={{
                              fontSize: { xs: "14px", sm: "18px", md: "18px" },
                              lineHeight: {
                                xs: "24px",
                                sm: "28px",
                                md: "28px",
                              },
                              letterSpacing: "1px",
                            }}
                          >
                            {review.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: "12px", sm: "12px", md: "16px" },
                              lineHeight: {
                                xs: "22px",
                                sm: "22px",
                                md: "26px",
                              },
                              color: "rgba(68, 200, 245)",
                              fontWeight: "700",
                            }}
                          >
                            Google Review, 5 Stars
                          </Typography>
                        </Box>
                      </Stack>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
