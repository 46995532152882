import { Box, Container } from '@mui/material';
import './index.scss';
import { Helmet } from 'react-helmet';
import Vimeo from '@u-wave/react-vimeo';
import { useState } from 'react';

const initialVideoAccess = {
  showVideo: false,
  inputValue: "",
};

export default function InsideSalesManagerOverview() {
  const [videoAccess, setVideoAccess] = useState(initialVideoAccess);

  const handleChange = (event: { target: { value: any; }; }) => {
    setVideoAccess({ ...videoAccess, inputValue: event.target.value });
  };

  const handleClick = () => {
    if (videoAccess.inputValue === "ISMoverview2023") {
      setVideoAccess({ ...videoAccess, showVideo: true });
    } else {
      setVideoAccess({ ...videoAccess, inputValue: "" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Inside Sales Manager Overview: Guide to Success | Safe Haven</title>
        <meta
          name="description"
          content="Equip yourself to excel as an inside sales manager. This overview provides valuable insights into key responsibilities, strategies, and best practices for driving sales success."
        />
        <meta
          name="keywords"
          content="inside sales manager, inside sales leadership, B2B sales, lead generation, sales process, customer relationship management, sales funnels, sales metrics, prospecting, sales communication"
        />
      </Helmet>
      <Box sx={{ background: '#fff', width: '100vw' }}>
        <Container maxWidth={"xl"} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: "100%", padding: { sm: '35px 0', md: "70px 0" } }}>
          <Vimeo className="vimeo-player-ISMO" video={"https://player.vimeo.com/video/877261677?h=ea88a3f9df&dnt=1&app_id=122963"} width={"100%"} height={"auto"} controls autoplay />
        </Container>
      </Box>
    </>
  );
}
