import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import culdesacPicture from "../../../Assets/AgentReferral/Wide-neighborhood-1.jpg";
import checkBoxes from "../../../Assets/AgentReferral/MicrosoftTeams-image-2.png";
import respaLogo from "../../../Assets/AgentReferral/RESPA-logo.jpg";
import appImage1 from "../../../Assets/AgentReferral/App-image-01.jpg";
import appImage2 from "../../../Assets/AgentReferral/App-image-02.jpg";
import phoneAndIcons from "../../../Assets/AgentReferral/Phone-and-Icons.png";
import { SetStateAction, useEffect, useRef, useState } from "react";
import AgentReferralForm from "../../../Components/Forms/AgentReferralForm";
import { Helmet } from "react-helmet";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import iphone from "../../../Assets/Rebranding/iPhone 15 blue app_alpha 01 1.png";
import {
  Swiper,
  SwiperRef,
  SwiperSlide,
  Swiper as SwiperType,
} from "swiper/react";
import { Navigation, Pagination } from "swiper";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import "swiper/css";
import "swiper/css/pagination";
import ScrollToTopButton from "../../../Components/ScrollToTopButton";
import { AWARD_IMAGES, HOMEPAGE_REVIEWS } from "../../../Constants";
import builderHero from "../../../Assets/Partner/Realtor-Hero.jpg";
import builderCircle from "../../../Assets/Partner/Realtor-Circle.png";
import productDemo from "../../../Assets/Home/Technician with Homeowners 02 Cropped.png";
import productDemoMobile from "../../../Assets/Home/Technician with Homeowners 02_mobile2.png";
import StarIcon from "@mui/icons-material/Star";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function AgentReferral() {
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === "_blank") {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };

  const [formOpen, setFormOpen] = useState(false);
  const handleFormOpen = () => {
    setFormOpen(true);
  };
  const handleFormClose = () => {
    setFormOpen(false);
  };

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  useEffect(() => {
    if (confirmationOpen) {
      handleFormClose();
    }
  }, [confirmationOpen]);

  const [showSttButton, setShowSttButton] = useState(false);
  const SHOW_SCROLL_BUTTON_THRESHOLD = 300;
  const currentUrl = window.location.href;
  const baseUrl = currentUrl.replace(/\/about-us$/, ""); // Remove /about-us at the end
  const videoFile = "Our_Journey.mp4";
  const videoSrc = `${baseUrl}/${videoFile}`;
  useEffect(() => {
    const scrollListener = () => {
      setShowSttButton(window.scrollY > SHOW_SCROLL_BUTTON_THRESHOLD);
    };
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  const swiperRef = useRef<SwiperRef | null>(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev(500, true);
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext(500, true);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = (swiper: {
    activeIndex: SetStateAction<number>;
  }) => {
    setActiveSlideIndex(swiper.activeIndex);
    console.log(swiper.activeIndex);
  };

  const missionSectionDesktopRef = useRef<HTMLDivElement>(null);
  const missionSectionMobileRef = useRef<HTMLDivElement>(null);

  // Video player logic -- Need to move video to own component
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoHovered, setIsVideoHovered] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    if (video) {
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);

      return () => {
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
      };
    }
  }, []);

  const handleVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.paused
        ? videoRef.current.play()
        : videoRef.current.pause();
    }
  };

  return (
    <>
      <Helmet>
        <title>ADT's #1 Agent Referral Program | Safe Haven</title>
        <meta
          name="description"
          content="Become a referral agent and earn money referring customers to ADT."
        />
        <meta
          name="keywords"
          content="ADT referral program, security referral program, agent referral program, home security referral, earn money referring customers, ADT security systems"
        />
      </Helmet>

      <ScrollToTopButton showButton={showSttButton}></ScrollToTopButton>

      {/* Family Image Sections */}
      <Box
        sx={{
          display: { xs: "none", md: "grid" },
          maxWidth: "1750px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
          }}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              gridColumn: "1",
              gridRow: "1",
              display: "block",
              width: "100%",
              maxWidth: "100%",
              height: "auto",
              margin: "auto",
              //filter: "blur(10px)",
              transition: "filter 0.5s ease",
            }}
            alt="Hero Image of Builder"
            src={builderHero}
            onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
              const target = e.target as HTMLImageElement;
              target.style.filter = "blur(0px)";
            }}
          />
          <Box
            sx={{
              gridColumn: "1",
              gridRow: "1",
              width: "100%",
              height: "100%",
              //backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: 1,
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            gridColumn: "1",
            gridRow: "1",
            color: "#ffffff",
            textAlign: "center",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                marginTop: { lg: "100px" },
                marginBottom: "32px",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  zIndex: "1",
                  fontSize: { xs: "32px", md: "42px", lg: "48px", xl: "62px" },
                  fontWeight: "700",
                  letterSpacing: "3px",
                  maxWidth: { xs: "500px", md: "800px" },
                }}
              >
                Join ADT’s Largest Agent Referral Program in the U.S.
              </Typography>
              <Typography
                sx={{
                  zIndex: "1",
                  textAlign: "center",
                  margin: {
                    xs: "24px auto auto auto",
                    lg: "48px auto auto auto",
                  },
                  fontSize: { xs: "14px", md: "22px", lg: "22px", xl: "28px" },
                  maxWidth: { xs: "500px", md: "900px" },
                  position: "relative",
                  width: "100%",
                }}
              >
                Partner with Safe Haven Security and become part of a network of
                over 30,000 agents. Enjoy fast payouts, unparalleled support,
                and provide your clients with personalized, top-tier home
                security solutions.
              </Typography>
            </Box>
            <Box>
              <Button
                className="home-free-quote-btn"
                sx={{
                  backgroundColor: "#00519C",
                  color: "#ffffff",
                  borderRadius: "30px",
                  height: "45px",
                  maxHeight: "45px",
                  fontSize: "14px",
                  padding: "0 24px",
                  fontFamily: "Rubik",
                  textTransform: "capitalize",
                  "&.home-free-quote-btn:hover": {
                    backgroundColor: "#00519C!important",
                  },
                  "&.home-free-quote-btn>span": {
                    marginLeft: "4px",
                  },
                }}
                onClick={handleFormOpen}
                endIcon={
                  <ArrowForwardIosIcon
                    id="btn-arrow-slide-right"
                    sx={{ height: "14px", paddingBottom: "1px" }}
                  />
                }
              >
                Become a Referral Agent Today!
              </Button>
              <Typography sx={{ marginTop: "10px" }}>
                or call 877-842-0818 for more information{" "}
              </Typography>
              <Modal
                open={formOpen}
                closeAfterTransition
                onClose={handleFormClose}
                className=""
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Slide direction="up" in={formOpen}>
                  <Box
                    sx={{
                      maxWidth: 900,
                      bgcolor: "#fff",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                      overflowX: "auto",
                      maxHeight: "100vh",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          paddingLeft: "8px",
                          fontSize: "24px",
                          fontWeight: 700,
                        }}
                      >
                        Become a Referral Agent Today!
                      </Typography>
                      <Box
                        className=""
                        sx={{
                          display: "flex",
                          marginRight: 0,
                          maxWidth: "100%",
                        }}
                      >
                        <IconButton
                          sx={{ margin: "auto 0 auto auto" }}
                          onClick={() => {
                            handleFormClose();
                          }}
                        >
                          <ClearIcon></ClearIcon>
                        </IconButton>
                      </Box>
                    </Box>

                    <Divider sx={{ margin: "10px" }}></Divider>
                    <AgentReferralForm
                      setConfirmationOpen={setConfirmationOpen}
                    ></AgentReferralForm>
                  </Box>
                </Slide>
              </Modal>

              <Modal
                open={confirmationOpen}
                closeAfterTransition
                onClose={() => {
                  handleConfirmationClose();
                }}
                className=""
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Slide direction="up" in={confirmationOpen}>
                  <Container
                    maxWidth={false}
                    sx={{
                      maxWidth: "700px",
                      minHeight: "400px",
                      height: "auto",
                      bgcolor: "#fff",
                      borderRadius: "9px",
                      boxShadow: 24,
                      padding: "0!important",
                    }}
                  >
                    <Box
                      sx={{
                        background: "rgb(18, 96, 168)",
                        width: "100%",
                        minHeight: "64px",
                        borderRadius: "9px 9px 0 0px",
                        padding: "10px 0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "700",
                          textAlign: "center",
                          color: "#fff",
                        }}
                      >
                        Thank you for contacting Safe Haven!
                      </Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          right: "5px",
                        }}
                      >
                        <IconButton
                          sx={{}}
                          onClick={() => {
                            handleConfirmationClose();
                            handleFormClose();
                          }}
                        >
                          <ClearIcon sx={{ color: "#fff" }}></ClearIcon>
                        </IconButton>
                      </Box>
                    </Box>
                    <Stack
                      direction={"row"}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box
                        sx={{
                          paddingLeft: "36px",
                          minHeight: "350px",
                          minWidth: "250px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box>
                          <Box sx={{ width: "100%", display: "flex" }}>
                            <CheckCircleOutlineIcon
                              sx={{
                                color: "rgb(18, 96, 168)",
                                margin: "auto",
                                height: "4rem",
                                width: "auto",
                                marginBottom: "15px",
                              }}
                            ></CheckCircleOutlineIcon>
                          </Box>

                          <Typography
                            sx={{ fontSize: "24px", maxWidth: "600px" }}
                          >
                            We received your request for more information and a
                            member of our team will be in touch with you shortly
                            to answer any questions you have.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "24px 0",
                            }}
                          >
                            <Button
                              variant={"contained"}
                              onClick={() => {
                                handleNavigate("/");
                              }}
                              sx={{
                                minWidth: "100px",
                                minHeight: "45px",
                                fontSize: "16px",
                                textTransform: "none",
                                fontWeight: "700",
                              }}
                            >
                              Back To Home
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                  </Container>
                </Slide>
              </Modal>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Mobile: Family Pic  */}
      <Box
        sx={{
          display: { xs: "grid", md: "none" },
          justifyContent: "center",
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
          }}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              gridColumn: "1",
              gridRow: "1",
              display: "block",
              width: "auto",
              maxWidth: { xs: "1000px", sm: "100%" },
              height: "auto",
              margin: "auto",
            }}
            alt="The house from the offer."
            src={builderHero}
          />
          <Box
            sx={{
              gridColumn: "1",
              gridRow: "1",
              width: "100%",
              height: "100%",
              //backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            gridColumn: "1",
            gridRow: "1",
            color: "#ffffff",
            textAlign: "center",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                marginBottom: "32px",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  zIndex: "1",
                  fontSize: {
                    xs: "28px",
                    sm: "42px",
                    md: "42px",
                    lg: "48px",
                    xl: "62px",
                  },
                  fontWeight: "700",
                  letterSpacing: "3px",
                  maxWidth: { xs: "calc(100vw - 32px)", sm: "600px" },
                }}
              >
                Join ADT’s Largest Agent Referral Program in the U.S.
              </Typography>
              <Typography
                sx={{
                  zIndex: "1",
                  textAlign: "center",
                  margin: "48px auto auto auto",
                  fontSize: {
                    xs: "18px",
                    sm: "24px",
                    md: "22px",
                    lg: "22px",
                    xl: "28px",
                  },
                  maxWidth: { xs: "calc(100vw - 32px)", sm: "500px" },
                  position: "relative",
                  width: "100%",
                }}
              >
                Partner with Safe Haven Security and become part of a network of
                over 30,000 agents. Enjoy fast payouts, unparalleled support,
                and provide your clients with personalized, top-tier home
                security solutions.
              </Typography>
            </Box>
            <Box>
              <Button
                className="home-free-quote-btn"
                sx={{
                  backgroundColor: "#00519C",
                  color: "#ffffff",
                  borderRadius: "30px",
                  height: "45px",
                  maxHeight: "45px",
                  fontSize: "14px",
                  padding: "0 24px",
                  fontFamily: "Rubik",
                  textTransform: "capitalize",
                  "&.home-free-quote-btn:hover": {
                    backgroundColor: "#00519C!important",
                  },
                  "&.home-free-quote-btn>span": {
                    marginLeft: "4px",
                  },
                }}
                onClick={handleFormOpen}
                endIcon={
                  <ArrowForwardIosIcon
                    id="btn-arrow-slide-right"
                    sx={{ height: "14px", paddingBottom: "1px" }}
                  />
                }
              >
                Become a Referral Agent Today!
              </Button>
              <Typography sx={{ marginTop: "10px" }}>
                or call 877-842-0818 for more information{" "}
              </Typography>

              <Modal
                open={formOpen}
                closeAfterTransition
                onClose={handleFormClose}
                className=""
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Slide direction="up" in={formOpen}>
                  <Box
                    sx={{
                      maxWidth: 900,
                      bgcolor: "#fff",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                      overflowX: "auto",
                      maxHeight: "100vh",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          paddingLeft: "8px",
                          fontSize: "24px",
                          fontWeight: 700,
                        }}
                      >
                        Become a Referral Agent Today!
                      </Typography>
                      <Box
                        className=""
                        sx={{
                          display: "flex",
                          marginRight: 0,
                          maxWidth: "100%",
                        }}
                      >
                        <IconButton
                          sx={{ margin: "auto 0 auto auto" }}
                          onClick={() => {
                            handleFormClose();
                          }}
                        >
                          <ClearIcon></ClearIcon>
                        </IconButton>
                      </Box>
                    </Box>

                    <Divider sx={{ margin: "10px" }}></Divider>
                    <AgentReferralForm
                      setConfirmationOpen={setConfirmationOpen}
                    ></AgentReferralForm>
                  </Box>
                </Slide>
              </Modal>

              <Modal
                open={confirmationOpen}
                closeAfterTransition
                onClose={() => {
                  handleConfirmationClose();
                }}
                className=""
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Slide direction="up" in={confirmationOpen}>
                  <Container
                    maxWidth={false}
                    sx={{
                      maxWidth: "700px",
                      minHeight: "400px",
                      height: "auto",
                      bgcolor: "#fff",
                      borderRadius: "9px",
                      boxShadow: 24,
                      padding: "0!important",
                    }}
                  >
                    <Box
                      sx={{
                        background: "rgb(18, 96, 168)",
                        width: "100%",
                        minHeight: "64px",
                        borderRadius: "9px 9px 0 0px",
                        padding: "10px 0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "700",
                          textAlign: "center",
                          color: "#fff",
                        }}
                      >
                        Thank you for contacting Safe Haven!
                      </Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          right: "5px",
                        }}
                      >
                        <IconButton
                          sx={{}}
                          onClick={() => {
                            handleConfirmationClose();
                            handleFormClose();
                          }}
                        >
                          <ClearIcon sx={{ color: "#fff" }}></ClearIcon>
                        </IconButton>
                      </Box>
                    </Box>
                    <Stack
                      direction={"row"}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box
                        sx={{
                          paddingLeft: "36px",
                          minHeight: "350px",
                          minWidth: "250px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box>
                          <Box sx={{ width: "100%", display: "flex" }}>
                            <CheckCircleOutlineIcon
                              sx={{
                                color: "rgb(18, 96, 168)",
                                margin: "auto",
                                height: "4rem",
                                width: "auto",
                                marginBottom: "15px",
                              }}
                            ></CheckCircleOutlineIcon>
                          </Box>

                          <Typography
                            sx={{ fontSize: "24px", maxWidth: "600px" }}
                          >
                            We received your request for more information and a
                            member of our team will be in touch with you shortly
                            to answer any questions you have.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "24px 0",
                            }}
                          >
                            <Button
                              variant={"contained"}
                              onClick={() => {
                                handleNavigate("/");
                              }}
                              sx={{
                                minWidth: "100px",
                                minHeight: "45px",
                                fontSize: "16px",
                                textTransform: "none",
                                fontWeight: "700",
                              }}
                            >
                              Back To Home
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                  </Container>
                </Slide>
              </Modal>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "rgb(244, 244, 244)",
          padding: { xs: "16px 0px", lg: "128px" },
        }}
      >
        <Container
          maxWidth={"xl"}
          sx={{ marginTop: "32px", marginBottom: "32px" }}
        >
          <Grid container className="stats-banner-grid" columnSpacing={0}>
            <Grid
              item
              xs={6}
              md={3}
              sx={{ position: "relative", padding: "8px" }}
            >
              <Typography
                className="stats-banner-number"
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: { xs: "40px", lg: "64px" },
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                $20M
              </Typography>
              <Typography
                className="stats-banner-text"
                sx={{
                  color: "rgb(112, 112, 123)",
                  fontSize: "12px",
                  fontWeight: "700",
                  textAlign: "center",
                  maxWidth: "250px",
                  margin: "auto",
                }}
              >
                Agent Referral Bonuses Paid Out to Date
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  borderLeft: "1px solid #cecece",
                  height: { xs: "64px", lg: "120px" },
                  right: "0",
                  bottom: { xs: "15%", lg: "0" },
                }}
              ></Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              sx={{ position: "relative", padding: "8px" }}
            >
              <Typography
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: { xs: "40px", lg: "64px" },
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                30k
              </Typography>
              <Typography
                sx={{
                  color: "rgb(112, 112, 123)",
                  fontSize: "12px",
                  fontWeight: "700",
                  textAlign: "center",
                  maxWidth: "250px",
                  margin: "auto",
                }}
              >
                Active Referral Agents Across the U.S.
              </Typography>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  position: "absolute",
                  borderLeft: "1px solid #cecece",
                  height: { xs: "64px", lg: "120px" },
                  right: "0",
                  bottom: { xs: "15%", lg: "0" },
                }}
              ></Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              sx={{ position: "relative", padding: "8px" }}
            >
              <Typography
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: { xs: "40px", lg: "64px" },
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                $3M
              </Typography>
              <Typography
                sx={{
                  color: "rgb(112, 112, 123)",
                  fontSize: "12px",
                  fontWeight: "700",
                  textAlign: "center",
                  maxWidth: "250px",
                  margin: "auto",
                }}
              >
                Referral Agent Bonuses PER YEAR
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  borderLeft: "1px solid #cecece",
                  height: { xs: "64px", lg: "120px" },
                  right: "0",
                  bottom: { xs: "15%", lg: "0" },
                }}
              ></Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              sx={{ position: "relative", padding: "8px" }}
            >
              <Typography
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: { xs: "40px", lg: "64px" },
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                600k
              </Typography>
              <Typography
                sx={{
                  color: "rgb(112, 112, 123)",
                  fontSize: "12px",
                  fontWeight: "700",
                  textAlign: "center",
                  maxWidth: "250px",
                  margin: "auto",
                }}
              >
                Satisifed Customers
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Commitment Section */}
      <Container
        maxWidth={"lg"}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          marginTop: "64px",
        }}
      >
        {/* Mobile: Heading Text */}
        <Stack
          ref={missionSectionMobileRef}
          id="our-mission-section-mobile"
          sx={{ display: { xs: "block", md: "none" }, marginBottom: "32px" }}
        >
          <Typography
            sx={{ fontSize: "32px", lineHeight: "48px", marginBottom: "16px" }}
          >
            <strong>Why Safe Haven?</strong>
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              letterSpacing: "1",
              wordSpacing: "1",
              marginBottom: "16px",
            }}
          >
            Since 1999, Safe Haven Security's Agent Referral Program has
            empowered agents nationwide to boost their earnings while delivering
            unmatched value to homeowners. We understand that every home,
            family, and lifestyle is unique, which is why our dedicated
            representatives work closely with your clients to create customized
            security solutions tailored to their specific needs.
          </Typography>
          <Typography
            sx={{ fontSize: "18px", letterSpacing: "1", wordSpacing: "1" }}
          >
            Plus, you'll never have to worry about your clients being directed
            to impersonal 1-800 numbers—they'll receive personalized attention
            from professionals who truly care. Join us today and experience
            faster, more convenient payouts than any competitor, all while
            enhancing customer satisfaction and growing your business.
          </Typography>
        </Stack>

        {/* Girl/Dog Image & Blue Dot */}

        {/* Desktop: Text and Gray Blocks */}
        <Stack
          ref={missionSectionDesktopRef}
          id="our-mission-section-desktop"
          sx={{ display: { xs: "none", md: "block" }, marginRight: "32px" }}
        >
          <Typography
            sx={{ fontSize: "48px", lineHeight: "56px", marginBottom: "32px" }}
          >
            <strong>Why Safe Haven?</strong>
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              letterSpacing: "1",
              wordSpacing: "1",
              marginBottom: "16px",
            }}
          >
            Since 1999, Safe Haven Security's Agent Referral Program has
            empowered agents nationwide to boost their earnings while delivering
            unmatched value to homeowners. We understand that every home,
            family, and lifestyle is unique, which is why our dedicated
            representatives work closely with your clients to create customized
            security solutions tailored to their specific needs.
          </Typography>
          <Typography
            sx={{ fontSize: "18px", letterSpacing: "1", wordSpacing: "1" }}
          >
            Plus, you'll never have to worry about your clients being directed
            to impersonal 1-800 numbers—they'll receive personalized attention
            from professionals who truly care. Join us today and experience
            faster, more convenient payouts than any competitor, all while
            enhancing customer satisfaction and growing your business.
          </Typography>
        </Stack>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            //marginRight: { md: "32px" },
          }}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              display: "block",
              height: "100%",
              maxHeight: "370px",
              maxWidth: "370px",
              //borderRadius: "50%",
            }}
            alt="The house from the offer."
            src={builderCircle}
          />
        </Box>
      </Container>

      <Container
        maxWidth={"lg"}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          marginTop: "64px",
        }}
      >
        <Grid
          container
          id="grid-container"
          columns={4}
          sx={{
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.12)",
            padding: "16px",
            marginTop: "3rem",
          }}
        >
          <Grid
            id="grid-1"
            item
            xs={4}
            lg={1.5}
            sx={{
              display: "flex",
              marginBottom: { xs: "60px", lg: "0" },
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              direction={"column"}
              sx={{ marginBottom: "32px", alignItems: "center" }}
            >
              <Typography
                sx={{
                  maxWidth: "600px",
                  fontSize: "32px",
                  textAlign: "center",
                  marginBottom: "35px",
                  fontWeight: 700,
                  marginRight: "1rem",
                }}
              >
                We pay our referral agents faster and more conveniently than any
                competitor!
              </Typography>
              <Box sx={{ margin: "auto" }}>
                <Button
                  className="home-free-quote-btn"
                  sx={{
                    backgroundColor: "#00519C",
                    color: "#ffffff",
                    borderRadius: "30px",
                    height: "45px",
                    maxHeight: "45px",
                    fontSize: "14px",
                    padding: "0 24px",
                    fontFamily: "Rubik",
                    textTransform: "capitalize",
                    "&.home-free-quote-btn:hover": {
                      backgroundColor: "#00519C!important",
                    },
                    "&.home-free-quote-btn>span": {
                      marginLeft: "4px",
                    },
                  }}
                  onClick={handleFormOpen}
                  endIcon={
                    <ArrowForwardIosIcon
                      id="btn-arrow-slide-right"
                      sx={{ height: "14px", paddingBottom: "1px" }}
                    />
                  }
                >
                  Become a Referral Agent Today!
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={4}
            sm={2}
            lg={1}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              component="img"
              sx={{
                maxWidth: "100%",
                marginBottom: { xs: "60px", lg: "0" },
              }}
              alt="The house from the offer."
              src={appImage1}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={2}
            lg={1}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              component="img"
              sx={{
                maxWidth: "100%",
              }}
              alt="The house from the offer."
              src={appImage2}
            />
          </Grid>
        </Grid>
      </Container>
      {/* Desktop: Iphone Strip */}
      <Box
        sx={{
          marginTop: "170px",
          marginBottom: "170px",
          display: { xs: "none", md: "grid" },
          backgroundColor: "rgb(0, 83, 156)",
          padding: { lg: "112px" },
        }}
      >
        <Container>
          <Grid container className="" sx={{}}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ paddingRight: "24px", display: "flex" }}
            >
              <Stack sx={{}}>
                <Typography
                  sx={{
                    color: "rgb(139, 221, 249)",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    marginBottom: "24px",
                  }}
                >
                  Control Your System
                </Typography>
                <Typography
                  sx={{
                    fontSize: "56px",
                    color: "#ffffff",
                    lineHeight: "64px",
                    marginBottom: "16px",
                  }}
                >
                  Total <strong>Control and</strong>
                  <br></br>
                  <strong>Security, Always.</strong>
                </Typography>
                <Typography sx={{ marginBottom: "32px", color: "#ffffff" }}>
                  Conveniently manage your security and smart home devices with
                  the Alarm.com app, all from one place. Arm and disarm your
                  system, view live video, and control smart devices remotely.
                  Get customized alerts and tailored security that adapts to
                  your needs, with seamless integration of compatible devices
                  for total peace of mind.
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                paddingLeft: "24px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative", height: "100%" }}>
                <Box
                  component="img"
                  sx={{
                    position: "absolute",
                    transform: {
                      xs: "translateX(50%) translateY(00%) scale(1)",
                      lg: "translateX(150%) translateY(00%) scale(2.5)",
                    },
                    display: "block",
                    width: "auto",
                    height: "100%",
                    maxHeight: "100%",
                  }}
                  alt="The house from the offer."
                  src={iphone}
                  loading="lazy"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          marginTop: "170px",
          marginBottom: "170px",
          display: { xs: "none", sm: "grid", md: "none" },
          backgroundColor: "rgb(0, 83, 156)",
          padding: { lg: "112px" },
        }}
      >
        <Container sx={{ margin: "32px 0" }}>
          <Grid container className="" sx={{}}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ paddingRight: "24px", display: "flex" }}
            >
              <Stack sx={{}}>
                <Typography
                  sx={{
                    color: "rgb(139, 221, 249)",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    marginBottom: "8px",
                  }}
                >
                  Control Your System
                </Typography>
                <Typography
                  sx={{
                    fontSize: "32px",
                    color: "#ffffff",
                    lineHeight: "48px",
                    marginBottom: "8px",
                  }}
                >
                  Total <strong>Control and Security, Always.</strong>
                </Typography>
                <Typography sx={{ marginBottom: "32px", color: "#ffffff" }}>
                  Conveniently manage your security and smart home devices with
                  the Alarm.com app, all from one place. Arm and disarm your
                  system, view live video, and control smart devices remotely.
                  Get customized alerts and tailored security that adapts to
                  your needs, with seamless integration of compatible devices
                  for total peace of mind.
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                paddingLeft: "24px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative", height: "100%" }}>
                <Box
                  component="img"
                  sx={{
                    position: "absolute",
                    transform: {
                      xs: "translateX(50%) translateY(00%) scale(1.8)",
                      lg: "translateX(150%) translateY(00%) scale(2.5)",
                    },
                    display: "block",
                    width: "auto",
                    height: "100%",
                    maxHeight: "100%",
                  }}
                  alt="The house from the offer."
                  src={iphone}
                  loading="lazy"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Mobile: Iphone Strip */}
      <Box
        sx={{
          marginTop: "170px",
          marginBottom: "170px",
          display: { xs: "grid", sm: "none" },
          backgroundColor: "rgb(0, 83, 156)",
          paddingTop: "64px",
        }}
      >
        <Container sx={{ paddingLeft: "24px", paddingRight: "24px" }}>
          <Stack sx={{ position: "relative" }}>
            <Typography
              sx={{
                color: "rgb(139, 221, 249)",
                textTransform: "uppercase",
                fontWeight: "700",
                marginBottom: "8px",
                fontSize: "14px",
                letterSpacing: "1px",
              }}
            >
              Control Your System
            </Typography>
            <Typography
              sx={{
                fontSize: "32px",
                color: "#ffffff",
                lineHeight: "42px",
                marginBottom: "16px",
              }}
            >
              Total <strong>Control and</strong>
              <br></br>
              <strong>Security, Always.</strong>
            </Typography>
            <Typography sx={{ color: "#ffffff", fontSize: "14px" }}>
              Conveniently manage your security and smart home devices with the
              Alarm.com app, all from one place.
              <br></br>
              Arm and disarm your system, view live video, and control smart
              devices remotely.
            </Typography>
          </Stack>
        </Container>
        <Box
          sx={{
            background: "#fff",
            display: "flex",
            justifyContent: "flex-end",
            backgroundImage:
              "linear-gradient(to bottom, rgb(0, 83, 156) 60%, transparent 60%)",
          }}
        >
          <Box
            component="img"
            sx={{
              display: "block",
              width: "auto",
              maxWidth: "55%",
              height: "auto",
              transform: "translateY(-35px)",
              marginRight: "8px",
            }}
            alt="The house from the offer."
            src={iphone}
            loading="lazy"
          />
        </Box>
      </Box>

      {/* Awards Carousel Section */}
      <Container
        sx={{ maxHeight: "500px", margin: "88px auto", position: "relative" }}
      >
        <Swiper
          style={{ height: "100%", width: "100%" }}
          loop={true}
          ref={swiperRef}
          className="about-us-swiper"
          navigation={false}
          spaceBetween={0}
          modules={[Pagination, Navigation]}
          initialSlide={activeIndex}
          onSlideChange={handleSlideChange}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 2,
            },
            900: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
        >
          {AWARD_IMAGES.map((award, index) => (
            <SwiperSlide
              key={index}
              className={`about-us-swiper-slide-${index}`}
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                paddingRight: "1px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "300px",
                  width: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    height: "210px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    loading="lazy"
                    sx={{
                      width: "auto",
                      maxHeight: "100%",
                      maxWidth: "250px",
                      cursor: "pointer",
                    }}
                    alt={`Product ${index + 1}`}
                    src={award.image}
                  />
                </Box>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "22px",
                    maxWidth: "250px",
                    height: "70px",
                    paddingTop: "20px",
                  }}
                >
                  {award.text}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    borderLeft: "1px solid #cecece",
                    height: "125px",
                    right: "0",
                    bottom: "45%",
                  }}
                ></Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: { xs: "50%", sm: "75%", md: "50%" },
            height: "300px",
            background:
              "linear-gradient(to right, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: { xs: "50%", sm: "75%", md: "50%" },
            height: "300px",
            background:
              "linear-gradient(to left, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
            zIndex: 2,
          }}
        >
          <Box sx={{ margin: "18px" }}>
            <IconButton
              className="award-carousel-nav-button"
              aria-label="delete"
              onClick={handlePrev}
              sx={{
                backgroundColor: "rgb(68,200,245)",
                minHeight: "50px",
                minWidth: "50px",
              }}
            >
              <WestIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Box>

          <Box sx={{ margin: "18px" }}>
            <IconButton
              className="award-carousel-nav-button"
              aria-label="delete"
              onClick={handleNext}
              sx={{
                backgroundColor: "rgb(68,200,245)",
                minHeight: "50px",
                minWidth: "50px",
              }}
            >
              <EastIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
        </Box>
      </Container>

      {/* Desktop: Reviews Sections */}
      <Box
        sx={{
          display: { xs: "none", lg: "grid" },
          position: "relative",
          marginBottom: "250px",
        }}
      >
        {/* Background Image */}
        <Box
          component="img"
          sx={{
            width: "100%",
            maxWidth: "xl",
            height: "auto",
            margin: "auto",
            display: { xs: "none", lg: "grid" },
            gridColumn: "1",
            gridRow: "1",
            filter: "blur(10px)",
            transition: "filter 0.5s ease",
          }}
          alt="The house from the offer."
          src={productDemo}
          loading="lazy"
          onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
            const target = e.target as HTMLImageElement;
            target.style.filter = "blur(0px)";
          }}
        />

        {/* Dark Tint Overlay */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "xl",
            margin: "auto",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
            zIndex: 1,
          }}
        />

        {/* Blue Review Side-By-Side */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, 10%)",
            color: "#ffffff",
            zIndex: "2",
            width: "100%",
            maxWidth: "1300px",
          }}
        >
          <Grid container className="">
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                backgroundColor: "rgba(0, 39, 78)",
                padding: {
                  xs: "56px 32px 48px 32px",
                  md: "56px 80px 80px 60px",
                },
              }}
            >
              <Stack sx={{ marginBottom: "8px" }}>
                <Box>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                </Box>
              </Stack>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: "700",
                  letterSpacing: "1px",
                  marginBottom: "8px",
                }}
              >
                22,000+ Reviews
              </Typography>
              <Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
                Rated Excellent by our customers, with 4.8 stars from over
                22,000 reviews, with an A+ BBB rating, we are proud to deliver
                top-notch home security solutions that exceed expectations.
              </Typography>
              <Box sx={{ marginTop: "48px" }}>
                <Button
                  variant="outlined"
                  className=""
                  sx={{
                    display: "flex",
                    color: "#ffffff",
                    borderColor: "#ffffff",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    height: "50px",
                    minWidth: "150px",
                    fontSize: "12px",
                    letterSpacing: "1px",
                    padding: "0 24px",
                    textTransform: "capitalize",
                  }}
                  onMouseDown={(event) => {
                    if (event.button === 1) {
                      handleNavigate("/contact-us", "_blank");
                    }
                  }}
                  onClick={(event) => {
                    handleNavigate("/contact-us");
                  }}
                >
                  CONTACT US
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                padding: "102px 80px 80px 60px",
                backgroundColor: "rgba(0, 83, 156)",
                borderTopRightRadius: "30% 45%",
              }}
            >
              <Swiper
                style={{ height: "100%", width: "100%" }}
                loop={true}
                className="home-reviews-swiper"
                navigation={false}
                pagination={{
                  clickable: true,

                  bulletClass: "home-reviews-carousel-bullet",
                  bulletActiveClass: "home-reviews-carousel-bullet-active",
                }}
                spaceBetween={5}
                modules={[Pagination, Navigation]}
                slidesPerView={1}
              >
                {HOMEPAGE_REVIEWS.map((review, index) => (
                  <SwiperSlide
                    key={index}
                    className={`homepage-reviews-slide-${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      paddingRight: "1px",
                      width: "60%",
                    }}
                  >
                    <Stack>
                      <Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
                        {review.text}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          letterSpacing: "1px",
                          marginTop: "24px",
                        }}
                      >
                        {review.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "rgba(68, 200, 245)",
                          fontWeight: "700",
                        }}
                      >
                        Google Review, 5 Stars
                      </Typography>
                    </Stack>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Mobile: Reviews Sections */}
      <Box
        sx={{
          display: { xs: "grid", lg: "none" },
          position: "relative",
          paddingBottom: "32px",
          background: "rgba(0, 83, 156)",
        }}
      >
        {/* Background Image */}
        <Box
          component="img"
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "auto",
            margin: "auto",
            display: { xs: "grid", lg: "none" },
            gridColumn: "1",
            gridRow: "1",
          }}
          alt="The house from the offer."
          src={productDemoMobile}
          loading="lazy"
        />

        {/* Dark Tint Overlay */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "xl",
            margin: "auto",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
          }}
        />

        <Box
          sx={{
            gridColumn: "1",
            gridRow: "2",
            backgroundColor: "rgba(0, 39, 78)",
            padding: { xs: "56px 32px 48px 32px", md: "56px 80px 80px 60px" },
            color: "#fff",
          }}
        >
          <Stack sx={{ marginBottom: "8px" }}>
            <Box>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
            </Box>
          </Stack>
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: "700",
              letterSpacing: "1px",
              marginBottom: "8px",
            }}
          >
            22,000+ Reviews
          </Typography>
          <Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
            Rated Excellent by our customers, with 4.8 stars from over 22,000
            reviews, with an A+ BBB rating, we are proud to deliver top-notch
            home security solutions that exceed expectations.
          </Typography>
          <Box sx={{ marginTop: "48px" }}>
            <Button
              variant="outlined"
              className=""
              sx={{
                display: "flex",
                color: "#ffffff",
                borderColor: "#ffffff",
                borderWidth: "2px",
                borderRadius: "30px",
                height: "50px",
                minWidth: "150px",
                fontSize: "12px",
                letterSpacing: "1px",
                padding: "0 24px",
                textTransform: "capitalize",
              }}
            >
              CONTACT US
            </Button>
          </Box>
        </Box>

        <Box sx={{ backgroundColor: "rgba(0, 39, 78)", minHeight: "350px" }}>
          <Box
            sx={{
              gridColumn: "1",
              gridRow: "3",
              backgroundColor: "rgba(0, 83, 156)",
              borderTopRightRadius: {
                xs: "50% 60%",
                sm: "50% 60%",
                md: "25% 45%",
              },
              color: "#fff",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Swiper
              style={{ height: "100%" }}
              loop={true}
              className="home-reviews-swiper mobile-reviews-swiper"
              navigation={false}
              pagination={{
                clickable: true,
                renderBullet: function (index, className) {
                  return `
                    <span class="${className} mobile-reviews-bullet">
                      <span class="inner-dot"></span> 
                    </span>
                  `;
                },
                bulletClass: "home-reviews-carousel-bullet",
                bulletActiveClass: "home-reviews-carousel-bullet-active",
              }}
              spaceBetween={5}
              modules={[Pagination, Navigation]}
              slidesPerView={1}
            >
              {HOMEPAGE_REVIEWS.map((review, index) => (
                <SwiperSlide
                  key={index}
                  className={`homepage-reviews-slide-${index}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    paddingRight: "1px",
                    width: "60%",
                    marginLeft: "0",
                  }}
                >
                  <Stack sx={{ height: "100%", justifyContent: " flex-start" }}>
                    <Typography
                      sx={{
                        maxWidth: "70%",
                        fontSize: { xs: "12px", sm: "18px", md: "24px" },
                        lineHeight: { xs: "22px", sm: "28px", md: "32px" },
                        minHeight: "225px",
                        maxHeight: "225px",
                        marginTop: "24px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {review.text}
                    </Typography>
                    <Box sx={{ minHeight: "60px" }}>
                      <Typography
                        sx={{
                          fontSize: { xs: "14px", sm: "18px", md: "18px" },
                          lineHeight: { xs: "24px", sm: "28px", md: "28px" },
                          letterSpacing: "1px",
                        }}
                      >
                        {review.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", sm: "12px", md: "16px" },
                          lineHeight: { xs: "22px", sm: "22px", md: "26px" },
                          color: "rgba(68, 200, 245)",
                          fontWeight: "700",
                        }}
                      >
                        Google Review, 5 Stars
                      </Typography>
                    </Box>
                  </Stack>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      </Box>
    </>
  );
}
