import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from './generalSlice';
import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';
import alertBannerSlice from './alertBannerSlice';
import navigationSlice from './navigationSlice';
import headerSlice from './headerSlice';
import footerSlice from './footerSlice';

const persistConfig = {
  key: 'user',
  storage,
};

const headerPersistConfig = {
  key: 'pageNavigation',
  storage: storage,
};

const navigationPersistConfig = {
  key: 'pageNavigation',
  storage: storage,
};

const alertBannerPersistConfig = {
  key: 'bannerAlert',
  storage: storage,
};

const persistedUserReducer = persistReducer(persistConfig, userSlice.reducer);

const persistedBannerAlertReducer = persistReducer(
  alertBannerPersistConfig,
  alertBannerSlice
);

const persistedHeaderReducer = persistReducer(headerPersistConfig, headerSlice);

const persistedFooterReducer = persistReducer(headerPersistConfig, footerSlice);

const persistedNavigationReducer = persistReducer(
  navigationPersistConfig,
  navigationSlice
);

export const store = configureStore({
  reducer: {
    userData: persistedUserReducer,
    alertBanner: persistedBannerAlertReducer,
    header: persistedHeaderReducer,
    footer: persistedFooterReducer,
    pageNavigation: persistedNavigationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
