import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Stack } from '@mui/system';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, Link, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // For form validation
import yardSign from "../../../../../Assets/Partner/Paddio/yard-sign-1-img.jpg";
import topBgCropped from "../../../../../Assets/Partner/Paddio/banner-img-1136x666-cropped.jpg";
import planIcons from "../../../../../Assets/Partner/Paddio/plans-icons.png";
import CompanyLogo from '../../../../../Components/Header/CompanyLogo';
import icon1 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-1.jpg";
import icon2 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-2.jpg";
import icon3 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-3.jpg";
import icon4 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-4.jpg";
import icon5 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-5.jpg";
import voucher from "../../../../../Assets/Templates/rebate-1000.png";
import voucherVu from "../../../../../Assets/Templates/voucher-vu.png";
import equipmentVu from "../../../../../Assets/Templates/equipment-vu.png";
import monitoring from "../../../../../Assets/Templates/monitoring.png";
import armyVu from "../../../../../Assets/Templates/army-vu.jpg";
import equipment from "../../../../../Assets/Templates/equipment.png";
import systemImage from "../../../../../Assets/Templates/System-image.png";
import plansVu from "../../../../../Assets/Templates/plans-vu.png";
import phhArrowVoucher from "../../../../../Assets/Templates/phh-Gold-voucher.png";
import sign from "../../../../../Assets/Templates/sign.png";
import "./index.scss";
import SwiperCarousel from '../../../../../Components/SwiperCarousel';
import * as Constants from '../../../../../Constants/Pages';
import { isMobile, isTablet } from 'react-device-detect';
import PartnerLogo from '../../../../../Components/PartnerLogo';
import useFetch from 'use-http';
import { HIDE_PARTNER_LOGO } from '../../Constants';
import { useLocation } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { STATE_LIST } from '../../../../../Constants';
import GoogleReviews from '../../../../../Components/GoogleReviews';

interface propsWrapper {
    partnerInfo: PartnerInfo
}

export default function TemplateEight(props: propsWrapper) {
    const { partnerInfo } = props;
    const { pathname } = useLocation();
    const SH_GOOGLE_PLACE_ID = 'ChIJ5ShJXbHwwIcRfQnYPYse8e0';
    const formRef = useRef<HTMLDivElement>(null);

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';

    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
        cacheLife: 1000,
    };

    const { post, response, loading } = useFetch(
        process.env.REACT_APP_API,
        options
    );

    const initialValues = {
        Name: '',
        Last_Name: '',
        Email: '',
        Primary_Phone: '',
        Address: '',
        Zip: '',

        customerNotes: '',
        clientType: '',
        task: '',
        Expected_Closing_Date: '',
        City: '',
        State: '',
    };

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required('Required'),
        Last_Name: Yup.string().required('Required'),
        Email: Yup.string().email('Invalid email address').required('Required'),
        Primary_phone: Yup.string().required('Required')
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
        Zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format').required('Required'),

        ...(pathname === '/partners/veterans-united' && {
            customerNotes: Yup.string().required('Required'),
            clientType: Yup.string().required('Required'),
            task: Yup.string().required('Required'),
            Expected_Closing_Date: Yup.date().typeError("Invalid date format").required('Required'),
            City: Yup.string().required('Required'),
            State: Yup.string().required('Required'),
        }),
    });

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    const handleScrollToForm = () => {
        if (formRef.current) {
            const elementTop = formRef.current.getBoundingClientRect().top;
            const offsetPosition = elementTop + window.pageYOffset - (window.innerHeight * 0.2);

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <Box id='template8-wrapper'>
            <Container maxWidth={'xl'}>
                <Box className='banner-box'>
                    <Stack className='banner-stack' flexDirection={{ xs: "column", lg: 'row' }} rowGap={{ xs: 5, lg: 0 }}>
                        <Box sx={{ position: "relative" }}>
                            <CompanyLogo></CompanyLogo>
                        </Box>

                        {HIDE_PARTNER_LOGO.includes(pathname) ? null : <PartnerLogo partnerInfo={partnerInfo}></PartnerLogo>}

                        {pathname === '/partners/roundpointoffer' &&
                            <Box className='header-button-wrapper'>
                                <Link className='header-link'>Call us: 1 877-664-0673</Link>
                                <Button className='porch-quote-btn' variant='contained'>GET A FREE QUOTE</Button>
                            </Box>
                        }
                    </Stack>
                </Box>
            </Container>

            <Container >
                {pathname === '/partners/roundpointoffer' &&
                    <Box ref={formRef}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                try {
                                    const submissionData = {
                                        ...values,
                                        // These were set for my-vu
                                        Partner_Company1: '1166',
                                        Campaign1: '1588',
                                        clientInfo: {
                                            url: window.location.href,
                                        }
                                    };

                                    const leadPostResponse = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                                    console.log("Lead Post Request Sent:", leadPostResponse);

                                    resetForm();
                                } catch (error) {
                                    console.error("Error while sending request:", error);
                                } finally {
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({ errors, touched, values, setFieldValue, isSubmitting }) => (
                                <Form>
                                    <Box className='fields-container freedom-mortgage'>
                                        <Field type="hidden" name="Partner_Company1" />
                                        <Field type="hidden" name="Campaign1" />

                                        <Field
                                            as={TextField}
                                            className='half-width left-field freedom-mortgage-field mini-form'
                                            name="Name"
                                            label="First Name *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.Name}
                                            onChange={(event: any) => setFieldValue("Name", event.target.value)}
                                            error={touched.Name && Boolean(errors.Name)}
                                            helperText={touched.Name && errors.Name}
                                        />


                                        <Field
                                            as={TextField}
                                            className='half-width right-field freedom-mortgage-field mini-form'
                                            name="Last_Name"
                                            label="Last Name *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.Last_Name}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("Last_Name", event.target.value)}
                                            error={touched.Last_Name && Boolean(errors.Last_Name)}
                                            helperText={touched.Last_Name && errors.Last_Name}
                                        />

                                        <Field
                                            as={TextField}
                                            className='full-width freedom-mortgage-field mini-form'
                                            name="Email"
                                            label="Email *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.Email}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("Email", event.target.value)}
                                            error={touched.Email && Boolean(errors.Email)}
                                            helperText={touched.Email && errors.Email}
                                        />

                                        <Field
                                            as={TextField}
                                            className='half-width left-field freedom-mortgage-field mini-form'
                                            name="Primary_Phone"
                                            label="Phone Number *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.Primary_Phone}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("Primary_Phone", event.target.value)}
                                            error={touched.Primary_Phone && Boolean(errors.Primary_Phone)}
                                            helperText={touched.Primary_Phone && errors.Primary_Phone}
                                        />

                                        <FormControl
                                            className='half-width right-field'
                                            sx={{ width: '100%', minHeight: '65px' }}
                                            error={touched.Expected_Closing_Date && Boolean(errors.Expected_Closing_Date)}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    className='full-width freedom-mortgage-field mini-form'
                                                    sx={{
                                                        "& div>div>button>svg": {
                                                            zIndex: 9,
                                                        },
                                                        "& div>fieldset": {
                                                            borderColor: (touched.Expected_Closing_Date && Boolean(errors.Expected_Closing_Date)) ? '#d32f2f' : 'initial'
                                                        },
                                                    }}
                                                    disablePast
                                                    label="Closing Date"
                                                    format="MM/DD/YYYY"
                                                    value={values.Expected_Closing_Date ? dayjs(values.Expected_Closing_Date) : null}
                                                    onChange={(value) => {
                                                        const formattedValue = value?.format('YYYY-MM-DD');
                                                        setFieldValue("Expected_Closing_Date", formattedValue, true);
                                                    }}
                                                />
                                                {touched.Expected_Closing_Date && (
                                                    <FormHelperText>{errors.Expected_Closing_Date}</FormHelperText>
                                                )}
                                            </LocalizationProvider>
                                        </FormControl>

                                        <Field
                                            as={TextField}
                                            className='half-width left-field freedom-mortgage-field mini-form'
                                            name="City"
                                            label="City *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.City}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("City", event.target.value)}
                                            error={touched.City && Boolean(errors.City)}
                                            helperText={touched.City && errors.City}
                                        />

                                        <Autocomplete
                                            disablePortal
                                            className={`half-width right-field`}
                                            sx={{ display: 'inline-flex', margin: "0 0 8px 0!important", minHeight: '65px' }}
                                            options={STATE_LIST}
                                            inputValue={values.State}
                                            onInputChange={(event, newInputValue) => {
                                                setFieldValue("State", newInputValue);
                                            }}

                                            renderInput={params => (
                                                <TextField
                                                    sx={{ marginLeft: '0px' }}
                                                    {...params}
                                                    className='freedom-mortgage-field mini-form'
                                                    name="State"
                                                    label="State *"
                                                    error={touched.State && Boolean(errors.State)}
                                                    helperText={touched.State && errors.State}
                                                />
                                            )}
                                        />

                                        <Box className='full-width'>
                                            <Field
                                                as={TextField}
                                                className='half-width left-field freedom-mortgage-field mini-form'
                                                name="Zip"
                                                label="Zip Code *"
                                                value={values.Zip}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("Zip", event.target.value)}
                                                error={touched.Zip && Boolean(errors.Zip)}
                                                helperText={touched.Zip && errors.Zip}
                                            />
                                        </Box>

                                        <FormControl
                                            className='full-width-adjusted'
                                            error={touched.clientType && Boolean(errors.clientType)}
                                        >
                                            <Field
                                                name="clientType"
                                                as={RadioGroup}
                                                sx={{ marginTop: '16px' }}
                                                value={values.clientType}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("clientType", event.target.value)}
                                            >
                                                <>
                                                    <FormLabel>ADT Customer Status: *</FormLabel>
                                                    <FormControlLabel
                                                        value="Current ADT Customer"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Radio sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Current ADT Customer"
                                                    />
                                                    <FormControlLabel
                                                        value="Previously an ADT Customer"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Radio sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Previously an ADT Customer"
                                                    />
                                                    <FormControlLabel
                                                        value="Never an ADT Customer"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Radio sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Never an ADT Customer"
                                                    />
                                                </>
                                            </Field>
                                            <FormHelperText>{touched.clientType && errors.clientType}</FormHelperText>
                                        </FormControl>

                                        <FormControl
                                            className='full-width-adjusted'
                                            error={touched.task && Boolean(errors.task)}
                                        >
                                            <FormLabel sx={{ marginTop: '16px' }} component="legend">Task: *</FormLabel>
                                            <Field
                                                name="clientType"
                                                as={FormGroup}

                                                value={values.task}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("task", event.target.value)}
                                            >
                                                <>
                                                    <FormControlLabel
                                                        value="VUI"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Checkbox sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="VUI"
                                                    />
                                                    <FormControlLabel
                                                        value="STIP"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Checkbox sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="STIP"
                                                    />
                                                    <FormControlLabel
                                                        value="First Payment"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Checkbox sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="First Payment"
                                                    />
                                                    <FormControlLabel
                                                        value="Anniversary"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Checkbox sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Anniversary"
                                                    />
                                                </>
                                            </Field>
                                            <FormHelperText>{touched.task && errors.task}</FormHelperText>
                                        </FormControl>

                                        <Box sx={{ marginTop: '8px' }}>
                                            <Typography sx={{ font: 'normal 12px/20px SHS Body Font,sans-serif', color: '#545859', marginBottom: '8px', marginTop: '8px' }}>Below you can add things like the best time to contact you, or anything else that you want your concierge rep to know.</Typography>
                                            <Field
                                                as={TextField}
                                                name="customerNotes"
                                                label="Comments"
                                                className="full-width freedom-mortgage-field mini-form"
                                                multiline
                                                rows={4}
                                                sx={{
                                                    '& div>textarea': {
                                                        zIndex: 99
                                                    },
                                                }}
                                                value={values.customerNotes}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("customerNotes", event.target.value)}
                                                error={touched.customerNotes && Boolean(errors.customerNotes)}
                                                helperText={touched.customerNotes && errors.customerNotes}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography className='my-vu-form-disclaimer'>
                                                By clicking the 'Get a Free Quote' button below, I agree that a Safe Haven team member may contact me via text messages or phone calls to the phone number provided by me using automated technology about ADT offers and consent is not required to make a purchase. Your information is collected and used in accordance with our privacy policy.
                                            </Typography>
                                        </Box>

                                        <Box sx={{ textAlign: 'center' }}>
                                            <LoadingButton
                                                variant='contained'
                                                className='partner-call-btn'
                                                type="submit"
                                                loading={isSubmitting}
                                                disabled={isSubmitting}
                                                sx={{
                                                    maxHeight: '56px',
                                                    minWidth: '100px',
                                                    fontSize: '16px',
                                                    textTransform: 'none',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                {isSubmitting ? 'Submitting...' : 'Submit'}
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                }

                <Stack sx={{ alignItems: 'center', marginBottom: '36px', marginTop: '36px' }}>
                    <Typography className='phh-header' sx={{}}>
                        {pathname === '/partners/veterans-united'
                            ? "A Special Offer for Veterans United Borrowers"
                            : "A special offer for PHH Mortgage Borrowers."
                        }
                    </Typography>
                    <Typography className='phh-sub-header' sx={{ maxWidth: '1024px' }}>
                        {pathname === '/partners/veterans-united'
                            ? "All VU Borrowers receive an upgraded ADT monitoring system PLUS smart smoke detection AND up to $1000 in additional equipment when establishing ADT monitoring•."
                            : "Receive a standard ADT monitoring system AND up to $1,000* in additional equipment when you establish ADT monitoring services*."
                        }


                    </Typography>

                    {pathname === '/partners/veterans-united' &&
                        <Grid container sx={{ maxWidth: '1000px' }} columnSpacing={7}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: "100%",
                                            height: "auto",
                                        }}
                                        src={equipmentVu}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{}}>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: "100%",
                                            height: "auto",
                                        }}
                                        src={voucherVu}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    }

                    {pathname !== '/partners/veterans-united' &&
                        <Link sx={{
                            maxWidth: '750px',
                            cursor: 'pointer',
                            '&:hover>div.rollover': {
                                display: 'block',
                                opacity: 1,
                            },
                            position: 'relative',
                            paddingRight: '5px'
                        }}
                            onClick={handleScrollToForm}>
                            <Box
                                component="img"
                                sx={{
                                    width: "100%",
                                    height: "auto",
                                }}

                                src={phhArrowVoucher}
                            />
                            <Box className='rollover'></Box>
                        </Link>
                    }

                    <Typography className='voucher-disclaimer-text' sx={{ maxWidth: '600px', marginTop: '36px', marginBottom: '36px' }}>
                        {pathname === '/partners/veterans-united' ? (
                            <>
                                *With $199.00 Customer Installation Charge and purchase of alarm monitoring services. <br />
                                Early termination fees apply. See Important Terms and Conditions to this offer below.
                            </>
                        ) : (
                            <>
                                *With $149.00 Customer Installation Charge and purchase of Video Lite alarm monitoring
                                services package. Specific equipment and pricing will vary by package. Early termination
                                fees apply. See important Terms and Conditions to this offer <Link
                                    sx={{ textDecoration: "underline" }}
                                    href="https://mysafehaven.com/terms-and-conditions"
                                    onMouseDown={(event) => {
                                        if (event.button === 1) {
                                            openInNewTab(`https://mysafehaven.com/terms-and-conditions`);
                                        }
                                    }}>
                                    here
                                </Link>.
                            </>
                        )}
                    </Typography>

                    <Box sx={{ textAlign: 'right', marginRight: '0' }}>
                        <Button className='phh-scroll-btn' sx={{ marginBottom: '36px' }} variant="contained" onClick={handleScrollToForm}>GET A FREE QUOTE</Button>
                    </Box>

                    <Box
                        component="img"
                        sx={{
                            width: "100%",
                            height: "auto",
                            marginBottom: '36px'
                        }}
                        src={systemImage}
                    />

                    {pathname === '/partners/veterans-united' &&
                        <>
                            <Typography className='vu-header' sx={{}}>
                                Plans To Fit Any Lifestyle
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    width: "100%",
                                    height: "auto",
                                }}
                                src={plansVu}
                            />
                        </>
                    }
                </Stack>

                {pathname !== '/partners/veterans-united' &&
                    <>
                        <Container maxWidth={false} className="" sx={{ backgroundColor: 'rgb(237, 237, 237)', marginBottom: '36px' }}>
                            <Typography sx={{ padding: '25px', color: '#545859', font: 'normal 600 34px/44px SHS Body Font,sans-serif', textAlign: 'center' }}>
                                Call today to claim your $1000 instant upgrade for additional equipment: <Link href="tel:8339251799" underline="none" className='phh-link' sx={{ color: '#0061aa', font: 'normal 700 34px/44px SHS Body Font,sans-serif' }}>(833) 925-1799</Link>
                            </Typography>
                        </Container>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography className='voucher-disclaimer-text' sx={{ maxWidth: '600px', marginBottom: '36px' }}>
                                {pathname === '/partners/veterans-united' ? (
                                    <>
                                        *With $199.00 Customer Installation Charge and purchase of alarm monitoring services. <br />
                                        Early termination fees apply. See Important Terms and Conditions to this offer below.
                                    </>
                                ) : (
                                    <>
                                        *With $149.00 Customer Installation Charge and purchase of Video Lite alarm monitoring
                                        services package. Specific equipment and pricing will vary by package. Early termination
                                        fees apply. See important Terms and Conditions to this offer <Link
                                            sx={{ textDecoration: "underline" }}
                                            href="https://mysafehaven.com/terms-and-conditions"
                                            onMouseDown={(event) => {
                                                if (event.button === 1) {
                                                    openInNewTab(`https://mysafehaven.com/terms-and-conditions`);
                                                }
                                            }}>
                                            here
                                        </Link>.
                                    </>
                                )}
                            </Typography>
                        </Box>
                    </>
                }

                <Box sx={{ width: '100%', display: { xs: 'none', md: 'flex' }, justifyContent: 'center', marginBottom: '36px' }}>
                    <Box
                        component="img"
                        sx={{
                            maxWidth: "100%",
                            height: "auto",
                        }}
                        src={planIcons}
                    />
                </Box>

                <Grid container spacing={0} sx={{
                    marginBottom: '36px',
                    display: { md: 'none' },
                    '& div': {
                        display: 'flex',
                        justifyContent: 'center'
                    },
                }}>
                    <Grid item xs={12} sm={6}>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "300px",
                                height: "auto",
                                margin: '12px auto'
                            }}
                            src={icon1}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "300px",
                                height: "auto",
                                margin: '12px auto'
                            }}
                            src={icon2}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "300px",
                                height: "auto",
                                margin: '12px auto'
                            }}
                            src={icon3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "300px",
                                height: "auto",
                                margin: '12px auto'
                            }}
                            src={icon4}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "300px",
                                height: "auto",
                                margin: '12px auto'
                            }}
                            src={icon5}
                        />
                    </Grid>
                </Grid>

                {pathname !== '/partners/veterans-united' &&
                    <Box
                        component="img"
                        sx={{
                            width: "100%",
                            height: "auto",
                            marginBottom: '36px'
                        }}
                        src={equipment}
                    />
                }

                {pathname === '/partners/veterans-united' &&
                    <Box
                        component="img"
                        sx={{
                            width: "100%",
                            height: "auto",
                            marginBottom: '36px'
                        }}
                        src={armyVu}
                    />
                }

                {pathname !== '/partners/veterans-united' &&
                    <Container maxWidth={false} className="" sx={{ backgroundColor: 'rgb(237, 237, 237)', marginBottom: '36px' }}>
                        <Typography sx={{ padding: '25px', color: '#545859', font: 'normal 600 34px/44px SHS Body Font,sans-serif', textAlign: 'center' }}>
                            Call now to get a free quote: <Link href="tel:8339251799" underline="none" className='phh-link' sx={{ color: '#0061aa', font: 'normal 700 34px/44px SHS Body Font,sans-serif' }}>(833) 925-1799</Link>
                        </Typography>
                    </Container>
                }

                <Box
                    component="img"
                    sx={{
                        width: "100%",
                        height: "auto",
                        marginBottom: '36px'
                    }}
                    src={sign}
                />

                <Box className="video-responsive" sx={{ marginBottom: '36px' }}>
                    <iframe
                        id="video-wrapper-shs"
                        title="The SmartHome Advantage System"
                        src="https://player.vimeo.com/video/456606979?dnt=1&amp;app_id=122963"
                        width="1200"
                        height="675"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                    />
                </Box>

                {pathname !== '/partners/veterans-united' &&
                    <Grid container sx={{ paddingTop: '50px' }} columnSpacing={7}>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <Typography className='voucher-blue-text'>
                                    Get a free quote and claim your $1000* instant upgrade for additional equipment.
                                </Typography>
                                <Typography className='voucher-body-text' sx={{ marginBottom: '8px' }}>
                                    Ready to customize an ADT plan that works for you? Give us a call at <Link href="tel:8339251799" underline="none" className='voucher-body-phone'>(833) 925-1799</Link>{" "}
                                    to talk to an ADT Specialist today – or fill out the form to have us contact you.
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "100%",
                                        height: "auto",
                                        maxHeight: '350px',
                                    }}
                                    src={voucher}
                                />
                                <Typography className='voucher-disclaimer-text' sx={{ marginTop: '8px' }}>
                                    {pathname === '/partners/veterans-united' ? (
                                        <>
                                            *With $199.00 Customer Installation Charge and purchase of alarm monitoring services. <br />
                                            Early termination fees apply. See Important Terms and Conditions to this offer below.
                                        </>
                                    ) : (
                                        <>
                                            *With $149.00 Customer Installation Charge and purchase of Video Lite alarm monitoring
                                            services package. Specific equipment and pricing will vary by package. Early termination
                                            fees apply. See important Terms and Conditions to this offer <Link
                                                sx={{ textDecoration: "underline" }}
                                                href="https://mysafehaven.com/terms-and-conditions"
                                                onMouseDown={(event) => {
                                                    if (event.button === 1) {
                                                        openInNewTab(`https://mysafehaven.com/terms-and-conditions`);
                                                    }
                                                }}>
                                                here
                                            </Link>.
                                        </>
                                    )}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} ref={formRef}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={async (values, { setSubmitting, resetForm }) => {
                                    try {
                                        const submissionData = {
                                            ...values,
                                            // These were set for my-vu
                                            Partner_Company1: '1166',
                                            Campaign1: '1588',
                                            clientInfo: {
                                                url: window.location.href,
                                            }
                                        };

                                        const leadPostResponse = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                                        console.log("Lead Post Request Sent:", leadPostResponse);

                                        resetForm();
                                    } catch (error) {
                                        console.error("Error while sending request:", error);
                                    } finally {
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({ errors, touched, values, setFieldValue, isSubmitting }) => (
                                    <Form>
                                        <Box className='fields-container freedom-mortgage'>
                                            <Field type="hidden" name="Partner_Company1" />
                                            <Field type="hidden" name="Campaign1" />

                                            <Field
                                                as={TextField}
                                                className='half-width left-field freedom-mortgage-field mini-form'
                                                name="Name"
                                                label="First Name *"
                                                sx={{ minHeight: '65px' }}
                                                value={values.Name}
                                                onChange={(event: any) => setFieldValue("Name", event.target.value)}
                                                error={touched.Name && Boolean(errors.Name)}
                                                helperText={touched.Name && errors.Name}
                                            />


                                            <Field
                                                as={TextField}
                                                className='half-width right-field freedom-mortgage-field mini-form'
                                                name="Last_Name"
                                                label="Last Name *"
                                                sx={{ minHeight: '65px' }}
                                                value={values.Last_Name}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("Last_Name", event.target.value)}
                                                error={touched.Last_Name && Boolean(errors.Last_Name)}
                                                helperText={touched.Last_Name && errors.Last_Name}
                                            />

                                            <Field
                                                as={TextField}
                                                className='full-width left-field freedom-mortgage-field mini-form'
                                                name="Primary_Phone"
                                                label="Phone Number *"
                                                sx={{ minHeight: '65px' }}
                                                value={values.Primary_Phone}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("Primary_Phone", event.target.value)}
                                                error={touched.Primary_Phone && Boolean(errors.Primary_Phone)}
                                                helperText={touched.Primary_Phone && errors.Primary_Phone}
                                            />

                                            <Box className='full-width'>
                                                <Field
                                                    as={TextField}
                                                    className='full-width left-field freedom-mortgage-field mini-form'
                                                    sx={{ minHeight: '65px' }}
                                                    name="address"
                                                    label="Address *"
                                                    value={values.Address}
                                                    onChange={(event: { target: { value: any; }; }) => setFieldValue("Address", event.target.value)}
                                                    error={touched.Address && Boolean(errors.Address)}
                                                    helperText={touched.Address && errors.Address}
                                                />
                                            </Box>

                                            <Box className='full-width'>
                                                <Field
                                                    as={TextField}
                                                    className='full-width left-field freedom-mortgage-field mini-form'
                                                    sx={{ minHeight: '65px' }}
                                                    name="Zip"
                                                    label="Zip Code *"
                                                    value={values.Zip}
                                                    onChange={(event: { target: { value: any; }; }) => setFieldValue("Zip", event.target.value)}
                                                    error={touched.Zip && Boolean(errors.Zip)}
                                                    helperText={touched.Zip && errors.Zip}
                                                />
                                            </Box>

                                            <Field
                                                as={TextField}
                                                className='full-width freedom-mortgage-field mini-form'
                                                name="Email"
                                                label="Email *"
                                                sx={{ minHeight: '65px' }}
                                                value={values.Email}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("Email", event.target.value)}
                                                error={touched.Email && Boolean(errors.Email)}
                                                helperText={touched.Email && errors.Email}
                                            />

                                            <Box sx={{ textAlign: 'center' }}>
                                                <Typography sx={{
                                                    color: '#545859',
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    lineHeight: '16px',
                                                    margin: '16px auto 16px auto',
                                                }}>
                                                    By clicking ‘Submit,’ I consent to being contacted by Safe Haven Security Services, LLC, Safe Haven Solar, LLC, or All Access Service, LLC regarding products and services via live, automated, or prerecorded telephone calls, text messages*, or emails, even if I’m on a Do Not Call list. Consent is not a condition of purchase. I can revoke this consent by emailing dnc@mysafehaven.com.
                                                </Typography>

                                                <LoadingButton
                                                    variant='contained'
                                                    className='phh-scroll-btn'
                                                    type="submit"
                                                    loading={isSubmitting}
                                                    disabled={isSubmitting}
                                                    sx={{
                                                        maxHeight: '56px',
                                                        minWidth: '100px',
                                                        fontSize: '16px',
                                                        textTransform: 'none',
                                                        fontWeight: '700',
                                                    }}
                                                >
                                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                                </LoadingButton>
                                            </Box>

                                            <Typography sx={{
                                                color: '#545859',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                lineHeight: '16px',
                                                margin: '16px auto auto auto',
                                            }}>
                                                * Standard message and data rates may apply to text messages. You may opt out of receiving further text messages at any time by replying STOP.
                                                <br></br>
                                                † Your information is collected and used in accordance with Safe Haven’s {" "}
                                                <Link
                                                    sx={{ textDecoration: "underline" }}
                                                    href="https://mysafehaven.com/legal/privacy"
                                                    onMouseDown={(event) => {
                                                        if (event.button === 1) {
                                                            openInNewTab(`https://mysafehaven.com/legal/privacy`);
                                                        }
                                                    }}>
                                                    Privacy Policy
                                                </Link>.
                                            </Typography>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                }

                {pathname !== '/partners/veterans-united' &&
                    <Container maxWidth={false} className="" sx={{ backgroundColor: 'rgb(237, 237, 237)', marginTop: '36px', marginBottom: '36px' }}>
                        <Typography sx={{ padding: '25px', color: '#545859', font: 'normal 600 34px/44px SHS Body Font,sans-serif', textAlign: 'center' }}>
                            Call now to get a free quote: <Link href="tel:8339251799" underline="none" className='phh-link' sx={{ color: '#0061aa', font: 'normal 700 34px/44px SHS Body Font,sans-serif' }}>(833) 925-1799</Link>
                        </Typography>
                    </Container>
                }

                <GoogleReviews placeId={SH_GOOGLE_PLACE_ID}></GoogleReviews>
            </Container>

            <Box className='disclaimer-footer'>
                <Typography>
                    License numbers by State: AL-1493, AR- CMPY.0002629, AZ- ROC-332758,18404-0, CA-ACO8016, CT-HIC.0661825, DC-602521000002, DE-17-39, FL-EF20000905, GA-LVU406182, IA-AS-0158, C115438, IL-127.001386, LA-F1672, MA-8150 A1, SS-002127, MD-107-1696, MI-3601208054, MN-TS710056, MS-15019531, NC-1150-CSA, SP.FA/LV.28805, NH-064, NJ- 34BX00006600, NM-403946, NV-0079533, NY-12000077734, OK-AC1802, OR-234026, PA-PA040539, RI-4604, TSC8204, SC-BAC.13574, TN-2280, TX-B14839,ACR-2625584, UT-10999117-6501, VA-11-6402, WA-SAFEHHS791CH, WV-WV060562
                </Typography>
                <Typography className='' sx={{ marginTop: '12px', color: '#ffffff', font: 'normal 14px / 24px SHS Body Font,sans-serif !important', textAlign: 'center' }}>
                    + <Link sx={{ color: '#ffffff', textDecoration: 'underline' }} href="https://www.adt.com/about-adt/legal/residential-terms-and-conditions"
                        onMouseDown={(event) => {
                            if (event.button === 1) {
                                openInNewTab(`https://www.adt.com/about-adt/legal/residential-terms-and-conditions`);
                            }
                        }}>ADT Disclaimers</Link>
                    <br></br>
                    ++ <Link sx={{ color: '#ffffff', textDecoration: 'underline' }} href="https://www.adt.com/about-adt/legal/residential-terms-and-conditions"
                        onMouseDown={(event) => {
                            if (event.button === 1) {
                                openInNewTab(`https://www.adt.com/about-adt/legal/residential-terms-and-conditions`);
                            }
                        }}>ADT Disclaimers</Link>
                </Typography>
                <Typography>
                    Copyright: Smart Home Advantage 2021 | Terms & Conditions
                </Typography>
            </Box>
        </Box>
    );
} 