import incAward2 from "../Assets/Home/Awards/inc. 5000 award.gif";
import incAward from "../Assets/Home/Awards/inc. 5000 award.png";
import newsweekAward1 from "../Assets/Home/Awards/Newsweek 2023 Greatest Work Places.jpg";
import bizJrnlAward from "../Assets/Home/Awards/kc-business-journal-1.svg";
import newsweekAward2 from "../Assets/Home/Awards/Newsweek Diversity_2024.png";
import newsweekAward3 from "../Assets/Home/Awards/Americas_Greatest_Workplaces_2023_GENERAL-Banner.jpeg";

import team1 from '../Assets/Careers/FeaturedTeams/Recruiter 02.jpg';
import team2 from '../Assets/Careers/FeaturedTeams/Technician with Homeowners 01 (2).png';
import team3 from '../Assets/Careers/FeaturedTeams/Install Video Doorbell 01 (1).jpg';
import team4 from '../Assets/Careers/FeaturedTeams//Concierge team.jpg';

export const HEADER_HEIGHT = "100px";

export const EMPTY_EMPLOYEE_PHOTO =
	"https://api.securitytrax.com/safehaven/user/v1/profile-image/W9uaWFUcmNTY2NDQ=lbWVudC";

export const STATE_CODES = [
	{ value: "AL", label: "Alabama" },
	{ value: "AK", label: "Alaska" },
	{ value: "AZ", label: "Arizona" },
	{ value: "AR", label: "Arkansas" },
	{ value: "CA", label: "California" },
	{ value: "CO", label: "Colorado" },
	{ value: "CT", label: "Connecticut" },
	{ value: "DC", label: "District of Columbia" },
	{ value: "DE", label: "Delaware" },
	{ value: "FL", label: "Florida" },
	{ value: "GA", label: "Georgia" },
	{ value: "HI", label: "Hawaii" },
	{ value: "ID", label: "Idaho" },
	{ value: "IL", label: "Illinois" },
	{ value: "IN", label: "Indiana" },
	{ value: "IA", label: "Iowa" },
	{ value: "KS", label: "Kansas" },
	{ value: "KY", label: "Kentucky" },
	{ value: "LA", label: "Louisiana" },
	{ value: "ME", label: "Maine" },
	{ value: "MD", label: "Maryland" },
	{ value: "MA", label: "Massachusetts" },
	{ value: "MI", label: "Michigan" },
	{ value: "MN", label: "Minnesota" },
	{ value: "MS", label: "Mississippi" },
	{ value: "MO", label: "Missouri" },
	{ value: "MT", label: "Montana" },
	{ value: "NE", label: "Nebraska" },
	{ value: "NV", label: "Nevada" },
	{ value: "NH", label: "New Hampshire" },
	{ value: "NJ", label: "New Jersey" },
	{ value: "NM", label: "New Mexico" },
	{ value: "NY", label: "New York" },
	{ value: "NC", label: "North Carolina" },
	{ value: "ND", label: "North Dakota" },
	{ value: "OH", label: "Ohio" },
	{ value: "OK", label: "Oklahoma" },
	{ value: "OR", label: "Oregon" },
	{ value: "PA", label: "Pennsylvania" },
	{ value: "RI", label: "Rhode Island" },
	{ value: "SC", label: "South Carolina" },
	{ value: "SD", label: "South Dakota" },
	{ value: "TN", label: "Tennessee" },
	{ value: "TX", label: "Texas" },
	{ value: "UT", label: "Utah" },
	{ value: "VT", label: "Vermont" },
	{ value: "VA", label: "Virginia" },
	{ value: "WA", label: "Washington" },
	{ value: "WV", label: "West Virginia" },
	{ value: "WI", label: "Wisconsin" },
	{ value: "WY", label: "Wyoming" },
];

export const STATE_LIST = [
	"",
	"Alabama",
	"Alaska",
	"Arizona",
	"Arkansas",
	"California",
	"Colorado",
	"Connecticut",
	"District of Columbia",
	"Delaware",
	"Florida",
	"Georgia",
	"Hawaii",
	"Idaho",
	"Illinois",
	"Indiana",
	"Iowa",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Maine",
	"Maryland",
	"Massachusetts",
	"Michigan",
	"Minnesota",
	"Mississippi",
	"Missouri",
	"Montana",
	"Nebraska",
	"Nevada",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"New York",
	"North Carolina",
	"North Dakota",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"Utah",
	"Vermont",
	"Virginia",
	"Washington",
	"West Virginia",
	"Wisconsin",
	"Wyoming",
];

export const HIDE_HEADER_PATHS = [
	"/partners/century-model",
	"/partners/all-access-service",
	"/partners/",
	"/partners/confirmation",
	"/partners/drh-model",
];
export const HIDE_FOOTER_PATHS = [
	"/NerdWallet/",
	"/nerdwallet/",
	"/NerdWallet",
	"/nerdwallet",
	"/partners/century-model",
	"/partners/all-access-service",
	"/partners/marketing-essentials",
	"/partners/drh-model",
];

export const TRAX_LEAD = [
	"/partners/perfect-vision-pg-latin",
	"/partners/perfect-vision-qrs-legacy",
	"/partners/perfect-vision-ht-visions",
	"/partners/perfect-vision-stallions",
	"/partners/perfect-vision-new-wave",
	"/partners/ahp",
	"/partners/perfect-vision-white-gloves",
	"/partners/perfect-vision-solaris-smart-home",
	"/partners/perfect-vision-lisam",
	"/partners/perfect-vision-all-in-one",
	"/perfect-vision-power-on-tech",
	"/partners/perfect-vision-connection-one",
	"/partners/perfect-vision-title-tronix",
	"/partners/perfect-vision-pelican",
	"/partners/perfect-vision-coreceptive",
	"/partners/perfect-vision-linking-flux",
	"/partners/perfect-vision-asdm",
	"/partners/perfect-vision-pyp-sales",
	"/partners/perfect-vision-simply-activate",
	"/partners/perfect-vision-simply-dartech",
	"/partners/perfect-vision-green-haya",
	"/partners/perfect-vision-ibuyer",
	"/partners/perfectvisionstarms",
	"/partners/perfect-vision-max-vision",
	"/partners/perfect-vision-right-link",
	"/partners/perfect-vision-citizen-home",
	"/partners/perfect-vision-rbreeze",
	"/partners/perfect-vision-bundle-finders",
	"/partners/perfect-vision-cable",
	"/partners/perfect-vision-corecentrix",
	"/partners/perfect-vision-ipfa",
	"/partners/perfect-vision-mmr",
	"/partners/perfect-vision-bamo",
	"/partners/perfect-vision-iconcomm",
	"/partners/perfect-vision-satcomm",
	"/partners/perfect-vision-sell-well",
	"/partners/perfect-vision-sj-global",
	"/partners/perfect-vision-summit-media",
	"/partners/perfect-vision-astros-digital",
	"/partners/perfect-vision-gem",
	"/partners/perfect-vision-enm-alpha",
	"/partners/perfect-vision-avd-solutions",
	"/partners/perfect-vision-compelpaso",
	"/partners/perfect-vision-weatherford",
	"/partners/perfectvision-emaan-commerce",
	"/partners/perfect-vision-xtrategy",
	"/partners/perfect-vision-jamalprime",
	"/partners/perfect-vision-camcom",
	"/partners/perfect-vision-blacksheep",
	"/partners/perfect-vision-medicators",
	"/partners/perfect-vision",
	"/partners/isg",
	"/partners/llpenterprises",
	"/partners/my-home-group",
	"/partners/pmh-sold",
	"/partners/perfect-vision-intechnologies",
	"/partners/perfect-vision-leilani",
	"/partners/perfect-vision-gabcyn",
	"/partners/perfect-vision-rexza",
	"/partners/perfect-vision-alliance",
	"/partners/perfect-vision-elina",
];

export const CONSENT_KEY = "cookie-consent";

export const AWARD_IMAGES = [
	{
		image: incAward,
		text: "Ranked in Inc. Magazine's 2023 Fastest Growing Private Companies",
	},
	{
		image: newsweekAward1,
		text: "Newsweek's \"America's Greatest Workplaces 2023\"",
	},
	{
		image: bizJrnlAward,
		text: "KC Business Journal's Largest Private Companies 2023 & 2024",
	},
	{
		image: newsweekAward2,
		text: "America's Greatest Workplaces for Diversity  2024",
	},
	{
		image: incAward,
		text: "Ranked in Inc. Magazine's 2023 Fastest Growing Private Companies",
	},
	{
		image: newsweekAward1,
		text: "Newsweek's \"America's Greatest Workplaces 2023\"",
	},
	{
		image: bizJrnlAward,
		text: "KC Business Journal's Largest Private Companies 2023 & 2024",
	},
	{
		image: newsweekAward2,
		text: "America's Greatest Workplaces for Diversity 2024",
	},
];

export const HOMEPAGE_REVIEWS = [
	{
		text: "I just want to say a big thank you to Steven. Very helpful, answered all my questions and fit everything into my schedule most of all he explained everything thoroughly. I would definitely recommend him to anyone looking for this service. ",
		name: "Seereena R.",
	},
	{
		text: "Working with Nader, he made everything easier to navigate and setting up appointment or helping with any questions even easier! Wouldn't have preferred to work with anyone else. I would recommend working with Nader to anyone! ",
		name: "Victoria N.",
	},
	{
		text: "My rep Najee was very knowledgeable and professional. I appreciate how Najee handled everything, making the details easy to understand and appreciated my time. Thanks again Najee!  ",
		name: "Tamar T.",
	},
	{
		text: "Liz was great with helping explain the services provided. She was thorough, kind, and patient when we had technical difficulties. Thanks again! ",
		name: "Anishea S.",
	},
];

export type LOCATION_TYPE = "_blank" | "_self" | "_parent" | "_top";

export const FEATURED_TEAMS = [
	{
		image: team1,
		heading: 'Inside Sales',
		text: 'Become an Inside Sales Specialist to help protect families and earn uncapped commissions.'
	},
	{
		image: team2,
		heading: 'Outside Sales',
		text: 'Become an Outside Sales Rep to provide custom security solutions, protect families, and enjoy great benefits.'
	},
	{
		image: team3,
		heading: 'Field Technician',
		text: 'Join us as an Installation Technician with competitive pay, incentives, and benefits.'
	},
	{
		image: team4,
		heading: 'Customer Support Specialist',
		text: 'Deliver exceptional service'
	},
	{
		image: team1,
		heading: 'Inside Sales',
		text: 'Become an Inside Sales Specialist to help protect families and earn uncapped commissions.'
	},
	{
		image: team2,
		heading: 'Outside Sales',
		text: 'Become an Outside Sales Rep to provide custom security solutions, protect families, and enjoy great benefits.'
	},
	{
		image: team3,
		heading: 'Field Technician',
		text: 'Join us as an Installation Technician with competitive pay, incentives, and benefits.'
	},
	{
		image: team4,
		heading: 'Customer Support Specialist',
		text: 'Deliver exceptional service'
	},
];
