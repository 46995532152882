import { createSlice } from '@reduxjs/toolkit';

export const footerSlice = createSlice({
  name: 'header',
  initialState: {
    display: true,
    aboutUs: false,
  },
  reducers: {
    disable: (state) => {
      state.display = false;
    },
    enable: (state) => {
      state.display = true;
    },
    toggle: (state, action) => {
      if (action.payload == 'show' || action.payload == 'hide') {
        state.display = action.payload;
      }
    },
    showAboutUs: (state) => {
      state.aboutUs = true;
    },
    hideAboutUs: (state) => {
      state.aboutUs = false;
    },
  },
});

export const { disable, enable, toggle, showAboutUs, hideAboutUs } =
  footerSlice.actions;

export default footerSlice.reducer;
