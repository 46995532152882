import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import mainRep from "../../Assets/Careers/Recruiter 05.jpg";
import realtorTeam from "../../Assets/Careers/Realtor_01.jpg";
import award1 from "../../Assets/Home/Awards/Newsweek Diversity_2024.png";
import award2 from "../../Assets/Home/Awards/Americas_Greatest_Workplaces_2023_GENERAL-Banner.jpeg";
import technicianWithHomeowners from "../../Assets/Careers/Technician with Homeowners 02 (1).jpg";
import groupPhoto from "../../Assets/Careers/SH olympics 07 (1).jpg";
import groupPhotoMobile from "../../Assets/Careers/SH olympics 07 (1)_mobile.jpg";
import ScrollToTopButton from "../../Components/ScrollToTopButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SideBySide from "../../Components/SideBySide";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { AWARD_IMAGES, FEATURED_TEAMS } from "../../Constants";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import {
	Navigation,
	Pagination as SwiperPagination,
	Pagination,
} from "swiper";
import "./index.scss";
import useFetch from "use-http";
import usePagination from "../../utils/pagination";

export default function Careers() {
	let apiKey = process.env.REACT_APP_API__ACCESS_KEY
		? process.env.REACT_APP_API__ACCESS_KEY
		: "";
	const options = {
		headers: {
			"Content-Type": "application/json",
			Connection: "keep-alive",
			"Accept-Encoding": "gzip, deflate, br",
			"X-API-Key": apiKey,
		},
		cacheLife: 1000,
	};
	const [careers, setCareers] = React.useState<any>([]);
	const [keywordSearch, setKeywordSearch] = React.useState("");
	const itemsPerPage: number = 5; // Set the number of items per page
	const {
		paginatedData,
		nextPage,
		prevPage,
	} = usePagination<any>(careers, itemsPerPage);
	const { get, post } = useFetch(
		process.env.REACT_APP_API,
		options,
	);

	// Scroll To Top Button Logic (Needs to be moved to own component eventually)
	const [showSttButton, setShowSttButton] = useState(false);
	const [locationSearch, setLocationSearch] = useState("Kansas City");
	const SHOW_SCROLL_BUTTON_THRESHOLD = 300;
	useEffect(() => {
		const scrollListener = () => {
			setShowSttButton(window.scrollY > SHOW_SCROLL_BUTTON_THRESHOLD);
		};
		window.addEventListener("scroll", scrollListener);
		return () => window.removeEventListener("scroll", scrollListener);
	}, []);

	// Form Logic
	const initialValues = {
		keyword: "",
		location: "",
	};

	const validationSchema = Yup.object({
		keyword: Yup.string().required("Required"),
		location: Yup.string().required("Required"),
	});

	// Job Search Logic
	const [location, setLocation] = React.useState("");

	const [division, setDivision] = React.useState("");
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		window.innerWidth < 500 ? 2 : window.innerWidth < 900 ? 6 : 9,
	);
	const [pageCount, setPageCount] = React.useState(0);
	const [totalRows, setTotalRows] = React.useState(0);

	// useEffect(() => {
	// 	getJobListings();
	// }, []);

	const getJobListings = async () => {
		const res = await get(`/JobSearch/getFullJobList`);
		if (res) {
			console.log(res);
			setCareers(res);
		}
	};
	const handleLocationChange = async (event: SelectChangeEvent) => {
		const res = await post(`/JobSearch/searchJobPostings`, {
			jobSearchStr: "Sales",
			locationSearchStr: event.target.value,
		});

		if (res) {
			console.log(res);
			setCareers(res.length > 0 ? res : []);
		}
		setLocation(event.target.value);
		// getTotalPageCount(_careers.meta.total, rowsPerPage);
	};

	function getTotalPageCount(totalRows: any, rowsPerPage: any) {
		const pageCount = Math.ceil(totalRows / rowsPerPage);
		setPageCount(pageCount);
	}

	const handleDivisionChange = async (event: SelectChangeEvent) => {
		const res = await post(`/JobSearch/searchJobPostings`, {
			jobSearchStr: "Sales",
			locationSearchStr: event.target.value,
		});

		if (res) {
			console.log(res);
			setCareers(res.length > 0 ? res : []);
		}

		setDivision(event.target.value);
		setCareers([]);
		setPage(1);
	};

	// Teams Swiper Logic
	const teamsSwiperRef = useRef<SwiperRef | null>(null);

	const handleTeamsPrev = () => {
		if (teamsSwiperRef.current && teamsSwiperRef.current.swiper) {
			teamsSwiperRef.current.swiper.slidePrev(500, true);
		}
	};

	const handleTeamsNext = () => {
		if (teamsSwiperRef.current && teamsSwiperRef.current.swiper) {
			teamsSwiperRef.current.swiper.slideNext(500, true);
		}
	};

	// Award Swiper Logic
	const awardSwiperRef = useRef<SwiperRef | null>(null);

	const handleAwardPrev = () => {
		if (awardSwiperRef.current && awardSwiperRef.current.swiper) {
			awardSwiperRef.current.swiper.slidePrev(500, true);
		}
	};

	const handleAwardNext = () => {
		if (awardSwiperRef.current && awardSwiperRef.current.swiper) {
			awardSwiperRef.current.swiper.slideNext(500, true);
		}
	};

	const featuredTeamsRef = useRef<HTMLDivElement>(null);
	const featuredTeamsRefMobileRef = useRef<HTMLDivElement>(null);

	const handleLearnMoreDesktopClick = () => {
		if (featuredTeamsRef.current) {
			const elementRect = featuredTeamsRef.current.getBoundingClientRect();
			window.scrollTo({
				top: elementRect.top + window.scrollY - 150, // Use window.scrollY
				behavior: "smooth",
			});
		}
	};

	const handleLearnMoreMobileClick = () => {
		if (featuredTeamsRef.current) {
			const elementRect = featuredTeamsRef.current.getBoundingClientRect();
			window.scrollTo({
				top: elementRect.top + window.scrollY - 25,
				behavior: "smooth",
			});
		}
	};

	return (
		<>
			<Helmet>
				<title>Careers | Safe Haven</title>
				<meta
					name="description"
					content="Join Safe Haven and build a rewarding career. We value your potential and empower you to make a positive impact."
				/>
				<meta
					name="keywords"
					content="Safe Haven careers, security jobs, career opportunities, work ethic, passion, teamwork, make a difference, rewarding career"
				/>
			</Helmet>

			<ScrollToTopButton showButton={showSttButton}></ScrollToTopButton>

			{/* Desktop: Main Rep Image */}
			<Box
				sx={{
					display: { xs: "none", md: "grid" },
					maxWidth: "1500px",
					margin: "auto",
				}}
			>
				<Box
					sx={{
						position: "relative",
						display: "grid",
						gridColumn: "1",
						gridRow: "1",
					}}
				>
					<Box
						component="img"
						sx={{
							gridColumn: "1",
							gridRow: "1",
							display: "block",
							width: "100%",
							maxWidth: "100%",
							height: "auto",
							margin: "auto",
							filter: 'blur(10px)',
							transition: 'filter 0.5s ease'
						}}
						alt="Customer service rep with headset."
						src={mainRep}
						onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
							const target = e.target as HTMLImageElement;
							target.style.filter = 'blur(0px)';
						}}
					/>
					<Box
						sx={{
							gridColumn: "1",
							gridRow: "1",
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.6)",
							zIndex: 1
						}}
					/>
				</Box>

				<Box
					className=""
					sx={{
						width: '100%',
						gridColumn: '1',
						gridRow: '1',
						color: '#ffffff',
						textAlign: 'center',
						zIndex: 1
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
							alignItems: 'center',
							flexWrap: 'wrap',
							height: '100%'
						}}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginTop: { lg: '100px' }, marginBottom: '32px', textAlign: 'center', width: '100%' }}>
							<Typography
								variant="h1"
								sx={{
									fontSize: { md: "48px", lg: "48px", xl: "62px" },
									fontWeight: "700",
									letterSpacing: "2px",
									maxWidth: { md: "800px", lg: "1000px" },
								}}
							>
								Help Protect Homes, Change Lives, Build Your Future.
							</Typography>
							<Typography
								sx={{
									textAlign: "center",
									margin: "48px auto auto auto",
									fontSize: "28px",
								}}
							>
								Advance your career while working in a culture that puts employees
								first.
							</Typography>
							<Box sx={{ display: "flex", maxWidth: "500px", margin: "auto" }}>
								<Button
									className="home-free-quote-btn"
									sx={{
										display: "flex",
										backgroundColor: "#1260a8",
										color: "#ffffff",
										borderRadius: "30px",
										height: "45px",
										maxHeight: "45px",
										fontSize: "14px",
										margin: "48px 16px auto auto",
										padding: "0 24px",
										textTransform: "capitalize",
										"&.home-free-quote-btn:hover": {
											backgroundColor: "#1260a8!important",
										},
										"&.home-free-quote-btn>span": {
											marginLeft: "4px",
										},
									}}
									onClick={() => {
										handleLearnMoreDesktopClick();
									}}
								>
									Learn More
								</Button>
								<Button
									className="home-free-quote-btn"
									onClick={() => {
										//opne new tab
										window.open(
											"https://myjobs.adp.com/safehavencs/cx",
											"_blank",
										);
									}}
									sx={{
										display: "flex",
										backgroundColor: "transparent",
										outline: "2px solid #ffffff",
										color: "#ffffff",
										borderRadius: "30px",
										height: "45px",
										maxHeight: "45px",
										fontSize: "14px",
										margin: "48px auto auto 16px",
										padding: "0 24px",
										textTransform: "capitalize",
										"&.home-free-quote-btn:hover": {
											backgroundColor: "#1260a8!important",
										},
										"&.home-free-quote-btn>span": {
											marginLeft: "4px",
										},
									}}
								>
									Search Jobs
								</Button>
							</Box>
						</Box>

					</Box>


				</Box>
			</Box>

			{/* Mobile: Main Rep Image */}
			<Box
				sx={{
					display: { xs: "grid", md: "none" },
					justifyContent: "center",
					maxWidth: "100vw",
					overflow: "hidden",
				}}
			>
				<Box
					sx={{
						position: "relative",
						display: "grid",
						gridColumn: "1",
						gridRow: "1",
					}}
				>
					<Box
						component="img"
						sx={{
							gridColumn: "1",
							gridRow: "1",
							display: { xs: "block", sm: "none" },
							width: "auto",
							maxWidth: { xs: "100%", sm: "100%" },
							height: "auto",
							margin: "auto",
							filter: "blur(10px)",
							transition: "filter 0.5s ease",
						}}
						alt="The house from the offer."
						src={groupPhotoMobile}
						loading="lazy"
						onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
							const target = e.target as HTMLImageElement;
							target.style.filter = "blur(0px)";
						}}
					/>

					<Box
						component="img"
						sx={{
							gridColumn: "1",
							gridRow: "1",
							display: { xs: "none", sm: "block" },
							width: "auto",
							maxWidth: { xs: "100%", sm: "100%" },
							height: "auto",
							margin: "auto",
							filter: "blur(10px)",
							transition: "filter 0.5s ease",
						}}
						alt="The house from the offer."
						src={groupPhoto}
						loading="lazy"
						onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
							const target = e.target as HTMLImageElement;
							target.style.filter = "blur(0px)";
						}}
					/>
					<Box
						sx={{
							gridColumn: "1",
							gridRow: "1",
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.5)",
							zIndex: 1,
						}}
					/>

					<Box
						className="md-translate-override"
						sx={{
							minWidth: "1200px",
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: {
								xs: "translate(-50%, -50%)",
								sm: "translate(-50%, -50%)",
								md: "translate(-50%, -70%)",
							},
							color: "#ffffff",
							zIndex: 2,
						}}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
								alignItems: "center",
								marginBottom: { xs: "18px", sm: "32px" },
								textAlign: "center",
							}}
						>
							<Typography
								variant="h1"
								sx={{
									fontSize: { xs: "28px", sm: "40px", md: "32px" },
									fontWeight: "700",
									letterSpacing: "2px",
									maxWidth: {
										xs: "calc(100vw - 16px)",
										sm: "650px",
										md: "800px",
										lg: "1000px",
									},
								}}
							>
								Help Protect Homes, Change Lives, Build Your Future.
							</Typography>
						</Box>
						<Typography
							sx={{
								textAlign: "center",
								margin: "48px auto auto auto",
								fontSize: { xs: "18px", sm: "24px", md: "24px" },
								maxWidth: { xs: "calc(100vw - 16px)" },
							}}
						>
							Advance your career while working in a culture that puts employees
							first.
						</Typography>
						<Box sx={{ display: "flex", maxWidth: "500px", margin: "auto" }}>
							<Button
								className="home-free-quote-btn"
								sx={{
									display: "flex",
									backgroundColor: "#1260a8",
									color: "#ffffff",
									borderRadius: "30px",
									height: "45px",
									maxHeight: "45px",
									fontSize: "14px",
									margin: "48px auto auto auto",
									padding: "0 24px",
									textTransform: "capitalize",
									"&.home-free-quote-btn:hover": {
										backgroundColor: "#1260a8!important",
									},
									"&.home-free-quote-btn>span": {
										marginLeft: "4px",
									},
								}}
								onClick={() => {
									handleLearnMoreDesktopClick();
								}}
							>
								Learn More
							</Button>
							<Button
								className="home-free-quote-btn"
								onClick={() => {
									//opne new tab
									window.open(
										"https://myjobs.adp.com/safehavencs/cx",
										"_blank",
									);
								}}
								sx={{
									display: "flex",
									backgroundColor: "transparent",
									outline: "2px solid #ffffff",
									color: "#ffffff",
									borderRadius: "30px",
									height: "45px",
									maxHeight: "45px",
									fontSize: "14px",
									margin: "48px auto auto auto",
									padding: "0 24px",
									textTransform: "capitalize",
									"&.home-free-quote-btn:hover": {
										backgroundColor: "#1260a8!important",
									},
									"&.home-free-quote-btn>span": {
										marginLeft: "4px",
									},
								}}
							>
								Search Jobs
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* Teams Carousel Section*/}
			<Box
				ref={featuredTeamsRef}
				sx={{ marginTop: "48px", marginBottom: { xs: "48px", md: "124px" }, overflow: 'hidden' }}
			>
				{/* Heading and Navigation */}
				<Container
					sx={{
						display: "flex",
						justifyContent: "space-between",
						flexDirection: { xs: "column", sm: "row", alignItems: "center" },
					}}
				>
					<Box>
						<Typography
							sx={{
								color: "rgb(139, 221, 249)",
								textTransform: "uppercase",
								fontWeight: "700",
								marginBottom: "8px",
								fontSize: "16px",
								letterSpacing: "1px",
							}}
						>
							EXPLORE OUR TOP ROLES AT SAFE HAVEN SECURITY
						</Typography>
						<Typography
							sx={{
								fontSize: { xs: "42px", md: "56px" },
								lineHeight: "48px",
								marginBottom: "16px",
								marginTop: "24px",
							}}
						>
							Featured <strong>Teams</strong>
						</Typography>
					</Box>

					<Box
						sx={{
							display: { xs: "none", sm: "flex" },
							justifyContent: "center",
							marginTop: "8px",
							zIndex: 2,
						}}
					>
						<Box sx={{ margin: "18px" }}>
							<IconButton
								className="award-carousel-nav-button"
								aria-label="delete"
								onClick={handleTeamsPrev}
								sx={{
									backgroundColor: "rgb(68,200,245)",
									minHeight: "50px",
									minWidth: "50px",
								}}
							>
								<WestIcon sx={{ color: "#fff" }} />
							</IconButton>
						</Box>

						<Box sx={{ margin: "18px" }}>
							<IconButton
								className="award-carousel-nav-button"
								aria-label="delete"
								onClick={handleTeamsNext}
								sx={{
									backgroundColor: "rgb(68,200,245)",
									minHeight: "50px",
									minWidth: "50px",
								}}
							>
								<EastIcon sx={{ color: "#fff" }} />
							</IconButton>
						</Box>
					</Box>
				</Container>

				{/* Swiper Component */}
				<Container sx={{ margin: "44px auto 44px auto", position: "relative" }}>
					<Swiper
						style={{ height: "100%", width: "calc(100% - 16px)" }}
						loop={true}
						ref={teamsSwiperRef}
						className="about-us-swiper careers-teams-carousel"
						navigation={false}
						spaceBetween={25}
						modules={[SwiperPagination, Navigation]}
						breakpoints={{
							0: {
								slidesPerView: 1,
							},
							600: {
								slidesPerView: 2,
							},
							1200: {
								slidesPerView: 3,
							},
						}}
					>
						{FEATURED_TEAMS.map((team, index) => (
							<SwiperSlide
								key={index}
								className={`about-us-swiper-slide-${index}`}
								style={{
									display: "flex",
									justifyContent: "center",
									height: "100%",
									paddingRight: "1px",
								}}
							>
								<Box
									sx={{
										border: "1px solid #cbcbcb",
										display: "flex",
										alignItems: "center",
										flexDirection: "column",
										justifyContent: "space-between",
										height: "500px",
										width: "100%",
										position: "relative",
									}}
								>
									<Container
										sx={{
											width: "100%",
											padding: "56px 56px 12px 56px!important",
											justifyContent: "space-between",
											display: "flex",
											flexDirection: "column",
											height: "300px",
										}}
									>
										<Typography
											variant="body2"
											sx={{
												fontSize: "28px",
												lineHeight: "32px",
												marginBottom: "0",
												color: "rgb(0, 83, 156)",
												fontWeight: 700,
											}}
										>
											{team.heading}
										</Typography>
										<Typography
											variant="body2"
											sx={{
												fontSize: "18px",
												lineHeight: "24px",
												marginBottom: "16px",
											}}
										>
											{team.text}
										</Typography>

										<Box sx={{ padding: "" }}>
											<Button
												variant="text"
												onClick={() => {
													nextPage();
													window.open(
														"https://myjobs.adp.com/safehavencs/cx",
														"_blank",
													);
												}}
												className="home-free-quote-btn"
												sx={{
													color: "rgb(0,83,156)",
													height: "45px",
													maxHeight: "45px",
													fontSize: "14px",
													fontWeight: "700",
													letterSpacing: "1px",
													textTransform: "capitalize",
													zIndex: "3",
												}}
												endIcon={
													<EastIcon
														id="btn-arrow-slide-right"
														sx={{ height: "14px", paddingBottom: "1px" }}
													/>
												}
											>
												APPLY NOW
											</Button>
										</Box>
									</Container>

									<Box
										sx={{
											height: "auto",
											maxHeight: "200px",
											display: "flex",
											alignItems: "center",
											width: "100%",
											overflow: 'hidden'
										}}
									>
										<Box
											component="img"
											loading="lazy"
											sx={{
												height: "auto",
												width: "100%",
												maxWidth: "100%",
											}}
											alt={`Product ${index + 1}`}
											src={team.image}
										/>
									</Box>
								</Box>
							</SwiperSlide>
						))}
					</Swiper>

					<Box
						sx={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "24px",
							height: "100%",
							background:
								"linear-gradient(to right, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 100%)",
							zIndex: 1,
						}}
					/>

					<Box
						sx={{
							position: "absolute",
							top: 0,
							right: -24,
							width: "36px",
							height: "100%",
							background:
								"linear-gradient(to left, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%)",
							zIndex: 1,
						}}
					/>

					<Box
						sx={{
							display: { xs: "flex", sm: "none" },
							justifyContent: "center",
							marginTop: "8px",
							zIndex: 2,
						}}
					>
						<Box sx={{ margin: "18px" }}>
							<IconButton
								href=""
								className="award-carousel-nav-button"
								aria-label="delete"
								onClick={handleTeamsPrev}
								sx={{
									backgroundColor: "rgb(68,200,245)",
									minHeight: "50px",
									minWidth: "50px",
								}}
							>
								<WestIcon sx={{ color: "#fff" }} />
							</IconButton>
						</Box>

						<Box sx={{ margin: "18px" }}>
							<IconButton
								className="award-carousel-nav-button"
								aria-label="delete"
								onClick={handleTeamsNext}
								sx={{
									backgroundColor: "rgb(68,200,245)",
									minHeight: "50px",
									minWidth: "50px",
								}}
							>
								<EastIcon sx={{ color: "#fff" }} />
							</IconButton>
						</Box>
					</Box>
				</Container>
			</Box>

			{/* Job Search */}
			<Box>
				<Box
					sx={{
						backgroundColor: "rgb(0, 83, 156)",
						padding: { xs: "20px", md: "112px" },
					}}
				>
					<Container>
						<Typography
							sx={{
								color: "#ffffff",
								fontSize: { xs: "20px", md: "48px" },
								marginBottom: "32px",
							}}
						>
							Discover <strong>Your Career at Safe Haven</strong>
						</Typography>
						<Box>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={async (values, { setSubmitting, resetForm }) => {
									try {
										console.log("Request sent successfully:", "postResponse");
										resetForm();
									} catch (error) {
										console.error("Error while sending request:", error);
									} finally {
										setSubmitting(false);
									}
								}}
							>
								{({
									errors,
									touched,
									values,
									setFieldValue,
									isSubmitting,
									setFieldTouched,
									setErrors,
									validateField,
								}) => (
									<Form>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}
										>
											<Grid container spacing={4}>
												<Grid
													item
													xs={12} // Full width on mobile
													sm={4} // 8 columns on small screens and above
													sx={{ display: "flex", alignItems: "center" }}
												>
													<Field
														as={TextField}
														className="full-width"
														name="keyword"
														label="KEYWORD E.G. (JOB TITLE, DESCRIPTION)"
														sx={{
															maxHeight: "50px",
															"& div>fieldset": {
																maxHeight: "50px",
																borderRadius: "30px",
																border: "2px solid #ffffff", // White border
															},
															"& div>input": {
																color: "#ffffff",
																background: "transparent",
															},
															"& label": {
																color: "#ffffff",
																left: "8px",
																fontSize: "14px",
															},
															"&:hover div>fieldset": {
																border: "2px solid #ffffff", // Keep white border on hover
															},
															"&.Mui-focused div>input": {
																background: "none", // No background on focus
															},
															"&.Mui-focused div>fieldset": {
																outline: "2px solid #ffffff", // White outline on focus
															},
														}}
														value={keywordSearch}
														onChange={(event: any) =>
															setKeywordSearch(event.target.value)
														}
													/>
												</Grid>
												<Grid
													item
													xs={12} // Full width on mobile
													sm={4} // 8 columns on small screens and above
													sx={{ display: "flex", alignItems: "center" }}
												>
													<Field
														as={TextField}
														className="full-width"
														name="location"
														label="LOCATION E.G. (POSTAL CODE, ZIP...)"
														sx={{
															maxHeight: "50px",
															"& div>fieldset": {
																maxHeight: "50px",
																borderRadius: "30px",
																border: "2px solid #ffffff", // White border
															},
															"& div>input": {
																color: "#ffffff",
																background: "transparent",
															},
															"& label": {
																color: "#ffffff",
																left: "8px",
																fontSize: "14px",
															},
															"&:hover div>fieldset": {
																border: "2px solid #ffffff", // Keep white border on hover
															},
															"&.Mui-focused div>input": {
																background: "none", // No background on focus
															},
															"&.Mui-focused div>fieldset": {
																outline: "2px solid #ffffff", // White outline on focus
															},
														}}
														value={locationSearch}
														onChange={(event: any) => {
															setLocationSearch(event.target.value);
															window.open(
																"https://myjobs.adp.com/safehavencs/cx",
																"_blank",
															);
														}}
													/>
												</Grid>
												<Grid
													item
													xs={12} // Full width on mobile
													sm={4} // 4 columns on small screens and above
													sx={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
												>
													<LoadingButton
														variant="contained"
														className="partner-call-btn"
														type="submit"
														loading={isSubmitting}
														disabled={isSubmitting}
														sx={{
															maxHeight: "56px",
															marginTop: "16px",
															minWidth: "140px",
															fontSize: "12px",
															textTransform: "none",
															backgroundColor: "rgb(0, 39, 78)",
															borderRadius: "30px",
															padding: "12px 0",
														}}
														onClick={async () => {
															window.open(
																"https://myjobs.adp.com/safehavencs/cx",
																"_blank",
															);
															const res = await post(
																`/JobSearch/searchJobPostings`,
																{
																	jobSearchStr: keywordSearch,
																	locationSearchStr: locationSearch,
																},
															);
															console.log(res);
															if (res) {
																setCareers(res.length > 0 ? res : []);
															}
														}}
													>
														{"SEARCH"}
													</LoadingButton>
												</Grid>
											</Grid>
										</Box>
									</Form>
								)}
							</Formik>
						</Box>
					</Container>
				</Box>

				{/* Job Search Results */}
				{
					paginatedData && careers.length != 0 &&
					<Box sx={{ background: "#fff", marginBottom: '32px' }}>
						<Container
							sx={{
								alignContent: "right",
							}}
						>
							<Grid container spacing={0}>
								<Grid item xs={12} sm={4}>
									<Typography
										sx={{
											fontSize: { xs: "12px", md: "18px" },
											fontWeight: "bold",
											padding: { xs: "12px", md: "18px" },
										}}
									>
										Jobs in Kansas City, MO
									</Typography>
								</Grid>
								<Grid item xs={12} sm={8}>
									<List style={{ minHeight: "400px", padding: "20px" }}>
										{paginatedData?.map((career, index) => (
											<ListItem
												key={index}
												style={{ borderBottom: "1px solid #dfdfdf" }}
											>
												<ListItemText
													primary={
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "bold",
																color: "#2f4e80",
															}}
														>
															{career.jobTitle}
														</Typography>
													}
													secondary={
														<Typography
															sx={{
																fontSize: "12px",
																color: "#2f4e80",
															}}
														>
															Safe Haven Security Services LLC - Kansas City, MO
														</Typography>
													}
												/>
												<Button
													slot="end"
													onClick={() => {
														nextPage();
														window.open(
															career.link,
															"_blank",
														);
													}}
													size="small"
													sx={{
														textTransform: "none",
														marginLeft: "auto",
														width: "60%",
														float: "right",
														display: "flex",
														alignItems: "right",
														color: "black",
													}}
												>
													APPLY NOW
													<ArrowForwardIcon
														sx={{ marginLeft: "5px", color: "rgb(68,200,245)" }}
													/>
												</Button>
											</ListItem>
										))}
										{careers.length === 0 && (
											<ListItem>
												<ListItemText
													primary={<Typography>No results found</Typography>}
													secondary={
														<Typography>
															Please try adjusting your search criteria or check
															back later.
														</Typography>
													}
													style={{ textAlign: "center", width: "100%" }}
												/>
											</ListItem>
										)}
									</List>
								</Grid>
							</Grid>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									marginTop: "8px",
									zIndex: 2,
								}}
							>
								<Box sx={{ margin: "18px" }}>
									<IconButton
										className="award-carousel-nav-button"
										aria-label="delete"
										onClick={() => {
											prevPage();
											window.open(
												"https://myjobs.adp.com/safehavencs/cx",
												"_blank",
											);
										}}
										sx={{
											backgroundColor: "rgb(68,200,245)",
											minHeight: "50px",
											minWidth: "50px",
										}}
									>
										<WestIcon sx={{ color: "#fff" }} />
									</IconButton>
								</Box>

								<Box sx={{ margin: "18px" }}>
									<IconButton
										className="award-carousel-nav-button"
										aria-label="delete"
										onClick={() => {
											nextPage();
											window.open(
												"https://myjobs.adp.com/safehavencs/cx",
												"_blank",
											);
										}}
										sx={{
											backgroundColor: "rgb(68,200,245)",
											minHeight: "50px",
											minWidth: "50px",
										}}
									>
										<EastIcon sx={{ color: "#fff" }} />
									</IconButton>
								</Box>
							</Box>
						</Container>
					</Box>
				}

			</Box>

			{/* Mission Strip */}
			<Box sx={{ backgroundColor: "rgb(244, 244, 244)", padding: "72px 0" }}>
				<Container maxWidth={"xl"}>
					<SideBySide
						leftContent={
							<Stack sx={{ maxWidth: { sm: "75%" }, padding: { xs: '24px', md: '0' } }}>
								<Typography
									sx={{
										fontSize: { xs: '32px', sm: "48px" },
										lineHeight: { xs: '40px', sm: "56px" },
										marginBottom: { sm: "8px" },
									}}
								>
									One Mission.
								</Typography>
								<Typography
									sx={{
										fontSize: { xs: '30px', sm: "48px" },
										lineHeight: { xs: '42px', sm: "56px" },
										marginBottom: "32px",
									}}
								>
									<strong>Homes Connected, <span className="mobile-break"></span>Families Protected.</strong>
								</Typography>
								<Typography sx={{ marginBottom: { sm: "24px" } }}>
									We protect families by connecting them to their home through
									comprehensive smart home solutions customized to meet their
									unique needs.
								</Typography>

								<Box sx={{ marginBottom: { xs: '24px', sm: '0' } }}>
									<Button
										className="home-free-quote-btn"
										sx={{
											backgroundColor: "#1260a8",
											color: "#ffffff",
											borderRadius: "30px",
											height: "45px",
											maxHeight: "45px",
											fontSize: "14px",
											margin: "32px 0 0 0",
											padding: "0 24px",
											textTransform: "capitalize",
											"&.home-free-quote-btn:hover": {
												backgroundColor: "#1260a8!important",
											},
											"&.home-free-quote-btn>span": {
												marginLeft: "4px",
											},
										}}
										onClick={() => {
											handleLearnMoreMobileClick();
										}}
									>
										Learn More
									</Button>
								</Box>
							</Stack>
						}
						rightContent={
							<Box sx={{ position: "relative" }}>
								<Box
									component="img"
									loading="lazy"
									sx={{
										display: "block",
										width: "100%",
										maxWidth: "100%",
										height: "auto",
										margin: "auto",
										borderTopLeftRadius: '200px 45%'
									}}
									alt="The house from the offer."
									src={technicianWithHomeowners}
								/>
							</Box>
						}
					/>
				</Container>
			</Box>

			{/* Awards Carousel Section */}
			<Container
				sx={{ maxHeight: "500px", margin: "88px auto", position: "relative" }}
			>
				<Swiper
					style={{ height: "100%", width: "100%" }}
					loop={true}
					ref={awardSwiperRef}
					className="about-us-swiper"
					navigation={false}
					spaceBetween={0}
					modules={[Pagination, Navigation]}
					breakpoints={{
						0: {
							slidesPerView: 1,
						},
						900: {
							slidesPerView: 2,
						},
						1200: {
							slidesPerView: 3,
						},
					}}
				>
					{AWARD_IMAGES.map((award, index) => (
						<SwiperSlide
							key={index}
							className={`about-us-swiper-slide-${index}`}
							style={{
								display: "flex",
								justifyContent: "center",
								height: "100%",
								paddingRight: "1px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									flexDirection: "column",
									justifyContent: "center",
									height: "300px",
									width: "100%",
									position: "relative",
								}}
							>
								<Box
									sx={{
										height: "210px",
										display: "flex",
										alignItems: "center",
									}}
								>
									<Box
										component="img"
										loading="lazy"
										sx={{
											width: "auto",
											maxHeight: "100%",
											maxWidth: "250px",
											cursor: "pointer",
										}}
										alt={`Product ${index + 1}`}
										src={award.image}
									/>
								</Box>
								<Typography
									variant="body2"
									align="center"
									sx={{
										fontSize: "20px",
										lineHeight: "22px",
										maxWidth: "250px",
										height: "70px",
										paddingTop: "20px",
									}}
								>
									{award.text}
								</Typography>
								<Box
									sx={{
										position: "absolute",
										borderLeft: "1px solid #cecece",
										height: "125px",
										right: "0",
										bottom: "45%",
									}}
								></Box>
							</Box>
						</SwiperSlide>
					))}
				</Swiper>
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "50%",
						height: "300px",
						background:
							"linear-gradient(to right, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
						zIndex: 1,
					}}
				/>
				<Box
					sx={{
						position: "absolute",
						top: 0,
						right: 0,
						width: "50%",
						height: "300px",
						background:
							"linear-gradient(to left, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
						zIndex: 1,
					}}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						marginTop: "8px",
						zIndex: 2,
					}}
				>
					<Box sx={{ margin: "18px" }}>
						<IconButton
							className="award-carousel-nav-button"
							aria-label="delete"
							onClick={handleAwardPrev}
							sx={{
								backgroundColor: "rgb(68,200,245)",
								minHeight: "50px",
								minWidth: "50px",
							}}
						>
							<WestIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Box>

					<Box sx={{ margin: "18px" }}>
						<IconButton
							className="award-carousel-nav-button"
							aria-label="delete"
							onClick={handleAwardNext}
							sx={{
								backgroundColor: "rgb(68,200,245)",
								minHeight: "50px",
								minWidth: "50px",
							}}
						>
							<EastIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Box>
				</Box>
			</Container>

			{/* Benefits Strip */}
			<Container sx={{ margin: "80px auto 160px auto" }}>
				<Box sx={{ display: "flex", marginBottom: "32px", flexDirection: { xs: 'column', sm: 'row' } }}>
					<Typography
						sx={{ fontSize: { xs: '32px', lg: "48px" }, lineHeight: "56px", padding: "16px 32px" }}
					>
						Benefits & Perks <strong>that Support You</strong>
					</Typography>
					<Typography
						sx={{
							display: "flex",
							alignItems: "center",
							maxWidth: { xs: '100%', sm: "50%" },
							padding: "16px 32px",
						}}
					>
						At Safe Haven Security, we provide medical, dental, vision, life
						insurance, paid time off, 401(k) plans, and monthly bonuses in a
						supportive environment with opporunities for growth.
					</Typography>
				</Box>
				<Grid container className="benefits-grid">
					<Grid item xs={12} md={6} lg={4}>
						<Box sx={{ display: "flex" }}>
							<CheckCircleIcon
								sx={{ color: "rgb(0, 83, 156)", minWidth: "40px" }}
							/>
							<Typography>Comprehensive Health Coverage</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Box sx={{ display: "flex" }}>
							<CheckCircleIcon
								sx={{ color: "rgb(0, 83, 156)", minWidth: "40px" }}
							/>
							<Typography>Dental Insurance</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Box sx={{ display: "flex" }}>
							<CheckCircleIcon
								sx={{ color: "rgb(0, 83, 156)", minWidth: "40px" }}
							/>
							<Typography>Paid Time Off + Paid Holidays</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Box sx={{ display: "flex" }}>
							<CheckCircleIcon
								sx={{ color: "rgb(0, 83, 156)", minWidth: "40px" }}
							/>
							<Typography>Retirement Savings Plan</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Box sx={{ display: "flex" }}>
							<CheckCircleIcon
								sx={{ color: "rgb(0, 83, 156)", minWidth: "40px" }}
							/>
							<Typography>Vision Insurance</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Box sx={{ display: "flex" }}>
							<CheckCircleIcon
								sx={{ color: "rgb(0, 83, 156)", minWidth: "40px" }}
							/>
							<Typography>Monthly Bonuses & Incentives</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Box sx={{ display: "flex" }}>
							<CheckCircleIcon
								sx={{ color: "rgb(0, 83, 156)", minWidth: "40px" }}
							/>
							<Typography>Flexible Work Arrangements</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Box sx={{ display: "flex" }}>
							<CheckCircleIcon
								sx={{ color: "rgb(0, 83, 156)", minWidth: "40px" }}
							/>
							<Typography>Weekly Pay</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<Box sx={{ display: "flex" }}>
							<CheckCircleIcon
								sx={{ color: "rgb(0, 83, 156)", minWidth: "40px" }}
							/>
							<Typography>Career Development</Typography>
						</Box>
					</Grid>
				</Grid>
			</Container>

			{/* Desktop: Diversity Block */}
			<Box sx={{ position: "relative", display: { xs: "none", md: "flex" } }}>
				<Grid container spacing={0} sx={{}}>
					<Grid
						item
						xs={4}
						sx={{
							height: "60vh",
						}}
					></Grid>
					<Grid item xs={8} sx={{}}></Grid>
				</Grid>
				<Container
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						display: "flex",
						alignItems: "center",
					}}
				>
					<Box
						sx={{ position: "relative", display: "flex", marginRight: "32px" }}
					>
						<Box
							component="img"
							loading="lazy"
							sx={{
								display: "block",
								height: "100%",
								maxHeight: "400px",
								maxWidth: "400px",
								borderRadius: "50%",
							}}
							alt="The house from the offer."
							src={realtorTeam}
						/>
						<Box
							sx={{
								width: "60px",
								height: "60px",
								position: "absolute",
								top: 15,
								left: 40,
								backgroundColor: "rgb(68, 200, 245)",
								borderRadius: "50%",
							}}
						/>
					</Box>
					<Stack sx={{ marginLeft: "32px" }}>
						<Typography sx={{ fontSize: "48px", lineHeight: "56px" }}>
							Embracing Diversity,
						</Typography>
						<Typography
							sx={{
								fontSize: "48px",
								lineHeight: "56px",
								marginBottom: "32px",
							}}
						>
							<strong>Empowering Inclusion</strong>
						</Typography>
						<Typography
							sx={{ fontSize: "18px", letterSpacing: "1", wordSpacing: "1" }}
						>
							At Safe Haven, diversity is our strength. We're committed to an
							inclusive environment where everyone is valued. This dedication
							has earned us Newsweek's recognition as one of 'America's Greatest
							Workplaces for Diversity' and 'America's Greatest Workplaces' for
							two years in a row.
						</Typography>
						<Box sx={{ display: "flex" }}>
							<Box
								component="img"
								loading="lazy"
								sx={{
									display: "block",
									height: "150px",
									width: "auto",
									maxHeight: "400px",
									padding: "25px",
								}}
								alt="The house from the offer."
								src={award1}
							/>
							<Box
								component="img"
								loading="lazy"
								sx={{
									display: "block",
									height: "150px",
									width: "auto",
									maxHeight: "400px",
									padding: "25px",
								}}
								alt="The house from the offer."
								src={award2}
							/>
						</Box>
					</Stack>
				</Container>
			</Box>
		</>
	);
}
