import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import './index.scss';
import { Helmet } from 'react-helmet';

export default function TermsAndConditionsSH() {
  return (
    <>
      <Helmet>
        <title>Safe Haven Security Services: Privacy Policy | Safe Haven</title>
        <meta
          name="description"
          content="Safe Haven Security Services: Understand how we collect, use, and protect your personal information."
        />
        <meta
          name="keywords"
          content="Safe Haven Security Services, privacy policy, personal information, data collection, data security, user rights, Safe Haven terms and conditions, ADT security"
        />
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          minHeight: '25vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{ color: '#1260a8', fontWeight: '700', fontSize: '48px' }}
        >
          Terms and Conditions for Safe Haven Subscriptions
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: '#ffffff' }}>
        <Container
          maxWidth='xl'
          className='privacy-policy-container'
          sx={{
            backgroundColor: '#ffffff',
            paddingBottom: '70px',
          }}
        >
          <Typography variant='body2'>
            Last updated September 13, 2022
          </Typography>
          <Typography variant='body2'>
            Safe Haven Security Services, LLC (“Safe Haven”) provides
            customers with access to various smart home systems and interfaces
            (the “Services”). This document sets forth the Terms and
            Conditions (“Terms”) that govern your legal rights to access or
            use the Services. Please read this page carefully. By accessing or
            using the Services, you acknowledge that you have read,
            understood, and agree to be bound by these Terms and to comply
            with all applicable laws and regulations. If you do not agree to
            these Terms, do not access or use the Services. Safe Haven
            reserves the right to revise these Terms at any time. If you
            violate any of these Terms, your permission to use the Services
            automatically terminates.
          </Typography>
          <Typography variant='body2'>
            YOU AGREE TO THE LIMITATION OF LIABILTY AND WARRANTY PROVISIONS
            DESCRIBED IN SECTION 4 AND TO THE ARBITRATION AGREEMENT AND CLASS
            ACTION WAIVER DESCRIBED IN SECTION 5 TO RESOLVE ANY DISPUTES WITH
            SAFE HAVEN.
          </Typography>
          <Typography variant='body1'>
            1. Subscription for the Services
          </Typography>
          <ul>
            <li>
              <strong>Subscription Fee:</strong> To use the Services, you must
              pay Safe Haven a monthly fee (the “Subscription Fee”) as
              indicated on your monthly invoice.
            </li>
            <li>
              <strong>Automatic Renewal:</strong> Your Subscription to use the
              Services will continue and automatically renew on a monthly
              basis until cancelled by you. You must cancel your subscription
              before it renews in order to avoid billing of the membership
              fees for the next billing cycle to your Payment Method (see
              “Cancellation” below).
            </li>
            <li>
              <strong>Additional Trip Fee for Service Calls:</strong>
              In addition to the Services included in your Subscription, in
              the event you require additional on-site support or assistance
              with your Services, you agree to pay Safe Haven a fee of $85.00
              per visit (the “Trip Fee”). The Trip Fee will be charged to you
              at the time of service or, if not paid by you at the time of
              service, may be added to your monthly invoice and paid by you as
              described in Section 2.
            </li>
          </ul>
          <Typography variant='body1'>2. Billing and Payment</Typography>
          <ul>
            <li>
              <strong>Billing Cycle:</strong> The Subscription Fee for the
              Services and any other charges you may incur in connection with
              your use of the Service, such as Trip Fees, taxes, and possible
              transaction fees, will be charged to your Payment Method on the
              specific payment date indicated on your monthly invoice.
            </li>
            <li>
              <strong>Payment Methods:</strong> To use the Services, you must
              provide one or more Payment Methods. A “Payment Method” means a
              current, valid, accepted method of payment, as may be updated
              from time to time and which may include payment through your
              account with a third party. You authorize Safe Haven to charge
              any Payment Method associated to your account in case your
              primary Payment Method is declined or no longer available to us
              for payment of your subscription fee. You remain responsible for
              any uncollected amounts. If a payment is not successfully
              settled, due to expiration, insufficient funds, or otherwise,
              and you do not cancel your account, we may suspend your access
              to the service until we have successfully charged a valid
              Payment Method. For some Payment Methods, the issuer may charge
              you certain fees, such as foreign transaction fees or other fees
              relating to the processing of your Payment Method. Check with
              your Payment Method service provider for details.
            </li>
            <li>
              <strong>Updating your Payment Methods:</strong>
              You can update your Payment Methods by logging into your account
              at{' '}
              <a href='https://subscriptions.zoho.com/portal/safehavensecurity/login'>
                https://subscriptions.zoho.com/portal/safehavensecurity/login
              </a>{' '}
              or by calling Safe Haven’s Accounts Receivables department at
              <strong>(816) 708-4358</strong>. We may also update your Payment
              Methods using information provided by the payment service
              providers. Following any update, you authorize us to continue to
              charge the applicable Payment Method(s).
            </li>
            <li>
              <strong>Changes to the Price and Subscription Plans:</strong>{' '}
              Safe Haven reserves the right to change the Subscription Fee or
              adjust pricing for the Services or any components thereof in any
              manner and at any time as Safe Haven may determine in its sole
              and absolute discretion. Except as otherwise expressly provided
              for in these Terms, any price changes or changes to your
              subscription plan will take effect following notice to you.
            </li>
            <li>
              <strong>Failure to Make Payment:</strong> If you fail to make
              any payment when due, Safe Haven may terminate your Subscription
              and/or stop providing the Services without notice. In addition
              to these remedies, Safe Haven does not waive and expressly
              retains the right to exercise any other legal remedy, including
              the right to charge you a late fee at the highest legal rate for
              each month that a payment is not received and/or interest on the
              unpaid amount, and the right to report you to one or more
              consumer reporting agencies if you become delinquent on your
              account (more than 90 days without a payment).
            </li>
          </ul>
          <Typography variant='body1'>3. Cancellation</Typography>
          <ul>
            <li>
              <strong>Cancellation:</strong> You can cancel your Subscription
              for Services at any time, and you will continue to have access
              to the Services through the end of your billing period. To
              cancel your Subscription, please call{' '}
              <strong>(816) 708-4358</strong>. If you cancel your
              Subscription, your account will automatically close at the end
              of your current billing period.
            </li>
            <li>
              <strong>No Refunds:</strong> Payments are nonrefundable and
              there are no refunds or credits for partially used membership
              periods. Following any cancellation, however, you will continue
              to have access to the Services through the end of your current
              billing period. At any time, and for any reason, Safe Haven may
              provide a refund, discount, or other consideration to some or
              all of our Subscribers (“credits”). The amount and form of such
              credits, and the decision to provide them, are made at Safe
              Haven’s sole and absolute discretion. The provision of credits
              in one instance does not entitle you to credits in the future
              for similar instances, nor does it obligate Safe Haven to
              provide credits in the future, under any circumstance.
            </li>
          </ul>
          <Typography variant='body1'>
            4. Limitation of Liability and Warranty
          </Typography>
          <ul>
            <li>
              <strong>LIMITATION OF LIABILITY AND EXCLUSIVE REMEDY:</strong>{' '}
              EXCEPT AS LIMITED BY LAW, AND DUE TO THE DIFFICULTY OF
              DETERMINING ANY ACTUAL DAMAGES, SAFE HAVEN’S LIABILITY FOR ANY
              LOSS OR DAMAGE ARISING OUT OF MISTAKES, OMISSIONS,
              INTERRUPTIONS, DELAYS, ERRORS, OR DEFECTS IN YOUR SERVICES OR
              TRANSMISSION OF YOUR SERVICES SHALL NOT EXCEED THE GREATER OF
              THE AMOUNT PAID BY YOU FOR YOUR SERVICES OR $250.00. THIS
              AGREED-UPON AMOUNT IS NOT A PENALTY. RATHER, IT IS YOUR SOLE AND
              EXCLUSIVE REMEDY.
            </li>
            <li>
              SAFE HAVEN SHALL NOT BE LIABLE FOR ANY MISTAKE, OMISSION,
              INTERRUPTION, OR DEFECT IN YOUR SERVICE CAUSED BY THE NEGLIGENCE
              OR WILLFUL ACT OF ANY PARTY OTHER THAN SAFE HAVEN, OR WHEN
              CAUSED BY ACTS OF GOD, FIRE, WAR, RIOTS, GOVERNMENT AUTHORITIES,
              OR OTHER CAUSES BEYOND SAFE HAVEN’S CONTROL, INCLUDING WITHOUT
              LIMITATION DEFECT IN OR FAILURE OF EQUIPMENT PROVIDED BY ANY
              PARTY OTHER THAN SAFE HAVEN.
            </li>
            <li>
              UNDER NO CIRCUMSTANCES WILL YOU ATTEMPT TO HOLD SAFE HAVEN
              LIABLE FOR ANY CONSEQUENTIAL OR INCIDENTAL DAMAGES, INCLUDING
              WITHOUT LIMITATION, DAMAGES FOR PERSONAL INJURY OR DAMAGES TO
              PROPERTY.
            </li>
            <li>
              YOU AGREE THAT ANY DUTIES OWED TO ME BY SAFE HAVEN ARE SET FORTH
              EXCLUSIVELY IN THESE TERMS. THEREFORE, YOU EXPRESSLY WAIVE ANY
              CLAIMS OR DEFENSES BASED ON TORTIOUS CONDUCT, INCLUDING WILLFUL
              OR INTENTIONAL TORTS.
            </li>
            <li>
              <strong>NO WARRANTY:</strong> EXCEPT AS REQUIRED BY LAW,
              SERVICES AND ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL
              FAULTS AND WITHOUT WARRANTY OF ANY KIND. SAFE HAVEN DOES NOT
              GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE SERVICES
              WILL BE UNINTERRUPTED OR ERROR-FREE.
            </li>
            <li>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
              WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
              CERTAIN TYPES OF DAMAGES. THEREFORE, NOTHING IN THESE TERMS
              SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO
              YOU.
            </li>
          </ul>
          <Typography variant='body1'>5. Arbitration Agreement</Typography>
          <ul>
            <li>
              SAFE HAVEN AND YOU AGREE THAT ANY AND ALL DISPUTES BETWEEN THEM
              SHALL BE GOVERNED BY THIS BINDING ARBITRATION AGREEMENT.
              Arbitration is a dispute-resolution process that does not
              involve a judge or jury. Instead, Disputes are decided by a
              neutral third-party arbitrator in a process that is less formal
              than court. As used herein, the term “Dispute” means any claim
              or controversy, including, but not limited to, initial claims,
              counterclaims, cross-claims and third-party claims, whether
              based in contract; tort; fraud; intentional acts; violations of
              any statute, code or regulation; or other legal theory. The term
              “Dispute” shall be given the broadest possible meaning and will
              apply to, without limitation, all claims and controversies
              arising from this Arbitration Agreement; sales activities; goods
              and services; advertisements, promotions, and other statements;
              billing and collection practices; privacy; and any other dispute
              arising from your interaction or relationship with Safe Haven.
              Safe Haven agrees not to elect arbitration if you file a Dispute
              in a small claims court in your state of residency so long as
              the Dispute is individual and non-representative in nature and
              remains exclusively as such in small claims court.
            </li>
            <li>
              <strong>Pre-Arbitration Notice Requirement:</strong> Before
              initiating an arbitration or a small claims matter, Safe Haven
              and you agree to first provide to the other a written “Notice of
              Dispute” that will contain: (a) a written description of the
              issue and the supporting documents and information, and (b) a
              specific request for money or other relief. A Notice of Dispute
              to Safe Haven should be sent to Safe Haven Security Services,
              LLC, Attn: General Counsel, 520 E 19th Ave., North Kansas City,
              MO 64116, or as you may otherwise be directed by Safe Haven or
              its assignee. Safe Haven will mail a Notice of Dispute to your
              residential address. Safe Haven and you agree to make attempts
              to resolve the Dispute prior to commencing an arbitration or
              small claims action.
            </li>
            <li>
              <strong>Initiation of Arbitration Proceeding:</strong> If either
              party elects to arbitrate a Dispute, the Dispute shall be
              resolved by arbitration pursuant to this Arbitration Agreement
              and the then-current code of proceedings of the American
              Arbitration Association (“AAA”). To obtain a copy of the
              procedures, or to file a Dispute, you may contact AAA at 335
              Madison Avenue, New York, NY 10017, www.adr.org.
            </li>
            <li>
              IF EITHER PARTY ELECTS TO ARBITRATE A DISPUTE, SAFE HAVEN AND
              YOU WAIVE THE RIGHT TO A JURY TRIAL AND TO OTHERWISE LITIGATE
              THE DISPUTE IN COURT. BY AGREEING TO ARBITRATE, THE PARTIES MAY
              ALSO WAIVE OTHER RIGHTS THAT WOULD OTHERWISE BE AVAILABLE IN
              COURT. FURTHER, IF EITHER PARTY ELECTS TO ARBITRATE A DISPUTE,
              YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A REPRESENTATIVE CAPACITY
              OR TO PARTICIPATE AS A MEMBER OF ANY CLASS ACTION RELATING TO
              THE DISPUTE. This means that all Disputes selected for
              arbitration will be arbitrated on an individual basis, between
              Safe Haven and you only, without exception. A Dispute cannot be
              joined or consolidated with any other claim or action.
            </li>
            <li>
              <strong>Arbitration Proceedings:</strong> Because your
              transaction(s) with Safe Haven involves interstate commerce,
              this Arbitration Agreement and any Dispute arbitrated hereunder
              shall be governed by the Federal Arbitration Act (“FAA”). The
              AAA code of procedures will govern the arbitration, but if there
              is a conflict between the AAA code of procedures and this
              Arbitration Agreement, this Arbitration Agreement shall control
              to the fullest extent permitted by the FAA. Unless otherwise
              agreed to by the parties, the arbitration will be conducted by a
              single, neutral arbitrator at a location within the federal
              judicial district in which you reside. Upon your request, Safe
              Haven will reimburse you for all filing and administrative fees
              required for initiating the arbitration. Otherwise, each party
              is responsible for its own respective costs and fees, including,
              but not limited to, attorney and expert fees. The arbitrator
              shall apply applicable substantive law and, upon the request of
              either party, issue a written explanation of the basis for the
              decision. Judgment on the arbitration award may be entered in
              any court having proper jurisdiction. EXCEPT AS FOLLOWS, THE
              ARBITRATOR’S DECISION WILL BE FINAL AND BINDING. A party may
              appeal the arbitrator’s initial award to a three-arbitrator
              panel administered by the AAA upon written notice within 30 days
              of the initial award. The AAA will notify the other party of the
              appeal. The panel shall consider any aspect of the initial award
              objected to by the appealing party and issue a decision within
              120 days of the date of the notice of appeal. The majority
              decision by the three-member panel shall be final and binding.
              Any dispute regarding the applicability, enforcement, or
              interpretation of Paragraph 4 above or this Paragraph 5, shall
              be resolved by a court having proper jurisdiction. This
              Arbitration Agreement will not prevent you from bringing a
              Dispute to the attention of any federal, state, or local
              government agency. This Arbitration Agreement shall survive
              termination of your Subscription.
            </li>
          </ul>
          <Typography variant='body1'>6. Miscellaneous</Typography>
          <ul>
            <li>
              <strong>Governing Law: </strong>These Terms shall be governed by
              and construed in accordance with the laws of the state of
              Missouri without regard to conflict of laws provisions.
            </li>
            <li>
              <strong>Third-Party Terms: </strong>Your use of the Services may
              involve equipment or services provided by third parties,
              including Alarm.com. In the event you have agreed to or are
              bound by any contract or terms of use or service with such third
              parties (“Third-Party Terms”), nothing in these Terms shall be
              construed to supersede or modify any Third-Party Terms.
            </li>
            <li>
              <strong>Customer Service and Service Calls: </strong> If you
              require any additional support or assistance with your Services,
              you may contact Safe Haven by calling (844) 413-1920 between 8
              AM–5:30 PM Central Time, Monday–Friday or by emailing{' '}
              <a href='mailto:service@mysafehaven.com'>
                service@mysafehaven.com
              </a>
              . As noted in Paragraph 1(c), you will be charged a Trip Fee for
              any on-site support or assistance with your Services.
            </li>
            <li>
              <strong>Changes to Terms and Assignment: </strong>Safe Haven
              may, from time to time, change these Terms. Such revisions shall
              be effective immediately; provided however, for existing
              Subscribers, such revisions shall, unless otherwise stated, be
              effective 30 days after posting. Safe Haven may assign its
              agreement with you to any affiliated company or to any entity
              that succeeds to all or substantially all of Safe Haven’s
              business or assets related to the applicable Safe Haven service.
            </li>
            <li>
              <strong>Survival: </strong>If any provision or provisions of
              these Terms shall be held to be invalid, illegal, or
              unenforceable, the validity, legality, and enforceability of the
              remaining provisions shall remain in full force and effect.
            </li>
            <li>
              <strong>Communication Preferences: </strong>Safe Haven will send
              you information relating to your account (e.g. payment
              authorizations, invoices, changes in password or Payment Method,
              confirmation messages, notices) in electronic form only, for
              example via emails to your email address provided during
              registration. You agree that any notices, agreements,
              disclosures, or other communications that Safe Haven send to you
              electronically will satisfy any legal communication
              requirements, including that such communications be in writing.
            </li>
          </ul>
        </Container>
      </Box>
    </>
  );
}
