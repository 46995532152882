import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import './index.scss';
import { Helmet } from 'react-helmet';
import mainimage from '../../../../Assets/HomeIsConnected/Gear_DRH_Jan 2024.png';
import step1 from '../../../../Assets/Templates/Step-01 copy.png';
import step2 from '../../../../Assets/Templates/Step-02 copy.png';
import step3 from '../../../../Assets/Templates/Step-03 copy.png';
import step4 from '../../../../Assets/Templates/Dan Ryan_Step 4.jpg';
import step1m from '../../../../Assets/HomeIsConnected/step-1m.png';
import step2m from '../../../../Assets/HomeIsConnected/step-2m.png';
import step3m from '../../../../Assets/HomeIsConnected/step-3mf.png';
import step4m from '../../../../Assets/HomeIsConnected/step-4m.png';
import HomeIsConnectedForm from '../../../../Components/Forms/HomeIsConnectedForm';
import YoutubeEmbed from '../../../../Components/YoutubeEmbed';
import logo1 from '../../../../Assets/DrbGreenville/DanRyanImageSafehavenLogo.png';
import logo2 from '../../../../Assets/DrbGreenville/DRB_Logo.png';
import drbGear from '../../../../Assets/DrbGreenville/drb-gear.png';
import stackedSteps from '../../../../Assets/DrbGreenville/Stacked-steps.jpg';
import step11 from '../../../../Assets/DrbGreenville/step-1.1.jpg';
import step21 from '../../../../Assets/DrbGreenville/step-2.1.jpg';
import step31 from '../../../../Assets/DrbGreenville/step-3.1.jpg';
import step41 from '../../../../Assets/DrbGreenville/step-4.1.png';
import step411 from '../../../../Assets/DrbGreenville/Steps-4.11.png';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { disable as hideHeader } from '../../../../App/State/headerSlice';
import { disable as hideFooter } from '../../../../App/State/footerSlice';
import HomeIsConnectedFormNew from '../../../../Components/Forms/HomeIsConnectedForm/indexNew';

export default function DRBHomes() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideHeader());
    dispatch(hideFooter());
  }, []);

  return (
    <>
      <Helmet>
        <title>DRB Homes | Safe Haven</title>
        <meta name="description" content="DRB Homes page for builder" />
        <meta
          name="keywords"
          content="affirmation submission, submit affirmation, online affirmation request, affirmation form, affirmation service, DRB Homes affirmation submission, submit affirmation for [specific purpose], free affirmation request, confidential affirmation submission, secure affirmation platform, how to submit an affirmation online, where to submit an affirmation for free, submit an affirmation for a specific goal, confidential online affirmation service, secure platform for submitting affirmations"
        />
      </Helmet>

      <Box sx={{ width: '100%', maxWidth: '100vw', overflow: 'hidden', background: "#fff" }}>
        {/* Logos */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          maxWidth: '1950px',
          margin: '32px auto auto auto'
        }}>
          <Box
            component='img'
            sx={{
              width: 'auto',
              maxHeight: { xs: '100px', lg: '150px' },
            }}
            alt='The house from the offer.'
            src={logo1}
          />
          <Box
            component='img'
            sx={{
              width: 'auto',
              maxHeight: { xs: '100px', lg: '150px' },
            }}
            alt='The house from the offer.'
            src={logo2}
          />
        </Box>

        <Container maxWidth={'xl'}>
          {/* Greeting and opening paragraph */}
          <Box sx={{ padding: '32px 0', width: '100%' }}>
            <Typography
              sx={{ fontWeight: '900', fontSize: { xs: '1.5em', md: '2em', lg: '3.5em' }, textAlign: 'center', color: '#55595a', marginBottom: '32px', }}
            >
              Welcome to your new home by DRB Homes!
            </Typography>
            <Typography sx={{ fontSize: '1.25em', lineHeight: '1.35', color: '#55595a', maxWidth: { xs: '700px', md: '1024px' }, margin: 'auto', textAlign: 'center' }}>
              Safe Haven is the smart home integrator for your new home by DRB Homes.
              Our job is to setup your <span style={{ fontWeight: '700', color: '#55595a', fontSize: '1.25em', lineHeight: '1.35' }}>CONNECTED SMART HOME SYSTEM</span> and complete installation.
              This includes not only delivering items you’ve paid for in your home purchase,
              but also our white glove installation and set-up service that includes helping you learn all the features of your new home.
            </Typography>
          </Box>

          {/* Gear Image */}
          <Box
            component='img'
            sx={{
              width: 'auto',
              maxWidth: '100%',
              maxHeight: '75vh',
              margin: 'auto',
              marginBottom: '32px',
              display: 'flex'
            }}
            alt='The house from the offer.'
            src={drbGear}
          />

          {/* Step images */}
          <Grid
            container
            maxWidth={'lg'}
            spacing={{ xs: 0, md: 5 }}
            sx={{
              paddingTop: '5vh',
              justifyContent: 'center',
              margin: 'auto'
            }}
          >
            <Grid item lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                  height: "auto",
                }}
                alt='The house from the offer.'
                src={step1}
              />
            </Grid>

            <Grid item lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                  height: "auto",
                }}
                alt='The house from the offer.'
                src={step2}
              />
            </Grid>

            <Grid item lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                  height: "auto",
                }}
                alt='The house from the offer.'
                src={step3}
              />
            </Grid>

            <Grid item lg={6}>
              <Box
                component='img'
                sx={{
                  width: '100%',
                  height: "auto",
                }}
                alt='The house from the offer.'
                src={step411}
              />
            </Grid>
          </Grid>

          {/* Call or Form */}
          <Box sx={{ margin: { xs: '70px 0px', lg: '70px' }, textAlign: 'center' }}>
            <Typography sx={{ fontSize: '24px', color: '#55595a', textAlign: 'center', marginBottom: '8px!important' }}>
              Speak to someone now from your DRB Homes Concierge Team.
            </Typography>
            <Link href="tel:855.675.0992" underline="none" sx={{ fontSize: '48px', color: '#66a746' }}>
              855.675.0992
            </Link>
            <Typography sx={{ fontSize: '24px', color: '#55595a', textAlign: 'center', marginTop: '8px!important' }}>
              Or complete the form below and you will be contacted.
            </Typography>
          </Box>

          <Container >
            <HomeIsConnectedFormNew></HomeIsConnectedFormNew>
          </Container>

          <Divider sx={{ margin: '32px 0' }}></Divider>

          {/* Video */}
          <Box>
            <Typography
              sx={{ fontWeight: '700', fontSize: '32px', textAlign: 'center', color: '#55595a', marginBottom: '32px' }}
            >
              Learn more about your new smart home!
            </Typography>
            <YoutubeEmbed embedId="4zJfKox07oU?si=6WYbMqJBEb6ouOCv" />
          </Box>

          {/* Closer text */}
          <Stack sx={{ margin: { xs: '70px 0px', lg: '70px' }, textAlign: 'center' }}>
            <Typography sx={{ fontSize: '24px', color: '#55595a', textAlign: 'center', marginBottom: '8px!important' }}>
              Questions?
            </Typography>
            <Typography sx={{ fontSize: '24px', color: '#55595a', textAlign: 'center', marginBottom: '8px!important' }}>
              Feel free to reach out directly to the DRB Homes Concierge Team.
            </Typography>
            <Link href="tel:855.675.0992" underline="none" sx={{ fontSize: '36px', color: '#66a746' }}>
              855.675.0992
            </Link>
            <Link href="mailto:drbgroup@mysafehaven.com" underline="none" sx={{ fontSize: '24px', marginTop: '12px' }}>
              drbgroup@mysafehaven.com
            </Link>
            <Typography sx={{ fontSize: '12px', color: '#555', maxWidth: 'lg', margin: '32px auto auto auto' }}>
              $99.00 Customer Installation Charge.
              36-Month Monitoring Agreement required at $52.99 per month ($1,907.64).
              24-Month Agreement required at $59.99 per month ($1,271.76) for California.
              Form of payment must be by credit card or electronic charge to your checking or savings account.
              Offer applies to homeowners only. Local permit fees may be required.
              Satisfactory credit history required. Certain restrictions may apply.
              Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC.
              Other rate plans available. Cannot be combined with any other offer. Photos are for illustrative purposes only and may not reflect the exact product/service actually provided.
            </Typography>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
