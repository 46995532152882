import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import AccoladesRibbon from '../../Components/NumbersBanner';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import mainPhoto from '../../Assets/RealtorTeam/Main-photo.jpg';
import greenIcons from '../../Assets/RealtorTeam/Green-icons.png';
import jodyHeadshot from '../../Assets/RealtorTeam/Jody-Evans.png';
import aliHeadshot from '../../Assets/RealtorTeam/Ali-Wilson.png';
import weWantYouGraphic from '../../Assets/RealtorTeam/We want you graphic.jpg';
import houseImage from '../../Assets/RealtorTeam/House-image.jpg';
import usMap1 from '../../Assets/RealtorTeam/US map.png';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './index.scss';

export default function RealtorTeam() {
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === '_blank') {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };

  return (
    <>
      <Helmet>
        <title>Realtor Team | Safe Haven</title>
        <meta name="description" content="Learn about Safe Haven Strong, your trusted provider for home security solutions." />
        <meta name="keywords" content="home security, ADT authorized dealer, Safe Haven Strong" />
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', background: '#ffffff' }}>
        <Container maxWidth='xl'>
          <Typography
            sx={{
              textAlign: 'center',
              paddingTop: '70px',
              paddingBottom: '70px',
              color: '#1260a8',
              fontWeight: '700',
              fontSize: { xs: '32px', md: '36px', lg: '48px' },
            }}
          >
            HELP SAVE LIVES FOR A LIVING!
            <br></br>
            BUILD A BOOK OF BUSINESS AND CONTROL YOUR INCOME!
          </Typography>
          <Box
            component='img'
            sx={{
              width: 'auto',
              maxWidth: '100%',
              maxHeight: '75vh',
              margin: 'auto',
            }}
            alt='The Realtor Team as a whole.'
            src={mainPhoto}
          />
          <Typography
            sx={{
              textAlign: 'center',
              paddingTop: '70px',
              paddingBottom: '70px',
              color: '#1260a8',
              fontSize: { xs: '32px', md: '36px', lg: '48px' },
            }}
          >
            <strong>ADT's #1 AGENT REFERRAL PROGRAM</strong> IN THE COUNTRY!
            <br></br>
            <strong>30,000+</strong> REFERRAL AGENTS WITH <strong>600,000+</strong> SATISFIED CUSTOMERS.
          </Typography>
          <Box>
            <Box
              component='img'
              sx={{
                width: 'auto',
                maxWidth: '100%',
                maxHeight: '75vh',
                margin: 'auto',
              }}
              alt='With us, there is six-figure opportunity, upward mobility, and work-life balance'
              src={greenIcons}
            />
          </Box>

          <Grid container spacing={10} sx={{ paddingTop: '70px', paddingBottom: '80px' }}>
            <Grid item xs={12} md={6}>
              <Stack sx={{}}>
                <Box
                  component='img'
                  sx={{
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '75vh',
                    margin: 'auto',
                  }}
                  alt='Jody Evans Headshot'
                  src={jodyHeadshot}
                />
                <Typography
                  sx={{
                    textAlign: 'center',
                    paddingTop: '16px',
                    color: '#1260a8',
                    fontSize: '32px'
                  }}
                >
                  Contact <strong>Jody Evans!</strong>
                </Typography>
                <Typography sx={{ textAlign: 'center', fontSize: '32px' }}>
                  Hiring Manager
                </Typography>
                <Typography sx={{ textAlign: 'center', fontSize: '32px' }}>
                  C: <strong>816-665-4876</strong>
                  <br></br>
                  O: <strong>816-608-8763</strong>
                </Typography>

                <Button
                  href='https://www.linkedin.com/in/jevans74?trk=public_profile_browsemap'
                  target='_blank'
                  variant='text'
                  sx={{
                    textAlign: 'center', fontSize: '32px',
                    textTransform: 'inherit',
                    textDecoration: 'underline',
                    padding: '0',
                    minWidth: 'initial',
                  }}
                >
                  Apply with Jody Today!
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack>
                <Box
                  component='img'
                  sx={{
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '75vh',
                    margin: 'auto',
                  }}
                  alt='Ali Wilson Headshot'
                  src={aliHeadshot}
                />
                <Typography
                  sx={{
                    textAlign: 'center',
                    paddingTop: '16px',
                    color: '#1260a8',
                    fontSize: '32px'
                  }}
                >
                  Contact <strong>Ali Wilson!</strong>
                </Typography>
                <Typography sx={{ textAlign: 'center', fontSize: '32px' }}>
                  Hiring Manager
                </Typography>
                <Typography sx={{ textAlign: 'center', fontSize: '32px' }}>
                  C: <strong>816-301-4637</strong>
                  <br></br>
                  O: <strong>816-439-8218</strong>
                </Typography>
                <Button
                  href='https://www.linkedin.com/in/ali-wilson-0a5320125'
                  target='_blank'
                  variant='text'
                  sx={{
                    textAlign: 'center', fontSize: '32px',
                    textTransform: 'inherit',
                    textDecoration: 'underline',
                    padding: '0',
                    minWidth: 'initial',
                  }}
                >
                  Apply with Ali Today!
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Box
            component='img'
            sx={{
              width: 'auto',
              maxWidth: '100%',
              maxHeight: '75vh',
              margin: 'auto',
            }}
            alt='We want you on the team graphic'
            src={weWantYouGraphic}
          />
          <Typography
            sx={{
              textAlign: 'center',
              paddingTop: '55px',
              paddingBottom: '55px',
              color: '#1260a8',
              fontWeight: '700',
              fontSize: { xs: '30px', md: '36px', lg: '48px' },
            }}
          >
            WE WANT YOUR SALES TALENT!
          </Typography>
          <Typography
            sx={{
              textAlign: 'justify',
              fontWeight: '700',
              fontSize: { xs: '24px', md: '36px', lg: '48px' },
            }}
          >
            JOIN OUR INSIDE SALES TEAM AND GET PAID TO HELP SALE LIVES FOR A LIVING.
          </Typography>

          <Typography
            sx={{
              marginTop: '24px',
              textAlign: 'justify',
              fontWeight: '700',
              fontSize: { xs: '24px', md: '28px', lg: '32px' },
              color: '#1260a8',
            }}
          >
            At Safe Haven Security, your pursuit to build a business and increase your income helps protect communities all over the country. Every time you push yourself outside of your comfort zone, you are impacting more than just your paycheck.
            <br></br>
            <br></br>
            We are willing to <u>pay big for exceptional sales people</u>, so we offer an hourly rate PLUS uncapped weekly commissions, monthly and yearly bonuses, AND no sales draw/seat cost to meet before commissioning.
            <br></br>
            <br></br>
            In this role you will utilize extensive outbound sales efforts to build a long term book of business and income potential that you deserve. Every dial you make for your business is an investment into <u>YOU</u>. As your business grows and your income increases, so does the number of communities you help protect.
            <br></br>
            <br></br>
            We are not bashful about the grind of inside sales, we embrace it! Big efforts means big payouts for anyone with an <u>entrepreneur mindset</u>. Our culture is highly supportive, we give high recognition, and we hold each other accountable to our “WHY”.
            <br></br>
            <br></br>
            If you are bold, resilient, and ready to get in the trenches you will achieve <u>life changing income</u> with us. If you are looking for management opportunities, and are exceeding expectations, you will put yourself in position for fast upward mobility in this company. This is well beyond a six figure sales management opportunity, and we promote candidates like you as early as 3-6 months from the start of your career with us.
            <br></br>
            <br></br>
            We can’t wait to speak with you! Please apply above/below.
            <br></br>
          </Typography>
          <Typography className='indent-left' sx={{
            marginTop: '16px',
            textAlign: 'justify',
            fontWeight: '700',
            fontSize: { xs: '24px', md: '28px', lg: '32px' },
            color: '#1260a8',
          }}>
            <i>Safe Haven Security Services, ADT’s largest Authorized Dealer, the nation’s leader for ADT-monitored smart home and security services.</i>
            <br></br>
            <br></br>
          </Typography>
          <Typography sx={{

            fontWeight: '700',
            fontSize: { xs: '24px', md: '28px', lg: '32px' },
            color: '#1260a8',
          }}>
            What Safe Haven Security Offers:
          </Typography>
          <ul className='green-text font-32 indent-left'>
            <li className='margin-bottom-16'>Hourly Rate PLUS Uncapped Commissions & Bonuses / Paid Weekly.</li>
            <li className='margin-bottom-16'>4 weeks of Paid Training.</li>
            <li className='margin-bottom-16'>Paid Time Off, Paid Holidays, Dental, Medical, Vision, 401k</li>
            <li className='margin-bottom-16'>Monday-Friday, 8 hours per day/no nights, no weekends, no travel required.</li>
            <li className='margin-bottom-16'>Upward mobility and leadership opportunities.</li>
          </ul>
          <Typography sx={{
            textAlign: 'justify',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '1.2',
            marginTop: '70px',
            marginBottom: '70px'
          }}
          >Equal Opportunity and Affirmative Action Employer
            <br></br>
            <br></br>
            Safe Haven is proud to be an Equal Opportunity / Affirmative Action employer. Qualified applicants will receive consideration for employment without regard to race, color, creed, religion, gender, age, sexual orientation, national origin, disability, veteran status, or any other characteristics protected by state, federal, or local law.
            <br></br>
            <br></br>
            The above statements are intended to describe the general nature and level of work being performed by people assigned to this classification. They are not intended to be construed as an exhaustive list of all responsibilities, duties, and skills required of personnel so classified. All employees may be required to perform duties outside of their normal responsibilities from time-to-time, as needed.
          </Typography>
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Box
              component='img'
              sx={{
                width: 'auto',
                maxWidth: '100%',
                maxHeight: '75vh',
                margin: '70px auto',
              }}
              alt='We want you on the team graphic'
              src={usMap1}
            />
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              paddingTop: '140px',
              color: '#1260a8',
              fontWeight: '700',
              fontSize: { xs: '28px', md: '36px', lg: '48px' },
            }}
          >
            WE ARE LEADERS IN
            <br></br>
            SECURITY AND SMART HOME AUTOMATION
          </Typography>

          <Box sx={{ width: '100%', display: 'flex' }}>
            <Box
              component='img'
              sx={{
                width: 'auto',
                maxWidth: '100%',
                maxHeight: '75vh',
                margin: '35px auto 70px auto',
              }}
              alt='We want you on the team graphic'
              src={houseImage}
            />
          </Box>


        </Container>
      </Box>
    </>
  );
}
