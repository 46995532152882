import { useNavigate } from 'react-router-dom';
import { Typography, Link } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Container, Stack } from '@mui/system';
import { TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'; // For form validation
import LoadingButton from '@mui/lab/LoadingButton';
import useFetch from 'use-http';
import { useState } from 'react';
import './index.scss';

interface GrayFormBannerProps {
    partnerPhone: string;
    partnerInfo: PartnerInfo;
}

export default function GrayFormBanner({
    partnerInfo,
    partnerPhone,
}: GrayFormBannerProps) {
    const navigate = useNavigate();
    const handleNavigate = (route: string, location: string | null = null) => {
        if (location === '_blank') {
            window.open(route, location);
        } else {
            navigate(route);
        }
    };
    const [leadCreationStatus, setLeadCreationStatus] = useState<boolean | null>(null);
    const [submitBtnText, setSubmitBtnText] = useState<string>('GET A FREE QUOTE');

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';

    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
        cacheLife: 1000,
    };

    const { post } = useFetch(
        process.env.REACT_APP_API,
        options,
    );

    const initialValues = {
        //TODO: update this to work off of a passed-in value
        miniForm: partnerInfo.CampaignId === '3723' || partnerInfo.CampaignId === '3130' || partnerInfo.CampaignId === '3149' || partnerInfo.CampaignId === '3684' || partnerInfo.CampaignId === '1920',
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        phoneCell: "",
        ssn: "",
        dob: "",
        email: "",
        emailConfirm: "",
        repName: "",
        repId: "",
        notes: "",
        package: "",
        consent: false,
    };

    const validationSchema = Yup.object({
        miniForm: Yup.boolean(),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),

        address: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        city: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        state: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        zip: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        phone: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        phoneCell: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        repName: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        repPhone: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        repId: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        notes: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        consent: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
    });

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Container
            maxWidth={false}
            className="gray-form-banner"
            sx={{ backgroundColor: "rgb(237, 237, 237)", paddingBottom: '12px' }}
        >
            <Stack
                direction={"column"}
                sx={{
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    alignItems: "center",
                }}
            >
                <Typography
                    className="white-title"
                    sx={{
                        color: "#545859",
                        font: {
                            xs: "normal 800 24px/34px SHS Body Font,sans-serif",
                            md: "normal 800 44px/54px SHS Body Font,sans-serif",
                        },
                        maxWidth: { xs: "300px", md: "650px" },
                        marginTop: "16px",
                        marginBottom: { xs: "16px" },
                    }}
                >
                    Get a FREE quote
                </Typography>

                <Typography
                    sx={{
                        textAlign: "center",
                        maxWidth: { xs: "300px", sm: "650px", md: "650px" },
                        marginBottom: { xs: "16px", md: "48px" },
                    }}
                >
                    Call{" "}
                    <Link sx={{ textDecoration: "none" }} href={`tel:${partnerPhone}`}>
                        {partnerPhone}
                    </Link>{" "}
                    or fill out the form below and a Safe Haven Security Specialist will
                    contact you, from time to time, about security and smart home
                    offers.
                </Typography>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        try {
                            const submissionData = {
                                ...values,
                                partnerCompanyId: partnerInfo.PartnerId,
                                partnerCampaignId: partnerInfo.CampaignId,
                                clientInfo: {
                                    url: window.location.href,
                                }
                            };

                            const postResponse: ApiResponseModel = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                            if (postResponse.code === 'OK') {
                                const parsedContent = JSON.parse(postResponse.content);

                                const newLeadId = parsedContent.details.output;
                                const userMessage = JSON.parse(parsedContent.details.userMessage[0]);

                                setLeadCreationStatus(true);

                                console.log("New ID: " + newLeadId);

                                navigate('/partners/confirmation', { state: { newLeadId, partnerInfo } });
                            } else {
                                console.log("Response code not OK: " + postResponse.code);
                                setLeadCreationStatus(false);
                            }

                            console.log("Request sent successfully:", postResponse);
                            resetForm();
                        } catch (error) {
                            console.error("Error sending request:", error);
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <Box className="fields-container perfect-vision" sx={{}}>
                                <Field
                                    as={TextField}
                                    className="left-field right-field nra-form-field freedom-mortgage-field"
                                    sx={{ width: { xs: 'calc(100% - 10px)', sm: 'calc(50% - 10px)', md: 'calc(25% - 10px)' } }}
                                    name="firstName"
                                    label="First Name*"
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                />

                                <Field
                                    as={TextField}
                                    className="left-field right-field nra-form-field freedom-mortgage-field"
                                    sx={{ width: { xs: 'calc(100% - 10px)', sm: 'calc(50% - 10px)', md: 'calc(25% - 10px)' } }}
                                    name="email"
                                    label="Email*"
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />

                                <Field
                                    as={TextField}
                                    className="left-field right-field nra-form-field freedom-mortgage-field"
                                    sx={{ width: { xs: 'calc(100% - 10px)', sm: 'calc(50% - 10px)', md: 'calc(25% - 10px)' } }}
                                    name="phone"
                                    label="Phone - Main*"
                                    onChange={(event: { target: { value: any; }; }) => {
                                        const inputValue = event.target.value.replace(/\D/g, '');

                                        let formattedValue = '';
                                        if (inputValue.length > 0) {
                                            formattedValue = '(' + inputValue.substring(0, 3);
                                            if (inputValue.length > 3) {
                                                formattedValue += ') ' + inputValue.substring(3, 6);
                                            }
                                            if (inputValue.length > 6) {
                                                formattedValue += '-' + inputValue.substring(6, 10);
                                            }
                                        }

                                        setFieldValue('phone', formattedValue);
                                    }}
                                    onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                                        const clipboardData = event.clipboardData;
                                        const pastedData = clipboardData.getData('text');
                                        const cleanedData = pastedData.replace(/\D/g, '');
                                        const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                        setFieldValue('phone', formattedValue);
                                        event.preventDefault();
                                    }}
                                    error={touched.phone && Boolean(errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />

                                <Field
                                    as={TextField}
                                    className="left-field right-field nra-form-field freedom-mortgage-field"
                                    sx={{ width: { xs: 'calc(100% - 10px)', sm: 'calc(50% - 10px)', md: 'calc(25% - 10px)' } }}
                                    name="zip"
                                    label="Zip*"
                                    error={touched.zip && Boolean(errors.zip)}
                                    helperText={touched.zip && errors.zip}
                                />

                                <Box sx={{ textAlign: "center", width: "100%" }}>
                                    <Typography sx={{
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        maxWidth: '950px',
                                        margin: '16px auto auto auto',
                                    }}>
                                        By clicking ‘{submitBtnText},’ I consent to being contacted by Safe Haven Security Services, LLC, Safe Haven Solar, LLC, or All Access Service, LLC regarding products and services via live, automated, or prerecorded telephone calls, text messages*, or emails, even if I’m on a Do Not Call list. Consent is not a condition of purchase. I can revoke this consent by emailing dnc@mysafehaven.com.
                                    </Typography>
                                    <LoadingButton
                                        className="blue-rounded-btn"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                        loadingIndicator={<CircularProgress className='loading-button-indicator' size={24} />}
                                        disabled={isSubmitting}
                                        sx={{
                                            fontWeight: 700,
                                            minHeight: "50px!important",
                                            marginTop: "16px!important",
                                        }}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'GET A FREE QUOTE'}
                                    </LoadingButton>
                                    <Typography sx={{
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        maxWidth: '950px',
                                        margin: '16px auto auto auto',
                                    }}>
                                        * Standard message and data rates may apply to text messages. You may opt out of receiving further text messages at any time by replying STOP.
                                        <br></br>
                                        † Your information is collected and used in accordance with Safe Haven’s {" "}
                                        <Link
                                            sx={{ textDecoration: "underline" }}
                                            href="https://mysafehaven.com/legal/privacy"
                                            onMouseDown={(event) => {
                                                if (event.button === 1) {
                                                    openInNewTab(`https://mysafehaven.com/legal/privacy`);
                                                }
                                            }}>
                                            Privacy Policy
                                        </Link>.
                                    </Typography>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Stack>
        </Container>
    );
}
