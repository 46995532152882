import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import './index.scss';
import { Helmet } from 'react-helmet';
import AffirmationForm from '../../Components/Forms/AffirmationForm';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function Affirmation() {
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === '_blank') {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>Request, Correct, or Delete My Personal Information | Safe Haven</title>
        <meta name="description" content="Submit an affirmation request" />
        <meta
          name="keywords"
          content="Request, Correct, or Delete My Personal Information, affirmation submission, submit affirmation, online affirmation request, affirmation form, affirmation service, [your company name] affirmation submission, submit affirmation for [specific purpose], free affirmation request, confidential affirmation submission, secure affirmation platform, how to submit an affirmation online, where to submit an affirmation for free, submit an affirmation for a specific goal, confidential online affirmation service, secure platform for submitting affirmations"
        />
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          minHeight: '15vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{ color: '#1260a8', fontWeight: '700', fontSize: '48px' }}
        >
          Request, Correct, or Delete My Personal Information
        </Typography>
      </Box>
      <AffirmationForm setConfirmationOpen={setConfirmationOpen}></AffirmationForm>
      <Modal
        open={confirmationOpen}
        closeAfterTransition
        onClose={() => {
          setConfirmationOpen(false);
        }}
        className=''
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Slide direction='up' in={confirmationOpen}>
          <Container
            maxWidth={false}
            sx={{
              maxWidth: '700px',
              minHeight: '400px',
              height: 'auto',
              bgcolor: '#fff',
              borderRadius: '9px',
              boxShadow: 24,
              padding: '0!important'
            }}
          >
            <Box sx={{ background: 'rgb(18, 96, 168)', width: '100%', minHeight: '64px', borderRadius: '9px 9px 0 0px', padding: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
              <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', color: '#fff', maxWidth: "500px" }}>
                Thank you for submitting your request.
              </Typography>
              <Box sx={{
                position: "absolute",
                right: "5px",
              }}>
                <IconButton
                  sx={{}}
                  onClick={() => {
                    setConfirmationOpen(false);
                  }}
                >
                  <ClearIcon sx={{ color: '#fff' }}></ClearIcon>
                </IconButton>
              </Box>
            </Box>
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
            >

              <Box
                sx={{
                  paddingLeft: '36px',
                  minHeight: '350px',
                  minWidth: '250px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <Box>
                  <Box sx={{ width: '100%', display: 'flex' }}>
                    <CheckCircleOutlineIcon sx={{ color: 'rgb(18, 96, 168)', margin: 'auto', height: '4rem', width: 'auto', marginBottom: '15px' }}></CheckCircleOutlineIcon>
                  </Box>

                  <Typography sx={{ fontSize: '24px', maxWidth: '600px' }}>
                    We have received your submission and will process it as soon as possible. Thank you for chosing Safe Haven Security!
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '24px 0' }}>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        handleNavigate('/');
                      }}
                      sx={{
                        minWidth: '100px',
                        minHeight: '45px',
                        fontSize: '16px',
                        textTransform: 'none',
                        fontWeight: '700',
                      }}
                    >
                      Back To Home
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Container>
        </Slide>
      </Modal>
    </>
  );
}
