import { Box, Container } from '@mui/system';
import companyAccolade from '../../Assets/Global/Safe-Haven-Top-Bar-2.png';
import Shsicon from '../ShsIcon';

export default function CompanyLogo(props: any) {
  const { maxHeight = '80px', maxWidth = '400px', marginLeft = 'initial', width = 'auto' } = props;

  return (
    <Box
      className='logo-wrapper'
      sx={{
        marginLeft: marginLeft,
        maxHeight: { xs: maxHeight, lg: maxHeight },
        maxWidth: maxWidth,
        display: 'flex',
        width: 'auto'
      }}
    >
      <Shsicon></Shsicon>
    </Box>
  );
}

