import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import builderHero from "../../../Assets/Partner/Builder-Hero.jpg";
import builderCircle from "../../../Assets/Partner/Builder-Circle.png";
import productDemo from "../../../Assets/Home/Technician with Homeowners 02 Cropped.png";
import productDemoMobile from "../../../Assets/Home/Technician with Homeowners 02_mobile2.png";
import { SetStateAction, useEffect, useRef, useState } from "react";
import ScrollToTopButton from "../../../Components/ScrollToTopButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import StarIcon from "@mui/icons-material/Star";
import {
  Swiper,
  SwiperRef,
  SwiperSlide,
  Swiper as SwiperType,
} from "swiper/react";
import { Navigation, Pagination } from "swiper";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import "swiper/css";
import "swiper/css/pagination";

import {
  AWARD_IMAGES,
  HOMEPAGE_REVIEWS,
  LOCATION_TYPE,
} from "../../../Constants";
import "./index.scss";

export default function HomeBuilderPartners() {
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === "_blank") {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };
  const [showSttButton, setShowSttButton] = useState(false);
  const SHOW_SCROLL_BUTTON_THRESHOLD = 300;
  const currentUrl = window.location.href;
  const baseUrl = currentUrl.replace(/\/about-us$/, ""); // Remove /about-us at the end
  const videoFile = "Our_Journey.mp4";
  const videoSrc = `${baseUrl}/${videoFile}`;
  useEffect(() => {
    const scrollListener = () => {
      setShowSttButton(window.scrollY > SHOW_SCROLL_BUTTON_THRESHOLD);
    };
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  const swiperRef = useRef<SwiperRef | null>(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev(500, true);
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext(500, true);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = (swiper: {
    activeIndex: SetStateAction<number>;
  }) => {
    setActiveSlideIndex(swiper.activeIndex);
    console.log(swiper.activeIndex);
  };

  const missionSectionDesktopRef = useRef<HTMLDivElement>(null);
  const missionSectionMobileRef = useRef<HTMLDivElement>(null);

  // Video player logic -- Need to move video to own component
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoHovered, setIsVideoHovered] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    if (video) {
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);

      return () => {
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
      };
    }
  }, []);

  const handleVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.paused
        ? videoRef.current.play()
        : videoRef.current.pause();
    }
  };

  return (
    <>
      <Helmet>
        <title>Safe Haven Homebuilder Program | Safe Haven</title>
        <meta
          name="description"
          content="Become a Safe Haven Homebuilder Partner and offer affordable home automation systems to your clients through ADT Pulse. Get connected and request information today!"
        />
        <meta
          name="keywords"
          content="homebuilder program, home automation, ADT Pulse, Safe Haven Security, partner, builder, affordable systems, client protection"
        />
      </Helmet>

      <ScrollToTopButton showButton={showSttButton}></ScrollToTopButton>

      {/* Family Image Sections */}
      <Box
        sx={{
          display: { xs: "none", md: "grid" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
          }}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              gridColumn: "1",
              gridRow: "1",
              display: "block",
              width: "100%",
              maxWidth: "100%",
              height: "auto",
              margin: "auto",
              //filter: "blur(10px)",
              transition: "filter 0.5s ease",
            }}
            alt="Hero Image of Builder"
            src={builderHero}
            onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
              const target = e.target as HTMLImageElement;
              target.style.filter = "blur(0px)";
            }}
          />
          <Box
            sx={{
              gridColumn: "1",
              gridRow: "1",
              width: "100%",
              height: "100%",
              //backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: 1,
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            gridColumn: "1",
            gridRow: "1",
            color: "#ffffff",
            textAlign: "center",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                marginTop: { lg: "100px" },
                marginBottom: "32px",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  zIndex: "1",
                  fontSize: { xs: "32px", md: "42px", lg: "48px", xl: "62px" },
                  fontWeight: "700",
                  letterSpacing: "3px",
                  maxWidth: { xs: "500px", md: "800px" },
                }}
              >
                Trusted Security Solutions for New Homeowners
              </Typography>
              <Typography
                sx={{
                  zIndex: "1",
                  textAlign: "center",
                  margin: {
                    xs: "24px auto auto auto",
                    lg: "48px auto auto auto",
                  },
                  fontSize: { xs: "14px", md: "22px", lg: "22px", xl: "28px" },
                  maxWidth: { xs: "500px", md: "900px" },
                  position: "relative",
                  width: "100%",
                }}
              >
                Partner directly with Safe Haven Security to offer your clients
                affordable home automation. Designed by builders for builders,
                our program enhances client relationships and lets you
                participate directly in the revenue stream.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Mobile: Family Pic  */}
      <Box
        sx={{
          display: { xs: "grid", md: "none" },
          justifyContent: "center",
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
          }}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              gridColumn: "1",
              gridRow: "1",
              display: "block",
              width: "auto",
              maxWidth: { xs: "1000px", sm: "100%" },
              height: "auto",
              margin: "auto",
            }}
            alt="The house from the offer."
            src={builderHero}
          />
          <Box
            sx={{
              gridColumn: "1",
              gridRow: "1",
              width: "100%",
              height: "100%",
              //backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            gridColumn: "1",
            gridRow: "1",
            color: "#ffffff",
            textAlign: "center",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                marginBottom: "32px",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  zIndex: "1",
                  fontSize: {
                    xs: "28px",
                    sm: "42px",
                    md: "42px",
                    lg: "48px",
                    xl: "62px",
                  },
                  fontWeight: "700",
                  letterSpacing: "3px",
                  maxWidth: { xs: "calc(100vw - 32px)", sm: "600px" },
                }}
              >
                Trusted Security Solutions for New Homeowners
              </Typography>
              <Typography
                sx={{
                  zIndex: "1",
                  textAlign: "center",
                  margin: "48px auto auto auto",
                  fontSize: {
                    xs: "18px",
                    sm: "24px",
                    md: "22px",
                    lg: "22px",
                    xl: "28px",
                  },
                  maxWidth: { xs: "calc(100vw - 32px)", sm: "500px" },
                  position: "relative",
                  width: "100%",
                }}
              >
                Partner directly with Safe Haven Security to offer your clients
                affordable home automation. Designed by builders for builders,
                our program enhances client relationships and lets you
                participate directly in the revenue stream.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "grid" },
          maxWidth: "950px",
          margin: "auto",
        }}
      >
        <Grid container className="benefits-grid" sx={{ marginTop: "16px" }}>
          <Grid item xs={4}>
            <Box sx={{ padding: "8px" }}>
              <Typography
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: "56px",
                  marginBottom: "4px",
                }}
              >
                25+
              </Typography>
              <Typography>Years in Business</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ padding: "8px" }}>
              <Typography
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: "56px",
                  marginBottom: "4px",
                }}
              >
                1M+
              </Typography>
              <Typography variant="body1">
                Over 1,000,000 Satisfied Clients
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ padding: "8px" }}>
              <Typography
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: "56px",
                  marginBottom: "4px",
                }}
              >
                43
              </Typography>
              <Typography variant="body1">States Served</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: { xs: "grid", md: "none" },

          margin: "auto",
        }}
      >
        <Grid container className="benefits-grid" sx={{ marginTop: "16px" }}>
          <Grid item xs={4}>
            <Box sx={{ padding: "2px" }}>
              <Typography
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: "24px",
                  marginBottom: "4px",
                }}
              >
                25+
              </Typography>
              <Typography>Years in Business</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ padding: "2px" }}>
              <Typography
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: "24px",
                  marginBottom: "4px",
                }}
              >
                1M+
              </Typography>
              <Typography variant="body1">
                Over 1,000,000 Satisfied Clients
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ padding: "2px" }}>
              <Typography
                sx={{
                  color: "rgb(0, 83, 156)",
                  fontSize: "24px",
                  marginBottom: "4px",
                }}
              >
                43
              </Typography>
              <Typography>States Served</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Commitment Section */}
      <Container
        maxWidth={"lg"}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          marginTop: "64px",
        }}
      >
        {/* Mobile: Heading Text */}
        <Stack
          ref={missionSectionMobileRef}
          id="our-mission-section-mobile"
          sx={{ display: { xs: "block", md: "none" }, marginBottom: "32px" }}
        >
          <Typography
            sx={{ fontSize: "32px", lineHeight: "48px", marginBottom: "16px" }}
          >
            <strong>Why Safe Haven?</strong>
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              letterSpacing: "1",
              wordSpacing: "1",
              marginBottom: "16px",
            }}
          >
            At Safe Haven Security, we understand the value of your relationship
            with your clients—that's why our Homebuilder Program puts them
            first. Developed by industry professionals, this program enables you
            to provide state-of-the-art home automation and security systems
            without the middleman. By partnering with us, you not only enhance
            your clients' homes with affordable smart home solutions, but also
            benefit from direct revenue participation.
          </Typography>
          <Typography
            sx={{ fontSize: "18px", letterSpacing: "1", wordSpacing: "1" }}
          >
            With over 25 years in business, serving 43 states, and over a
            million satisfied clients, we're committed to taking care of both
            our partners and their customers. Join us today to elevate your
            business and deliver exceptional value to new homeowners.
          </Typography>
        </Stack>

        {/* Girl/Dog Image & Blue Dot */}

        {/* Desktop: Text and Gray Blocks */}
        <Stack
          ref={missionSectionDesktopRef}
          id="our-mission-section-desktop"
          sx={{ display: { xs: "none", md: "block" }, marginRight: "32px" }}
        >
          <Typography
            sx={{ fontSize: "48px", lineHeight: "56px", marginBottom: "32px" }}
          >
            <strong>Why Safe Haven?</strong>
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              letterSpacing: "1",
              wordSpacing: "1",
              marginBottom: "16px",
            }}
          >
            At Safe Haven Security, we understand the value of your relationship
            with your clients—that's why our Homebuilder Program puts them
            first. Developed by industry professionals, this program enables you
            to provide state-of-the-art home automation and security systems
            without the middleman. By partnering with us, you not only enhance
            your clients' homes with affordable smart home solutions, but also
            benefit from direct revenue participation.
          </Typography>
          <Typography
            sx={{ fontSize: "18px", letterSpacing: "1", wordSpacing: "1" }}
          >
            With over 25 years in business, serving 43 states, and over a
            million satisfied clients, we're committed to taking care of both
            our partners and their customers. Join us today to elevate your
            business and deliver exceptional value to new homeowners.
          </Typography>
        </Stack>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            //marginRight: { md: "32px" },
          }}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              display: "block",
              height: "100%",
              maxHeight: "370px",
              maxWidth: "370px",
              //borderRadius: "50%",
            }}
            alt="The house from the offer."
            src={builderCircle}
          />
        </Box>
      </Container>
      <Container
        sx={{ maxHeight: "500px", margin: "88px auto", position: "relative" }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "500px",
            margin: "auto",
            width: "100%",
          }}
        >
          <Button
            className="home-free-quote-btn"
            sx={{
              backgroundColor: "#00519C",
              color: "#ffffff",
              borderRadius: "30px",
              height: "45px",
              maxHeight: "45px",
              fontSize: "14px",
              padding: "0 24px",
              fontFamily: "Rubik",
              textTransform: "capitalize",
              "&.home-free-quote-btn:hover": {
                backgroundColor: "#00519C!important",
              },
              "&.home-free-quote-btn>span": {
                marginLeft: "4px",
              },
            }}
            onMouseDown={(event) => {
              if (event.button === 1) {
                handleNavigate("/contact-us", "_blank");
              }
            }}
            onClick={(event) => {
              handleNavigate("/contact-us");
            }}
            endIcon={
              <ArrowForwardIosIcon
                id="btn-arrow-slide-right"
                sx={{ height: "14px", paddingBottom: "1px" }}
              />
            }
          >
            Contact Us
          </Button>
        </Box>
      </Container>

      {/* Awards Carousel Section */}
      <Container
        sx={{ maxHeight: "500px", margin: "88px auto", position: "relative" }}
      >
        <Swiper
          style={{ height: "100%", width: "100%" }}
          loop={true}
          ref={swiperRef}
          className="about-us-swiper"
          navigation={false}
          spaceBetween={0}
          modules={[Pagination, Navigation]}
          initialSlide={activeIndex}
          onSlideChange={handleSlideChange}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 2,
            },
            900: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
        >
          {AWARD_IMAGES.map((award, index) => (
            <SwiperSlide
              key={index}
              className={`about-us-swiper-slide-${index}`}
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                paddingRight: "1px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "300px",
                  width: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    height: "210px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    loading="lazy"
                    sx={{
                      width: "auto",
                      maxHeight: "100%",
                      maxWidth: "250px",
                      cursor: "pointer",
                    }}
                    alt={`Product ${index + 1}`}
                    src={award.image}
                  />
                </Box>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "22px",
                    maxWidth: "250px",
                    height: "70px",
                    paddingTop: "20px",
                  }}
                >
                  {award.text}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    borderLeft: "1px solid #cecece",
                    height: "125px",
                    right: "0",
                    bottom: "45%",
                  }}
                ></Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: { xs: "50%", sm: "75%", md: "50%" },
            height: "300px",
            background:
              "linear-gradient(to right, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: { xs: "50%", sm: "75%", md: "50%" },
            height: "300px",
            background:
              "linear-gradient(to left, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
            zIndex: 2,
          }}
        >
          <Box sx={{ margin: "18px" }}>
            <IconButton
              className="award-carousel-nav-button"
              aria-label="delete"
              onClick={handlePrev}
              sx={{
                backgroundColor: "rgb(68,200,245)",
                minHeight: "50px",
                minWidth: "50px",
              }}
            >
              <WestIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Box>

          <Box sx={{ margin: "18px" }}>
            <IconButton
              className="award-carousel-nav-button"
              aria-label="delete"
              onClick={handleNext}
              sx={{
                backgroundColor: "rgb(68,200,245)",
                minHeight: "50px",
                minWidth: "50px",
              }}
            >
              <EastIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
        </Box>
      </Container>

      {/* Desktop: Reviews Sections */}
      <Box
        sx={{
          display: { xs: "none", lg: "grid" },
          position: "relative",
          marginBottom: "250px",
        }}
      >
        {/* Background Image */}
        <Box
          component="img"
          sx={{
            width: "100%",
            maxWidth: "xl",
            height: "auto",
            margin: "auto",
            display: { xs: "none", lg: "grid" },
            gridColumn: "1",
            gridRow: "1",
            filter: "blur(10px)",
            transition: "filter 0.5s ease",
          }}
          alt="The house from the offer."
          src={productDemo}
          loading="lazy"
          onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
            const target = e.target as HTMLImageElement;
            target.style.filter = "blur(0px)";
          }}
        />

        {/* Dark Tint Overlay */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "xl",
            margin: "auto",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
            zIndex: 1,
          }}
        />

        {/* Blue Review Side-By-Side */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, 10%)",
            color: "#ffffff",
            zIndex: "2",
            width: "100%",
            maxWidth: "1300px",
          }}
        >
          <Grid container className="">
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                backgroundColor: "rgba(0, 39, 78)",
                padding: {
                  xs: "56px 32px 48px 32px",
                  md: "56px 80px 80px 60px",
                },
              }}
            >
              <Stack sx={{ marginBottom: "8px" }}>
                <Box>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                  <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
                </Box>
              </Stack>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: "700",
                  letterSpacing: "1px",
                  marginBottom: "8px",
                }}
              >
                22,000+ Reviews
              </Typography>
              <Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
                Rated Excellent by our customers, with 4.8 stars from over
                22,000 reviews, with an A+ BBB rating, we are proud to deliver
                top-notch home security solutions that exceed expectations.
              </Typography>
              <Box sx={{ marginTop: "48px" }}>
                <Button
                  variant="outlined"
                  className=""
                  sx={{
                    display: "flex",
                    color: "#ffffff",
                    borderColor: "#ffffff",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    height: "50px",
                    minWidth: "150px",
                    fontSize: "12px",
                    letterSpacing: "1px",
                    padding: "0 24px",
                    textTransform: "capitalize",
                  }}
                  onMouseDown={(event) => {
                    if (event.button === 1) {
                      handleNavigate("/contact-us", "_blank");
                    }
                  }}
                  onClick={(event) => {
                    handleNavigate("/contact-us");
                  }}
                >
                  CONTACT US
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                padding: "102px 80px 80px 60px",
                backgroundColor: "rgba(0, 83, 156)",
                borderTopRightRadius: "30% 45%",
              }}
            >
              <Swiper
                style={{ height: "100%", width: "100%" }}
                loop={true}
                className="home-reviews-swiper"
                navigation={false}
                pagination={{
                  clickable: true,

                  bulletClass: "home-reviews-carousel-bullet",
                  bulletActiveClass: "home-reviews-carousel-bullet-active",
                }}
                spaceBetween={5}
                modules={[Pagination, Navigation]}
                slidesPerView={1}
              >
                {HOMEPAGE_REVIEWS.map((review, index) => (
                  <SwiperSlide
                    key={index}
                    className={`homepage-reviews-slide-${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      paddingRight: "1px",
                      width: "60%",
                    }}
                  >
                    <Stack>
                      <Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
                        {review.text}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          letterSpacing: "1px",
                          marginTop: "24px",
                        }}
                      >
                        {review.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "rgba(68, 200, 245)",
                          fontWeight: "700",
                        }}
                      >
                        Google Review, 5 Stars
                      </Typography>
                    </Stack>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Mobile: Reviews Sections */}
      <Box
        sx={{
          display: { xs: "grid", lg: "none" },
          position: "relative",
          paddingBottom: "32px",
          background: "rgba(0, 83, 156)",
        }}
      >
        {/* Background Image */}
        <Box
          component="img"
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "auto",
            margin: "auto",
            display: { xs: "grid", lg: "none" },
            gridColumn: "1",
            gridRow: "1",
          }}
          alt="The house from the offer."
          src={productDemoMobile}
          loading="lazy"
        />

        {/* Dark Tint Overlay */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "xl",
            margin: "auto",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
          }}
        />

        <Box
          sx={{
            gridColumn: "1",
            gridRow: "2",
            backgroundColor: "rgba(0, 39, 78)",
            padding: { xs: "56px 32px 48px 32px", md: "56px 80px 80px 60px" },
            color: "#fff",
          }}
        >
          <Stack sx={{ marginBottom: "8px" }}>
            <Box>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
              <StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
            </Box>
          </Stack>
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: "700",
              letterSpacing: "1px",
              marginBottom: "8px",
            }}
          >
            22,000+ Reviews
          </Typography>
          <Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
            Rated Excellent by our customers, with 4.8 stars from over 22,000
            reviews, with an A+ BBB rating, we are proud to deliver top-notch
            home security solutions that exceed expectations.
          </Typography>
          <Box sx={{ marginTop: "48px" }}>
            <Button
              variant="outlined"
              className=""
              sx={{
                display: "flex",
                color: "#ffffff",
                borderColor: "#ffffff",
                borderWidth: "2px",
                borderRadius: "30px",
                height: "50px",
                minWidth: "150px",
                fontSize: "12px",
                letterSpacing: "1px",
                padding: "0 24px",
                textTransform: "capitalize",
              }}
            >
              CONTACT US
            </Button>
          </Box>
        </Box>

        <Box sx={{ backgroundColor: "rgba(0, 39, 78)", minHeight: "350px" }}>
          <Box
            sx={{
              gridColumn: "1",
              gridRow: "3",
              backgroundColor: "rgba(0, 83, 156)",
              borderTopRightRadius: {
                xs: "50% 60%",
                sm: "50% 60%",
                md: "25% 45%",
              },
              color: "#fff",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Swiper
              style={{ height: "100%" }}
              loop={true}
              className="home-reviews-swiper mobile-reviews-swiper"
              navigation={false}
              pagination={{
                clickable: true,
                renderBullet: function (index, className) {
                  return `
                          <span class="${className} mobile-reviews-bullet">
                            <span class="inner-dot"></span> 
                          </span>
                        `;
                },
                bulletClass: "home-reviews-carousel-bullet",
                bulletActiveClass: "home-reviews-carousel-bullet-active",
              }}
              spaceBetween={5}
              modules={[Pagination, Navigation]}
              slidesPerView={1}
            >
              {HOMEPAGE_REVIEWS.map((review, index) => (
                <SwiperSlide
                  key={index}
                  className={`homepage-reviews-slide-${index}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    paddingRight: "1px",
                    width: "60%",
                    marginLeft: "0",
                  }}
                >
                  <Stack sx={{ height: "100%", justifyContent: " flex-start" }}>
                    <Typography
                      sx={{
                        maxWidth: "70%",
                        fontSize: { xs: "12px", sm: "18px", md: "24px" },
                        lineHeight: { xs: "22px", sm: "28px", md: "32px" },
                        minHeight: "225px",
                        maxHeight: "225px",
                        marginTop: "24px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {review.text}
                    </Typography>
                    <Box sx={{ minHeight: "60px" }}>
                      <Typography
                        sx={{
                          fontSize: { xs: "14px", sm: "18px", md: "18px" },
                          lineHeight: { xs: "24px", sm: "28px", md: "28px" },
                          letterSpacing: "1px",
                        }}
                      >
                        {review.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", sm: "12px", md: "16px" },
                          lineHeight: { xs: "22px", sm: "22px", md: "26px" },
                          color: "rgba(68, 200, 245)",
                          fontWeight: "700",
                        }}
                      >
                        Google Review, 5 Stars
                      </Typography>
                    </Box>
                  </Stack>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      </Box>
    </>
  );
}
