import { useState, useEffect } from 'react';

interface PaginationHook<T> {
	currentPage: number;
	paginatedData: T[];
	totalPages: number;
	goToPage: (pageNumber: number) => void;
	nextPage: () => void;
	prevPage: () => void;
}

function usePagination<T>(data: T[], itemsPerPage: number): PaginationHook<T> {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [paginatedData, setPaginatedData] = useState<T[]>([]);
	const totalPages: number = Math.ceil(data?.length / itemsPerPage);

	useEffect(() => {
		const startIndex: number = (currentPage - 1) * itemsPerPage;
		const endIndex: number = startIndex + itemsPerPage;
		const newData: T[] = data?.slice(startIndex, endIndex);
		setPaginatedData(newData);
	}, [data, currentPage, itemsPerPage]);

	const goToPage: (pageNumber: number) => void = (pageNumber: number) => {
		if (pageNumber >= 1 && pageNumber <= totalPages) {
			setCurrentPage(pageNumber);
		}
	};

	const nextPage: () => void = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const prevPage: () => void = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return {
		currentPage,
		paginatedData,
		totalPages,
		goToPage,
		nextPage,
		prevPage,
	};
}

export default usePagination;
