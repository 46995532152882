import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Container, Stack } from "@mui/system";
import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import yardSign2 from "../../../../../Assets/Nerdwallet/ADTYardSign.png";
import TechInHome from "../../../../../Assets/Nerdwallet/TechInhome.png";
import exceptionalOfferImg from "../../../../../Assets/Nerdwallet/SpecialOffer_rev.jpg";
import heroImg from "../../../../../Assets/Nerdwallet/HeroPic.jpg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSpring, animated } from "@react-spring/web";
import { isMobile } from "react-device-detect";
import useFetch from "use-http";
import nerdWalletHeader from "../../../../../Assets/Nerdwallet/NW_Logo_Hor_RGB_Screen.png";
import { logo_slides, mhgSwiperSlides, reviews } from "../../../../../Constants/NerdWallet";
import phoneButton from "../../../../../Assets/Nerdwallet/ButtonPhoneNumber.png";
import contactUsButton from "../../../../../Assets/Nerdwallet/BlueButton_Contact Us.png";
import NerdwalletFooter from "./Components/NerdwalletFooter";
import ExceptopnalOfferMobile from "../../../../../Assets/Nerdwallet/SpecialOffer_Mobileversion_01.jpg";
import HeroImgMobile from "../../../../../Assets/Nerdwallet/MobileHeroimage.jpg";
import { Link as RouterLink, useLocation, useNavigate, } from "react-router-dom";
import Logo from "../../../../../Assets/Global/Horiz_Prim_FC.svg";
import findOutHowButton from "../../../../../Assets/Nerdwallet/Bluebutton_FindOutHow.png";
import ScrollableImageGrid from "./Components/ScrollImageGrid";
import Shsicon from "../../../../../Components/ShsIcon";
import ErrorIcon from "@mui/icons-material/Error";
import { ReactTagManager } from "react-gtm-ts";
import CookieConsentPopup from "../../../../../Components/CookieConsent/CookieConsentPopup";
import mhgHero from "../../../../../Assets/Templates/Nerdwallet/Hero image.jpg";
import mhgHeroMobile from "../../../../../Assets/Templates/Nerdwallet/Hero_image_cropped.png";
import PartnerLogo from "../../../../../Components/PartnerLogo";
import FullSystemEquipment from "../../../../../Assets/Templates/Nerdwallet/Full system offer.png";
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.scss";

import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import LoadingButton from "@mui/lab/LoadingButton";
import { getPhoneNumberForPath } from "../../Functions";

interface propsWrapper {
	partnerInfo: PartnerInfo
}

export default function Nerdwallet(props: propsWrapper) {
	const { partnerInfo } = props;
	const { pathname } = useLocation();
	const url = new URL(window.location.href);
	const path = url.pathname;
	const searchParams = url.searchParams;
	const navigate = useNavigate();
	const handleNavigate = (route: string, location: string | null = null) => {
		if (location === '_blank') {
			window.open(route, location);
		} else {
			navigate(route);
		}
	};
	const targetDivRef = useRef<HTMLDivElement>(null); // Add type annotation to useRef
	const [currentLogoSlide, setCurrentLogoSlide] = useState(0);
	const [currentReviewSlide, setCurrentReviewSlide] = useState(0);
	const [springs, api] = useSpring(() => ({
		from: { x: 0 },
	}));

	const [phoneNumber, setPhoneNumber] = useState('877-842-2194');
	const [clickId, setClickId] = useState<any>("");
	const [campaignId, setCampaignId] = useState<any>("");

	const [networkError, setNetworkError] = useState(false);
	const formRef = useRef<HTMLDivElement>(null);

	const [leadCreationStatus, setLeadCreationStatus] = useState<boolean | null>(null);

	const openInNewTab = (url: string) => {
		window.open(url, "_blank", "noreferrer");
	};

	useEffect(() => {
		const newPhoneNumber = getPhoneNumberForPath(pathname);
		setPhoneNumber(newPhoneNumber);
	}, [pathname]);

	useMemo(() => {
		const hasConsent = localStorage.getItem("cookie-consent") === "true";
		console.log("hasConsent", hasConsent);
		if (hasConsent) {
			console.log("test");
			// console.log("test")
			ReactTagManager.init({
				code: "GTM-K3XGL8HK",
				debug: false,
				performance: false,
			});
			ReactTagManager.action({
				event: "nerd_wallet_page_view",
				data: {
					page_path: url.pathname,
					page_title: document.title,
				},
			});
		}
	}, []);

	const onconsent = () => {
		ReactTagManager.init({
			code: "GTM-K3XGL8HK",
			debug: false,
			performance: false,
		});
		ReactTagManager.action({
			event: "nerd_wallet_page_view",
			data: {
				page_path: url.pathname,
				page_title: document.title,
			},
		});
	}

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			nextSlide();
		}, 5000);
		return () => clearTimeout(timeOutId);
	}, [currentLogoSlide]);

	useEffect(() => {
		nextSlide();
	}, []);

	const nextSlide = () => {
		api.start({
			from: {
				x: 130,
			},
			to: {
				x: isMobile ? -9 : -16,
			},
		});
		const nextSlideIndex = (currentLogoSlide + 1) % logo_slides.length;
		setCurrentLogoSlide(nextSlideIndex);
	};

	const prevSlide = () => {
		api.start({
			from: {
				x: -130,
			},
			to: {
				x: isMobile ? -8 : -8,
			},
		});
		const prevSlideIndex =
			currentLogoSlide === 0 ? logo_slides.length - 1 : currentLogoSlide - 1;
		setCurrentLogoSlide(prevSlideIndex);
	};

	const handleScrollClick = () => {
		if (targetDivRef.current) {
			const targetDivPosition = targetDivRef.current.getBoundingClientRect();
			const scrollOffset =
				window.scrollY ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			const adjustedScrollPosition = targetDivPosition.top + scrollOffset;
			window.scrollTo({ behavior: "smooth", top: adjustedScrollPosition });
		}
	};

	useEffect(() => {
		setClickId(searchParams.get("clickid"));
		setCampaignId(searchParams.get("campaignid"));
		ReactTagManager.init({
			code: "GTM-T5T79RDF",
			debug: true,
			performance: false,
		});
	}, []);

	let apiKey = process.env.REACT_APP_API__ACCESS_KEY
		? process.env.REACT_APP_API__ACCESS_KEY
		: "";

	const options = {
		headers: {
			"Content-Type": "application/json",
			Connection: "keep-alive",
			"Accept-Encoding": "gzip, deflate, br",
			"X-API-Key": apiKey,
		},
		cacheLife: 1000,
	};

	const { post, response, loading } = useFetch(
		process.env.REACT_APP_API,
		options,
	);

	const initialValues = {
		firstName: "",
		lastName: "",
		email: "",
		zip: "",
		phone: "",
	};

	const validationSchema = Yup.object({
		firstName: Yup.string().required("Required"),
		lastName: Yup.string().required("Required"),
		email: Yup.string().required("Required"),
		zip: Yup.string().required("Required"),
		phone: Yup.string().required("Required"),
	});

	useEffect(() => {
		// On component mount, ensure no side-to-side scrolling
		document.body.style.overflowX = "hidden";
		document.body.style.margin = "0"; // Reset body margin

		// Clean up on unmount to reset overflow and margin behavior
		return () => {
			document.body.style.overflowX = "auto";
			document.body.style.margin = ""; // Reset to default or remove if not needed
		};
	}, []);

	const handleScrollToForm = () => {
		if (formRef.current) {
			const elementTop = formRef.current.getBoundingClientRect().top;
			const offsetPosition =
				elementTop + window.pageYOffset - window.innerHeight * 0.2;

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
		}
	};

	return (
		<>
			{/* Nerd Wallet Logo */}
			{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
				<Grid
					container
					sx={{
						maxWidth: { xs: "100vw", md: "1200px" },
						margin: "0 auto",
						overflowX: "hidden",
						padding: 0,
					}}
				>
					<Grid item xs={12} >
						<div className="spacer-header"></div>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Typography
								variant="h6"
								sx={{ marginRight: "5px", fontSize: "22px", fontWeight: 580 }}
							>
								Welcome
							</Typography>
							<img
								className="nerdwallet-logo"
								src={nerdWalletHeader}
								alt="Header Image"
							/>
							<Typography
								variant="h5"
								sx={{ marginRight: "10px", fontSize: "22px", fontWeight: 580 }}
							>
								Users
							</Typography>
							<br />
						</Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Typography
								sx={{
									marginRight: "10px",
									fontSize: "10px",
									color: "CaptionText",
								}}
							>
								©2014-2024 and TM, NerdWallet, Inc. All Rights Reserved.
							</Typography>
						</Box>
					</Grid>
				</Grid>
			}

			<div className="spacer-header"></div>

			{/* ADT Dealer Logo & Call Button */}
			<Grid
				container
				sx={{
					maxWidth: "1500px",
					margin: "auto",
					backgroundColor: "rgba(224, 224, 224, .3)",
					paddingTop: { sm: "10px", md: "12px" },
					paddingBottom: { sm: "10px", xs: "19px", md: "12px" },
					paddingLeft: '12px',
					paddingRight: '12px'
				}}
			>
				<Grid
					item
					xs={6}
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Box
						className="header-logo-image"
						component="img"
						alt="The house from the offer."
						src={Logo}
						sx={{
							marginTop: { xs: '18px', sm: '0' },
							maxHeight: { xs: "30px", sm: "50px" },
							cursor: "pointer",
						}}
						onMouseDown={(
							event: React.MouseEvent<HTMLDivElement, MouseEvent>
						) => {
							if (event.button === 1) {
								handleNavigate("/about-us", "_blank");
							}
						}}
						onClick={() => {
							handleNavigate("/");
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					{
						<Box className="nerdwallet-header-button-wrapper">
							<Typography
								sx={{
									margin: "auto",
									color: "#0061aa",
									width: "100%",
									textAlign: "center",
									fontSize: { xs: "12px", md: "14px" },
									marginRight: { xs: "25px", md: "0px" },
									display: {
										xs: "none",
										sm: "block",
										md: "block",
										lg: "block",
									}, // Hide text on mobile, show on medium and larger screens
								}}
							>
								Help Protect What Matters Most
								<br />
							</Typography>

							{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
								<a href={`tel:${phoneNumber}`}>
									<img
										className="header-button-img"
										src={phoneButton}
										alt="Contact Us"
									/>
								</a>
							}

							{(pathname != "/partners/nerdwallet" && pathname != "/nerdwallet") &&
								<Box
									sx={{
										display: { xs: "flex", md: "flex" },
										alignItems: "center",
										justifyContent: 'center'
									}}
								>
									<Button
										href={`tel:${phoneNumber}`}
										className="home-free-quote-btn"
										sx={{
											backgroundColor: "#00519C",
											color: "#ffffff",
											borderRadius: "30px",
											height: "45px",
											maxHeight: "45px",
											fontSize: "14px",
											padding: "0 24px",
											fontFamily: "Rubik",
											textTransform: "capitalize",
											"&:hover": {
												backgroundColor: "#1260a8!important",
											},
											marginTop: '8px',
										}}
									>
										{phoneNumber}
									</Button>
								</Box>
							}
						</Box>
					}
				</Grid>
			</Grid>

			{/* Nerd Wallet Hero Image */}
			{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
				<Container maxWidth={false} className="nerdwallet-img-bg">
					<Box
						onClick={() => handleScrollClick()}
						component="img"
						sx={{
							display: { xs: "none", md: "block" },
							maxWidth: "100%",
						}}
						src={heroImg}
					/>
					<Box
						onClick={() => handleScrollClick()}
						component="img"
						sx={{
							display: { xs: "block", md: "none" },
							maxWidth: "100%",
						}}
						src={HeroImgMobile}
					/>
					<Stack
						direction={"column"}
						className="nerdwallet-panel-text-overlay"
						sx={{ marginTop: { xs: "25%", md: "11%" } }}
					>
						<span className="spacer-header"></span>

						{/* New element with positioning adjustments */}
						<div
							style={{
								position: "absolute",
								top: "calc(90% + 80px)",
								float: "left",
								left: "-20%",
							}}
						>
							{/* Adjust top and left values as needed */}
							<img
								onClick={() => handleScrollClick()}
								className="button-img-overlay"
								style={{
									paddingTop: "20px",
								}}
								src={findOutHowButton}
								alt="find out how"
							/>
						</div>
					</Stack>
				</Container>
			}

			{/* Client Logo Header */}
			{(pathname != "/partners/nerdwallet" && pathname != "/nerdwallet") &&
				<Box>
					{/* Desktop */}
					<Box
						sx={{
							display: { xs: 'none', md: 'flex' },
							alignItems: 'center',
							justifyContent: 'center',
							margin: '16px auto 36px auto',
							flexDirection: { xs: 'column', md: 'row' }
						}}
					>
						<Typography
							variant="h1"
							sx={{
								zIndex: "1",
								fontSize: { xs: "18px", sm: '24px', md: '32px' },
								lineHeight: '38px',
								letterSpacing: "1px",
								color: '#000',
								textAlign: 'center',
							}}
						>
							New Safe Haven Offering for
						</Typography>
						<Box sx={{ maxWidth: { xs: '250px', md: '300px', lg: '300px', xl: '400px' }, marginLeft: '8px', marginRight: '8px', marginBottom: { md: '20px', lg: '20px', xl: '32px' } }}>
							<PartnerLogo partnerInfo={partnerInfo}></PartnerLogo>
						</Box>
						<Typography
							variant="h1"
							sx={{
								zIndex: "1",
								fontSize: { xs: "24px", md: '32px' },
								lineHeight: '38px',
								letterSpacing: "1px",
								color: '#000',
								textAlign: 'center',
							}}
						>
							Clients
						</Typography>
					</Box>

					{/* Tablet/Mobile */}
					<Box
						sx={{
							display: { xs: 'flex', md: 'none' },
							alignItems: 'center',
							justifyContent: 'center',
							margin: '32px auto',
							flexDirection: { xs: 'column', md: 'row' }
						}}
					>
						<Typography
							variant="h1"
							sx={{
								zIndex: "1",
								fontSize: { xs: "20px", sm: '24px', md: '32px' },
								lineHeight: '38px',
								letterSpacing: "1px",
								color: '#000',
								textAlign: 'center',
							}}
						>
							New Safe Haven Offering
						</Typography>

						<Box sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: '12px'
						}}>
							<Typography
								sx={{
									zIndex: "1",
									fontSize: { xs: "20px", sm: '24px', md: '32px' },
									lineHeight: { xs: "24px", sm: '28px', md: '38px' },
									letterSpacing: "1px",
									color: '#000',
									textAlign: 'center',
									marginTop: '18px'
								}}
							>
								for
							</Typography>
							<Box sx={{ maxWidth: { xs: '200px', md: '300px', lg: '300px', xl: '400px' }, marginLeft: '8px', marginRight: '8px', marginBottom: { md: '20px', lg: '20px', xl: '32px' } }}>
								<PartnerLogo partnerInfo={partnerInfo}></PartnerLogo>
							</Box>
							<Typography
								variant="h1"
								sx={{
									zIndex: "1",
									fontSize: { xs: "20px", sm: '24px', md: '32px' },
									lineHeight: '38px',
									letterSpacing: "1px",
									color: '#000',
									textAlign: 'center',
									marginTop: '18px'
								}}
							>
								Clients
							</Typography>
						</Box>
					</Box>
				</Box>
			}

			{/* Hero Image */}
			{(pathname != "/partners/nerdwallet" && pathname != "/nerdwallet") &&
				<Box
					sx={{
						display: { xs: "grid", md: "grid" },
						maxWidth: "1500px",
						margin: "auto auto auto auto",
					}}
				>
					{/* Background Image Wrapper */}
					<Box
						sx={{
							position: "relative",
							display: "grid",
							gridColumn: "1",
							gridRow: "1",
							overflow: 'hidden',
							width: '100%',
							maxWidth: '100vw'
						}}
					>
						<Box
							component="img"
							loading="lazy"
							sx={{
								gridColumn: "1",
								gridRow: "1",
								display: { xs: "none", sm: 'grid' },
								width: 'auto',
								minHeight: '500px',
								right: 0,
								position: 'absolute',
								filter: "blur(10px)",
								transition: "filter 0.5s ease",
							}}
							alt="Hero image of father entering home with kids."
							src={mhgHero}
							onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
								const target = e.target as HTMLImageElement;
								target.style.filter = "blur(0px)";
							}}
						/>
						<Box
							component="img"
							loading="lazy"
							sx={{
								gridColumn: "1",
								gridRow: "1",
								display: { xs: "grid", sm: 'none' },
								width: '100%',
								minHeight: '500px',
								right: 0,
								position: 'absolute',
								filter: "blur(10px)",
								transition: "filter 0.5s ease",
							}}
							alt="Hero image of father entering home with kids."
							src={mhgHeroMobile}
							onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
								const target = e.target as HTMLImageElement;
								target.style.filter = "blur(0px)";
							}}
						/>
						<Box
							sx={{
								width: "100%",
								maxWidth: "xl",
								margin: "auto",
								height: "100%",
								backgroundColor: "rgb(255 254 254 / 62%)",
								display: { xs: "grid", md: 'none' },
								gridColumn: "1",
								gridRow: "1",
								zIndex: 1,
							}}
						/>
					</Box>

					{/* Text/Button Overlay */}
					<Box
						sx={{
							maxHeight: '500px',
							width: { md: "50%" },
							maxWidth: { md: "50%" },
							gridColumn: "1",
							gridRow: "1",
							color: "#ffffff",
							zIndex: 1,
							padding: { xs: '16px', md: '0 0 0 42px' },
							minHeight: '450px',
							display: "flex",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignContent: "center",
								alignItems: 'flex-start',
								marginBottom: "32px",
								width: "100%",
							}}
						>
							<Typography
								variant="h1"
								sx={{
									zIndex: "1",
									fontSize: { xs: "24px", md: '32px' },
									lineHeight: '38px',
									fontWeight: "700",
									letterSpacing: "1px",
									color: '#000',
									marginTop: '12px'
								}}
							>
								Complimentary* Smart Home Security System <span className="desktop-break"></span>
								+ $500* Equipment Voucher <span className="desktop-break"></span>
								For Our Clients
							</Typography>

							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Box
									sx={{
										display: { xs: "flex", md: "flex" },
										alignItems: "center",
										justifyContent: 'center'
									}}
								>
									<Button
										className="home-free-quote-btn"
										sx={{
											margin: { xs: '16px auto', md: '24px auto 32px 0' },
											backgroundColor: "#00519C",
											color: "#ffffff",
											borderRadius: "30px",
											height: "45px",
											maxHeight: "45px",
											fontSize: "14px",
											padding: "0 24px",
											fontFamily: "Rubik",
											textTransform: "capitalize",
											"&:hover": {
												backgroundColor: "#1260a8!important",
											},
										}}
										onClick={() => {
											handleScrollToForm();
										}}
									>
										Find Out How
									</Button>
								</Box>

								<Typography
									sx={{
										zIndex: "1",
										fontSize: { xs: "12px" },
										position: "relative",
										width: "100%",
										color: '#000'
									}}
								>
									*Requires 36-month monitoring contract (24 months in CA) beginning at $55.99 per month and payment of Customer Installation Charge beginning at $99.00. Specific equipment and pricing will vary by package.
									Early termination fees apply. See important Terms and Conditions to this offer {" "}
									<Link
										sx={{ textDecoration: "underline" }}
										href="https://mysafehaven.com/terms-and-conditions"
										onMouseDown={(event) => {
											if (event.button === 1) {
												openInNewTab(`https://mysafehaven.com/terms-and-conditions`);
											}
										}}>
										here
									</Link>.
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			}

			<div ref={targetDivRef} id="target-div"></div>

			{/* Form Container */}
			<Container
				sx={{
					backgroundColor: "rgba(237, 237, 237, .4)",
					margin: "auto",
					marginTop: "44px",
					marginBottom: "44px",
					borderRadius: "18px",
				}}
			>
				<Stack
					direction={"column"}
					sx={{
						padding: { xs: '32px', md: "64px" },
						alignItems: "center",
					}}
				>
					<Typography
						className="nerdwallet-entry-form-title"
						sx={{
							color: "black",
							fontSize: { xs: '24px', md: '32px' },
							lineHeight: { xs: '34px', md: '42px' },
							fontWeight: 700,
							width: '100%'
						}}
					>
						Your Peace of Mind is Only a Few Clicks Away...
					</Typography>

					{networkError ? (
						<div>
							<Typography
								sx={{
									display: "flex",
									alignItems: "center",
									color: "#eb4034", // Change color to indicate error
									backdropFilter: "blur(10px)",
									borderRadius: "10px",
									padding: "10px",
									marginTop: "10px",
									backgroundColor: "rgba(235, 0, 0, 0.1)", // Reddish background for error
								}}
							>
								<ErrorIcon sx={{ marginRight: "10px" }} />
								{/* Replace with your error icon component */}
								<b>
									This was an error with your submission. Please try again later
								</b>
							</Typography>
						</div>
					) : null}
					<Box
						ref={formRef}
						sx={{
							marginBottom: { xs: "16px", md: "28px" },
						}}
					>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={async (values, { setSubmitting, resetForm }) => {
								try {
									const submissionData = {
										...values,
										partnerCompanyId: pathname === '/partners/nerdwallet' ? "1505" : partnerInfo.PartnerId,
										partnerCampaignId: pathname === '/partners/nerdwallet' ? campaignId : partnerInfo.CampaignId,
										clickId: clickId,
										leadSourceType: "Call Back",
										clientInfo: {
											url: window.location.href,
										}
									};

									const postResponse = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

									if (postResponse.code === 'OK') {
										const parsedContent = JSON.parse(postResponse.content);

										const newLeadId = parsedContent.details.output;
										const userMessage = JSON.parse(parsedContent.details.userMessage[0]);

										setLeadCreationStatus(true);

										console.log("New ID: " + newLeadId);

										navigate('/partners/confirmation', { state: { newLeadId, partnerInfo } });

										console.log("Request sent successfully:", postResponse);
										resetForm();
									} else {
										console.log("Response code not OK: " + postResponse.code);
										setLeadCreationStatus(false);
									}
								} catch (error) {
									console.error("Error sending request:", error);
									setNetworkError(true);
									setTimeout(() => {
										setNetworkError(false);
									}, 5000);
								} finally {
									setSubmitting(false);
								}
							}}
						>
							{({ errors, touched, setFieldValue, isSubmitting, values }) => (
								<Form>
									<Box
										className="fields-container perfect-vision"
										sx={{
											alignContent: "center",
											justifyContent: "center",
										}}
									>
										<Grid
											container
											sx={{
												margin: "auto",
												alignItems: "center",
												justifyContent: "center",

												maxWidth: "1200px",
											}}
											style={{ justifyContent: "space-between" }}
										>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													as={TextField}
													variant="standard"
													fullWidth
													className="contact-grid-form"
													name="firstName"
													label="First Name"
													error={touched.firstName && Boolean(errors.firstName)} // Check if touched and error exists
													helperText={touched.firstName && errors.firstName} // Show error message if touched
												/>
											</Grid>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													as={TextField}
													variant="standard"
													fullWidth
													name="lastName"
													className="contact-grid-form"
													label="Last Name"
													error={touched.lastName && Boolean(errors.lastName)}
													helperText={touched.lastName && errors.lastName}
												/>
											</Grid>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													as={TextField}
													fullWidth
													variant="standard"
													className="contact-grid-form"
													name="email"
													label="Email"
													error={touched.email && Boolean(errors.email)}
													helperText={touched.email && errors.email}
												/>
											</Grid>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													as={TextField}
													fullWidth
													variant="standard"
													name="phone"
													label="Phone"
													onChange={(event: { target: { value: any; }; }) => {
														const inputValue = event.target.value.replace(/\D/g, '');

														let formattedValue = '';
														if (inputValue.length > 0) {
															formattedValue = '(' + inputValue.substring(0, 3);
															if (inputValue.length > 3) {
																formattedValue += ') ' + inputValue.substring(3, 6);
															}
															if (inputValue.length > 6) {
																formattedValue += '-' + inputValue.substring(6, 10);
															}
														}

														setFieldValue('phone', formattedValue);
													}}
													onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
														const clipboardData = event.clipboardData;
														const pastedData = clipboardData.getData('text');
														const cleanedData = pastedData.replace(/\D/g, '');
														const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
														setFieldValue('phone', formattedValue);
														event.preventDefault();
													}}
													error={touched.phone && Boolean(errors.phone)}
													helperText={touched.phone && errors.phone}
												/>
											</Grid>
											<Grid item xs={12} md={6} className="contact-grid-form">
												<Field
													sx={{
														marginBottom: { xs: "10px" },
													}}
													as={TextField}
													fullWidth
													variant="standard"
													name="zip"
													label="Zip"
													error={touched.zip && Boolean(errors.zip)}
													helperText={touched.zip && errors.zip}
												/>
											</Grid>
											<Grid item xs={12} className="contact-grid-form">
												<Typography
													sx={{
														fontSize: "12px",
														color: "#545859",
														float: "right",
														alignContent: "center",
													}}
												>
													By clicking 'Submit,' I consent to being contacted by
													Safe Haven Security Services, LLC, Safe Haven Solar,
													LLC, or All Access Service, LLC regarding products and
													services via live, automated, or prerecorded telephone
													calls, text messages*, or emails, even if I'm on a Do
													Not Call list. Consent is not a condition of purchase.
													I can revoke this consent by emailing{" "}
													<RouterLink to="dnc@mysafehaven.com">
														dnc@mysafehaven.com.
													</RouterLink>
												</Typography>

												<br />

												<Box sx={{ width: '100%', display: 'flex' }}>
													<LoadingButton
														type="submit"
														sx={{
															maxWidth: '150px',
															minWidth: '150px',
															margin: "15px auto",
															backgroundColor: "#00519C",
															color: "#ffffff",
															borderRadius: "30px",
															height: "45px",
															maxHeight: "45px",
															fontSize: "14px",
															padding: "0 24px",
															fontFamily: "Rubik",
															textTransform: "capitalize",
															"&:hover": {
																backgroundColor: "#1260a8!important",
															},
														}}
														variant="contained"
														loading={isSubmitting}
														disabled={isSubmitting}
														loadingIndicator={<CircularProgress className='loading-button-indicator' size={24} />}
													>
														{isSubmitting ? 'Submitting...' : 'Submit'}
													</LoadingButton>
												</Box>

												<Typography sx={{ fontSize: '12px', color: "#545859", }}>
													* Standard message and data rates may apply to
													text messages. You may opt out of receiving further
													text messages at any time by replying STOP.
													<br />† Your information is collected and used in
													accordance with Safe Haven’s{" "}
													<RouterLink to="https://mysafehaven.com/legal/privacy/">
														Privacy Policy.
													</RouterLink>
												</Typography>
											</Grid>
										</Grid>
									</Box>
								</Form>
							)}
						</Formik>
					</Box>
				</Stack>
			</Container>

			{/* Voucher Image */}
			{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
				<Container
					maxWidth={false}
					className="panel-img-bg"
					sx={{
						height: "80%",
					}}
				>
					<div
						style={{
							height: "90%",
						}}
					>
						<Box
							onClick={() => {
								// go to url
								window.open(
									"https://smarthomeadvantage.com/terms-and-conditions/",
									"_blank",
								);
							}}
							component="img"
							sx={{
								display: { xs: "none", md: "block" },
								maxWidth: "100%",
							}}
							src={exceptionalOfferImg}
						/>
						<Box
							onClick={() => {
								window.open(
									"https://smarthomeadvantage.com/terms-and-conditions/",
									"_blank",
								);
							}}
							component="img"
							sx={{
								display: { xs: "block", md: "none" },
								maxWidth: "100%",
							}}
							src={ExceptopnalOfferMobile}
						/>
					</div>
				</Container>
			}

			{/* ADT Sign Image */}
			<Container
				sx={{
					backgroundColor: "rgb(237, 237, 237, .4)",
					width: { xs: "90%", md: "79%" },
					margin: "auto",
					marginTop: "44px",
					marginBottom: "44px",
					borderRadius: "18px",
				}}
			>
				<Grid container sx={{ padding: "10px 0" }} spacing={2}>
					<Grid
						item
						xs={12}
						md={12}
						sx={{
							alignContent: "center",
							justifyContent: "center",
							margin: "auto",
						}}
					>
						<Typography
							sx={{
								textAlign: { xs: "center", md: "center" },
								color: "black",
								fontSize: { xs: "24px", md: "34px" },
							}}
						>
							<b>Safe Haven Presents a Special Opportunity...</b>
						</Typography>
					</Grid>

					{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
						<Grid
							item
							xs={12}
							md={12}
							sx={{
								alignContent: "center",
								justifyContent: "center",
								margin: "auto",
							}}
						>
							<Box
								sx={{
									marginTop: "10px",
									margin: "auto",
									maxHeight: {
										xs: "78px",
										lg: "78px",
									},
									padding: "10px",
									marginBottom: "20px",
									maxWidth: "330px",
									display: "flex",
								}}
							>
								<Shsicon></Shsicon>
							</Box>
						</Grid>
					}

					<Grid item xs={12} md={5}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Box
								component="img"
								sx={{
									maxWidth: "100%",
									height: { xs: "auto", md: "auto" },
									float: "left",
									marginBottom: { xs: "20px", md: "0px" },
								}}
								src={yardSign2}
							/>
						</Box>
					</Grid>

					<Grid item xs={12} md={7}>
						{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
							<>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px",
										fontSize: { xs: "14px", md: "15px" },
										fontWeight: { xs: 700, md: 600 },

									}}
									className="nerdwallet-nerdwallet-partnership-body-text"
								>
									Safe Haven Security + ADT monitoring is a winning combination
									where you reap the benefits!
								</Typography>

								<br />

								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px",
										fontSize: { xs: "14px", md: "15px" },
										fontWeight: { xs: 700, md: 600 },

									}}
									className="nerdwallet-partnership-body-text"
								>
									Not only do you get the exceptional reliability you've come to
									expect from ADT, but you'll also receive the expertise of Safe
									Haven providing you with:
								</Typography>
							</>
						}

						{(pathname != "/partners/nerdwallet" && pathname != "/nerdwallet") &&
							<Typography
								sx={{
									color: "black",
									marginBottom: "5px",
									margin: "5px 5px 5px 8px",
									fontSize: { xs: "14px", md: "24px" },
									fontWeight: { xs: 700, md: 600 },

								}}
								className="nerdwallet-nerdwallet-partnership-body-text"
							>
								#1 ADT-Authorized Dealer
							</Typography>
						}

						<Box sx={{ maxWidth: '550px' }}>
							<ul style={{}}>
								<li>
									<Typography
										sx={{
											color: "black",
											marginBottom: "5px",
											margin: "5px 5px 12px 5px",
											fontSize: { xs: "14px", md: "18px" },

										}}
										className="nerdwallet-partnership-body-text"
									>
										Comprehensive access to the renowned ADT-monitored security
										systems.
									</Typography>
								</li>
								<li>
									<Typography
										sx={{
											color: "black",
											marginBottom: "5px",
											margin: "5px",
											fontSize: { xs: "14px", md: "18px" },

										}}
										className="nerdwallet-partnership-body-text"
									>
										The latest security devices, professionally installed by Safe
										Haven Technicians.
									</Typography>
								</li>
								<li>
									<Typography
										sx={{
											color: "black",
											marginBottom: "5px",
											margin: "5px",
											fontSize: { xs: "14px", md: "18px" },

										}}
										className="nerdwallet-partnership-body-text"
									>
										Convenience of accessing and controlling your entire system
										from your smartphone.
									</Typography>
								</li>
								<li>
									<Typography
										sx={{
											color: "black",
											marginBottom: "5px",
											margin: "5px",
											fontSize: { xs: "14px", md: "18px" },

										}}
										className="nerdwallet-partnership-body-text"
									>
										Safe Haven's SmartHome Advantage - an all-in-one security
										solution.
									</Typography>
								</li>
							</ul>

							<Box
								sx={{
									display: { xs: "flex", md: "flex" },
									alignItems: "center",
									justifyContent: 'center',
									marginRight: '12px'
								}}
							>
								<Button
									className="home-free-quote-btn"
									sx={{
										margin: { xs: '16px auto', md: '32px 0 32px auto' },
										backgroundColor: "#00519C",
										color: "#ffffff",
										borderRadius: "30px",
										height: "45px",
										maxHeight: "45px",
										fontSize: "14px",
										padding: "0 24px",
										fontFamily: "Rubik",
										textTransform: "capitalize",
										"&:hover": {
											backgroundColor: "#1260a8!important",
										},
									}}
									onClick={() => {
										handleScrollToForm();
									}}
									endIcon={
										<ArrowForwardIosIcon
											id="btn-arrow-slide-right"
											sx={{ height: "14px", paddingBottom: "1px" }}
										/>
									}
								>
									Learn More
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>

			{/* Why Safe Haven Section */}
			<Container
				sx={{
					backgroundColor: "rgba(237, 237, 237, .4)",
					width: { xs: "90%", md: "79%" },
					margin: "auto",
					marginTop: "44px",
					marginBottom: "44px",
					borderRadius: "18px",
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={12} md={6} sx={{ paddingRight: '5vw' }}>
						<Typography
							sx={{
								color: "black",
								width: "100%",
								marginBottom: "30px",
								float: "left",
								margin: "20px",
								fontSize: { xs: "34px", md: "34px" },
								fontWeight: { xs: 510, md: 620 },

							}}
						>
							Why Safe Haven?
						</Typography>

						<ul>
							<li>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px 5px 12px 5px",
										fontSize: { xs: "14px", md: "18px" },

									}}
									className="nerdwallet-partnership-body-text"
								>
									Trusted by over 1 million homes and counting.
								</Typography>
							</li>
							<li>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px 5px 12px 5px",
										fontSize: { xs: "14px", md: "18px" },

									}}
									className="nerdwallet-partnership-body-text"
								>
									A+ BBB Rating and 4.8/5 stars from over 22,000 reviews on Google.
								</Typography>
							</li>
							<li>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px 5px 12px 5px",
										fontSize: { xs: "14px", md: "18px" },

									}}
									className="nerdwallet-partnership-body-text"
								>
									Exclusive savings for clients!
								</Typography>
							</li>
							<li>
								<Typography
									sx={{
										color: "black",
										marginBottom: "5px",
										margin: "5px 5px 12px 5px",
										fontSize: { xs: "14px", md: "18px" },

									}}
									className="nerdwallet-partnership-body-text"
								>
									Trusted by over 1 million homes and counting.
								</Typography>
							</li>

							{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
								<>
									<li>
										<Typography
											sx={{
												color: "black",
												marginBottom: "5px",
												margin: "5px",
												fontSize: { xs: "14px", md: "15px" },
												fontWeight: { xs: 600, md: 600 },

											}}
										>
											Locations across 100 metro areas serving communities
											nationwode.
										</Typography>
									</li>
									<li>
										<Typography
											className="nerdwallet-partnership-body-text"
											sx={{
												color: "black",
												marginBottom: "5px",
												margin: "5px",
												fontSize: { xs: "14px", md: "15px" },
												fontWeight: { xs: 600, md: 600 },

											}}
										>
											20,000+ positive customer experiences resulting in an A+ BBB
											Rating.
										</Typography>
									</li>
									<li>
										<Typography
											className="nerdwallet-partnership-body-text"
											sx={{
												color: "black",
												marginBottom: "5px",
												margin: "5px",
												fontSize: { xs: "14px", md: "15px" },
												fontWeight: { xs: 600, md: 600 },

											}}
										>
											Well-trained, friendly staff who will look after your unique
											home security needs.
										</Typography>
									</li>
								</>
							}
						</ul>

						{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
							<Typography
								className="nerdwallet-partnership-body-text"
								sx={{
									color: "black",
									marginBottom: "5px",
									margin: "5px",
									fontSize: { xs: "14px", md: "15px" },
									fontWeight: { xs: 600, md: 600 },

								}}
							>
								When you reach out to us, you'll speak to a knowledgeable team who
								are ready to help. Click below to get started today and experience
								the difference Safe Haven makes for you.
							</Typography>
						}

						<Box
							sx={{
								display: { xs: "flex", md: "flex" },
								alignItems: "center",
								justifyContent: 'center',
								marginRight: '12px'
							}}
						>
							<Button
								className="home-free-quote-btn"
								sx={{
									margin: { xs: '16px auto', md: '32px auto 32px 0' },
									backgroundColor: "#00519C",
									color: "#ffffff",
									borderRadius: "30px",
									height: "45px",
									maxHeight: "45px",
									fontSize: "14px",
									padding: "0 24px",
									fontFamily: "Rubik",
									textTransform: "capitalize",
									"&:hover": {
										backgroundColor: "#1260a8!important",
									},
								}}
								onClick={() => {
									handleScrollToForm();
								}}
								endIcon={
									<ArrowForwardIosIcon
										id="btn-arrow-slide-right"
										sx={{ height: "14px", paddingBottom: "1px" }}
									/>
								}
							>
								Contact Us
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box sx={{ display: 'flex' }}>
							<Box
								component="img"
								sx={{
									maxWidth: { xs: "100%", md: "100%" },
									height: { xs: "auto" },
									float: { xs: "", md: "right" },
									margin: "auto",
								}}
								src={TechInHome}
							/>
						</Box>
					</Grid>
				</Grid>
				<div style={{ textAlign: "center" }}>
					<img
						onClick={() => {
							handleScrollToForm();
						}}
						className="button-img-mobile"
						style={{
							width: "190px",
							height: "58px",
							margin: "auto",
							padding: "20px 0", // Adjust padding as needed
							cursor: "pointer",
						}}
						src={contactUsButton}
						alt="learn more"
					/>
				</div>
			</Container>

			{/* Gray Strip #1 */}
			<Container
				sx={{
					backgroundColor: "rgba(30, 30, 30, 0.8)",
					height: "195px",
					marginBottom: "44px",
					borderRadius: "18px",
					textAlign: "center",
					padding: "30px 0",
					display: 'flex',
					justifyContent: 'center',
					alignContent: 'center',
					flexDirection: 'column'
				}}
			>
				<Typography
					sx={{
						color: "white",
						fontWeight: '700',
						marginTop: "10px",
						marginBottom: "10px",
						fontSize: { xs: "14px", md: "24px" },
					}}
				>
					Let Us Help Secure Your Home
				</Typography>

				<Box
					sx={{
						display: { xs: "flex", md: "flex" },
						alignItems: "center",
						justifyContent: 'center',
						marginRight: '12px'
					}}
				>
					<Button
						className="home-free-quote-btn"
						sx={{
							margin: { xs: 'auto', },
							backgroundColor: "#00519C",
							color: "#ffffff",
							borderRadius: "30px",
							height: "45px",
							maxHeight: "45px",
							fontSize: "14px",
							padding: "0 24px",
							fontFamily: "Rubik",
							textTransform: "capitalize",
							"&:hover": {
								backgroundColor: "#1260a8!important",
							},
						}}
						onClick={() => {
							handleScrollToForm();
						}}
						endIcon={
							<ArrowForwardIosIcon
								id="btn-arrow-slide-right"
								sx={{ height: "14px", paddingBottom: "1px" }}
							/>
						}
					>
						Get Started
					</Button>
				</Box>
			</Container>

			{/* Products Carousel Section*/}
			<Box>
				<Container
					className="lifestyle-text"
					sx={{
						width: { xs: "90%", md: "79%" },
					}}
				>
					{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
						<>
							<Typography
								component="h2"
								sx={{
									fontSize: { xs: "24px", md: "32px" },
									color: "black",
									borderRadius: "18px",
									margin: "auto",
									marginTop: "44px",
								}}
							>
								<b>Customize Your Home To Fit Your Lifestyle</b>
							</Typography>

							<Typography
								sx={{
									fontSize: { xs: "14px", md: "18px" },
									fontWeight: 400,
									color: "black",
									margin: "auto",
									marginRight: "20px",
									borderRadius: "18px",

								}}
							>
								Your equipment voucher can be used to add additional security and
								smarthome technology such as interior/exterior cameras, smart
								thermostats, life safety devices, and more.
							</Typography>

							<ScrollableImageGrid />
						</>
					}

					{(pathname != "/partners/nerdwallet" && pathname != "/nerdwallet") &&
						<Box>
							<Box
								component="img"
								sx={{
									maxWidth: "100%",
								}}
								src={FullSystemEquipment}
							/>
						</Box>
					}
				</Container>
			</Box>

			{/* Gray Strip #2 */}
			{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') &&
				<Container
					sx={{
						backgroundColor: "rgba(30, 30, 30, 0.8)",
						height: "195px",
						marginBottom: "44px",
						borderRadius: "18px",
						textAlign: "center",
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center',
						flexDirection: 'column'
					}}
				>
					<Typography
						sx={{
							color: "white",
							marginBottom: "10px",
							fontSize: { xs: "14px", md: "24px" },
						}}
					>
						<b>Talk to a Safe Haven Security Specialist Today</b>
					</Typography>

					<Box
						sx={{
							display: { xs: "flex" },
							alignItems: "center",
							justifyContent: 'center',
							marginRight: '12px'
						}}
					>
						<Button
							className="home-free-quote-btn"
							sx={{
								margin: { xs: 'auto', },
								backgroundColor: "#00519C",
								color: "#ffffff",
								borderRadius: "30px",
								height: "45px",
								maxHeight: "45px",
								fontSize: "14px",
								padding: "0 24px",
								fontFamily: "Rubik",
								textTransform: "capitalize",
								"&:hover": {
									backgroundColor: "#1260a8!important",
								},
							}}
							onClick={() => {
								handleScrollToForm();
							}}
							endIcon={
								<ArrowForwardIosIcon
									id="btn-arrow-slide-right"
									sx={{ height: "14px", paddingBottom: "1px" }}
								/>
							}
						>
							Get Started
						</Button>
					</Box>
				</Container>
			}

			{/* Reviews Carousel */}
			<Box>
				{/* Desktop */}
				<Container
					sx={{
						display: { xs: 'none', md: "block" },
						backgroundColor: "rgba(237, 237, 237, .4)",
						width: { xs: "90%", md: "79%" },
						margin: "auto",
						paddingTop: "64px",
						marginBottom: { xs: "14px", md: "24px" },
						borderRadius: "18px",
						textAlign: "center",
						minHeight: { xs: "80%", md: "100%" },
					}}
				>
					<>
						<Box
							sx={{
								textAlign: "center",
								bottom: "0",
								minHeight: { xs: "153px", md: "160px" },
							}}
						>
							<Typography
								sx={{
									color: "rgba(30, 30, 30, 0.88)",

									fontWeight: 550,
									fontSize: { xs: "14px", md: "19px" },
									marginBottom: "10px",
									width: { xs: "90%", md: "80%" },
									margin: "auto",
								}}
							>
								"{reviews[currentReviewSlide].description}"
							</Typography>
							<Typography
								sx={{
									fontSize: { xs: "15px", md: "21px" },
									color: "rgba(30, 30, 30, 0.88)",
									marginBottom: "10px",

									width: "50%",
									margin: "10px auto",
								}}
							>
								<b>
									<u>{reviews[currentReviewSlide].name}</u>
								</b>
							</Typography>
						</Box>
					</>

					<button
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: "pointer",
							padding: "10px",
							color: "rgba(30, 30, 30, 0.5)",
						}}
						onClick={() => {
							currentReviewSlide === 0
								? setCurrentReviewSlide(reviews.length - 1)
								: setCurrentReviewSlide(currentReviewSlide - 1);
						}}
						disabled={currentLogoSlide === 0} // Disable button if on first slide
					>
						<ChevronLeftIcon fontSize="large" />
					</button>

					<button
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: "pointer",
							color: "rgba(30, 30, 30, 0.5)",
							padding: "10px",
							marginLeft: "50px",
						}}
						onClick={() => {
							currentReviewSlide === reviews.length - 1
								? setCurrentReviewSlide(0)
								: setCurrentReviewSlide(currentReviewSlide + 1);
						}}
					>
						<ChevronRightIcon fontSize="large" />
					</button>
				</Container>

				{/* Mobile */}
				{(pathname != "/partners/nerdwallet" && pathname != "/nerdwallet") &&
					<Box sx={{ display: { xs: 'block', md: "none" }, padding: '0 16px' }}>
						<Swiper
							loop={true}
							navigation={false}
							pagination={true}
							keyboard={true}
							modules={[Navigation, Pagination, Mousewheel, Keyboard]}
							className="mhg-swiper"
						>
							{reviews.map((review, index) => (
								<SwiperSlide
									key={index}
									className={`mhg-swiper-slide-${index}`}
									style={{
										display: "flex",
										justifyContent: "center",
										height: "100%",
										paddingRight: "1px",
										width: "60%",
									}}
								>
									<Box>
										<Typography
											sx={{
												color: "rgba(30, 30, 30, 0.88)",

												fontWeight: 550,
												fontSize: { xs: "14px", md: "19px" },
												marginBottom: "10px",
												width: { xs: "90%", md: "80%" },
												margin: "auto",
											}}
										>
											"{review.description}"
										</Typography>
										<Typography
											sx={{
												fontSize: { xs: "15px", md: "21px" },
												color: "rgba(30, 30, 30, 0.88)",
												marginBottom: "10px",

												width: "50%",
												margin: "10px auto",
												fontWeight: 700
											}}
										>
											- {review.name}
										</Typography>
									</Box>
								</SwiperSlide>
							))}
						</Swiper>
					</Box>
				}
			</Box>

			{/* Logos Carousel */}
			<Container
				sx={{
					width: { xs: "90%", md: "79%" },
					margin: "auto",
					height: "80%",
					marginBottom: "10px",
					borderRadius: "18px",
					textAlign: "center",
					padding: "50px 0",
				}}
			>
				<Typography
					sx={{
						color: "rgba(30, 30, 30, 0.88)",
						marginTop: "10px",
						fontWeight: 600,
						font: {
							xs: "normal 800 24px/34px SHS Body Font,sans-serif",
							md: "normal 800 44px/54px SHS Body Font,sans-serif",
						},
						fontSize: { xs: "24px", md: "38px" },
					}}
				>
					<b>Quality Partners with Quality Experience</b>
				</Typography>

				<Grid container spacing={0}>
					<Grid item xs={12}>

						{/* Desktop */}
						<Box
							sx={{
								display: { xs: 'none', md: "flex" },
								justifyContent: "space-between",
								overflow: "hidden", // Ensure overflow is hidden for the carousel effect
								minHeight: { xs: "130px", md: "290px" },
							}}
						>
							{/* Previous slide image */}
							<Box
								component="img"
								sx={{
									width: { xs: "22%", md: "20%" },
									height: { xs: "100%", md: "0%" },
									margin: "auto",
									padding: "20px",
									opacity: 0.3,
									cursor: "pointer",
									transition: "opacity 0.3s ease",
									"&:hover": {
										opacity: 0.5,
									},
								}}
								alt="Previous Slide"
								src={
									logo_slides[
										currentLogoSlide === 0
											? logo_slides.length - 1
											: currentLogoSlide - 1
									]?.image
								}
								onClick={prevSlide}
							/>

							{/* Center slide image */}
							<animated.div
								style={{
									...springs,
									width: "100%",
									height: "100%",
									margin: "auto",
								}}
							>
								<Box
									component="img"
									sx={{
										width: { xs: "100%", md: "50%" },
										height: { xs: "100%", md: "70%" },
										maxHeight: "200px",
										margin: "auto",
										padding: "20px",
										cursor: "pointer",
										transition: "transform 0.3s ease",
										"&:hover": {
											cursor: "pointer",
										},
										right: "20%",
									}}
									alt="Current Slide"
									src={logo_slides[currentLogoSlide]?.image}
									onClick={nextSlide}
								/>
							</animated.div>

							{/* Next slide image */}
							<Box
								component="img"
								sx={{
									width: { xs: "22%", md: "20%" },
									height: { xs: "80%", md: "0%" },
									margin: "auto",
									padding: "20px",
									opacity: 0.3,
									cursor: "pointer",
									transition: "opacity 0.3s ease",
									"&:hover": {
										opacity: 0.5,
									},
								}}
								alt="Next Slide"
								src={
									logo_slides[(currentLogoSlide + 1) % logo_slides.length]
										?.image
								}
								onClick={nextSlide}
							/>
						</Box>
						<Box sx={{ display: { xs: 'none', md: "block" }, }}>
							<div
								style={{
									justifyContent: "center",
									marginTop: "10px",
								}}
							>
								{logo_slides.map((_, index) => (
									<button
										key={index}
										style={{
											backgroundColor:
												currentLogoSlide === index
													? "rgba(30, 30, 30, 0.7)"
													: "rgba(30, 30, 30, 0.1)",
											border: "none",
											opacity: 1,
											margin: "3px", // Adjust margin for spacing
											marginTop: "5px", // Adjust top margin
											borderRadius: "50%",
											width: "8px", // Adjust width for smaller size
											height: "8px", // Adjust height for smaller size
											cursor: "pointer",
											padding: 0, // Remove padding to ensure exact dimensions
											outline: "none", // Remove outline on focus
										}}
										onClick={() => {
											if (index > currentLogoSlide) {
												api.start({
													from: {
														x: 130,
													},
													to: {
														x: isMobile ? -8 : -8,
													},
												});
											} else {
												api.start({
													from: {
														x: -100,
													},
													to: {
														x: isMobile ? -8 : -8,
													},
												});
											}
											setCurrentLogoSlide(index);
										}}
									/>
								))}
							</div>
						</Box>

						{/* Mobile */}
						{(pathname != "/partners/nerdwallet" && pathname != "/nerdwallet") &&
							<Box sx={{ display: { xs: 'block', md: "none" } }}>
								<Swiper
									loop={true}
									navigation={false}
									pagination={true}
									keyboard={true}
									modules={[Navigation, Pagination, Mousewheel, Keyboard]}
									className="mhg-swiper"
								>
									{mhgSwiperSlides.map((logo, index) => (
										<SwiperSlide
											key={index}
											className={`mhg-swiper-slide-${index}`}
											style={{
												display: "flex",
												justifyContent: "center",
												height: "100%",
												paddingRight: "1px",
												width: "60%",
											}}
										>
											<Box
												component="img"
												alt="The house from the offer."
												src={logo}
												sx={{ width: "100%" }}
											/>
										</SwiperSlide>
									))}
								</Swiper>
							</Box>
						}
					</Grid>
				</Grid>
			</Container>

			{/* Gray Strip #3 */}
			<Container
				className='nerd-wallet-gray-strip-3'
				sx={{
					backgroundColor: "rgba(30, 30, 30, 0.8)",
					height: "195px",
					marginBottom: "44px",
					borderRadius: { md: "18px" },
					textAlign: "center",
					display: 'flex',
					justifyContent: 'center',
					alignContent: 'center',
					flexDirection: 'column'
				}}
			>
				<Typography
					sx={{
						color: "white",
						marginBottom: '10px',
						fontSize: { xs: "14px", md: "24px" },
						fontWeight: '700'
					}}
				>
					{(pathname === '/partners/nerdwallet' || pathname === '/nerdwallet') && 'Let Us Help Secure Your Home'}
					{(pathname != "/partners/nerdwallet" && pathname != "/nerdwallet") && 'Talk to a Safe Haven Security Specialist Today'}
				</Typography>

				<Box
					sx={{
						display: { xs: "flex", md: "flex" },
						alignItems: "center",
						justifyContent: 'center',
					}}
				>
					<Button
						className="home-free-quote-btn"
						sx={{
							margin: { xs: 'auto' },
							backgroundColor: "#00519C",
							color: "#ffffff",
							borderRadius: "30px",
							height: "45px",
							maxHeight: "45px",
							fontSize: "14px",
							padding: "0 24px",
							fontFamily: "Rubik",
							textTransform: "capitalize",
							"&:hover": {
								backgroundColor: "#1260a8!important",
							},
						}}
						onClick={() => {
							handleScrollToForm();
						}}
						endIcon={
							<ArrowForwardIosIcon
								id="btn-arrow-slide-right"
								sx={{ height: "14px", paddingBottom: "1px" }}
							/>
						}
					>
						Get Started
					</Button>
				</Box>
			</Container>

			<CookieConsentPopup onconsent={() => onconsent()} />

			<NerdwalletFooter></NerdwalletFooter>
		</>
	);
}
