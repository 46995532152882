import React from 'react';
import Router from './Router';
import { setUserInfo } from './App/State/generalSlice';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import './App.scss';
import '@fontsource-variable/montserrat'
import useAnalytics from './utils/useAnalytics';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'SHS Body Font, sans-serif',
    h1: {
      fontFamily: 'SHS Heading Font, sans-serif',
    },
    h2: {
      fontFamily: 'SHS Heading Font, sans-serif',
    },
  },
});

function App() {
  const dispatch = useDispatch();
  useAnalytics();
  //TODO: move to service
  const getIp = async () => {
    const result = await axios.get('https://api.ipify.org/?format=json');
    return result.data.ip;
  };

  React.useEffect(() => {
    //const ip = getIp();
    //dispatch(setUserInfo({ ip }));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router></Router>
    </ThemeProvider>
  );
}

export default App;
