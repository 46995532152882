import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import './index.scss';
import { Helmet } from 'react-helmet';
import useFetch from "use-http";
import { useSearchParams } from 'react-router-dom';
import { CandidateVideoQuestion } from "../../DTOs/CandidateVideo";
import VideoRec from './video';


export default function CandidateVideo() {

  const [queryParameters] = useSearchParams()
  const candidateId = queryParameters.get("candidateId")
  const [showVideoRec, setShowVideoRec] = useState<boolean>(false);
  const [isChecking, setChecking] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [fullName, setFullName] = useState<string>("");
  const [alluploaded, setAllUploaded] = useState<boolean>(false);

  let apiKey = process.env.REACT_APP_API__ACCESS_KEY
    ? process.env.REACT_APP_API__ACCESS_KEY
    : '';

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Connection: 'keep-alive',
      'Accept-Encoding': 'gzip, deflate, br',
      'X-API-Key': apiKey,
    },
    cacheLife: 1000,
  };
  const { get } = useFetch(process.env.REACT_APP_API, options);

  const isShowVideoRec = async () => {
    const response = await get("/CandidateVideo/getCandidate/" + candidateId);
    setChecking(false)
    if (response) {
      setShowVideoRec(response.isShowViedoRecord);
      setFullName(response.fullName)
    } else {
      setShowVideoRec(false);
    }
  };

  useEffect(() => {
    if (!candidateId) {
      setShowVideoRec(false);
      setChecking(false)
    } else {
      isShowVideoRec()
    }
  }, [candidateId]);


  const [candidateVideoQuestions, setCandidateVideoQuestions] = useState<
    Array<CandidateVideoQuestion>
  >([]);

  const getCandidateVideoQuestion = async () => {
    const response = await get("/CandidateVideo/getVideoQuestions/" + candidateId);
    setLoading(false)
    if (response) {
      setCandidateVideoQuestions(response);
      setAllUploaded(true)
      response.forEach((element: { hasVideo: any; }) => {
        if (!element.hasVideo) {
          setAllUploaded(false)
        }
      })
    } else {
      console.warn("Error getting enrolled courses");
    }
  };



  useEffect(() => {
    if (showVideoRec) getCandidateVideoQuestion();
  }, [showVideoRec]
  );

  const arrayDataItems = candidateVideoQuestions.map(question =>
    <li key={question.id}>
      <p>{question.questionNumber}. {question.question}</p>
      <div>
        <VideoRec question={question}
          candidateName={fullName}
          candidateId={candidateId}
          setAllUploaded={setAllUploaded} />
      </div>
    </li>
  )


  return (
    <>
      <Helmet>
        <title>Candidate Video | Safe Haven</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'white', paddingBottom: '128px' }}>
        <div className="container" style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {alluploaded ? <h1>Thank you for submitting your question </h1>
            : isChecking ? <h1>Loading ...</h1>
              : showVideoRec ?
                isLoading ? <h1>Loading Question ...</h1>
                  : <div><h1>Welcome {fullName},
                    Please answer all of the following questions using the videro recorder.
                  </h1>
                    <ul>{arrayDataItems}</ul>
                  </div>
                : <h1>Not Found</h1>
          }
        </div>
      </Box>
    </>
  );
}
