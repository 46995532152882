import { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { keyframes } from "@emotion/react";

import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";

import product1 from "../../Assets/Home/ProductCarousel/01 Smoke Sensor.jpg";
import product2 from "../../Assets/Home/ProductCarousel/02 CO Sensor.jpg";
import product3 from "../../Assets/Home/ProductCarousel/03 Int Camera.jpg";
import product4 from "../../Assets/Home/ProductCarousel/04 Motion.jpg";
import product5 from "../../Assets/Home/ProductCarousel/05 Ext Camera.jpg";
import product6 from "../../Assets/Home/ProductCarousel/06 Heat sensor.jpg";
import product7 from "../../Assets/Home/ProductCarousel/2024 Amazon Pop.jpg";
import product8 from "../../Assets/Home/ProductCarousel/2024 Deako switch.jpg";
import product9 from "../../Assets/Home/ProductCarousel/09 Door Window sensor.jpg";
import product10 from "../../Assets/Home/ProductCarousel/10 Glass break.jpg";
import product11 from "../../Assets/Home/ProductCarousel/2024 Doorbell.jpg";
import product12 from "../../Assets/Home/ProductCarousel/2024 Panel.jpg";

import "./index.scss";
import { a, animated, useSpring } from "@react-spring/web";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export default function ProductCarousel() {
	const productImages = [
		{
			image: product1,
			title: "Smoke Sensor",
			description:
				"This smoke sensor is a critical component of your home security system. It will detect smoke and alert you to a potential fire, giving you precious time to react and ensure the safety of your loved ones.",
		},
		{
			image: product2,
			title: "CO Sensor",
			description:
				"Help protect your family from the silent danger of carbon monoxide. This sensor monitors CO levels in your home and will sound a loud alarm if dangerous levels are detected, potentially saving lives.",
		},
		{
			image: product3,
			title: "Interior Camera",
			footNote:
				"*Certain features require working internet and WiFi connections.",
			description:
				"Gain peace of mind by monitoring the inside of your home from virtually anywhere. This interior camera provides a live feed directly to your smartphone, allowing you to check in pets, children, or simply ensure everything is secure.*",
		},
		{
			image: product4,
			title: "Motion Sensor",
			description:
				"Enhance your home security with this motion sensor. It detects movement within its range and triggers an alert, notifying you of any potential intruders and helping deter unwanted activity.",
		},
		{
			image: product5,
			title: "Exterior Camera",
			footNote:
				"*Certain features require working internet and WiFi connections. Recordings are subject to plans and storage limitations.",
			description:
				"Keep a watchful eye on the outside of your home with this weather-resistant exterior camera. It provides a clear view of your property, records activity, and sends you an alert if anything suspicious is detected, day or night.*",
		},
		{
			image:
				"https://itsformsattachments.blob.core.windows.net/formattachments/06 Heat sensor.jpg", // azure hosted image
			title: "Heat Sensor",
			description:
				"This heat sensor helps add another layer of protection to your home. It detects rapid increases in temperature, which could indicate a fire, and alerts you to the potential danger.",
		},
		{
			image: product7,
			title: "Amazon Echo Pop",
			footNote:
				"*Certain features require working internet and WiFi connections.",
			description:
				"Enhance your home security system by using the Amazon Echo Pop to control devices, arm and disarm your system, and receive voice alerts — all hands-free.*",
		},
		{
			image: product9,
			title: "Door/Window Sensor",
			description:
				"Help secure every entry point with these door/window sensors. They’ll alert you if a door or window is opened unexpectedly, providing an extra layer of protection against unauthorized access.",
		},
		{
			image: product10,
			title: "Glass Break Sensor",
			description:
				"This glass break sensor adds another dimension to your home security. It detects the sound of breaking glass, such as a window being smashed, and triggers an alarm, alerting you to a potential intrusion.",
		},
		{
			image: product11,
			footNote:
				"*Certain features require working internet and WiFi connections. Recordings are subject to plans and storage limitations",
			title: "Doorbell Camera",
			description:
				"See and speak to visitors at your door from virtually anywhere with this doorbell camera. It provides a live video feed, allows two-way communication, and records footage, so you’ll never miss a visitor or delivery.*",
		},
		{
			image: product12,
			title: "Security Panel",
			footNote:
				"*Certain features require working internet and WiFi connections.",
			description:
				"The central hub of your home security system, this security panel allows you to arm and disarm the system, control all connected devices, and monitor activity.* Its intuitive interface and user-friendly design make managing your home security a breeze.",
		},
	];
	const duplicatedImages = [...productImages, ...productImages];

	const theme = useTheme();
	const xl = useMediaQuery(theme.breakpoints.up("xl")); // >= 1200px
	const lg = useMediaQuery(theme.breakpoints.only("lg")); // 1200px > width >= 900px
	const md = useMediaQuery(theme.breakpoints.only("md")); // 900px > width >= 600px
	const sm = useMediaQuery(theme.breakpoints.only("sm")); // 600px > width >= 375px
	const xs = useMediaQuery(theme.breakpoints.only("xs")); // 375px > width
	const [springs, api] = useSpring(() => ({
		from: { x: 0 },
	}));
	const [imagesToShow, setImagesToShow] = useState(5);

	useEffect(() => {
		if (xl) {
			setImagesToShow(5);
		} else if (lg) {
			setImagesToShow(4);
		} else if (md) {
			setImagesToShow(3);
		} else if (sm) {
			setImagesToShow(2);
		} else if (xs) {
			setImagesToShow(1);
		}
	}, [xl, lg, md, sm, xs]);

	const [activeIndex, setActiveIndex] = useState(5);
	const ref = useRef<HTMLDivElement>(null);
	const [disableTransition, setDisableTransition] = useState(true);
	const [animateLeft, setAnimateLeft] = useState(false);
	const [animateRight, setAnimateRight] = useState(false);
	const [activeTitle, setActiveTitle] = useState(productImages[1].title);
	const [slides, setSlides] = useState(duplicatedImages);
	const [activeDescription, setActiveDescription] = useState(
		productImages[1].description,
	);
	const handlePrev = () => {
		api.start({
			from: {
				x: -130,
			},
			to: {
				x: -9,
			},
		});
		setActiveIndex((prevIndex) => {
			const newIndex = prevIndex - 1;
			if (newIndex < 0) {
				setDisableTransition(true);
				setAnimateRight(true); // Trigger the animation
				return duplicatedImages.length - 12;
			}
			return newIndex;
		});
	};

	const handleNext = () => {
		api.start({
			from: {
				x: 130,
			},
			to: {
				x: -9,
			},
		});
		setActiveIndex((prevIndex) => {
			const newIndex = prevIndex + 1;
			if (newIndex >= duplicatedImages.length - 11) {
				setDisableTransition(true);
				setAnimateLeft(true); // Trigger the animation
				return 0;
			}
			return newIndex;
		});
	};

	const startX = useRef(0);
	const endX = useRef(0);

	useEffect(() => {
		if (ref.current) {
			ref.current.style.transform = `translateX(-${activeIndex * (100 / imagesToShow)
				}%)`;

			// Slight delay to allow the jump to happen before re-enabling the transition
			const enableTransition = setTimeout(() => {
				setDisableTransition(false);
			}, 0);

			if (animateLeft) {
				ref.current.classList.add("slide-left");
				const animationTimeout = setTimeout(() => {
					ref.current?.classList.remove("slide-left");
					setAnimateLeft(false);
					setDisableTransition(false);
				}, 300);

				return () => clearTimeout(animationTimeout);
			} else if (animateRight) {
				ref.current.classList.add("slide-right");
				const animationTimeout = setTimeout(() => {
					ref.current?.classList.remove("slide-right");
					setAnimateRight(false);
					setDisableTransition(false);
				}, 300);

				return () => clearTimeout(animationTimeout);
			} else {
				setDisableTransition(false);
			}

			// Add touch event listeners
			const carouselElement = ref.current;

			const handleTouchStart = (e: TouchEvent) => {
				startX.current = e.touches[0].clientX;
			};

			const handleTouchMove = (e: TouchEvent) => {
				endX.current = e.touches[0].clientX;
			};

			const handleTouchEnd = () => {
				if (startX.current < endX.current) {
					handlePrev();
				} else if (startX.current > endX.current) {
					handleNext();
				}
			};

			carouselElement.addEventListener("touchstart", handleTouchStart);
			carouselElement.addEventListener("touchmove", handleTouchMove);
			carouselElement.addEventListener("touchend", handleTouchEnd);

			// Clean up event listeners
			return () => {
				carouselElement.removeEventListener("touchstart", handleTouchStart);
				carouselElement.removeEventListener("touchmove", handleTouchMove);
				carouselElement.removeEventListener("touchend", handleTouchEnd);
			};
		}
	}, [
		activeIndex,
		disableTransition,
		animateLeft,
		animateRight,
		imagesToShow,
		handlePrev,
		handleNext,
	]);
	const dotSprings = useSpring({
		// Define your animation logic here (e.g., scaling, fading, etc.)
		to: { transform: "scale(1.2)" }, // Example scale effect
		from: { transform: "scale(1)" },
	});
	const [animationState, setAnimationState] = useState<
		"idle" | "fadingOut" | "fadingIn"
	>("idle");

	useEffect(() => {
		setAnimationState("fadingOut");
		const timeoutId = setTimeout(() => {
			setAnimationState("fadingIn");
		}, 100);

		setActiveDescription(
			duplicatedImages[
				activeIndex == productImages.length - 1 ? 0 : activeIndex + 1
			]?.description,
		);
		setActiveTitle(
			duplicatedImages[
				activeIndex == productImages.length - 1 ? 0 : activeIndex + 1
			]?.title,
		);
		return () => clearTimeout(timeoutId);
	}, [activeIndex]);

	return (
		<>
			<Box
				className="product-carousel-container"
				sx={{
					position: "relative",
					float: "right",
					display: { xs: "none", md: "block" },
				}}
			>
				<Box
					sx={{
						position: "relative",
						minHeight: "40px",
						maxHeight: "100px",
						padding: "0 16px",
						animation:
							animationState === "fadingOut"
								? `${fadeOut} 0s ease-in-out forwards`
								: animationState === "fadingIn"
									? `${fadeIn} 0.65s ease-in-out forwards`
									: "none",
					}}
				>
					<div
						style={{
							position: "absolute",
							left: "55%",
							top: "80px",
							width: 700,
						}}
						className="product-text-container"
					>
						<Typography
							variant="h5"
							sx={{
								fontWeight: "700",
								fontSize: {
									xs: "16px!important",
									sm: "24px!important",
									lg: "37px",
									xl: "37px!important",
								},
							}}
						>
							{activeTitle}
						</Typography>
						<Typography
							sx={{
								fontSize: {
									xs: "16px",
									sm: "18px",
									lg: "23px",
									xl: "22px!important",
								},
							}}
						>
							{activeDescription}
						</Typography>
						<Typography sx={{ fontSize: "13px", marginTop: "10px" }}>
							{
								duplicatedImages[
									activeIndex + 1 >= productImages.length ? 0 : activeIndex + 1
								]?.footNote
							}
						</Typography>
					</div>
				</Box>

				<div className="tablet-product-container">
					<Typography variant="h5" sx={{ fontWeight: "700", fontSize: "38px" }}>
						{duplicatedImages[activeIndex + 1]?.title}
					</Typography>
					<Typography sx={{ fontSize: "25px" }}>
						{duplicatedImages[activeIndex + 1]?.description}
					</Typography>
					<Typography sx={{ fontSize: "13px", marginTop: "10px" }}>
						{
							duplicatedImages[
								activeIndex + 1 >= productImages.length ? 0 : activeIndex + 1
							]?.footNote
						}
					</Typography>
				</div>

				<Box
					sx={{}}
					className={`product-carousel ${disableTransition ? "no-transition" : ""
						} ${animateLeft ? "slide-left" : ""} ${animateRight ? "slide-right" : ""
						}`}
					ref={ref}
				>
					{duplicatedImages.map((image, index) => (
						<Box
							key={index}
							className="product-carousel-slide"
							style={{
								display: "flex",
								justifyContent: "flex-end",
								flexDirection: "column",
								...(activeIndex + 1 === index
									? {
										flex: `0 0 calc(100% / ${imagesToShow})`,
									}
									: {
										flex: `0 0 calc(100% / ${imagesToShow})`,
									}),
							}}
						>
							<Box
								sx={{
									position: "relative",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Box
									component="img"
									sx={{
										...(activeIndex + 1 === index
											? {
												borderRadius: "50%",
												maxHeight: "600px",
												maxWidth: "600px",
												width: "520px",
											}
											: {
												borderRadius: "50%",
												maxHeight: "250px",
												maxWidth: "250px",
												width: "calc(100% - 24px)",
												marginTop: "340px",
												cursor: "pointer",
											}),
									}}
									onClick={() => {
										if (index === activeIndex) {
											handlePrev();
										} else {
											setActiveIndex((prevIndex) => {
												const newIndex = index - 1;
												if (newIndex > duplicatedImages.length - 11) {
													setDisableTransition(true);
													setAnimateLeft(true); // Trigger the animation
													return 0;
												}
												return newIndex;
											});
										}
									}}
									loading="lazy"
									alt={`Product ${index + 1}`}
									src={image.image}
									role="img"
								/>
								<Box
									className="product-blue-dot"
									sx={
										activeIndex + 1 === index
											? {
												width: "65px",
												height: "65px",
												position: "absolute",
												top: 35,
												right: 55,
												backgroundColor: "rgb(68,200,245)",
												borderRadius: "50%",
											}
											: {
												display: "none",
											}
									}
								/>
							</Box>
						</Box>
					))}
				</Box>

				<Box
					sx={{
						display: { xs: "flex" },
						justifyContent: "center",
						marginTop: "8px",
						zIndex: 2,
					}}
				>
					<Box sx={{ margin: "18px" }}>
						<IconButton
							className="award-carousel-nav-button"
							aria-label="delete"
							onClick={handlePrev}
							sx={{
								backgroundColor: "rgb(68,200,245)",
								minHeight: "50px",
								minWidth: "50px",
							}}
						>
							<WestIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Box>

					<Box sx={{ margin: "18px" }}>
						<IconButton
							className="award-carousel-nav-button"
							aria-label="delete"
							onClick={handleNext}
							sx={{
								backgroundColor: "rgb(68,200,245)",
								minHeight: "50px",
								minWidth: "50px",
							}}
						>
							<EastIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					display: { xs: "block", md: "none" },
				}}
			>
				<div>
					<Typography variant="h5" sx={{ fontWeight: "700", fontSize: "29px" }}>
						{
							duplicatedImages[
								activeIndex + 1 >= productImages.length ? 0 : activeIndex + 1
							]?.title
						}
					</Typography>
					<Typography sx={{ fontSize: "23px" }}>
						{
							duplicatedImages[
								activeIndex + 1 >= productImages.length ? 0 : activeIndex + 1
							]?.description
						}
					</Typography>
					<Typography sx={{ fontSize: "13px", marginTop: "10px" }}>
						{
							duplicatedImages[
								activeIndex + 1 >= productImages.length ? 0 : activeIndex + 1
							]?.footNote
						}
					</Typography>
				</div>

				<animated.div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "100%",
						margin: "auto",
						position: "relative",
					}}
				>
					<animated.img
						style={{
							borderRadius: "50%",
							maxHeight: "400px",
							maxWidth: "400px",
							width: "calc(100% - 54px)",
							margin: "38px",
							...springs,
						}}
						src={
							activeIndex + 1 >= productImages.length
								? duplicatedImages[0]?.image
								: duplicatedImages[activeIndex + 1]?.image
						}
						alt="product"
					/>
					<Box>
						<animated.div
							className={`blue-dot`}
							style={{
								width: "50px",
								height: "50px",
								backgroundColor: "rgb(68,200,245)",
								borderRadius: "50%",
								...springs,
							}}
						/>

						<img
							className="tablet-preview-img"
							style={{
								borderRadius: "50%",
								maxHeight: "400px",
								maxWidth: "400px",
								width: "200px",
								margin: "38px",
							}}
							onClick={() => {
								setActiveIndex((prevIndex) => {
									const newIndex = prevIndex + 1;
									if (newIndex > duplicatedImages.length - 11) {
										setDisableTransition(true);
										setAnimateLeft(true); // Trigger the animation
										return 0;
									}
									return newIndex;
								});
							}}
							src={
								productImages[
									activeIndex + 2 >= productImages.length ? 0 : activeIndex + 2
								]?.image
							}
							alt="product"
						/>
					</Box>
				</animated.div>

				<Box
					sx={{
						display: { xs: "flex" },
						justifyContent: "right",
						marginTop: "8px",
						zIndex: 2,
					}}
				>
					<Box sx={{ margin: "18px" }}>
						<IconButton
							className="award-carousel-nav-button"
							aria-label="delete"
							onClick={handlePrev}
							sx={{
								backgroundColor: "rgb(68,200,245)",
								minHeight: "50px",
								minWidth: "50px",
							}}
						>
							<WestIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Box>

					<Box sx={{ margin: "18px" }}>
						<IconButton
							className="award-carousel-nav-button"
							aria-label="delete"
							onClick={handleNext}
							sx={{
								backgroundColor: "rgb(68,200,245)",
								minHeight: "50px",
								minWidth: "50px",
							}}
						>
							<EastIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Box>
				</Box>
			</Box>
		</>
	);
}
