import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useAppSelector } from "../../App/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import BirdeyeWidget from "../Birdeye";
import { useEffect, useState } from "react";
import SubscribeBanner from "./SubscribeBanner";
import logo from "../../Assets/Rebranding/Mark_Rev.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./index.scss";
import ContactUsForm from "../Forms/ContactUsForm";
import contactFormBackground from "../../Assets/Footer/Contact-Form.jpg";
import birdeyeBadge from "../../Assets/Footer/birdeye-badge.png";
import StarIcon from "@mui/icons-material/Star";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function Footer() {
  const location = useLocation();
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === "_blank") {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };
  const generalFooterDisplay = useAppSelector((state) => state.footer.display);
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const excludedPaths = [
    "/legal",
    "/legal/privacy",
    "/terms-and-conditions",
    "/legal/affirmation",
    "/legal/safe-haven-terms-and-conditions",
    "/contact-us",
    "/partners/agent-referral",
    "/solar",
    "/partners/homebuilder-partners",
    "/partners",
    "/customer-solutions",
    "/installers",
  ];

  const handleAccordianChange =
    (panel: string) =>
      (event: React.SyntheticEvent, newExpandedPanel: boolean) => {
        setExpandedPanel(newExpandedPanel ? panel : false);
      };

  useEffect(() => {
    const scriptElement = document.createElement("script");
    scriptElement.src =
      "https://birdeye.com/embed/v4/153357839059541/7/2602589841";
    scriptElement.async = true;
    document.body.appendChild(scriptElement);
  }, []);

  return (
    <>
      {generalFooterDisplay && (
        <>
          {/* <Box
                        sx={{
                            backgroundColor: '#33ccff',
                            padding: '48px 0'
                        }}
                    >
                        <Container maxWidth={false} sx={{ maxWidth: { xs: '100vw', lg: '1200px' }, padding: { lg: '0!important' } }}>
                            <SubscribeBanner></SubscribeBanner>
                        </Container>
                    </Box> */}

          {/* Gloabal contact form */}
          {!excludedPaths.includes(location.pathname) && (
            <Box>
              <Container
                className=""
                maxWidth={false}
                sx={{
                  marginTop: "32px",
                  backgroundColor: "rgb(0, 83, 156)",
                  position: "relative",
                  backgroundImage: `url(${contactFormBackground})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backdropFilter: "blur(0px)",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    zIndex: -2,
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage:
                      "linear-gradient(to top, rgb(0 6 24 / 80%) 36%, transparent 123%)",
                    zIndex: -1,
                  },
                }}
              >
                <Box
                  sx={{
                    zIndex: 1,
                    position: "absolute",
                    filter: "blur(4px)",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                  }}
                >
                  test
                </Box>
                <Container
                  maxWidth={"lg"}
                  sx={{
                    padding: "128px 0 128px 0",
                    zIndex: 2,
                    position: "relative",
                  }}
                >
                  {!confirmationOpen && (
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          marginBottom: "32px",
                          fontSize: "32px",
                          color: "#fff",
                        }}
                      >
                        Get Your Free Quote Today
                      </Typography>
                    </Box>
                  )}

                  <Box
                    sx={{
                      backgroundColor: confirmationOpen
                        ? "transparent"
                        : "#fff",
                      borderRadius: "6px",
                      padding: "48px 40px",
                      position: "relative",
                    }}
                  >
                    {!confirmationOpen && (
                      <ContactUsForm
                        setConfirmationOpen={setConfirmationOpen}
                      ></ContactUsForm>
                    )}

                    {confirmationOpen && (
                      <Container
                        maxWidth={false}
                        sx={{
                          maxWidth: "700px",
                          minHeight: "400px",
                          height: "auto",
                          bgcolor: "#fff",
                          borderRadius: "9px",
                          boxShadow: 24,
                          padding: "0!important",
                          zIndex: "1",
                        }}
                      >
                        <Box
                          sx={{
                            background: "rgb(18, 96, 168)",
                            width: "100%",
                            minHeight: "64px",
                            borderRadius: "9px 9px 0 0px",
                            padding: "10px 0",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "24px",
                              fontWeight: "700",
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            Thank you for contacting Safe Haven!
                          </Typography>
                        </Box>
                        <Stack
                          direction={"row"}
                          sx={{
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Box
                            sx={{
                              paddingLeft: "36px",
                              minHeight: "350px",
                              minWidth: "250px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-around",
                            }}
                          >
                            <Box>
                              <Box sx={{ width: "100%", display: "flex" }}>
                                <CheckCircleOutlineIcon
                                  sx={{
                                    color: "rgb(18, 96, 168)",
                                    margin: "auto",
                                    height: "4rem",
                                    width: "auto",
                                    marginBottom: "15px",
                                  }}
                                ></CheckCircleOutlineIcon>
                              </Box>

                              <Typography
                                sx={{
                                  fontSize: "24px",
                                  maxWidth: "600px",
                                  marginBottom: "45px",
                                }}
                              >
                                We received your request for more information
                                and a member of our team will be in touch with
                                you shortly to answer any questions you have.
                              </Typography>
                            </Box>
                          </Box>
                        </Stack>
                      </Container>
                    )}
                  </Box>
                </Container>
              </Container>
            </Box>
          )}

          <Box
            sx={{
              backgroundColor: "#00539c",
              borderTop: "1px solid black",
              color: "#ffffff",
            }}
          >
            {/* Mobile Version */}
            <>
              <Box
                sx={{
                  width: "100vw",
                  background: "#00539c",
                  textAlign: "center",
                  display: { xs: "flex", md: "none" },
                  justifyContent: "space-around",
                  paddingTop: "24px",
                  paddingBottom: "18px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    maxHeight: "100px",
                    width: "auto",
                    padding: "8px 0 16px 0",
                  }}
                  alt="Safe Haven Logo"
                  src={logo}
                />
                <Stack
                  direction={{ xs: "row" }}
                  sx={{
                    "& button.footer-social-icon": {
                      background: "#ffffff",
                      margin: "4px",
                      maxWidth: "34px",
                      maxHeight: "35px",
                    },
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    className="footer-social-icon"
                    aria-label="facebook"
                    size="small"
                  >
                    <FacebookIcon sx={{ color: "#00539c" }} />
                  </IconButton>
                  <IconButton
                    className="footer-social-icon"
                    aria-label="twitter"
                    size="small"
                  >
                    <TwitterIcon sx={{ color: "#00539c" }} />
                  </IconButton>
                  <IconButton
                    className="footer-social-icon"
                    aria-label="instagram"
                    size="small"
                  >
                    <InstagramIcon sx={{ color: "#00539c" }} />
                  </IconButton>
                  <IconButton
                    className="footer-social-icon"
                    aria-label="youtube"
                    size="small"
                  >
                    <YoutubeIcon sx={{ color: "#00539c" }} />
                  </IconButton>
                  <IconButton
                    className="footer-social-icon"
                    aria-label="linkedin"
                    size="small"
                  >
                    <LinkedInIcon sx={{ color: "#00539c" }} />
                  </IconButton>
                </Stack>
              </Box>

              <Divider
                sx={{ background: "#fff", display: { xs: "flex", md: "none" } }}
              ></Divider>

              <Box
                className="accordianWrapper"
                sx={{
                  display: { xs: "block", md: "none" },
                  padding: "0",
                  background: "#00539c",
                  ".MuiPaper-root": {
                    backgroundColor: "#00539c",
                    color: "#ffffff",
                    boxShadow: "none",
                  },
                  ".MuiPaper-root.Mui-expanded": { margin: 0 },
                }}
              >
                <Accordion
                  expanded={expandedPanel === "ourStory"}
                  onChange={handleAccordianChange("ourStory")}
                  sx={{ borderBottom: "1px solid #ffffff" }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon sx={{ color: "#ffffff" }} />}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        letterSpacing: "1px",
                      }}
                      className="footer-heading"
                    >
                      Our Story
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px 16px 16px 16px" }}>
                    <Stack sx={{}}>
                      <Box>
                        <Link
                          className="footer-link"
                          onMouseDown={(event) => {
                            if (event.button === 1) {
                              handleNavigate("/about-us", "_blank");
                            }
                          }}
                          onClick={(event) => {
                            handleNavigate("/about-us");
                          }}
                        >
                          About Safe Haven
                        </Link>
                      </Box>

                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate("/about-us", "_blank");
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/about-us");
                        }}
                        target="_blank"
                      >
                        Why Safe Haven
                      </Link>
                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate("/careers", "_blank");
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/careers");
                        }}
                      >
                        Careers
                      </Link>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedPanel === "resources"}
                  onChange={handleAccordianChange("resources")}
                  sx={{ borderBottom: "1px solid #ffffff" }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon sx={{ color: "#ffffff" }} />}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        letterSpacing: "1px",
                      }}
                      className="footer-heading"
                    >
                      Resources
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px 16px 16px 16px" }}>
                    <Stack>
                      {/* <Link
                                                className='footer-link'
                                                onMouseDown={(event) => {
                                                    if (event.button === 1) {
                                                        handleNavigate('/', '_blank');
                                                    }
                                                }}
                                                onClick={(event) => {
                                                    handleNavigate('/');
                                                }}
                                            >
                                                Blog
                                            </Link> */}
                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate("/contact-us", "_blank");
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/contact-us");
                        }}
                      >
                        Contact
                      </Link>
                      <Link
                        className="footer-link"
                        href="https://www.youtube.com/@SafeHavenSecurity"
                        target="_blank"
                        rel="noopener"
                      >
                        How-to
                      </Link>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedPanel === "departments"}
                  onChange={handleAccordianChange("departments")}
                  sx={{ borderBottom: "1px solid #ffffff" }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon sx={{ color: "#ffffff" }} />}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        letterSpacing: "1px",
                      }}
                      className="footer-heading"
                    >
                      Departments
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px 16px 16px 16px" }}>
                    <Stack>
                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate(
                              "/partners/homebuilder-partners",
                              "_blank"
                            );
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/partners/homebuilder-partners");
                        }}
                      >
                        Builder
                      </Link>
                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate("/partners", "_blank");
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/partners");
                        }}
                      >
                        Affiliate
                      </Link>
                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate(
                              "/partners/agent-referral",
                              "_blank"
                            );
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/partners/agent-referral");
                        }}
                      >
                        Realtor
                      </Link>
                      {/* <Link
                                                className='footer-link'
                                                onMouseDown={(event) => {
                                                    if (event.button === 1) {
                                                        handleNavigate('/customer-solutions', '_blank');
                                                    }
                                                }}
                                                onClick={(event) => {
                                                    handleNavigate('/customer-solutions');
                                                }}
                                            >
                                                Technicians
                                            </Link> */}
                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate("/customer-solutions", "_blank");
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/customer-solutions");
                        }}
                      >
                        Pinpoint
                      </Link>
                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate("/solar", "_blank");
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/solar");
                        }}
                      >
                        Solar
                      </Link>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedPanel === "support"}
                  onChange={handleAccordianChange("support")}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon sx={{ color: "#ffffff" }} />}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        letterSpacing: "1px",
                      }}
                      className="footer-heading"
                    >
                      Support
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px 16px 16px 16px" }}>
                    <Stack>
                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate("/customer-support", "_blank");
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/customer-support");
                        }}
                      >
                        Customer Support
                      </Link>
                      {/* <Link
                                                className='footer-link'
                                                onMouseDown={(event) => {
                                                    if (event.button === 1) {
                                                        handleNavigate('/customer-solutions', '_blank');
                                                    }
                                                }}
                                                onClick={(event) => {
                                                    handleNavigate('/customer-solutions');
                                                }}
                                            >
                                                Newsroom
                                            </Link> */}
                      <Link
                        className="footer-link"
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            handleNavigate(
                              "/customer-support/?scroll=verifyRep"
                            );
                          }
                        }}
                        onClick={(event) => {
                          handleNavigate("/customer-support/?scroll=verifyRep");
                        }}
                      >
                        Verify Rep
                      </Link>
                      {/* <Link
                                                className='footer-link'
                                                onMouseDown={(event) => {
                                                    if (event.button === 1) {
                                                        handleNavigate('/customer-solutions', '_blank');
                                                    }
                                                }}
                                                onClick={(event) => {
                                                    handleNavigate('/customer-solutions');
                                                }}
                                            >
                                                Military
                                            </Link> */}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Divider
                sx={{ background: "#fff", display: { xs: "flex", md: "none" } }}
              ></Divider>
            </>

            {/* Tablet/Desktop Version */}
            <Container
              maxWidth={"xl"}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Grid
                container
                spacing={0}
                columns={10}
                sx={{
                  padding: "48px 0",
                  "& div.MuiGrid-item > div.MuiStack-root> p.footer-heading": {
                    fontWeight: "700 !important",
                    fontSize: "20px !important",
                  },
                  "& div.MuiGrid-item > div.MuiStack-root> a.footer-link": {
                    fontSize: "16px !important",
                    lineHeight: "20px",
                    margin: "10px 0",
                    fontFamily: 'SHS Body Font,sans-serif',
                    textDecoration: "none",
                    color: "#ffffff",
                  },
                }}
              >
                <Grid
                  item
                  md={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    component="img"
                    sx={{ maxWidth: "100%", maxHeight: "85px" }}
                    alt="Employee Image Not Found"
                    src={logo}
                  />
                </Grid>
                <Grid item md={2}>
                  <Stack
                    sx={{
                      marginBottom: "28px",
                    }}
                  >
                    <Typography className="footer-heading">
                      Our Story
                    </Typography>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/about-us", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/about-us");
                      }}
                    >
                      About Safe Haven
                    </Link>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/?scroll=products", "_blank");
                        }
                      }}
                      onClick={() => {
                        {
                          handleNavigate("/?scroll=why-safe-haven");
                        }
                      }}
                    >
                      Why Safe Haven
                    </Link>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/careers", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/careers");
                      }}
                    >
                      Careers
                    </Link>
                  </Stack>
                  <Stack>
                    <Typography className="footer-heading">
                      Resources
                    </Typography>
                    {/* <Link
                                            className='footer-link'
                                            onMouseDown={(event) => {
                                                if (event.button === 1) {
                                                    handleNavigate('/', '_blank');
                                                }
                                            }}
                                            onClick={(event) => {
                                                handleNavigate('/');
                                            }}
                                        >
                                            Blog
                                        </Link> */}
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/contact-us", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/contact-us");
                      }}
                    >
                      Contact
                    </Link>
                    <Link
                      className="footer-link"
                      href="https://www.youtube.com/@SafeHavenSecurity"
                      target="_blank"
                      rel="noopener"
                    >
                      How-to
                    </Link>
                  </Stack>
                </Grid>
                <Grid item md={2}>
                  <Stack>
                    <Typography className="footer-heading">
                      Departments
                    </Typography>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate(
                            "/partners/homebuilder-partners",
                            "_blank"
                          );
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/partners/homebuilder-partners");
                      }}
                    >
                      Builder
                    </Link>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/partners", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/partners");
                      }}
                    >
                      Affiliate
                    </Link>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/partners/agent-referral", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/partners/agent-referral");
                      }}
                    >
                      Realtor
                    </Link>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/installers", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/installers");
                      }}
                    >
                      Technicians
                    </Link>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/customer-solutions", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/customer-solutions");
                      }}
                    >
                      Pinpoint
                    </Link>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/solar", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/solar");
                      }}
                    >
                      Solar
                    </Link>
                  </Stack>
                </Grid>
                <Grid item md={2}>
                  <Stack>
                    <Typography className="footer-heading">Support</Typography>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate("/customer-support", "_blank");
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/customer-support");
                      }}
                    >
                      Customer Support
                    </Link>
                    <Link
                      className="footer-link"
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          handleNavigate(
                            "/customer-support/?scroll=verifyRep",
                            "_blank"
                          );
                        }
                      }}
                      onClick={(event) => {
                        handleNavigate("/customer-support/?scroll=verifyRep");
                      }}
                    >
                      Verify Rep
                    </Link>

                    {/* <Link
                                            className='footer-link'
                                            onMouseDown={(event) => {
                                                if (event.button === 1) {
                                                    handleNavigate('/customer-solutions', '_blank');
                                                }
                                            }}
                                            onClick={(event) => {
                                                handleNavigate('/customer-solutions');
                                            }}
                                        >
                                            Newsroom
                                        </Link> */}
                    {/* <Link
                                            className='footer-link'
                                            onMouseDown={(event) => {
                                                if (event.button === 1) {
                                                    handleNavigate('/customer-solutions', '_blank');
                                                }
                                            }}
                                            onClick={(event) => {
                                                handleNavigate('/customer-solutions');
                                            }}
                                        >
                                            Military
                                        </Link> */}
                  </Stack>
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Stack
                    direction={{ xs: "row" }}
                    sx={{
                      "& a.footer-social-icon": {
                        background: "#ffffff",
                        margin: "4px",
                        maxWidth: "34px",
                      },
                      marginTop: "4px",
                      flexWrap: "wrap",
                      justifyContent: "start",
                      padding: "0 8px",
                    }}
                  >
                    <IconButton
                      className="footer-social-icon"
                      aria-label="facebook"
                      size="small"
                      sx={{
                        "&:hover": {
                          transform: "scale(1.10)",
                        },
                      }}
                      href="https://www.facebook.com/your-facebook-page"
                      target="_blank"
                      rel="noopener"
                    >
                      <FacebookIcon sx={{ color: "#00539c" }} />
                    </IconButton>

                    <IconButton
                      className="footer-social-icon"
                      aria-label="twitter"
                      size="small"
                      sx={{
                        "&:hover": {
                          transform: "scale(1.10)",
                        },
                      }}
                      href="https://x.com/safehavensecure"
                      target="_blank"
                      rel="noopener"
                    >
                      <TwitterIcon sx={{ color: "#00539c" }} />
                    </IconButton>

                    <IconButton
                      className="footer-social-icon"
                      aria-label="instagram"
                      size="small"
                      sx={{
                        "&:hover": {
                          transform: "scale(1.10)",
                        },
                      }}
                      href="https://www.instagram.com/safehavensecure"
                      target="_blank"
                      rel="noopener"
                    >
                      <InstagramIcon sx={{ color: "#00539c" }} />
                    </IconButton>

                    <IconButton
                      className="footer-social-icon"
                      aria-label="youtube"
                      size="small"
                      sx={{
                        "&:hover": {
                          transform: "scale(1.10)",
                        },
                      }}
                      href="https://www.youtube.com/@SafeHavenSecurity"
                      target="_blank"
                      rel="noopener"
                    >
                      <YoutubeIcon sx={{ color: "#00539c" }} />
                    </IconButton>

                    <IconButton
                      className="footer-social-icon"
                      aria-label="linkedin"
                      size="small"
                      sx={{
                        "&:hover": {
                          transform: "scale(1.10)",
                        },
                      }}
                      href="https://www.linkedin.com/company/952529"
                      target="_blank"
                      rel="noopener"
                    >
                      <LinkedInIcon sx={{ color: "#00539c" }} />
                    </IconButton>
                  </Stack>

                  <Box
                    className="footer-birdeye-widget-container"
                    sx={{
                      margin: "8px 0 16px 0",
                      cursor: "pointer",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: "0 16px",
                    }}
                    component={Link}
                    href="https://birdeye.com/safe-haven-security-authorized-adt-dealer-153357839059541"
                    target="_blank"
                    rel="noopener"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Stack sx={{}}>
                        <Typography
                          className="birdeye-text"
                          sx={{
                            fontSize: { xs: "20px", lg: "22px", xl: "28px" },
                            lineHeight: { xs: "20px", lg: "22px", xl: "28px" },
                            textDecoration: "none!important",
                            color: "#fff",
                          }}
                        >
                          20,000+
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: "20px", lg: "22px", xl: "28px" },
                            lineHeight: { xs: "20px", lg: "22px", xl: "28px" },
                            textDecoration: "none",
                            color: "#fff",
                          }}
                        >
                          Customer<br></br> Reviews
                        </Typography>
                        <Box sx={{ display: "flex", marginTop: "4px" }}>
                          <StarIcon
                            sx={{
                              color: "rgb(255, 176, 3)",
                              height: { xs: "16px", lg: "20px", xl: "24px" },
                              width: { xs: "16px", lg: "20px", xl: "24px" },
                            }}
                          ></StarIcon>
                          <StarIcon
                            sx={{
                              color: "rgb(255, 176, 3)",
                              height: { xs: "16px", lg: "20px", xl: "24px" },
                              width: { xs: "16px", lg: "20px", xl: "24px" },
                            }}
                          ></StarIcon>
                          <StarIcon
                            sx={{
                              color: "rgb(255, 176, 3)",
                              height: { xs: "16px", lg: "20px", xl: "24px" },
                              width: { xs: "16px", lg: "20px", xl: "24px" },
                            }}
                          ></StarIcon>
                          <StarIcon
                            sx={{
                              color: "rgb(255, 176, 3)",
                              height: { xs: "16px", lg: "20px", xl: "24px" },
                              width: { xs: "16px", lg: "20px", xl: "24px" },
                            }}
                          ></StarIcon>
                          <StarIcon
                            sx={{
                              color: "rgb(255, 176, 3)",
                              height: { xs: "16px", lg: "20px", xl: "24px" },
                              width: { xs: "16px", lg: "20px", xl: "24px" },
                            }}
                          ></StarIcon>
                          <Typography
                            sx={{
                              marginLeft: "8px",
                              fontSize: { xs: "14px", lg: "18px", xl: "18px" },
                              lineHeight: {
                                xs: "calc(100% + 5px)",
                                lg: "calc(100% + 10px)",
                              },
                              textDecoration: "none",
                              color: "#fff",
                            }}
                          >
                            5
                          </Typography>
                        </Box>
                      </Stack>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                          marginLeft: "18px",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            maxHeight: { md: "60px", lg: "80px", xl: "100px" },
                            width: "auto",
                            padding: "16px 0",
                          }}
                          alt="Safe Haven Logo"
                          src={birdeyeBadge}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>

            <Container
              maxWidth={"xl"}
              sx={{ paddingTop: "32px", paddingBottom: "16px" }}
            >
              <Box
                sx={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  font: 'normal 12px / 22px SHS Body Font,sans-serif',
                }}
              >
                License numbers by State: AL-1493, AR- CMPY.0002629, AZ-
                ROC-332758,18404-0, CA-ACO8016, CT-HIC.0661825, DC-602521000002,
                DE-17-39, FL-EF20000905, GA-LVU406182, IA-AS-0158, C115438,
                IL-127.001386, LA-F1672, MA-8150 A1, SS-002127, MD-107-1696,
                MI-3601208054, MN-TS710056, MS-15019531, NC-1150-CSA,
                SP.FA/LV.28805, NH-064, NJ- 34BX00006600, NM-403946, NV-0079533,
                NY-12000077734, OK-AC1802, OR-234026, PA-PA040539, RI-4604,
                TSC8204, SC-BAC.13574, TN-2280, TX-B14839,ACR-2625584,
                UT-10999117-6501, VA-11-6402, WA-SAFEHHS791CH, WV-WV060562
                <br></br>
                <br></br>© Safe Haven Security 2024 |
                <Link
                  sx={{
                    color: "#fff",
                    font: 'normal 12px / 22px SHS Body Font,sans-serif',
                    cursor: "pointer",
                  }}
                  onMouseDown={(event) => {
                    if (event.button === 1) {
                      handleNavigate("/legal", "_blank");
                    }
                  }}
                  onClick={(event) => {
                    handleNavigate("/legal");
                  }}
                >
                  {" "}
                  Legal
                </Link>{" "}
                |
                <Link
                  sx={{
                    color: "#fff",
                    font: 'normal 12px / 22px SHS Body Font,sans-serif',
                    cursor: "pointer",
                  }}
                  onMouseDown={(event) => {
                    if (event.button === 1) {
                      handleNavigate("/legal/privacy", "_blank");
                    }
                  }}
                  onClick={(event) => {
                    handleNavigate("/legal/privacy");
                  }}
                >
                  {" "}
                  Privacy
                </Link>{" "}
                |
                <Link
                  sx={{
                    color: "#fff",
                    font: 'normal 12px / 22px SHS Body Font,sans-serif',
                    cursor: "pointer",
                  }}
                  onMouseDown={(event) => {
                    if (event.button === 1) {
                      handleNavigate("/terms-and-conditions", "_blank");
                    }
                  }}
                  onClick={(event) => {
                    handleNavigate("/terms-and-conditions");
                  }}
                >
                  {" "}
                  Terms & Conditions
                </Link>
              </Box>
            </Container>
          </Box>
        </>
      )}
    </>
  );
}
