import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Typography,
} from '@mui/material';
import useFetch from 'use-http';
import './index.scss';
import { STATE_LIST } from '../../../Constants';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup'; // For form validation
import { useNavigate, useSearchParams } from 'react-router-dom';

interface AgentReferralFormProps {
  setConfirmationOpen: (arg0: boolean) => void;
}

export default function AgentReferralForm({ setConfirmationOpen }: AgentReferralFormProps) {
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === "_blank") {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };
  const [queryParameters] = useSearchParams();
  let apiKey = process.env.REACT_APP_API__ACCESS_KEY
    ? process.env.REACT_APP_API__ACCESS_KEY
    : '';
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Connection: 'keep-alive',
      'Accept-Encoding': 'gzip, deflate, br',
      'X-API-Key': apiKey,
    },
  };
  const { post, response, loading, error } = useFetch(
    process.env.REACT_APP_API,
    options
  );

  // Formik Validation Schema
  const initialValues = {
    firstName: '',
    lastName: '',
    nameOfCompany: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    acknowledge: false,
  };
  // Formik Validation Schema
  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required')
      .min(2, 'First name must be at least 2 characters')
      .max(50, 'First name must be at most 50 characters')
      .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
    lastName: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    phone: Yup.string().required('Required')
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
    nameOfCompany: Yup.string().required('Required'),
    acknowledge: Yup.bool().oneOf([true], 'This field is required'),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            let recipients = 'ReferralAgentPartnerSubmit@mysafehaven.com';

            if (queryParameters.get("msh-test")) {
              recipients = 'Samual.Tarpenning@mysafehaven.com, codey.bower@mysafehaven.com, wai.cheng@mysafehaven.com';
            }

            const subject = `New Realtor Partnership Information Request From ${values.firstName} ${' '} ${values.lastName}`;
            const content = `
              <table style="max-width: 1024px; margin: auto;">
                <tbody>
                  ${Object.entries(values).map(([key, value], index) => `
                    <tr style="background-color: ${index % 2 === 0 ? '#f2f2f2' : 'white'};">
                      <th style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd;, width: auto;">${key}:</th>
                      <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd;">${value}</td>
                    </tr>
                  `).join('')}
                </tbody>
              </table>
            `;

            const postResponse = await post(`/Email/SendEmail`, {
              to: recipients,
              subject: subject,
              content: content,
            });

            console.log("Request sent successfully:", postResponse);

            setConfirmationOpen(true);

            resetForm();
          } catch (error) {
            console.error("Error sending request:", error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => (
          <Form>
            <Box className='' sx={{
              maxWidth: '1024px',
              margin: 'auto',
              '& .quarter-width': { width: { xs: 'calc(50%)', sm: 'calc(25%)' }, minHeight: '90px' },
              '& .half-width': { width: { xs: 'calc(100%)', sm: 'calc(50%)' }, minHeight: '90px' },
              '& .full-width': { width: 'calc(100%)', minHeight: '90px' },
              '& .quarter-width > div': { margin: '0 5px 24px 5px' },
              '& .half-width > div': { margin: '0 5px 24px 5px' },
              '& .full-width > div': { margin: '0 5px 24px 5px' },
            }}>
              <Field
                as={TextField}
                sx={{ margin: '0!important' }}
                className='half-width '
                name="firstName"
                label="First Name *"
                value={values.firstName}
                onChange={(event: any) => { setFieldValue("firstName", event.target.value) }}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName && (
                  <div key={`error-firstName`}>{errors.firstName}</div>
                )} />

              <Field
                as={TextField}
                className='half-width '
                name="lastName"
                label="Last Name *"
                value={values.lastName}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("lastName", event.target.value)}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />

              <Field
                as={TextField}
                sx={{ minHeight: '65px' }}
                className='half-width'
                name="phone"
                label="Phone Number *"
                value={values.phone}
                onChange={(event: { target: { value: any; }; }) => {
                  const inputValue = event.target.value.replace(/\D/g, '');

                  let formattedValue = '';
                  if (inputValue.length > 0) {
                    formattedValue = '(' + inputValue.substring(0, 3);
                    if (inputValue.length > 3) {
                      formattedValue += ') ' + inputValue.substring(3, 6);
                    }
                    if (inputValue.length > 6) {
                      formattedValue += '-' + inputValue.substring(6, 10);
                    }
                  }

                  setFieldValue('phone', formattedValue);
                }}
                onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                  const clipboardData = event.clipboardData;
                  const pastedData = clipboardData.getData('text');
                  const cleanedData = pastedData.replace(/\D/g, '');
                  const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                  setFieldValue('phone', formattedValue);
                  event.preventDefault();
                }}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
              />

              <Field
                as={TextField}
                className='half-width '
                name="email"
                label="Email *"
                value={values.email}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("email", event.target.value)}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />

              <Field
                as={TextField}
                className='quarter-width '
                name="city"
                label="City *"
                value={values.city}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("city", event.target.value)}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />

              <Autocomplete
                disablePortal
                className={`quarter-width `}
                sx={{ display: 'inline-flex', margin: "0!important" }}
                options={STATE_LIST}
                inputValue={values.state}
                onInputChange={(event, newInputValue) => {
                  setFieldValue("state", newInputValue);
                }}

                renderInput={params => (
                  <TextField
                    sx={{ marginLeft: '4px' }}
                    {...params}
                    name="state"
                    label="State *"
                    error={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                )}
              />

              <Field
                as={TextField}
                sx={{ display: 'inline-flex', margin: "0!important" }}
                className='half-width '
                name="nameOfCompany"
                label="Name of Company *"
                value={values.nameOfCompany}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("nameOfCompany", event.target.value)}
                error={touched.nameOfCompany && Boolean(errors.nameOfCompany)}
                helperText={touched.nameOfCompany && errors.nameOfCompany}
              />

              <Box sx={{ paddingTop: '10px' }}>
                <Typography sx={{ paddingLeft: '17px' }}>
                  Terms and Conditions *
                </Typography>
                <FormControlLabel
                  name='acknowledge'
                  className={touched.acknowledge && !values.acknowledge ? 'error-checkbox' : ''}
                  control={
                    <Checkbox
                      checked={values.acknowledge}
                      onChange={(event: { target: { checked: boolean; }; }) => { setFieldValue("acknowledge", event.target.checked) }
                      }
                    />
                  }
                  label={
                    <span>
                      By clicking 'Submit,' I consent to being contacted by Safe Haven Security
                      Services, LLC, Safe Haven Solar, LLC, or All Access Service, LLC regarding
                      products and services via live, automated, or prerecorded telephone calls,
                      text messages*, or emails, even if I'm on a Do Not Call list. Consent is
                      not a condition of purchase. I can revoke this consent by emailing{' '}
                      <a href="mailto:dnc@mysafehaven.com">dnc@mysafehaven.com</a>.†
                    </span>
                  }
                  sx={{
                    margin: '0 8px 8px 8px',
                    alignItems: 'flex-start',
                    '& .MuiFormControlLabel-asterisk': {
                      paddingTop: '9px',
                      height: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'space-around',
                    },
                    '& .MuiTypography-root': {
                      paddingTop: '8px',
                    },
                  }}
                />
              </Box>

              <Box sx={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>
                <LoadingButton
                  variant='contained'
                  className='contact-form-submit'
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  sx={{
                    minWidth: '100px',
                    fontSize: '16px',
                    textTransform: 'none',
                    fontWeight: '700',
                  }}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </LoadingButton>
              </Box>

              <Typography variant="body2" sx={{ fontSize: '12px' }}>
                * Standard message and data rates may apply to text messages. You may
                opt out of receiving further text messages at any time by replying STOP.
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '12px' }}>
                † Your information is collected and used in accordance with Safe Haven’s{" "}
                <a
                  style={{
                    color: "#007bff",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNavigate("/legal/privacy/")}
                >
                  Privacy Policy.
                </a>
              </Typography>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}



