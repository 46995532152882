import {
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeroBg from "../../Assets/AboutUs/Hero/shutterstock_792003463_cropped.jpg";
import dadCarryingKids from "../../Assets/AboutUs/Commitment/shutterstock_2301505081_cropped.jpg";
import glassOffice from "../../Assets/AboutUs/Realtor_15_cropped.jpg";
import glassOfficeMobile from "../../Assets/AboutUs/Realtor_15_mobile.jpg";
import SideBySide from "../../Components/SideBySide";
import group from "../../Assets/AboutUs/Principles/SH olympics 07_cropped_2.jpg";
import groupMobile from "../../Assets/AboutUs/Principles/SH olympics 07_cropped_2_mobile.jpg";
import tutuFamily from "../../Assets/AboutUs/mylittlearmy_01_HighRes_cropped.jpg";
import { SetStateAction, useEffect, useRef, useState } from "react";
import ScrollToTopButton from "../../Components/ScrollToTopButton";
import ourJourneyVid from "../../Assets/AboutUs/Videos/Our_Journey.mp4";
import thumbnail from "../../Assets/AboutUs/Videos/thumb v2.png";
import {
	Swiper,
	SwiperRef,
	SwiperSlide,
	Swiper as SwiperType,
} from "swiper/react";
import { Navigation, Pagination } from "swiper";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import "swiper/css";
import "swiper/css/pagination";
import { AWARD_IMAGES } from "../../Constants";
import "./index.scss";

export default function AboutUs() {
	const navigate = useNavigate();
	const handleNavigate = (route: string, location: string | null = null) => {
		if (location === "_blank") {
			window.open(route, location);
		} else {
			navigate(route);
		}
	};
	const [showSttButton, setShowSttButton] = useState(false);
	const SHOW_SCROLL_BUTTON_THRESHOLD = 300;
	const currentUrl = window.location.href;
	const baseUrl = currentUrl.replace(/\/about-us$/, ""); // Remove /about-us at the end
	const videoFile = "Our_Journey.mp4";
	const videoSrc = `${baseUrl}/${videoFile}`;
	useEffect(() => {
		const scrollListener = () => {
			setShowSttButton(window.scrollY > SHOW_SCROLL_BUTTON_THRESHOLD);
		};
		window.addEventListener("scroll", scrollListener);
		return () => window.removeEventListener("scroll", scrollListener);
	}, []);

	const swiperRef = useRef<SwiperRef | null>(null);

	const handlePrev = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev(500, true);
		}
	};

	const handleNext = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext(500, true);
		}
	};

	const [activeIndex, setActiveIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	const handleSlideChange = (swiper: {
		activeIndex: SetStateAction<number>;
	}) => {
		setActiveSlideIndex(swiper.activeIndex);
		console.log(swiper.activeIndex);
	};

	const missionSectionDesktopRef = useRef<HTMLDivElement>(null);
	const missionSectionMobileRef = useRef<HTMLDivElement>(null);

	const handleMissionDesktopClick = () => {
		if (missionSectionDesktopRef.current) {
			const elementRect =
				missionSectionDesktopRef.current.getBoundingClientRect();
			window.scrollTo({
				top: elementRect.top + window.scrollY - 150, // Use window.scrollY
				behavior: "smooth",
			});
		}
	};

	const handleMissionMobileClick = () => {
		if (missionSectionMobileRef.current) {
			const elementRect =
				missionSectionMobileRef.current.getBoundingClientRect();
			window.scrollTo({
				top: elementRect.top + window.scrollY - 25,
				behavior: "smooth",
			});
		}
	};

	// Video player logic -- Need to move video to own component
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isVideoHovered, setIsVideoHovered] = useState(false);

	useEffect(() => {
		const video = videoRef.current;

		const handlePlay = () => setIsPlaying(true);
		const handlePause = () => setIsPlaying(false);

		if (video) {
			video.addEventListener("play", handlePlay);
			video.addEventListener("pause", handlePause);

			return () => {
				video.removeEventListener("play", handlePlay);
				video.removeEventListener("pause", handlePause);
			};
		}
	}, []);

	const handleVideoClick = () => {
		if (videoRef.current) {
			videoRef.current.paused
				? videoRef.current.play()
				: videoRef.current.pause();
		}
	};

	return (
		<>
			<Helmet>
				<title>About Us | Safe Haven</title>
				<meta
					name="description"
					content="Learn about Safe Haven Strong, your trusted provider for home security solutions."
				/>
				<meta
					name="keywords"
					content="home security, ADT authorized dealer, Safe Haven Strong"
				/>
			</Helmet>

			<ScrollToTopButton showButton={showSttButton}></ScrollToTopButton>

			{/* Family Image Sections */}
			<Box
				sx={{
					display: { xs: "none", md: "grid" },
					maxWidth: "1500px",
					margin: "auto",
				}}
			>
				<Box
					sx={{
						position: "relative",
						display: "grid",
						gridColumn: "1",
						gridRow: "1",
					}}
				>
					<Box
						component="img"
						loading="lazy"
						sx={{
							gridColumn: "1",
							gridRow: "1",
							display: "block",
							width: "100%",
							maxWidth: "100%",
							height: "auto",
							margin: "auto",
							filter: "blur(10px)",
							transition: "filter 0.5s ease",
						}}
						alt="Hero image of father entering home with kids."
						src={HeroBg}
						onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
							const target = e.target as HTMLImageElement;
							target.style.filter = "blur(0px)";
						}}
					/>
					<Box
						sx={{
							gridColumn: "1",
							gridRow: "1",
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.6)",
							zIndex: 1,
						}}
					/>
				</Box>

				<Box
					sx={{
						width: "100%",
						gridColumn: "1",
						gridRow: "1",
						color: "#ffffff",
						textAlign: "center",
						zIndex: 1,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignContent: "center",
							alignItems: "center",
							flexWrap: "wrap",
							height: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignContent: "center",
								alignItems: "center",
								marginTop: { lg: "100px" },
								marginBottom: "32px",
								textAlign: "center",
								width: "100%",
							}}
						>
							<Typography
								variant="h1"
								sx={{
									zIndex: "1",
									fontSize: { xs: "32px", md: "42px", lg: "48px", xl: "62px" },
									fontWeight: "700",
									letterSpacing: "3px",
									maxWidth: { xs: "500px", md: "800px" },
								}}
							>
								25 Years of Protecting What Matters Most
							</Typography>
							<Typography
								sx={{
									zIndex: "1",
									textAlign: "center",
									margin: {
										xs: "24px auto auto auto",
										lg: "48px auto auto auto",
									},
									fontSize: { xs: "14px", md: "22px", lg: "22px", xl: "28px" },
									maxWidth: { xs: "500px", md: "900px" },
									position: "relative",
									width: "100%",
								}}
							>
								Since 1999, Safe Haven Security has been committed to
								safeguarding homes and providing peace of mind to millions of
								families
							</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								maxWidth: "500px",
								margin: "auto",
								width: "100%",
							}}
						>
							<Button
								className="home-free-quote-btn"
								sx={{
									display: "flex",
									backgroundColor: "rgb(0,83,156)",
									color: "#ffffff",
									borderRadius: "30px",
									height: "45px",
									maxHeight: "45px",
									fontSize: "12px",
									margin: {
										xs: "12px auto auto auto",
										lg: "48px auto auto auto",
									},
									padding: "24px 32px",
									fontWeight: "700",
									letterSpacing: "1px",
									textTransform: "capitalize",
									"&.home-free-quote-btn:hover": {
										backgroundColor: "#1260a8!important",
									},
									"&.home-free-quote-btn>span": {
										marginLeft: "4px",
									},
								}}
								onClick={handleMissionDesktopClick}
							>
								DISCOVER OUR STORY
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* Mobile: Family Pic  */}
			<Box
				sx={{
					display: { xs: "grid", md: "none" },
					justifyContent: "center",
					maxWidth: "100vw",
					overflow: "hidden",
				}}
			>
				<Box
					sx={{
						position: "relative",
						display: "grid",
						gridColumn: "1",
						gridRow: "1",
					}}
				>
					<Box
						component="img"
						loading="lazy"
						sx={{
							gridColumn: "1",
							gridRow: "1",
							display: "block",
							width: "auto",
							maxWidth: { xs: "1000px", sm: "100%" },
							height: "auto",
							margin: "auto",
						}}
						alt="The house from the offer."
						src={tutuFamily}
					/>
					<Box
						sx={{
							gridColumn: "1",
							gridRow: "1",
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.5)",
						}}
					/>
				</Box>

				<Box
					sx={{
						width: "100%",
						gridColumn: "1",
						gridRow: "1",
						color: "#ffffff",
						textAlign: "center",
						zIndex: 1,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignContent: "center",
							alignItems: "center",
							flexWrap: "wrap",
							height: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignContent: "center",
								alignItems: "center",
								marginBottom: "32px",
								textAlign: "center",
								width: "100%",
							}}
						>
							<Typography
								variant="h1"
								sx={{
									zIndex: "1",
									fontSize: {
										xs: "28px",
										sm: "42px",
										md: "42px",
										lg: "48px",
										xl: "62px",
									},
									fontWeight: "700",
									letterSpacing: "3px",
									maxWidth: { xs: "calc(100vw - 32px)", sm: "600px" },
								}}
							>
								25 Years of Protecting What Matters Most
							</Typography>
							<Typography
								sx={{
									zIndex: "1",
									textAlign: "center",
									margin: "48px auto auto auto",
									fontSize: {
										xs: "18px",
										sm: "24px",
										md: "22px",
										lg: "22px",
										xl: "28px",
									},
									maxWidth: { xs: "calc(100vw - 32px)", sm: "500px" },
									position: "relative",
									width: "100%",
								}}
							>
								Since 1999, Safe Haven Security has been committed to
								safeguarding homes and providing peace of mind to millions of
								families
							</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								maxWidth: "500px",
								margin: "auto",
								width: "100%",
							}}
						>
							<Button
								className="home-free-quote-btn"
								sx={{
									display: "flex",
									backgroundColor: "rgb(0,83,156)",
									color: "#ffffff",
									borderRadius: "30px",
									height: "45px",
									maxHeight: "45px",
									fontSize: "14px",
									margin: "48px auto auto auto",
									padding: "32px",
									fontWeight: "700",
									letterSpacing: "1px",
									textTransform: "capitalize",
									"&.home-free-quote-btn:hover": {
										backgroundColor: "#1260a8!important",
									},
									"&.home-free-quote-btn>span": {
										marginLeft: "4px",
									},
								}}
								onClick={handleMissionMobileClick}
							>
								DISCOVER OUR STORY
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* Commitment Section */}
			<Container
				maxWidth={"lg"}
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					alignItems: "center",
					marginTop: "64px",
				}}
			>
				{/* Mobile: Heading Text */}
				<Stack
					ref={missionSectionMobileRef}
					id="our-mission-section-mobile"
					sx={{ display: { xs: "block", md: "none" }, marginBottom: "32px" }}
				>
					<Typography
						sx={{
							fontSize: "28px",
							lineHeight: "48px",
							marginBottom: "16px",
							color: "rgb(68,200,245)",
						}}
					>
						OUR MISSION AND VISION
					</Typography>
					{/* <Typography
						sx={{ fontSize: "32px", lineHeight: "48px", marginBottom: "16px" }}
					>
						Our <strong>Commitment to You</strong>
					</Typography> */}
					<Typography
						sx={{ fontSize: "18px", letterSpacing: "1", wordSpacing: "1" }}
					>
						With over 25 years of experience, Safe Haven Security has grown to
						become the{" "}
						<strong>largest ADT Authorized Dealer in the nation</strong>,
						serving over 1 million satisfied clients across 43 states. We stand
						out by offering exceptional home security solutions tailored to each
						customer's needs, backed by a team of highly trained professionals
						dedicated to providing peace of mind.
					</Typography>
				</Stack>

				{/* Dad carrying kids Image & Blue Dot */}
				<Box
					sx={{
						position: "relative",
						display: "flex",
						flexDirection: "column",
						marginRight: { md: "32px" },
					}}
				>
					<Box
						component="img"
						loading="lazy"
						sx={{
							display: "block",
							height: "100%",
							maxHeight: "400px",
							maxWidth: "400px",
							borderRadius: "50%",
						}}
						alt="The house from the offer."
						src={dadCarryingKids}
					/>
					<Box
						sx={{
							width: "60px",
							height: "60px",
							position: "absolute",
							top: 20,
							left: 30,
							backgroundColor: "rgb(0, 83, 156)",
							borderRadius: "50%",
						}}
					/>
				</Box>

				{/* Desktop: Text and Gray Blocks */}
				<Stack
					ref={missionSectionDesktopRef}
					id="our-mission-section-desktop"
					sx={{ display: { xs: "none", md: "block" }, marginLeft: "32px" }}
				>
					<Typography
						sx={{
							fontSize: "48px",
							lineHeight: "56px",
							marginBottom: "32px",
							color: "rgb(68,200,245)",
						}}
					>
						OUR MISSION AND VISION
					</Typography>
					<Typography
						sx={{ fontSize: "18px", letterSpacing: "1", wordSpacing: "1" }}
					>
						With over 25 years of experience, Safe Haven Security has grown to
						become the{" "}
						<strong>largest ADT Authorized Dealer in the nation</strong>,
						serving over 1 million satisfied clients across 43 states. We stand
						out by offering exceptional home security solutions tailored to each
						customer's needs, backed by a team of highly trained professionals
						dedicated to providing peace of mind.
					</Typography>

					<Grid container className="benefits-grid" sx={{ marginTop: "16px" }}>
						<Grid item xs={12} lg={6}>
							<Box sx={{ padding: "8px" }}>
								<Typography
									sx={{
										color: "rgb(0, 83, 156)",
										fontSize: "24px",
										marginBottom: "4px",
										fontWeight: "700",
									}}
								>
									<b>Our Mission</b>
								</Typography>
								<Typography>
									We protect families by connecting them to their home through
									comprehensive smart home solutions customized to meet their
									unique needs.
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Box sx={{ padding: "8px" }}>
								<Typography
									sx={{
										color: "rgb(0, 83, 156)",
										fontSize: "24px",
										marginBottom: "4px",
										fontWeight: 700,
									}}
								>
									<b>Our Vision</b>
								</Typography>
								<Typography
									sx={{
										fontWeight: 150,
									}}
								>
									To be the gold standard for comprehensive smart home
									automation and monitoring solutions.
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Stack>
			</Container>

			{/* Awards Carousel Section */}
			<Container
				sx={{ maxHeight: "500px", margin: "88px auto", position: "relative" }}
			>
				<Swiper
					style={{ height: "100%", width: "100%" }}
					loop={true}
					ref={swiperRef}
					className="about-us-swiper"
					navigation={false}
					spaceBetween={0}
					modules={[Pagination, Navigation]}
					initialSlide={activeIndex}
					onSlideChange={handleSlideChange}
					breakpoints={{
						0: {
							slidesPerView: 1,
						},
						600: {
							slidesPerView: 2,
						},
						900: {
							slidesPerView: 2,
						},
						1200: {
							slidesPerView: 3,
						},
					}}
				>
					{AWARD_IMAGES.map((award, index) => (
						<SwiperSlide
							key={index}
							className={`about-us-swiper-slide-${index}`}
							style={{
								display: "flex",
								justifyContent: "center",
								height: "100%",
								paddingRight: "1px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									flexDirection: "column",
									justifyContent: "center",
									height: "300px",
									width: "100%",
									position: "relative",
								}}
							>
								<Box
									sx={{
										height: "210px",
										display: "flex",
										alignItems: "center",
									}}
								>
									<Box
										component="img"
										loading="lazy"
										sx={{
											width: "auto",
											maxHeight: "100%",
											maxWidth: "250px",
											cursor: "pointer",
										}}
										alt={`Product ${index + 1}`}
										src={award.image}
									/>
								</Box>
								<Typography
									variant="body2"
									align="center"
									sx={{
										fontSize: "18px",
										lineHeight: "22px",
										maxWidth: "250px",
										height: "70px",
										paddingTop: "20px",
									}}
								>
									{award.text}
								</Typography>
								<Box
									sx={{
										position: "absolute",
										borderLeft: "1px solid #cecece",
										height: "125px",
										right: "0",
										bottom: "45%",
									}}
								></Box>
							</Box>
						</SwiperSlide>
					))}
				</Swiper>
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: { xs: "50%", sm: "75%", md: "50%" },
						height: "300px",
						background:
							"linear-gradient(to right, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
						zIndex: 1,
					}}
				/>
				<Box
					sx={{
						position: "absolute",
						top: 0,
						right: 0,
						width: { xs: "50%", sm: "75%", md: "50%" },
						height: "300px",
						background:
							"linear-gradient(to left, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
						zIndex: 1,
					}}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						marginTop: "8px",
						zIndex: 2,
					}}
				>
					<Box sx={{ margin: "18px" }}>
						<IconButton
							className="award-carousel-nav-button"
							aria-label="delete"
							onClick={handlePrev}
							sx={{
								backgroundColor: "rgb(68,200,245)",
								minHeight: "50px",
								minWidth: "50px",
							}}
						>
							<WestIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Box>

					<Box sx={{ margin: "18px" }}>
						<IconButton
							className="award-carousel-nav-button"
							aria-label="delete"
							onClick={handleNext}
							sx={{
								backgroundColor: "rgb(68,200,245)",
								minHeight: "50px",
								minWidth: "50px",
							}}
						>
							<EastIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Box>
				</Box>
			</Container>

			{/* Mobile: Benefits Gray Block */}
			<Grid
				container
				className="benefits-grid"
				sx={{
					marginTop: "36px",
					marginBottom: "64px",
					display: { xs: "block", md: "none" },
				}}
			>
				<Grid item xs={12} md={6} sx={{ marginBottom: "24px" }}>
					<Box sx={{ padding: "8px" }}>
						<Typography
							sx={{
								color: "rgb(0, 83, 156)",
								fontSize: "24px",
								marginBottom: "4px",
							}}
						>
							Our Mission
						</Typography>
						<Typography sx={{ fontSize: "18px" }}>
							We protect families by connecting them to their home through
							comprehensive smart home solutions customized to meet their unique
							needs.
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} sx={{ marginTop: "24px" }}>
					<Box sx={{ padding: "8px" }}>
						<Typography
							sx={{
								color: "rgb(0, 83, 156)",
								fontSize: "24px",
								marginBottom: "4px",
							}}
						>
							Our Vision
						</Typography>
						<Typography sx={{ fontSize: "18px" }}>
							To be the gold standard for comprehensive smart home automation
							and monitoring solutions.
						</Typography>
					</Box>
				</Grid>
			</Grid>

			{/* Desktop: Core Values Section */}
			<Container
				maxWidth={"lg"}
				sx={{ display: { xs: "block", lg: "block" } }}
			>
				<Grid container className="" sx={{}}>
					<Grid item xs={12} md={6} sx={{ paddingRight: '24px', display: 'flex' }}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<Box sx={{ maxWidth: "auto" }}>
								<Typography
									sx={{
										fontSize: { xs: '32px', lg: "42px" },
										marginBottom: { xs: '24px', lg: "28px" },
										color: "rgb(68,200,245)",
									}}
								>
									OUR CORE VALUES
								</Typography>
							</Box>

							{/* Tenacity */}
							<Grid container className="" rowSpacing={0} columnSpacing={5}>
								<Grid item xs={12} md={12} sx={{ marginBottom: '18px' }}>
									<Typography
										sx={{
											color: "rgb(0,83,156)",
											fontWeight: "700",
											marginBottom: { xs: '4px', lg: "12px" },
											fontSize: "24px",
										}}
									>
										TENACITY
									</Typography>
									<Typography sx={{ fontWeight: "300", fontSize: "18px" }}>
										We go above and beyond to achieve excellence.
									</Typography>
								</Grid>

								{/* Accountability */}
								<Grid item xs={12} md={12} sx={{ marginBottom: '18px' }}>
									<Typography
										sx={{
											color: "rgb(0,83,156)",
											fontWeight: "700",
											marginBottom: { xs: '4px', lg: "12px" },
											fontSize: "24px",
										}}
									>
										ACCOUNTABILTY
									</Typography>
									<Typography sx={{ fontWeight: "300", fontSize: "18px" }}>
										We own our actions and their outcomes.
									</Typography>
								</Grid>

								{/* Leadership */}
								<Grid item xs={12} md={12} sx={{ marginBottom: '18px' }}>
									<Typography
										sx={{
											color: "rgb(0,83,156)",
											fontWeight: "700",
											marginBottom: { xs: '4px', lg: "12px" },
											fontSize: "24px",
										}}
									>
										LEADERSHIP
									</Typography>
									<Typography sx={{ fontWeight: "300", fontSize: "18px" }}>
										We lead with integrity, empowering families to feel safe.
									</Typography>
								</Grid>

								{/* Drive */}
								<Grid item xs={12} md={12} sx={{ marginBottom: '18px' }}>
									<Typography
										sx={{
											color: "rgb(0,83,156)",
											fontWeight: "700",
											marginBottom: { xs: '4px', lg: "12px" },
											fontSize: "24px",
										}}
									>
										DRIVE
									</Typography>
									<Typography sx={{ fontWeight: "300", fontSize: "18px" }}>
										We embrace challenges with enthusiasm and resilience.
									</Typography>
								</Grid>

								{/* Innovation */}
								<Grid item xs={12} md={12} sx={{ marginBottom: '18px' }}>
									<Typography
										sx={{
											color: "rgb(0,83,156)",
											fontWeight: "700",
											marginBottom: { xs: '4px', lg: "12px" },
											fontSize: "24px",
										}}
									>
										INNOVATION
									</Typography>
									<Typography sx={{ fontWeight: "300", fontSize: "18px" }}>
										We are always exploring new ways to protect and connect.
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} sx={{ paddingLeft: { xs: '0', md: '24px' }, display: 'flex', alignItems: 'flex-end' }}>
						<Box
							sx={{
								overflow: "hidden",
								position: "relative",
								width: "100%",
								maxWidth: "100%",
								display: { xs: 'none', md: 'block' },
								borderTopLeftRadius: "250px 30%",
							}}
						>
							<Box
								component="img"
								loading="lazy"
								sx={{
									display: "block",
									width: "100%",
									maxWidth: "100%",
									height: "auto",
									margin: "auto",
									transform: "translateX(0) scale(1.2)",
								}}
								alt="The house from the offer."
								src={group}
							/>
						</Box>

						<Box
							sx={{
								position: "relative",
								width: "100%",
								maxWidth: "100%",
								display: { xs: 'block', md: 'none' }
							}}
						>
							<Box
								component="img"
								loading="lazy"
								sx={{
									display: "block",
									width: "100%",
									maxWidth: "100%",
									height: "auto",
									margin: "auto",
								}}
								alt="The house from the offer."
								src={groupMobile}
							/>
						</Box>
					</Grid>
				</Grid>
			</Container>

			{/* Journey Video Section */}
			<Box sx={{ backgroundColor: "rgb(0, 83, 156)" }}>
				<Container maxWidth={false} sx={{ maxWidth: "lg", padding: "64px 0" }}>
					<Stack sx={{ textAlign: "center" }}>
						<Typography
							sx={{
								color: "rgb(68,200,245)",
								marginBottom: "0px",
								fontSize: "16px",
								fontWeight: "700",
								letterSpacing: "1px",
							}}
						>
							THE SAFE HAVEN STORY
						</Typography>
						<Typography
							sx={{
								color: "#ffffff",
								marginBottom: "32px",
								fontSize: "56px",
								lineHeight: "56px",
								marginTop: "24px",
							}}
						>
							Our <strong>Journey</strong>
						</Typography>
						<Typography
							sx={{
								color: "#ffffff",
								marginBottom: "24px",
								fontSize: "18px",
								padding: "0 16px",
								display: { xs: "none", md: "block" },
							}}
						>
							Safe Haven started with a simple goal: to provide exceptional home
							security solutions that people can trust. What{" "}
							<span className="desktop-break">began</span> as a small operation
							has now evolved into a trusted name across the country, but our
							mission remains the same
						</Typography>
						<Typography
							sx={{
								color: "#ffffff",
								marginBottom: "32px",
								fontSize: "18px",
								padding: "0 16px",
								display: { xs: "block", md: "none" },
							}}
						>
							Safe Haven started with a simple goal: to provide exceptional home
							security solutions that people can trust. What began as a small
							operation has now evolved into a trusted name across the country,
							but our mission remains the same
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
								position: "relative",
							}}
						>
							<video
								ref={videoRef}
								id="about-us-video"
								poster={thumbnail}
								className=""
								controls
								playsInline
								style={{
									width: "100%",
									cursor: "pointer",
								}}
								aria-hidden={isPlaying}
							>
								<source
									src={`https://mysafehaven.com/Our_Journey.mp4`}
									type="video/mp4"
								/>
								<source
									src={`https://mysafehaven-dev.azurewebsites.net/Our_Journey.mp4`}
									type="video/mp4"
								/>
								<source
									src={
										"https://itsformsattachments.blob.core.windows.net/formattachments/Our_Journey.mp4"
									}
									type="video/mp4"
								/>
								<source src={videoSrc} type="video/mp4" />
								<source src={ourJourneyVid} type="video/mp4" />
								Your browser does not support videos.
							</video>
						</Box>
					</Stack>
				</Container>
			</Box>

			{/* Ipad/Desktop: Join Us Section */}
			<Container
				maxWidth={false}
				sx={{
					maxWidth: "1500px",
					paddingTop: "72px",
					position: "relative",
					display: { xs: "none", md: "block" },
					paddingLeft: { md: "0", lg: "24px" },
					paddingRight: { md: "0", lg: "24px" },
				}}
			>
				<Box
					component="img"
					loading="lazy"
					sx={{
						display: "block",
						width: "100%",
						maxWidth: "100%",
						height: "auto",
						margin: "auto",
						borderTopLeftRadius: "250px 45%",
						filter: "blur(10px)",
						transition: "filter 0.5s ease",
					}}
					alt="The house from the offer."
					src={glassOffice}
					onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
						const target = e.target as HTMLImageElement;
						target.style.filter = "blur(0px)";
					}}
				/>
				<Box>
					<Box
						sx={{
							width: { xs: "35%", lg: "32.5%" },
							minWidth: "32.5%",
							height: "45%",
							maxHeight: "45%",
							minHeight: "45%",
							position: "absolute",
							bottom: 0,
							left: { lg: 24 },
							backgroundColor: "rgb(0, 39, 78)",
						}}
					>
						<Stack
							sx={{
								color: "#ffffff",
								padding: { xs: "32px 0 32px 32px", lg: "48px 0 48px 48px" },
							}}
						>
							<Typography
								sx={{ fontSize: { xs: "16px", lg: "32px" }, fontWeight: 700 }}
							>
								Join Us in Shaping the <br></br>
								Future of Home Security
							</Typography>
							<Typography
								sx={{
									fontSize: { xs: "12px", lg: "18px" },
									margin: "8px 0 16px 0",
								}}
							>
								Be a part of a company dedicated to <br></br> innovation and
								protection.
							</Typography>
							<Button
								className="home-free-quote-btn"
								sx={{
									display: "flex",
									backgroundColor: "#1260a8",
									color: "#ffffff",
									borderRadius: "30px",
									height: "60px",
									maxHeight: "60px",
									fontSize: "12px",
									letterSpacing: "1px",
									marginTop: "16px",
									padding: "0 24px",
									maxWidth: { xs: "100%", md: "250px", lg: "350px" },
									width: "100%",
									fontWeight: "700",
									textTransform: "capitalize",
									"&.home-free-quote-btn:hover": {
										backgroundColor: "#1260a8!important",
									},
									"&.home-free-quote-btn>span": {
										marginLeft: "4px",
									},
								}}
								onMouseDown={(event) => {
									if (event.button === 1) {
										handleNavigate("/careers", "_blank");
									}
								}}
								onClick={(event) => {
									handleNavigate("/careers");
								}}
							>
								EXPLORE CAREER OPPORTUNITIES
							</Button>
						</Stack>
					</Box>
				</Box>
			</Container>

			{/* Mobile: Join Us Section */}
			<Box sx={{ display: { xs: "block", md: "none" }, marginBottom: "64px" }}>
				<Box
					component="img"
					loading="lazy"
					sx={{
						display: "block",
						width: "100%",
						maxWidth: "100%",
						height: "auto",
						margin: "auto",
					}}
					alt="The house from the offer."
					src={glassOfficeMobile}
				/>
				<Box sx={{ backgroundColor: "rgb(0,39,78)" }}>
					<Stack sx={{ color: "#ffffff", padding: "32px 24px" }}>
						<Typography
							sx={{ fontSize: "32px", fontWeight: 700, paddingBottom: "12px" }}
						>
							Join Us in Shaping the Future of Home Security
						</Typography>
						<Typography
							sx={{ fontSize: "18px", marginTop: "8px", paddingBottom: "12px" }}
						>
							Be a part of a company dedicated to innovation and protection.
						</Typography>
						<Button
							className="home-free-quote-btn"
							sx={{
								width: "100%",
								display: "flex",
								backgroundColor: "#1260a8",
								color: "#ffffff",
								borderRadius: "30px",
								height: "45px",
								maxHeight: "45px",
								fontSize: "16px",
								margin: "16px auto 16px auto",
								padding: "32px",
								fontWeight: "700",
								letterSpacing: "1px",
								textTransform: "capitalize",
								"&.home-free-quote-btn:hover": {
									backgroundColor: "#1260a8!important",
								},
								"&.home-free-quote-btn>span": {
									marginLeft: "4px",
								},
							}}
							onMouseDown={(event) => {
								if (event.button === 1) {
									handleNavigate("/careers", "_blank");
								}
							}}
							onClick={(event) => {
								handleNavigate("/careers");
							}}
						>
							EXPLORE CAREER OPPORTUNITIES
						</Button>
					</Stack>
				</Box>
			</Box>
		</>
	);
}
