import {
	Box,
	Button,
	Container,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from "@mui/material";
import mainImageMobile from "../../Assets/Home/Hero/shutterstock_1084354847_cropped.jpg";
import mainImageMobile2 from "../../Assets/Home/Hero/shutterstock_1084354847_cropped2.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "../../Components/ScrollToTopButton";
import {
	SetStateAction,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import familyPorch from "../../Assets/Home/Hero/shutterstock_794996449_cropped.jpg";
import familyPorchMobile from "../../Assets/Home/Hero/shutterstock_794996449_mobile.jpg";
import productDemo from "../../Assets/Home/Technician with Homeowners 02 Cropped.png";
import productDemoMobile from "../../Assets/Home/Technician with Homeowners 02_mobile2.png";
import YardSign from "../../Assets/Home/ADT Yard Sign.png";
import iphone from "../../Assets/Rebranding/iPhone 15 blue app_alpha 01 1.png";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import map from "../../Assets/Home/map_cropped.png";
import StarIcon from "@mui/icons-material/Star";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import ProductCarousel from "./productCarousel";
import { AWARD_IMAGES, HOMEPAGE_REVIEWS, LOCATION_TYPE } from "../../Constants";
import "./index.scss";
import { isMobile } from "react-device-detect";

export default function Home() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const handleNavigate = (route: string, location: LOCATION_TYPE = "_self") => {
		if (location === "_blank") {
			const newWindow = window.open(route, location);
			if (!newWindow) {
				console.error("Failed to open link in a new window.");
				// Potentially warn the user that the link failed to open in a new window
				// setShowLinkError(true);
				// {showLinkError && (
				//   <div className="link-error">
				//     <p>The link failed to open. You may need to adjust your popup blocker settings.</p>
				//     <p>Alternatively, you can <a href={route} target="_blank" rel="noopener noreferrer">click here</a> to open the link in the current window.</p>
				//     <button onClick={() => setShowLinkError(false)}>Close</button>
				//   </div>
				// )}
			}
		} else {
			navigate(route);
		}
	};
	const currentUrl = window.location.href;
	const [showSttButton, setShowSttButton] = useState(false);
	const SHOW_SCROLL_BUTTON_THRESHOLD = 300;
	const baseUrl = currentUrl;
	const videoFile = "Home Video-No Home.mp4";
	const videoSrc = `${baseUrl}/${videoFile}`;
	useEffect(() => {
		const scrollListener = () => {
			setShowSttButton(window.scrollY > SHOW_SCROLL_BUTTON_THRESHOLD);
		};
		window.addEventListener("scroll", scrollListener);
		return () => window.removeEventListener("scroll", scrollListener);
	}, []);

	const productSectionRef = useRef<HTMLDivElement>(null);
	const whySafeHavenSectionRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (searchParams.get("scroll") === "products") {
			setTimeout(() => {
				productSectionRef.current?.scrollIntoView({ behavior: "smooth" });
			}, 10); // Small delay
		} else if (searchParams.get("scroll") === "why-safe-haven") {
			setTimeout(() => {
				// Check if the element exists and is visible
				if (whySafeHavenSectionRef.current) {
					const elementRect =
						whySafeHavenSectionRef.current.getBoundingClientRect();
					const originalY = window.pageYOffset + elementRect.top;

					window.scrollTo({
						top: originalY - 200,
						behavior: "smooth",
					});
				} else {
					// Handle the case where the element is not found (e.g., log an error)
					console.error("whySafeHavenSectionRef element not found!");
				}
			}, 10); // Small delay
		}
	}, [searchParams]);

	const swiperRef = useRef<SwiperRef | null>(null);

	const handlePrev = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev(500, true);
		}
	};

	const handleNext = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext(500, true);
		}
	};

	const [activeIndex, setActiveIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	const handleSlideChange = (swiper: {
		activeIndex: SetStateAction<number>;
	}) => {
		setActiveSlideIndex(swiper.activeIndex);
		console.log(swiper.activeIndex);
	};

	return (
		<>
			<Helmet>
				<title>Home | Safe Haven</title>
				<meta
					name="description"
					content="Protect your home and family with a reliable security system from Safe Haven Security. Explore our comprehensive solutions and find the perfect fit for your needs."
				/>
				<meta
					name="keywords"
					content="home security system, security system installation, home security monitoring, security cameras, alarm systems, Safe Haven Security"
				/>
			</Helmet>

			<ScrollToTopButton showButton={showSttButton}></ScrollToTopButton>

			{/* Ipad/Desktop: Main House Image */}
			<Box
				sx={{
					display: { xs: "none", md: "grid" },
					maxWidth: "2000px",
					margin: "auto",
				}}
			>
				<Box
					sx={{
						position: "relative",
						display: "grid",
						gridColumn: "1",
						gridRow: "1",
					}}
				>
					<Box
						sx={{
							display: { xs: "none", md: "flex" },
							justifyContent: "center",
							width: "100%",
							position: "relative",
						}}
					>
						{!isMobile ? (
							<video
								id=""
								className=""
								autoPlay
								muted
								style={{
									width: "100%",
									gridColumn: "1",
									gridRow: "1",
								}}
							>
								<source
									src={
										"https://itsformsattachments.blob.core.windows.net/formattachments/Home Video-No Home.mp4"
									}
									type="video/mp4"
								/>
								Your browser does not support videos.
							</video>
						) : null}
					</Box>

					<Box
						sx={{
							position: "absolute",
							gridColumn: "1",
							gridRow: "1",
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.6)",
							zIndex: 1,
						}}
					/>
				</Box>

				<Box
					sx={{
						width: "100%",
						gridColumn: "1",
						gridRow: "1",
						color: "#ffffff",
						textAlign: "center",
						zIndex: 1,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignContent: "center",
							alignItems: "center",
							flexWrap: "wrap",
							height: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignContent: "center",
								alignItems: "center",
								marginTop: { lg: "100px" },
								textAlign: "center",
								width: "100%",
							}}
						>
							<Typography sx={{ fontSize: "64px", marginBottom: "16px" }}>
								Homes Connected. <span className="homepage-title-break"></span>
								Families Protected.
							</Typography>
							<Typography
								sx={{
									zIndex: 1,
									textAlign: "center",
									maxWidth: "700px",
									margin: "auto auto auto auto",
									fontSize: {
										xs: "20px",
										sm: "20px",
										md: "20px",
										lg: "20px",
										xl: "22px",
									},
								}}
							>
								Safe Haven Security is the largest Authorized Dealer for ADT - the
								most trusted professionals in home security for over 150 years.
							</Typography>
							<Button
								className="home-free-quote-btn"
								sx={{
									display: "flex",
									backgroundColor: "#00519C",
									color: "#ffffff",
									borderRadius: "30px",
									height: "45px",
									maxHeight: "45px",
									fontSize: "14px",
									margin: "48px auto auto auto",
									padding: "0 24px",
									textTransform: "capitalize",
									"&.home-free-quote-btn:hover": {
										backgroundColor: "#115391!important",
									},
									"&.home-free-quote-btn>span": {
										marginLeft: "4px",
									},
								}}
								onMouseDown={(event) => {
									if (event.button === 1) {
										handleNavigate("/about-us", "_blank");
									}
								}}
								onClick={(event) => {
									handleNavigate("/about-us");
								}}
							>
								See The Difference
							</Button>
						</Box>
					</Box>
				</Box>

				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: {
							xs: "translate(-50%, -50%)",
							sm: "translate(-50%, -50%)",
							md: "translate(-50%, -50%)",
							lg: "translate(-50%, -80%)",
							xl: "translate(-50%, -10%)",
						},
						color: "#ffffff",
						minWidth: "1000px",
						zIndex: 1,
					}}
				></Box>
			</Box>

			{/* Mobile: Main House Image */}
			<Box
				sx={{
					display: { xs: "grid", md: "none" },
					justifyContent: "center",
					maxWidth: "100vw",
					overflow: "hidden",
				}}
			>
				<Box
					sx={{
						position: "relative",
						display: "grid",
						gridColumn: "1",
						gridRow: "1",
					}}
				>
					<Box
						component="img"
						sx={{
							gridColumn: "1",
							gridRow: "1",
							display: { xs: "block", sm: "none" },
							width: "auto",
							maxWidth: { xs: "100%", sm: "100%" },
							height: "auto",
							margin: "auto",
							filter: "blur(10px)",
							transition: "filter 0.5s ease",
						}}
						alt="The house from the offer."
						src={mainImageMobile}
						loading="lazy"
						onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
							const target = e.target as HTMLImageElement;
							target.style.filter = "blur(0px)";
						}}
					/>

					<Box
						component="img"
						sx={{
							gridColumn: "1",
							gridRow: "1",
							display: { xs: "none", sm: "block" },
							width: "auto",
							maxWidth: { xs: "100%", sm: "100%" },
							height: "auto",
							margin: "auto",
							filter: "blur(10px)",
							transition: "filter 0.5s ease",
						}}
						alt="The house from the offer."
						src={mainImageMobile2}
						loading="lazy"
						onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
							const target = e.target as HTMLImageElement;
							target.style.filter = "blur(0px)";
						}}
					/>
					<Box
						sx={{
							gridColumn: "1",
							gridRow: "1",
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.5)",
							zIndex: 1,
						}}
					/>
				</Box>

				<Box
					sx={{
						width: "100%",
						gridColumn: "1",
						gridRow: "1",
						color: "#ffffff",
						textAlign: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignContent: "center",
							alignItems: "center",
							flexWrap: "wrap",
							height: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignContent: "center",
								alignItems: "center",
								marginBottom: "32px",
								textAlign: "center",
								width: "100%",
							}}
						>
							<Typography
								sx={{ fontSize: "64px", marginBottom: "16px", zIndex: 1 }}
							>
								Homes Connected. <span className="homepage-title-break"></span>
								Families Protected.
							</Typography>
							<Typography
								className="mobile-home-main-image-2"
								sx={{
									zIndex: "1",
									textAlign: "center",
									margin: "32px auto auto auto",
									fontSize: { xs: "16px", md: "22px", lg: "22px", xl: "28px" },
									position: "relative",
									width: "100%",
									maxWidth: { sm: "500px" },
								}}
							>
								Safe Haven Security is the largest Authorized Dealer for ADT - the
								most trusted professionals in home security for over 150 years.
							</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								maxWidth: "500px",
								margin: "auto",
								width: "100%",
							}}
						>
							<Button
								className="home-free-quote-btn"
								sx={{
									zIndex: 1,
									display: "flex",
									backgroundColor: "#00519C",
									color: "#ffffff",
									borderRadius: "30px",
									height: "45px",
									maxHeight: "45px",
									fontSize: "14px",
									margin: "auto auto auto auto",
									padding: "0 24px",
									textTransform: "capitalize",
									"&.home-free-quote-btn:hover": {
										backgroundColor: "#1260a8!important",
									},
									"&.home-free-quote-btn>span": {
										marginLeft: "4px",
									},
								}}
								onMouseDown={(event) => {
									if (event.button === 1) {
										handleNavigate("/about-us", "_blank");
									}
								}}
								onClick={(event) => {
									handleNavigate("/about-us");
								}}
							>
								See The Difference
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* Ipad/Desktop: Tricycle Strip */}
			<Container
				ref={whySafeHavenSectionRef}
				maxWidth={false}
				sx={{
					display: { xs: "none", md: "grid" },
					maxWidth: "2000px",
					marginTop: "72px",
				}}
			>
				<Grid container className="" sx={{}}>
					<Grid
						item
						xs={12}
						lg={6}
						sx={{ paddingRight: "24px", display: "flex" }}
					>
						<Box sx={{ position: "relative" }}>
							<Box
								sx={{
									overflow: "hidden",
									borderTopRightRadius: "300px",
									position: "relative",
								}}
							>
								<Box
									component="img"
									sx={{
										display: "block",
										width: "100%",
										maxWidth: "100%",
										height: "auto",
										margin: "auto",
										transform: "translateX(175px) scale(1)",
									}}
									alt="The house from the offer."
									src={familyPorch}
									loading="lazy"
								/>
								<Box
									sx={{
										position: "absolute",
										top: 0,
										left: 0,
										width: "30%",
										height: "100%",
										background:
											"linear-gradient(to right, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
									}}
								/>
							</Box>
							<Box
								component="img"
								sx={{
									display: "block",
									width: "50%",
									maxWidth: "50%",
									height: "auto",
									margin: "auto",
									position: "absolute",
									bottom: "-35px",
								}}
								alt="The house from the offer."
								src={YardSign}
								loading="lazy"
							/>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						lg={6}
						sx={{
							paddingLeft: "24px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Stack sx={{ maxWidth: "75%", justifyContent: "center" }}>
							<Typography
								sx={{
									color: "rgb(139, 221, 249)",
									textTransform: "uppercase",
									fontWeight: "700",
								}}
							>
								Welcome to Smart Home Systems
							</Typography>
							<Typography sx={{ fontSize: "56px" }}>
								Why <strong>Safe Haven?</strong>
							</Typography>
							<Typography sx={{ marginBottom: "32px" }}>
								Celebrating 25 years of protecting homes in 2024, Safe Haven
								Security is dedicated to providing you a safer lifestyle with
								cutting-edge Smart Home technology. Headquartered in Kansas
								City, we prioritize our customers' security every day with
								innovative and reliable home protection solutions.
							</Typography>
							<List>
								<ListItem disablePadding sx={{ marginBottom: "8px" }}>
									<ListItemIcon sx={{ justifyContent: "center" }}>
										<CheckCircleIcon
											sx={{ color: "rgb(68, 200, 245)", minWidth: "40px" }}
										/>
									</ListItemIcon>
									<ListItemText>
										<Typography sx={{ fontWeight: "700" }}>
											Advanced security systems with 24/7 ADT-monitoring
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disablePadding sx={{ marginBottom: "8px" }}>
									<ListItemIcon sx={{ justifyContent: "center" }}>
										<CheckCircleIcon
											sx={{ color: "rgb(68, 200, 245)", minWidth: "40px" }}
										/>
									</ListItemIcon>
									<ListItemText>
										<Typography sx={{ fontWeight: "700" }}>
											Manage your home remotely with our Smart Home technology.
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disablePadding>
									<ListItemIcon sx={{ justifyContent: "center" }}>
										<CheckCircleIcon
											sx={{ color: "rgb(68, 200, 245)", minWidth: "40px" }}
										/>
									</ListItemIcon>
									<ListItemText>
										<Typography sx={{ fontWeight: "700" }}>
											25 years of providing personalized, reliable security
											solutions.
										</Typography>
									</ListItemText>
								</ListItem>
							</List>

							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<Button
									className="home-free-quote-btn"
									sx={{
										backgroundColor: "#1260a8",
										color: "#ffffff",
										borderRadius: "30px",
										height: "45px",
										maxHeight: "45px",
										fontSize: "14px",
										margin: "32px 0 0 0",
										padding: "0 24px",
										textTransform: "capitalize",
										"&.home-free-quote-btn:hover": {
											backgroundColor: "#115391!important",
										},
										"&.home-free-quote-btn>span": {
											marginLeft: "4px",
										},
									}}
									onClick={() => handleNavigate("/about-us")}
									aria-label="Learn more about us"
								>
									Learn More
								</Button>
							</Box>
						</Stack>
					</Grid>
				</Grid>
			</Container>

			{/* Mobile: Tricycle Strip */}
			<Box sx={{ display: { lg: "none" } }}>
				<Container sx={{}}>
					<Stack sx={{ marginTop: "64px" }}>
						<Typography
							sx={{
								color: "rgb(139, 221, 249)",
								textTransform: "uppercase",
								fontWeight: "700",
								fontSize: "12px",
								marginLeft: "16px",
							}}
						>
							Welcome to Smart Home Systems
						</Typography>
						<Typography
							sx={{
								fontSize: "32px",
								marginTop: "8px",
								marginBottom: "8px",
								marginLeft: "16px",
							}}
						>
							Why <strong>Safe Haven?</strong>
						</Typography>
						<Typography sx={{ marginBottom: "8px", marginLeft: "16px" }}>
							Celebrating 25 years of protecting homes in 2024, Safe Haven
							Security is dedicated to providing you a safer lifestyle with
							cutting-edge Smart Home technology. Headquartered in Kansas City,
							we prioritize our customers' security every day with innovative
							and reliable home protection solutions.
						</Typography>
						<List sx={{ display: "flex", flexWrap: "wrap" }}>
							<ListItem
								disablePadding
								sx={{
									marginBottom: "8px",
									minWidth: "50%",
									width: { xs: "100%", sm: "50%" },
								}}
							>
								<ListItemIcon>
									<CheckCircleIcon
										sx={{ color: "rgb(68, 200, 245)", minWidth: "40px" }}
									/>
								</ListItemIcon>
								<ListItemText>
									<Typography sx={{ fontWeight: "700", fontSize: "14px" }}>
										Advanced security systems with 24/7 <br></br>ADT-monitoring
									</Typography>
								</ListItemText>
							</ListItem>
							<ListItem
								disablePadding
								sx={{
									marginBottom: "8px",
									minWidth: "50%",
									width: { xs: "100%", sm: "50%" },
								}}
							>
								<ListItemIcon>
									<CheckCircleIcon
										sx={{ color: "rgb(68, 200, 245)", minWidth: "40px" }}
									/>
								</ListItemIcon>
								<ListItemText>
									<Typography sx={{ fontWeight: "700", fontSize: "14px" }}>
										Manage your home remotely with our <br></br>Smart Home
										technology.
									</Typography>
								</ListItemText>
							</ListItem>
							<ListItem
								disablePadding
								sx={{ minWidth: "50%", width: { xs: "100%", sm: "50%" } }}
							>
								<ListItemIcon>
									<CheckCircleIcon
										sx={{ color: "rgb(68, 200, 245)", minWidth: "40px" }}
									/>
								</ListItemIcon>
								<ListItemText>
									<Typography sx={{ fontWeight: "700", fontSize: "14px" }}>
										25 years of providing personalized, reliable <br></br>
										security solutions.
									</Typography>
								</ListItemText>
							</ListItem>
						</List>

						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button
								className="home-free-quote-btn"
								sx={{
									backgroundColor: "#1260a8",
									color: "#ffffff",
									borderRadius: "30px",
									height: "45px",
									maxHeight: "45px",
									fontSize: "14px",
									margin: "32px 0 42px 0",
									padding: "0 24px",
									textTransform: "capitalize",
									"&.home-free-quote-btn:hover": {
										backgroundColor: "#1260a8!important",
									},
									"&.home-free-quote-btn>span": {
										marginLeft: "4px",
									},
									minWidth: "150px",
								}}
								onMouseDown={(event) => {
									if (event.button === 1) {
										handleNavigate("/about-us", "_blank");
									}
								}}
								onClick={(event) => {
									handleNavigate("/about-us");
								}}
							>
								Learn More
							</Button>
						</Box>
					</Stack>
				</Container>
				<Box sx={{ position: "relative", display: { xs: "grid", md: "none" } }}>
					<Box
						sx={{
							overflow: "hidden",
							borderTopRightRadius: "55% 45%",
							position: "relative",
							width: "100%",
						}}
					>
						<Box
							component="img"
							sx={{
								display: "block",
								width: "100%",
								maxWidth: "100%",
								height: "auto",
								margin: "auto",
							}}
							alt="The house from the offer."
							src={familyPorchMobile}
							loading="lazy"
						/>
					</Box>
				</Box>
			</Box>

			{/* Stats Strip */}
			<Box
				sx={{
					backgroundColor: "rgb(244, 244, 244)",
					padding: { xs: "16px 0px", lg: "128px" },
				}}
			>
				<Container
					maxWidth={"xl"}
					sx={{ marginTop: "32px", marginBottom: "32px" }}
				>
					<Grid container className="stats-banner-grid" columnSpacing={0}>
						<Grid
							item
							xs={6}
							md={3}
							sx={{ position: "relative", padding: "8px" }}
						>
							<Typography
								className="stats-banner-number"
								sx={{
									color: "rgb(0, 83, 156)",
									fontSize: { xs: "40px", lg: "64px" },
									fontWeight: "700",
									textAlign: "center",
								}}
							>
								25+
							</Typography>
							<Typography
								className="stats-banner-text"
								sx={{
									color: "rgb(112, 112, 123)",
									fontSize: "12px",
									fontWeight: "700",
									textAlign: "center",
									maxWidth: "250px",
									margin: "auto",
								}}
							>
								Years serving our communities as a home security leader
							</Typography>
							<Box
								sx={{
									position: "absolute",
									borderLeft: "1px solid #cecece",
									height: { xs: "64px", lg: "120px" },
									right: "0",
									bottom: { xs: "15%", lg: "0" },
								}}
							></Box>
						</Grid>
						<Grid
							item
							xs={6}
							md={3}
							sx={{ position: "relative", padding: "8px" }}
						>
							<Typography
								sx={{
									color: "rgb(0, 83, 156)",
									fontSize: { xs: "40px", lg: "64px" },
									fontWeight: "700",
									textAlign: "center",
								}}
							>
								96%
							</Typography>
							<Typography
								sx={{
									color: "rgb(112, 112, 123)",
									fontSize: "12px",
									fontWeight: "700",
									textAlign: "center",
									maxWidth: "250px",
									margin: "auto",
								}}
							>
								Customer Satisfaction from Over 22,000 Google Reviews.
							</Typography>
							<Box
								sx={{
									display: { xs: "none", md: "block" },
									position: "absolute",
									borderLeft: "1px solid #cecece",
									height: { xs: "64px", lg: "120px" },
									right: "0",
									bottom: { xs: "15%", lg: "0" },
								}}
							></Box>
						</Grid>
						<Grid
							item
							xs={6}
							md={3}
							sx={{ position: "relative", padding: "8px" }}
						>
							<Typography
								sx={{
									color: "rgb(0, 83, 156)",
									fontSize: { xs: "40px", lg: "64px" },
									fontWeight: "700",
									textAlign: "center",
								}}
							>
								1M+
							</Typography>
							<Typography
								sx={{
									color: "rgb(112, 112, 123)",
									fontSize: "12px",
									fontWeight: "700",
									textAlign: "center",
									maxWidth: "250px",
									margin: "auto",
								}}
							>
								Happy Families with Smart Home Solutions from Safe Haven
							</Typography>
							<Box
								sx={{
									position: "absolute",
									borderLeft: "1px solid #cecece",
									height: { xs: "64px", lg: "120px" },
									right: "0",
									bottom: { xs: "15%", lg: "0" },
								}}
							></Box>
						</Grid>
						<Grid
							item
							xs={6}
							md={3}
							sx={{ position: "relative", padding: "8px" }}
						>
							<Typography
								sx={{
									color: "rgb(0, 83, 156)",
									fontSize: { xs: "40px", lg: "64px" },
									fontWeight: "700",
									textAlign: "center",
								}}
							>
								100+
							</Typography>
							<Typography
								sx={{
									color: "rgb(112, 112, 123)",
									fontSize: "12px",
									fontWeight: "700",
									textAlign: "center",
									maxWidth: "250px",
									margin: "auto",
								}}
							>
								Safe Haven Offices Around the Country
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>

			{/* Awards Carousel Section*/}
			<Box
				sx={{ marginTop: "48px", marginBottom: { xs: "48px", md: "124px" } }}
			>
				<Container
					sx={{
						display: "flex",
						justifyContent: "space-between",
						flexDirection: { xs: "column", sm: "row", alignItems: "center" },
					}}
				>
					<Box>
						<Typography
							sx={{
								color: "rgb(139, 221, 249)",
								textTransform: "uppercase",
								fontWeight: "700",
								marginBottom: "8px",
								fontSize: "12px",
							}}
						>
							OUR AWARDS
						</Typography>
						<Typography
							sx={{
								fontSize: "42px",
								lineHeight: "48px",
								marginBottom: "16px",
							}}
						>
							Latest <strong>Awards</strong>
						</Typography>
					</Box>

					<Box
						sx={{
							display: { xs: "none", sm: "flex" },
							justifyContent: "center",
							marginTop: "8px",
							zIndex: 2,
						}}
					>
						<Box sx={{ margin: "18px" }}>
							<IconButton
								className="award-carousel-nav-button"
								aria-label="delete"
								onClick={handlePrev}
								sx={{
									backgroundColor: "rgb(68,200,245)",
									minHeight: "50px",
									minWidth: "50px",
								}}
							>
								<WestIcon sx={{ color: "#fff" }} />
							</IconButton>
						</Box>

						<Box sx={{ margin: "18px" }}>
							<IconButton
								className="award-carousel-nav-button"
								aria-label="delete"
								onClick={handleNext}
								sx={{
									backgroundColor: "rgb(68,200,245)",
									minHeight: "50px",
									minWidth: "50px",
								}}
							>
								<EastIcon sx={{ color: "#fff" }} />
							</IconButton>
						</Box>
					</Box>
				</Container>

				{/* Swiper Component */}
				<Container
					sx={{
						maxHeight: "500px",
						margin: "44px auto 44px auto",
						position: "relative",
						padding: "0",
					}}
				>
					<Swiper
						style={{ height: "100%", width: "100%" }}
						loop={true}
						ref={swiperRef}
						className="about-us-swiper"
						navigation={false}
						spaceBetween={0}
						modules={[Pagination, Navigation]}
						initialSlide={activeIndex}
						onSlideChange={handleSlideChange}
						breakpoints={{
							0: {
								slidesPerView: 1,
							},
							600: {
								slidesPerView: 2,
							},
							1200: {
								slidesPerView: 3,
							},
						}}
					>
						{AWARD_IMAGES.map((award, index) => (
							<SwiperSlide
								key={index}
								className={`about-us-swiper-slide-${index}`}
								style={{
									display: "flex",
									justifyContent: "center",
									height: "100%",
									paddingRight: "1px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										flexDirection: "column",
										justifyContent: "center",
										height: "300px",
										width: "100%",
										position: "relative",
									}}
								>
									<Box
										sx={{
											height: "210px",
											display: "flex",
											alignItems: "center",
										}}
									>
										<Box
											component="img"
											sx={{
												width: "auto",
												maxHeight: "100%",
												maxWidth: "250px",
												cursor: "pointer",
											}}
											alt={`Product ${index + 1}`}
											src={award.image}
											loading="lazy"
										/>
									</Box>
									<Typography
										variant="body2"
										align="center"
										sx={{
											fontSize: "20px",
											lineHeight: "22px",
											maxWidth: "250px",
											height: "70px",
											paddingTop: "20px",
										}}
									>
										{award.text}
									</Typography>
									<Box
										sx={{
											position: "absolute",
											borderLeft: "1px solid #cecece",
											height: "125px",
											right: "0",
											bottom: "45%",
										}}
									></Box>
								</Box>
							</SwiperSlide>
						))}
					</Swiper>
					<Box
						sx={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "50%",
							height: "300px",
							background:
								"linear-gradient(to right, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
							zIndex: 1,
						}}
					/>
					<Box
						sx={{
							position: "absolute",
							top: 0,
							right: 0,
							width: "50%",
							height: "300px",
							background:
								"linear-gradient(to left, rgba(255,255,255,1) 4%, rgba(255,255,255,0) 15%)",
							zIndex: 1,
						}}
					/>
					<Box
						sx={{
							display: { xs: "flex", sm: "none" },
							justifyContent: "center",
							marginTop: "8px",
							zIndex: 2,
						}}
					>
						<Box sx={{ margin: "18px" }}>
							<IconButton
								className="award-carousel-nav-button"
								aria-label="delete"
								onClick={handlePrev}
								sx={{
									backgroundColor: "rgb(68,200,245)",
									minHeight: "50px",
									minWidth: "50px",
								}}
							>
								<WestIcon sx={{ color: "#fff" }} />
							</IconButton>
						</Box>

						<Box sx={{ margin: "18px" }}>
							<IconButton
								className="award-carousel-nav-button"
								aria-label="delete"
								onClick={handleNext}
								sx={{
									backgroundColor: "rgb(68,200,245)",
									minHeight: "50px",
									minWidth: "50px",
								}}
							>
								<EastIcon sx={{ color: "#fff" }} />
							</IconButton>
						</Box>
					</Box>
				</Container>
			</Box>

			{/* Desktop: Iphone Strip */}
			<Box
				sx={{
					display: { xs: "none", md: "grid" },
					backgroundColor: "rgb(0, 83, 156)",
					padding: { lg: "112px" },
				}}
			>
				<Container>
					<Grid container className="" sx={{}}>
						<Grid
							item
							xs={12}
							sm={6}
							sx={{ paddingRight: "24px", display: "flex" }}
						>
							<Stack sx={{}}>
								<Typography
									sx={{
										color: "rgb(139, 221, 249)",
										textTransform: "uppercase",
										fontWeight: "700",
										marginBottom: "24px",
									}}
								>
									Control Your System
								</Typography>
								<Typography
									sx={{
										fontSize: "56px",
										color: "#ffffff",
										lineHeight: "64px",
										marginBottom: "16px",
									}}
								>
									Total <strong>Control and</strong>
									<br></br>
									<strong>Security, Always.</strong>
								</Typography>
								<Typography sx={{ marginBottom: "32px", color: "#ffffff" }}>
									Conveniently manage your security and smart home devices with
									the Alarm.com app, all from one place. Arm and disarm your
									system, view live video, and control smart devices remotely.
									Get customized alerts and tailored security that adapts to
									your needs, with seamless integration of compatible devices
									for total peace of mind.
								</Typography>
							</Stack>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							sx={{
								paddingLeft: "24px",
								display: "flex",
								alignItems: "center",
							}}
						>
							<Box sx={{ position: "relative", height: "100%" }}>
								<Box
									component="img"
									sx={{
										position: "absolute",
										transform: {
											xs: "translateX(50%) translateY(00%) scale(1)",
											lg: "translateX(150%) translateY(00%) scale(2.5)",
										},
										display: "block",
										width: "auto",
										height: "100%",
										maxHeight: "100%",
									}}
									alt="The house from the offer."
									src={iphone}
									loading="lazy"
								/>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					display: { xs: "none", sm: "grid", md: "none" },
					backgroundColor: "rgb(0, 83, 156)",
					padding: { lg: "112px" },
				}}
			>
				<Container sx={{ margin: "32px 0" }}>
					<Grid container className="" sx={{}}>
						<Grid
							item
							xs={12}
							sm={6}
							sx={{ paddingRight: "24px", display: "flex" }}
						>
							<Stack sx={{}}>
								<Typography
									sx={{
										color: "rgb(139, 221, 249)",
										textTransform: "uppercase",
										fontWeight: "700",
										marginBottom: "8px",
									}}
								>
									Control Your System
								</Typography>
								<Typography
									sx={{
										fontSize: "32px",
										color: "#ffffff",
										lineHeight: "48px",
										marginBottom: "8px",
									}}
								>
									Total <strong>Control and Security, Always.</strong>
								</Typography>
								<Typography sx={{ marginBottom: "32px", color: "#ffffff" }}>
									Conveniently manage your security and smart home devices with
									the Alarm.com app, all from one place. Arm and disarm your
									system, view live video, and control smart devices remotely.
									Get customized alerts and tailored security that adapts to
									your needs, with seamless integration of compatible devices
									for total peace of mind.
								</Typography>
							</Stack>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							sx={{
								paddingLeft: "24px",
								display: "flex",
								alignItems: "center",
							}}
						>
							<Box sx={{ position: "relative", height: "100%" }}>
								<Box
									component="img"
									sx={{
										position: "absolute",
										transform: {
											xs: "translateX(50%) translateY(00%) scale(1.8)",
											lg: "translateX(150%) translateY(00%) scale(2.5)",
										},
										display: "block",
										width: "auto",
										height: "100%",
										maxHeight: "100%",
									}}
									alt="The house from the offer."
									src={iphone}
									loading="lazy"
								/>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>

			{/* Mobile: Iphone Strip */}
			<Box
				sx={{
					display: { xs: "grid", sm: "none" },
					backgroundColor: "rgb(0, 83, 156)",
					paddingTop: "64px",
				}}
			>
				<Container sx={{ paddingLeft: "24px", paddingRight: "24px" }}>
					<Stack sx={{ position: "relative" }}>
						<Typography
							sx={{
								color: "rgb(139, 221, 249)",
								textTransform: "uppercase",
								fontWeight: "700",
								marginBottom: "8px",
								fontSize: "14px",
								letterSpacing: "1px",
							}}
						>
							Control Your System
						</Typography>
						<Typography
							sx={{
								fontSize: "32px",
								color: "#ffffff",
								lineHeight: "42px",
								marginBottom: "16px",
							}}
						>
							Total <strong>Control and</strong>
							<br></br>
							<strong>Security, Always.</strong>
						</Typography>
						<Typography sx={{ color: "#ffffff", fontSize: "14px" }}>
							Conveniently manage your security and smart home devices with the
							Alarm.com app, all from one place.
							<br></br>
							Arm and disarm your system, view live video, and control smart
							devices remotely.
						</Typography>
					</Stack>
				</Container>
				<Box
					sx={{
						background: "#fff",
						display: "flex",
						justifyContent: "flex-end",
						backgroundImage:
							"linear-gradient(to bottom, rgb(0, 83, 156) 60%, transparent 60%)",
					}}
				>
					<Box
						component="img"
						sx={{
							display: "block",
							width: "auto",
							maxWidth: "55%",
							height: "auto",
							transform: "translateY(-35px)",
							marginRight: "8px",
						}}
						alt="The house from the offer."
						src={iphone}
						loading="lazy"
					/>
				</Box>
			</Box>

			{/* Products Section */}
			<Container
				ref={productSectionRef}
				maxWidth={false}
				sx={{ marginTop: { xs: "0", md: "175px" }, maxWidth: "1750px" }}
			>
				<Container
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box>
						<Typography
							sx={{
								color: "rgba(68, 200, 245)",
								fontWeight: "700",
								fontSize: "12px",
								marginBottom: "8px",
							}}
						>
							360° TOTAL SECURITY SERVICES
						</Typography>
						<Typography sx={{ fontSize: "42px" }}>
							Our <strong>Products</strong>
						</Typography>
					</Box>
					<Box>
						<Button
							className="home-free-quote-btn"
							sx={{
								display: { xs: "none", md: "none" },
								backgroundColor: "rgb(0,39,78)",
								color: "#ffffff",
								borderRadius: "30px",
								height: "45px",
								maxHeight: "45px",
								fontSize: "14px",
								margin: "32px 0 0 0",
								padding: "0 24px",
								textTransform: "capitalize",
								"&.home-free-quote-btn:hover": {
									backgroundColor: "#1260a8!important",
								},
								"&.home-free-quote-btn>span": {
									marginLeft: "4px",
								},
							}}
						>
							OUR PRODUCTS
						</Button>
					</Box>
				</Container>

				<Container>
					<Divider
						sx={{
							marginTop: { xs: "16px", md: "52px" },
							marginBottom: { xs: "22px", md: "14px" },
						}}
					></Divider>
				</Container>

				<ProductCarousel></ProductCarousel>

				<Box
					sx={{
						display: "flex",
						width: "100%",
						justifyContent: "center",
						marginBottom: "72px",
					}}
				>
					<Button
						className="home-free-quote-btn"
						sx={{
							display: { xs: "none", md: "none" },
							backgroundColor: "rgb(0,39,78)",
							color: "#ffffff",
							borderRadius: "30px",
							height: "55px",
							maxHeight: "55px",
							minWidth: "185px",
							fontSize: "14px",
							fontWeight: "700",
							letterSpacing: "1px",
							margin: "32px 0 0 0",
							padding: "0 24px",
							textTransform: "capitalize",
							"&.home-free-quote-btn:hover": {
								backgroundColor: "#1260a8!important",
							},
							"&.home-free-quote-btn>span": {
								marginLeft: "4px",
							},
						}}
					>
						OUR PRODUCTS
					</Button>
				</Box>
			</Container>

			{/* Desktop: Reviews Sections */}
			<Box
				sx={{
					display: { xs: "none", lg: "grid" },
					position: "relative",
					marginBottom: "250px",
				}}
			>
				{/* Background Image */}
				<Box
					component="img"
					sx={{
						width: "100%",
						maxWidth: "xl",
						height: "auto",
						margin: "auto",
						display: { xs: "none", lg: "grid" },
						gridColumn: "1",
						gridRow: "1",
						filter: "blur(10px)",
						transition: "filter 0.5s ease",
					}}
					alt="The house from the offer."
					src={productDemo}
					loading="lazy"
					onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
						const target = e.target as HTMLImageElement;
						target.style.filter = "blur(0px)";
					}}
				/>

				{/* Dark Tint Overlay */}
				<Box
					sx={{
						width: "100%",
						maxWidth: "xl",
						margin: "auto",
						height: "100%",
						backgroundColor: "rgba(0, 0, 0, 0.4)",
						display: "grid",
						gridColumn: "1",
						gridRow: "1",
						zIndex: 1,
					}}
				/>

				{/* Blue Review Side-By-Side */}
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, 10%)",
						color: "#ffffff",
						zIndex: "2",
						width: "100%",
						maxWidth: "1300px",
					}}
				>
					<Grid container className="">
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								backgroundColor: "rgba(0, 39, 78)",
								padding: {
									xs: "56px 32px 48px 32px",
									md: "56px 80px 80px 60px",
								},
							}}
						>
							<Stack sx={{ marginBottom: "8px" }}>
								<Box>
									<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
									<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
									<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
									<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
									<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
								</Box>
							</Stack>
							<Typography
								sx={{
									fontSize: "28px",
									fontWeight: "700",
									letterSpacing: "1px",
									marginBottom: "8px",
								}}
							>
								22,000+ Reviews
							</Typography>
							<Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
								Rated Excellent by our customers, with 4.8 stars from over
								22,000 reviews, with an A+ BBB rating, we are proud to deliver
								top-notch home security solutions that exceed expectations.
							</Typography>
							<Box sx={{ marginTop: "48px" }}>
								<Button
									variant="outlined"
									className=""
									sx={{
										display: "flex",
										color: "#ffffff",
										borderColor: "#ffffff",
										borderWidth: "2px",
										borderRadius: "30px",
										height: "50px",
										minWidth: "150px",
										fontSize: "12px",
										letterSpacing: "1px",
										padding: "0 24px",
										textTransform: "capitalize",
									}}
									onMouseDown={(event) => {
										if (event.button === 1) {
											handleNavigate("/contact-us", "_blank");
										}
									}}
									onClick={(event) => {
										handleNavigate("/contact-us");
									}}
								>
									CONTACT US
								</Button>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								padding: "102px 80px 80px 60px",
								backgroundColor: "rgba(0, 83, 156)",
								borderTopRightRadius: "30% 45%",
							}}
						>
							<Swiper
								style={{ height: "100%", width: "100%" }}
								loop={true}
								className="home-reviews-swiper"
								navigation={false}
								pagination={{
									clickable: true,

									bulletClass: "home-reviews-carousel-bullet",
									bulletActiveClass: "home-reviews-carousel-bullet-active",
								}}
								spaceBetween={5}
								modules={[Pagination, Navigation]}
								slidesPerView={1}
							>
								{HOMEPAGE_REVIEWS.map((review, index) => (
									<SwiperSlide
										key={index}
										className={`homepage-reviews-slide-${index}`}
										style={{
											display: "flex",
											justifyContent: "center",
											height: "100%",
											paddingRight: "1px",
											width: "60%",
										}}
									>
										<Stack>
											<Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
												{review.text}
											</Typography>
											<Typography
												sx={{
													fontSize: "16px",
													letterSpacing: "1px",
													marginTop: "24px",
												}}
											>
												{review.name}
											</Typography>
											<Typography
												sx={{
													fontSize: "14px",
													color: "rgba(68, 200, 245)",
													fontWeight: "700",
												}}
											>
												Google Review, 5 Stars
											</Typography>
										</Stack>
									</SwiperSlide>
								))}
							</Swiper>
						</Grid>
					</Grid>
				</Box>
			</Box>

			{/* Mobile: Reviews Sections */}
			<Box
				sx={{
					display: { xs: "grid", lg: "none" },
					position: "relative",
					paddingBottom: "32px",
					background: "rgba(0, 83, 156)",
				}}
			>
				{/* Background Image */}
				<Box
					component="img"
					sx={{
						width: "100%",
						maxWidth: "100%",
						height: "auto",
						margin: "auto",
						display: { xs: "grid", lg: "none" },
						gridColumn: "1",
						gridRow: "1",
					}}
					alt="The house from the offer."
					src={productDemoMobile}
					loading="lazy"
				/>

				{/* Dark Tint Overlay */}
				<Box
					sx={{
						width: "100%",
						maxWidth: "xl",
						margin: "auto",
						height: "100%",
						backgroundColor: "rgba(0, 0, 0, 0.6)",
						display: "grid",
						gridColumn: "1",
						gridRow: "1",
					}}
				/>

				<Box
					sx={{
						gridColumn: "1",
						gridRow: "2",
						backgroundColor: "rgba(0, 39, 78)",
						padding: { xs: "56px 32px 48px 32px", md: "56px 80px 80px 60px" },
						color: "#fff",
					}}
				>
					<Stack sx={{ marginBottom: "8px" }}>
						<Box>
							<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
							<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
							<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
							<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
							<StarIcon sx={{ color: "rgba(68, 200, 245)" }}></StarIcon>
						</Box>
					</Stack>
					<Typography
						sx={{
							fontSize: "28px",
							fontWeight: "700",
							letterSpacing: "1px",
							marginBottom: "8px",
						}}
					>
						22,000+ Reviews
					</Typography>
					<Typography sx={{ fontSize: "18px", lineHeight: "24px" }}>
						Rated Excellent by our customers, with 4.8 stars from over 22,000
						reviews, with an A+ BBB rating, we are proud to deliver top-notch
						home security solutions that exceed expectations.
					</Typography>
					<Box sx={{ marginTop: "48px" }}>
						<Button
							variant="outlined"
							className=""
							sx={{
								display: "flex",
								color: "#ffffff",
								borderColor: "#ffffff",
								borderWidth: "2px",
								borderRadius: "30px",
								height: "50px",
								minWidth: "150px",
								fontSize: "12px",
								letterSpacing: "1px",
								padding: "0 24px",
								textTransform: "capitalize",
							}}
						>
							CONTACT US
						</Button>
					</Box>
				</Box>

				<Box sx={{ backgroundColor: "rgba(0, 39, 78)", minHeight: "350px" }}>
					<Box
						sx={{
							gridColumn: "1",
							gridRow: "3",
							backgroundColor: "rgba(0, 83, 156)",
							borderTopRightRadius: {
								xs: "50% 60%",
								sm: "50% 60%",
								md: "25% 45%",
							},
							color: "#fff",
							height: "100%",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Swiper
							style={{ height: "100%" }}
							loop={true}
							className="home-reviews-swiper mobile-reviews-swiper"
							navigation={false}
							pagination={{
								clickable: true,
								renderBullet: function (index, className) {
									return `
                          <span class="${className} mobile-reviews-bullet">
                            <span class="inner-dot"></span> 
                          </span>
                        `;
								},
								bulletClass: "home-reviews-carousel-bullet",
								bulletActiveClass: "home-reviews-carousel-bullet-active",
							}}
							spaceBetween={5}
							modules={[Pagination, Navigation]}
							slidesPerView={1}
						>
							{HOMEPAGE_REVIEWS.map((review, index) => (
								<SwiperSlide
									key={index}
									className={`homepage-reviews-slide-${index}`}
									style={{
										display: "flex",
										justifyContent: "center",
										height: "100%",
										paddingRight: "1px",
										width: "60%",
										marginLeft: "0",
									}}
								>
									<Stack sx={{ height: "100%", justifyContent: " flex-start" }}>
										<Typography
											sx={{
												maxWidth: "70%",
												fontSize: { xs: "12px", sm: "18px", md: "24px" },
												lineHeight: { xs: "22px", sm: "28px", md: "32px" },
												minHeight: "225px",
												maxHeight: "225px",
												marginTop: "24px",
												display: "flex",
												alignItems: "center",
											}}
										>
											{review.text}
										</Typography>
										<Box sx={{ minHeight: "60px" }}>
											<Typography
												sx={{
													fontSize: { xs: "14px", sm: "18px", md: "18px" },
													lineHeight: { xs: "24px", sm: "28px", md: "28px" },
													letterSpacing: "1px",
												}}
											>
												{review.name}
											</Typography>
											<Typography
												sx={{
													fontSize: { xs: "12px", sm: "12px", md: "16px" },
													lineHeight: { xs: "22px", sm: "22px", md: "26px" },
													color: "rgba(68, 200, 245)",
													fontWeight: "700",
												}}
											>
												Google Review, 5 Stars
											</Typography>
										</Box>
									</Stack>
								</SwiperSlide>
							))}
						</Swiper>
					</Box>
				</Box>
			</Box>

			{/* Crime Map Section */}
			<Container
				sx={{
					display: "none",
					marginTop: { xs: "56px", md: "200px" },
					marginBottom: "32px",
				}}
			>
				<Grid
					container
					className=""
					sx={{}}
					flexDirection={{ xs: "column-reverse", md: "row" }}
				>
					<Grid item xs={12} md={5} sx={{}}>
						<Box
							sx={{
								display: "flex",
								marginRight: { md: "32px" },
								justifyContent: "center",
							}}
						>
							<Box sx={{ position: "relative" }}>
								<Box
									component="img"
									sx={{
										display: "block",
										height: "100%",
										maxHeight: "400px",
										maxWidth: { xs: "100%", md: "400px" },
										borderRadius: "50%",
										transform: "translate(0, 0%)",
									}}
									alt="The house from the offer."
									src={map}
									loading="lazy"
								/>
								<Box
									sx={{
										position: "absolute",
										width: "60px",
										height: "60px",
										top: 15,
										left: 45,
										backgroundColor: "rgb(0, 83, 156)",
										borderRadius: "50%",
									}}
								/>
							</Box>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={7}
						sx={{ display: "flex", alignItems: "center" }}
					>
						<Stack>
							<Typography
								sx={{
									color: "rgb(139, 221, 249)",
									textTransform: "uppercase",
									fontWeight: "700",
									marginBottom: "8px",
									fontSize: { xs: "14px", md: "16px" },
								}}
							>
								CRIME RATE MAPS FROM ADT
							</Typography>
							<Typography
								sx={{
									fontSize: { xs: "36px", md: "56px" },
									marginBottom: "16px",
								}}
							>
								Track <strong>Crime Near You</strong>
							</Typography>
							<Typography sx={{ marginBottom: { xs: "16px", md: "0" } }}>
								Explore our interactive crime map to stay informed about local
								crime trends and police activity in your area. Search by zip
								code or address, and filter by crime type to see how your
								community is affected. Use the color-coded map to identify areas
								with below or above-average crime rates and make informed
								decisions about your home security.
							</Typography>
							<Divider
								sx={{ marginTop: "24px", display: { xs: "none", md: "block" } }}
							></Divider>
							<Box
								sx={{
									display: { xs: "none", md: "flex" },
									alignItems: "center",
								}}
							>
								<Button
									variant="text"
									className="home-free-quote-btn"
									sx={{
										color: "rgb(0,83,156)",
										borderRadius: "30px",
										height: "45px",
										maxHeight: "45px",
										fontSize: "12px",
										fontWeight: "700",
										letterSpacing: "1px",
										textTransform: "capitalize",
									}}
									endIcon={
										<EastIcon
											id="btn-arrow-slide-right"
											sx={{ height: "14px", paddingBottom: "1px" }}
										/>
									}
								>
									LEARN MORE
								</Button>
							</Box>
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
