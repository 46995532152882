import { Box, Container, Typography } from '@mui/material';
import './index.scss';
import { Helmet } from 'react-helmet';

export default function TermsAndConditionsADT() {
  return (
    <>
      <Helmet>
        <title>ADT Security Services: Terms & Conditions | Safe Haven</title>
        <meta
          name="description"
          content="Understand the terms and conditions governing your ADT security service contract."
        />
        <meta
          name="keywords"
          content="ADT terms and conditions, ADT security contract, ADT monitoring agreement, home security terms and conditions, security system contract, ADT monitoring fees, ADT early termination fee, ADT service cancellation, ADT warranty policy, ADT homeowner's insurance discount"
        />
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          maxWidth: '100vw',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >

        <Container
          maxWidth='md'
          className='privacy-policy-container'
          sx={{
            backgroundColor: '#ffffff',
            paddingBottom: '70px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#ffffff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography className='title' variant='h6' sx={{ marginBottom: '16px' }}
            >
              Terms & Conditions for ADT Security Services
            </Typography>
          </Box>
          <Typography variant='body2'>Last updated May 08, 2024</Typography>

          <Typography className='title' variant='h6'>
            Standard Disclaimers for Residential Customers
          </Typography>
          <Typography className='sub-title' variant='body2'>
            Traditional w/ Fire
          </Typography>
          <Typography variant='body2'>
            $99.00 Customer Installation Charge. 36-Month Monitoring Agreement required at $45.99 per month ($1,655.64). 24-Month Monitoring Agreement required at $45.99 per month ($1,103.76) for California. Early termination fee applies. Form of payment must be by credit card or electronic charge to your checking or savings account. Offer applies to homeowners only. Local permit fees may be required. Satisfactory credit history required. Certain restrictions may apply. Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC. Other rate plans available. Cannot be combined with any other offer. Photos are for illustrative purposes only and may not reflect the exact product/service actually provided.
          </Typography>

          <Typography className='title' variant='h6'>
            ADT Command + Control Disclaimers for Residential Customers
          </Typography>
          <Typography variant='body2'>
            ADT Command Interactive Services, which help you manage your home environment and family lifestyle, requires the purchase and/or activation of an ADT-monitored alarm system with monitored burglary service and a compatible computer, cell phone or PDA with Internet and email access. These ADT Command Interactive Solutions Services do not cover the operation or maintenance of any household equipment/systems that are connected to the ADT Command Interactive Solutions Services/Equipment. All ADT Command Interactive Solutions Services are not available with the various levels of ADT Command Interactive Solutions Services. All ADT Command Interactive Solutions Services may not be available in all geographic areas. Standard message and data rates may apply to text alerts. You may be required to pay additional charges to purchase equipment required to utilize the ADT Command Interactive Solutions Services features you desire.
          </Typography>
          <Typography className='sub-title' variant='body2'>
            Remote $55.99
          </Typography>
          <Typography variant='body2'>
            $199.00 Customer Installation Charge. 36-Month Monitoring Agreement required at $55.99 per month ($2,015.64). 24-Month Monitoring Agreement required at $55.99 per month ($1,343.76) for California. Form of payment must be by credit card or electronic charge to your checking or savings account. Early termination fees apply. Offer applies to homeowners only. Local permit fees may be required. Satisfactory credit history required. Certain restrictions may apply. Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC. Other rate plans available. Cannot be combined with any other offer. ADT Command Interactive Services, which help you manage your home environment and family lifestyle, requires the purchase and/or activation of an ADT alarm system with monitored burglary service and a compatible computer, cell phone or PDA with Internet and email access. These ADT Command Interactive Solutions Services do not cover the operation or maintenance of any household equipment/systems that are connected to the ADT Command Interactive Solutions Services/Equipment. All ADT Command Interactive Solutions Services are not available with the various levels of ADT Command Interactive Solutions Services. All ADT Command Interactive Solutions Services may not be available in all geographic areas. Standard message and data rates may apply to text alerts. You may be required to pay additional charges to purchase equipment required to utilize the ADT Pulse Interactive Solutions Services features you desire.
          </Typography>
          <Typography className='sub-title' variant='body2'>
            Control $63.99
          </Typography>
          <Typography variant='body2'>
            $399.00 Customer Installation Charge. 36-Month Monitoring Agreement required at $63.99 per month ($2,303.64). 24-Month Monitoring Agreement required at $63.99 per month ($1,535.76) for California. Form of payment must be by credit card or electronic charge to your checking or savings account. Early termination fees apply. Offer applies to homeowners only. Local permit fees may be required. Satisfactory credit history required. Certain restrictions may apply. Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC. Other rate plans available. Cannot be combined with any other offer. ADT Command Interactive Services, which help you manage your home environment and family lifestyle, requires the purchase and/or activation of an ADT alarm system with monitored burglary service and a compatible computer, cell phone or PDA with Internet and email access. These ADT Command Interactive Solutions Services do not cover the operation or maintenance of any household equipment/systems that are connected to the ADT Command Interactive Solutions Services/Equipment. All ADT Command Interactive Solutions Services are not available with the various levels of ADT Command Interactive Solutions Services. All ADT Command Interactive Solutions Services may not be available in all geographic areas. Standard message and data rates may apply to text alerts. You may be required to pay additional charges to purchase equipment required to utilize the ADT Pulse Interactive Solutions Services features you desire.
          </Typography>
          <Typography className='sub-title' variant='body2'>
            Video Lite $63.99
          </Typography>
          <Typography variant='body2'>
            $399.00 Customer Installation Charge. 36-Month Monitoring Agreement required at $63.99 per
            month ($2,303.64). 24-Month Monitoring Agreement required at $63.99 per month ($1,535.76) for California. Form of payment must be by credit card or electronic charge to your checking or savings account. Early termination fees apply. Offer applies to homeowners only. Local permit fees may be required. Satisfactory credit history required. Certain restrictions may apply. Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC. Other rate plans available. Cannot be combined with any other offer. ADT Command Interactive Services, which help you manage your home environment and family lifestyle, requires the purchase and/or activation of an ADT alarm system with monitored burglary service and a compatible computer, cell phone or PDA with Internet and email access. These ADT Command Interactive Solutions Services do not cover the operation or maintenance of any household equipment/systems that are connected to the ADT Command Interactive Solutions Services/Equipment. All ADT Command Interactive Solutions Services are not available with the various levels of ADT Command Interactive Solutions Services. All ADT Command Interactive Solutions Services may not be available in all geographic areas. Standard message and data rates may apply to text alerts. You may be required to pay additional charges to purchase equipment required to utilize the ADT Pulse Interactive Solutions Services features you desire.
          </Typography>
          <Typography className='sub-title' variant='body2'>
            Video & Home Automation $65.99
          </Typography>
          <Typography variant='body2'>
            $499.00 Customer Installation Charge. 36-Month Monitoring Agreement required at $65.99 per month ($2,375.64). 24-Month Monitoring Agreement required at $65.99 per month ($1,583.76) for California. Form of payment must be by credit card or electronic charge to your checking or savings account. Early termination fees apply. Offer applies to homeowners only. Local permit fees may be required. Satisfactory credit history required. Certain restrictions may apply. Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC. Other rate plans available. Cannot be combined with any other offer. ADT Command Interactive Services, which help you manage your home environment and family lifestyle, requires the purchase and/or activation of an ADT alarm system with monitored burglary service and a compatible computer, cell phone or PDA with Internet and email access. These ADT Command Interactive Solutions Services do not cover the operation or maintenance of any household equipment/systems that are connected to the ADT Command Interactive Solutions Services/Equipment. All ADT Command Interactive Solutions Services are not available with the various levels of ADT Command Interactive Solutions Services. All ADT Command Interactive Solutions Services may not be available in all geographic areas. Standard message and data rates may apply to text alerts. You may be required to pay additional charges to purchase equipment required to utilize the ADT Pulse Interactive Solutions Services features you desire.
          </Typography>

          <Typography className='title' variant='h6'>
            Disclaimers for Small Business Customers
          </Typography>
          <Typography className='sub-title' variant='body2'>
            Premise Secured (SMB Secure) $49.99
          </Typography>
          <Typography variant='body2'>
            $249.00 Customer Installation Charge. 36-Month Monitoring Agreement required at $49.99 per month ($1,799.64). 24-Month Monitoring Agreement required at $49.99 per month ($1,199.76) for California. Form of payment must be by credit card or electronic charge to your checking or savings account. Early termination fees apply. Local permit fees may be required. Satisfactory credit history required. Certain restrictions may apply. Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC. Other rate plans available. Cannot be combined with any other offer. Standard message and data rates may apply to text alerts. You may be required to pay additional charges to purchase equipment required to utilize the ADT Command Interactive Solutions Services features you desire.
          </Typography>
          <Typography className='sub-title' variant='body2'>
            Premise Remote (SMB Interactive) $55.99
          </Typography>
          <Typography variant='body2'>
            $399.00 Customer Installation Charge. 36-Month Monitoring Agreement required at $55.99 per month ($2,015.64). 24-Month Monitoring Agreement required at $55.99 per month ($1,343.76) for California. Form of payment must be by credit card or electronic charge to your checking or savings account. Early termination fees apply. Offer applies to business owners only. Local permit fees may be required. Satisfactory credit history required. Certain restrictions may apply. Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC. Other rate plans available. Cannot be combined with any other offer. ADT Command Interactive Services, which help you manage your business surveillance and automation, requires the purchase and/or activation of an ADT-monitored alarm system with monitored burglary service and a compatible computer, cell phone or PDA with Internet and email access. All ADT Command Interactive Solutions Services are not available with the various levels of ADT Command Interactive Solutions Services. All ADT Command Interactive Solutions Services may not be available in all geographic areas. Standard message and data rates may apply to text alerts. You may be required to pay additional charges to purchase equipment required to utilize the ADT Command Interactive Solutions Services features you desire.
          </Typography>
          <Typography className='sub-title' variant='body2'>
            Enterprise View $61.99
          </Typography>
          <Typography variant='body2'>
            $499.00 Customer Installation Charge. 36-Month Monitoring Agreement required at $61.99 per month ($2,231.64). 24-Month Monitoring Agreement required at $61.99 per month ($1,487.76) for California. Form of payment must be by credit card or electronic charge to your checking or savings account. Early termination fees apply. Offer applies to business owners only. Local permit fees may be required. Satisfactory credit history required. Certain restrictions may apply. Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC. Other rate plans available. Cannot be combined with any other offer. ADT Command Interactive Services, which help you manage your business surveillance and automation, requires the purchase and/or activation of an ADT-monitored alarm system with monitored burglary service and a compatible computer, cell phone or PDA with Internet and email access. All ADT Command Interactive Solutions Services are not available with the various levels of ADT Command Interactive Solutions Services. All ADT Command Interactive Solutions Services may not be available in all geographic areas. Standard message and data rates may apply to text alerts. You may be required to pay additional charges to purchase equipment required to utilize the ADT Command Interactive Solutions Services features you desire.
          </Typography>
          <Typography className='sub-title' variant='body2'>
            Total Productivity (SMB Complete) $65.99
          </Typography>
          <Typography variant='body2'>
            $599.00 Customer Installation Charge. 36-Month Monitoring Agreement required at $65.99 per month ($2,375.64). 24-Month Monitoring Agreement required at $65.99 per month ($1,583.76) for California. Form of payment must be by credit card or electronic charge to your checking or savings account. Early termination fees apply. Offer applies to business owners only. Local permit fees may be required. Satisfactory credit history required. Certain restrictions may apply. Offer valid for new ADT Authorized Dealer customers only and not on purchases from ADT LLC. Other rate plans available. Cannot be combined with any other offer. ADT Command Interactive Services, which help you manage your business surveillance and automation, requires the purchase and/or activation of an ADT-monitored alarm system with monitored burglary service and a compatible computer, cell phone or PDA with Internet and email access. All ADT Command Interactive Solutions Services are not available with the various levels of ADT Command Interactive Solutions Services. All ADT Command Interactive Solutions Services may not be available in all geographic areas. Standard message and data rates may apply to text alerts. You may be required to pay additional charges to purchase equipment required to utilize the ADT Command Interactive Solutions Services features you desire.
          </Typography>

          <Typography className='title' variant='h6'>
            General
          </Typography>
          <Typography variant='body2'>
            Additional charges may apply in areas that require guard response service for municipal alarm verification. Local permit fees may be required. Prices and offers subject to change and may vary by market. Additional taxes and fees may apply. Satisfactory credit required. A security deposit may be required. Simulated screen images and photos are for illustrative purposes only.
          </Typography>
        </Container>
      </Box>
    </>
  );
}
