import { ReactElement } from "react";
import HomeIcon from "@mui/icons-material/Home";

interface NavItem {
  label: string;
  path: string;
  value: string;
  subMenuItems?: any;
}

export const navItems: NavItem[] = [
  // {
  //   label: "Partners",
  //   path: "/partners",
  //   value: "partners",
  //   subMenuItems: [
  //     { label: "Agent Referral", path: "/partners/agent-referral" },
  //     { label: "Homebuilder Partners", path: "/partners/homebuilder-partners" },
  //   ],
  // },
  // {
  //   label: "Customers",
  //   path: "",
  //   value: "customers",
  //   subMenuItems: [
  //     { label: "Customer Solutions", path: "/customer-solutions" },
  //     { label: "Customer Support", path: "/customer-support" },
  //   ],
  // },
  {
    label: "Story",
    path: "/about-us",
    value: "/about-us",
  },
  {
    label: "Careers",
    path: "/careers",
    value: "/careers",
    // subMenuItems: [
    //   { label: "Realtor Team", path: "/careers/realtor-team" },
    // ],
  },
  {
    label: "Products",
    path: "/?scroll=products",
    value: "/products",
  },
  {
    label: "Departments",
    path: "",
    value: "/departments",
    subMenuItems: [
      { label: "Solar", path: "/solar" },
      { label: "Builder", path: "/partners/homebuilder-partners" },
      { label: "Affiliate", path: "/partners" },
      { label: "Realtor", path: "/partners/agent-referral" },
      ,
      { label: "PinPoint", path: "/customer-solutions" },
      { label: "Technicians", path: "/installers" },
    ],
  },
  {
    label: "Contact",
    path: "/contact-us",
    value: "/contact-us",
  },
  {
    label: "Support",
    path: "",
    value: "/customer-support",
    subMenuItems: [
      { label: "Customer Support", path: "/customer-support" },
      {
        label: "Verify Rep",
        path: "/customer-support/?scroll=verifyRep",
      },
    ],
  },
  // {
  //   label: "Resources",
  //   path: "/careers",
  //   value: "/resources",
  // },
  // {
  //   label: "Community",
  //   path: "/about-us",
  //   value: "/community",
  // },
];
