import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Button, Link, Autocomplete, FormControl, Slide, Modal, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Container, Stack } from '@mui/system';
import { TextField } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "./index.scss";
import LoadingButton from '@mui/lab/LoadingButton';
import useFetch from 'use-http';
import { useEffect, useState } from 'react';
import './index.scss';
import { STATE_LIST } from '../../../Constants';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';

// /partners/drb-greenville

export default function HomeIsConnectedFormNew() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleNavigate = (route: string, location: string | null = null) => {
    if (location === '_blank') {
      window.open(route, location);
    } else {
      navigate(route);
    }
  };
  const [value, setValue] = useState<string | null>(STATE_LIST[0]);
  const [inputValue, setInputValue] = useState('');
  const [sendEmailResult, setSendEmailResult] = useState();
  const [closingDate, setClosingDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    console.log(pathname)
  }, []);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  let apiKey = process.env.REACT_APP_API__ACCESS_KEY
    ? process.env.REACT_APP_API__ACCESS_KEY
    : '';

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Connection: 'keep-alive',
      'Accept-Encoding': 'gzip, deflate, br',
      'X-API-Key': apiKey,
    },
  };

  const { post, response, loading, error } = useFetch(
    process.env.REACT_APP_API,
    options
  );

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    secondaryPhone: "",
    expectedClosingDate: null,
    customerNotes: "",
    email: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required')
      .min(2, 'First name must be at least 2 characters')
      .max(50, 'First name must be at most 50 characters')
      .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
    lastName: Yup.string().required('Required')
      .min(2, 'Last name must be at least 2 characters')
      .max(50, 'Last name must be at most 50 characters')
      .matches(/^[a-zA-Z '-]+$/, 'Last name can only contain letters, hyphens, apostrophes, and spaces'),
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format').required('Required'),
    phone: Yup.string().required('Required')
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
    secondaryPhone: Yup.string().required('Required')
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
    expectedClosingDate: Yup.date()
      .required("Required"),
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
  });

  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 1 },
        '& .MuiTextarea-root': { m: 1 },
        '& .quarter-width': { width: { xs: 'calc(100% - 16px)', md: 'calc(25% - 16px)' } },
        '& .quarter-width-2': { width: { xs: 'calc(100%)', md: 'calc(25% - 16px)' }, maxWidth: { xs: 'calc(100%)', md: 'calc(25% - 16px)' } },
        '& .half-width': { width: { xs: 'calc(100% - 16px)', sm: 'calc(50% - 16px)' }, maxWidth: { xs: 'calc(100% - 16px)', sm: 'calc(50% - 16px)' } },
        '& .full-width': { width: 'calc(100% - 16px)' },
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const today = new Date().toLocaleString();

            let recipients = 'DRHortonQR@mysafehaven.com';
            //let recipients = 'codey.bower@mysafehaven.com';
            const subject = `New Submission From ${pathname === '/partners/drb-greenville' ? 'DRB Greenville' : 'DR Horton QR'} Page For: ${values.firstName} ${' '} ${values.lastName}`;
            const content = `
              <table style="border-collapse: collapse; width: 100%; max-width: 600px; font-family: sans-serif;">
              <thead style="background-color: #f2f2f2;">
                <tr>
                  <th colspan="2" style="padding: 10px; text-align: left;">Lead Information From  ${pathname === '/partners/drb-greenville' ? 'DRB Greenville' : 'DR Horton QR'} page </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">Client Name</td>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">${values.firstName} ${values.lastName}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd;">Phone</td>
                  <td style="padding: 10px; border: 1px solid #ddd;">${values.phone}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">Secondary Phone</td>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">${values.secondaryPhone}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd;">Street Address</td>
                  <td style="padding: 10px; border: 1px solid #ddd;">${values.address}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">City</td>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">${values.city}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd;">State</td>
                  <td style="padding: 10px; border: 1px solid #ddd;">${values.state}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">Zip</td>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">${values.zip}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd;">Email</td>
                  <td style="padding: 10px; border: 1px solid #ddd;">${values.email}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">Expected Closing Date</td>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">${values.expectedClosingDate}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd;">Notes</td>
                  <td style="padding: 10px; border: 1px solid #ddd;">${values.customerNotes}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">Submission Date</td>
                  <td style="padding: 10px; border: 1px solid #ddd; background-color: #f9f9f9;">${today}</td>
                </tr>
              </tbody>
            </table>        
              `;

            let emailReqBody = {
              to: recipients,
              subject: subject,
              content: content,
            }

            const sendEmail = async () => {
              const postResponse = await post(`/Email/SendEmail`, emailReqBody);

              if (postResponse) {
                console.log("Request sent successfully:", postResponse);
                resetForm();
                setModalOpen(true);
              }
            };

            sendEmail().then((res: any) => setSendEmailResult(res));

            // Short delay for UE (e.g., 1000ms)
            await new Promise(resolve => setTimeout(resolve, 1000));

          } catch (error) {
            console.error("Error sending request:", error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
          <Form>
            <Box className="" sx={{}}>
              <Field
                as={TextField}
                className="half-width"
                name="firstName"
                label="First Name*"
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <Field
                as={TextField}
                className="half-width"
                name="lastName"
                label="Last Name*"
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />

              <Field
                as={TextField}
                className="half-width"
                name="phone"
                label="Phone*"
                onChange={(event: { target: { value: any; }; }) => {
                  const inputValue = event.target.value.replace(/\D/g, '');

                  let formattedValue = '';
                  if (inputValue.length > 0) {
                    formattedValue = '(' + inputValue.substring(0, 3);
                    if (inputValue.length > 3) {
                      formattedValue += ') ' + inputValue.substring(3, 6);
                    }
                    if (inputValue.length > 6) {
                      formattedValue += '-' + inputValue.substring(6, 10);
                    }
                  }

                  setFieldValue('phone', formattedValue);
                }}
                onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                  const clipboardData = event.clipboardData;
                  const pastedData = clipboardData.getData('text');
                  const cleanedData = pastedData.replace(/\D/g, '');
                  const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                  setFieldValue('phone', formattedValue);
                  event.preventDefault();
                }}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
              />

              <Field
                as={TextField}
                className="half-width"
                name="secondaryPhone"
                label="Secondary Phone*"
                onChange={(event: { target: { value: any; }; }) => {
                  const inputValue = event.target.value.replace(/\D/g, '');

                  let formattedValue = '';
                  if (inputValue.length > 0) {
                    formattedValue = '(' + inputValue.substring(0, 3);
                    if (inputValue.length > 3) {
                      formattedValue += ') ' + inputValue.substring(3, 6);
                    }
                    if (inputValue.length > 6) {
                      formattedValue += '-' + inputValue.substring(6, 10);
                    }
                  }

                  setFieldValue('secondaryPhone', formattedValue);
                }}
                onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                  const clipboardData = event.clipboardData;
                  const pastedData = clipboardData.getData('text');
                  const cleanedData = pastedData.replace(/\D/g, '');
                  const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                  setFieldValue('secondaryPhone', formattedValue);
                  event.preventDefault();
                }}
                error={touched.secondaryPhone && Boolean(errors.secondaryPhone)}
                helperText={touched.secondaryPhone && errors.secondaryPhone}
              />

              <Field
                as={TextField}
                className="half-width"
                name="email"
                label="Email*"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />

              <Field
                as={TextField}
                sx={{ minHeight: '65px' }}
                className='half-width'
                name="address"
                label="Address *"
                value={values.address}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("address", event.target.value)}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
              />

              <Field
                as={TextField}
                className='quarter-width'
                name="city"
                label="City *"
                sx={{ minHeight: '65px' }}
                value={values.city}
                onChange={(event: { target: { value: any; }; }) => setFieldValue("city", event.target.value)}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />

              <Autocomplete
                disablePortal
                className={`quarter-width-2`}
                sx={{ display: 'inline-flex', margin: "0 0 8px 0!important", minHeight: '65px' }}
                options={STATE_LIST}
                inputValue={values.state}
                onInputChange={(event, newInputValue) => {
                  setFieldValue("state", newInputValue);
                }}

                renderInput={params => (
                  <TextField
                    sx={{ marginLeft: '0px' }}
                    {...params}
                    className=''
                    name="state"
                    label="State *"
                    error={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                )}
              />

              <Field
                as={TextField}
                className="quarter-width"
                name="zip"
                label="Zip*"
                error={touched.zip && Boolean(errors.zip)}
                helperText={touched.zip && errors.zip}
              />

              <FormControl
                className='quarter-width-2'
                sx={{
                  width: '100%',
                  minHeight: '65px',
                  "& div>label": {
                    color: (touched.expectedClosingDate && Boolean(errors.expectedClosingDate)) ? '#d32f2f!important' : 'rgba(0, 0, 0, 0.6)',
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    className='full-width'
                    sx={{
                      "& div>div>button>svg": {
                        zIndex: 9,
                        color: (touched.expectedClosingDate && Boolean(errors.expectedClosingDate)) ? '#d32f2f!important' : (touched.expectedClosingDate && !Boolean(errors.expectedClosingDate)) ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.6)',
                      },
                      "& div, p.MuiFormHelperText-root": {
                        color: (touched.expectedClosingDate && Boolean(errors.expectedClosingDate)) ? '#d32f2f!important' : 'rgba(0, 0, 0, 0.87)',
                      },
                      "& div>fieldset": {
                        borderColor: (touched.expectedClosingDate && Boolean(errors.expectedClosingDate)) ? '#d32f2f!important' : 'rgba(0, 0, 0, 0.23)',
                      },
                      "& div>fieldset:hover": {
                        borderColor: (touched.expectedClosingDate && Boolean(errors.expectedClosingDate)) ? '#d32f2f!important' : 'rgba(0, 0, 0, 0.23)',
                      },
                    }}
                    slotProps={{
                      textField: {
                        helperText: (touched.expectedClosingDate && errors.expectedClosingDate),
                        onBlur: (value) => {
                          setFieldTouched('expectedClosingDate', true);
                        },
                      },
                    }}
                    onAccept={(newValue) => {
                      console.log("onAccept: ", newValue);
                      setFieldValue("expectedClosingDate", newValue);
                      console.log("values.expectedClosingDate: ", values.expectedClosingDate);
                    }}
                    disablePast
                    label="Expected Closing Date"
                    format="MM/DD/YYYY"
                    value={values.expectedClosingDate}
                    onChange={(value) => {
                      console.log(value)
                      setFieldValue("expectedClosingDate", value);
                      console.log("values.expectedClosingDate2: ", values.expectedClosingDate);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>

              <Box sx={{ marginTop: '0' }}>
                <Field
                  as={TextField}
                  name="customerNotes"
                  label="Here you can put any additional notes that you would like for the concierge team/installers to know."
                  className="full-width"
                  multiline
                  rows={4}
                  sx={{
                    '& div>textarea': {
                      zIndex: 99
                    },
                  }}
                  value={values.customerNotes}
                  onChange={(event: { target: { value: any; }; }) => setFieldValue("customerNotes", event.target.value)}
                  error={touched.customerNotes && Boolean(errors.customerNotes)}
                  helperText={touched.customerNotes && errors.customerNotes}
                />
              </Box>

              <Box sx={{ textAlign: "center", width: "100%" }}>
                <Typography sx={{
                  textAlign: 'center',
                  fontSize: '12px',
                  lineHeight: '16px',
                  maxWidth: '950px',
                  margin: '16px auto auto auto',
                }}>
                  By clicking ‘Submit,’ I consent to being contacted by Safe Haven Security Services, LLC, to receive additional information on special discounted ADT monitoring rates for DRB Homes buyers exclusively through Safe Haven. I can revoke this consent by emailing dnc@mysafehaven.com.
                </Typography>
                <LoadingButton
                  className="contact-form-submit"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  loadingIndicator={<CircularProgress className='loading-button-indicator' size={24} />}
                  disabled={isSubmitting}
                  sx={{
                    marginTop: '16px',
                    maxHeight: '56px',
                    minWidth: '100px',
                    fontSize: '16px',
                    textTransform: 'none',
                    fontWeight: '700',
                  }}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </LoadingButton>
                <Modal
                  open={modalOpen}
                  closeAfterTransition
                  onClose={handleModalClose}
                  className=''
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Slide direction='up' in={modalOpen}>
                    <Container
                      maxWidth={false}
                      sx={{
                        maxWidth: '700px',
                        minHeight: '400px',
                        height: 'auto',
                        bgcolor: '#fff',
                        borderRadius: '9px',
                        boxShadow: 24,
                        padding: '0!important'
                      }}
                    >
                      <Box sx={{ background: 'rgb(18, 96, 168)', width: '100%', height: '64px', borderRadius: '9px 9px 0 0px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', color: '#fff' }}>
                          Thank you for contacting Safe Haven!
                        </Typography>
                        <Box sx={{
                          position: "absolute",
                          right: "5px",
                        }}>
                          <IconButton
                            sx={{}}
                            onClick={() => { handleModalClose() }}
                          >
                            <ClearIcon sx={{ color: '#fff' }}></ClearIcon>
                          </IconButton>
                        </Box>
                      </Box>
                      <Stack
                        direction={'row'}
                        sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
                      >

                        <Box
                          sx={{
                            paddingLeft: '36px',
                            minHeight: '350px',
                            minWidth: '250px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Box>
                            <Box sx={{ width: '100%', display: 'flex' }}>
                              <CheckCircleOutlineIcon sx={{ color: 'rgb(18, 96, 168)', margin: 'auto', height: '4rem', width: 'auto', marginBottom: '15px' }}></CheckCircleOutlineIcon>
                            </Box>

                            <Typography sx={{ fontSize: '24px', maxWidth: '600px', textAlign: 'center' }}>
                              {
                                pathname === '/partners/drb-greenville' && (
                                  <>
                                    We have received your request and a Concierge Team Member will be in contact shortly.
                                  </>
                                )
                              }
                              {pathname === '/partners/home-is-connected' && 'We received your information and will be in touch shortly to discuss your Home is Connected® system for your new D.R. Horton home.'}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '24px 0' }}>
                              <Button
                                variant={'contained'}
                                onClick={() => {
                                  handleNavigate('/');
                                }}
                                sx={{
                                  minWidth: '100px',
                                  minHeight: '45px',
                                  fontSize: '16px',
                                  textTransform: 'none',
                                  fontWeight: '700',
                                }}
                              >
                                Back To Home
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Stack>
                    </Container>
                  </Slide>
                </Modal>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}